// -- App.js

import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css"; // <-- Ensure this line exists at the top of your App.js file
import AboutPage from "./pages/public/AboutPage";
import Home from "./pages/public/Home";
import InvitesPage from "./pages/public/InvitesPage";
import MembershipPage from "./pages/public/MembershipPage";
import ServicesPage from "./pages/public/ServicesPage";

import EmployeeLoginPage from "./pages/EmployeeLoginPage";
import MemberSignUpPage from "./pages/memberFlow/MemberSignUpPage";
import ContactPage from "./pages/public/ContactPage";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Navigate } from "react-router-dom";
import EnvironmentInfo from "./components/EnvironmentInfo";
import GeneralSignUp from "./components/GeneralSignUp";
import GuestHeader from "./components/GuestHeader";
import ScrollToTop from "./components/ScrollToTop";
import GeneralLogin from "./components/login/GeneralLogin";
import WheelPopup from "./components/popup/WheelPopup";
import AdCopyPage from "./pages/AdCopyPage";
import CreateEmployeePage from "./pages/CreateEmployeePage";
import CreateMemberAndAccountPage from "./pages/CreateMemberAndAccountPage";
import DashboardPage from "./pages/DashboardPage";
import EmployeeSignUpPage from "./pages/EmployeeSignUpPage";
import LeadFunnelPage from "./pages/LeadFunnelPage";
import HiringPage from "./pages/public/HiringPage";
import ParentLanding from "./pages/public/ParentLanding";
import PrivacyPolicyPage from "./pages/public/PrivacyPolicyPage";
import ReschedulePublicPage from "./pages/public/ReschedulePublicPage";
import ReviewsPage from "./pages/public/ReviewsPage";
import TaskSearchPage from "./pages/public/TaskSearchPage";
import TermsAndConditionsPage from "./pages/public/TermsAndConditionsPage";
import WebFooter from "./pages/public/sections/WebFooter";
import { SnackbarProvider } from "./providers/SnackbarProvider";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/senior-help/:cityState" element={<Home />} />
        <Route path="/:cityState" element={<Home />} />
        <Route path="/pricing" element={<MembershipPage />} />
        <Route path="/taskmom" element={<ParentLanding />} />

        <Route path="/task-search" element={<TaskSearchPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/get-started" element={<LeadFunnelPage />} />
        <Route path="/ad-copy" element={<AdCopyPage />} />

        <Route path="/signup" element={<GeneralSignUp />} />
        <Route path="/login" element={<GeneralLogin />} />
        <Route path="/spin-to-win" element={
          <>
          <GuestHeader />
          <WheelPopup fullScreen={true} />
          <WebFooter />
          </>
          } />

        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route
          path="/member/create-account"
          element={
            <SnackbarProvider>
              <CreateMemberAndAccountPage />
            </SnackbarProvider>
          }
        />
        <Route path="/env" element={<EnvironmentInfo />} />

        <Route path="/member/signup" element={<MemberSignUpPage />} />
        <Route path="/member/login" element={<GeneralLogin />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/hiring" element={<HiringPage />} />
        <Route path="/invites" element={<InvitesPage />} />
        <Route path="/reschedule" element={<ReschedulePublicPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />

        {/* <Route path="/register" element={<RegisterPage />} /> */}

        {/* Test routes */}
        <Route path="/employee/signup" element={<EmployeeSignUpPage />} />
        <Route path="/employee/login" element={<EmployeeLoginPage />} />

        <Route
          path="/employee/create-account"
          element={<CreateEmployeePage />}
        />

        <Route path="/dashboard" element={<DashboardPage />} />

        {/* Redirects */}
        <Route path="/about-us" element={<Navigate to="/about" replace />} />
        <Route
          path="/membership"
          element={<Navigate to="/pricing" replace />}
        />
        <Route
          path="/household-help"
          element={<Navigate to="/senior-help" replace />}
        />
        <Route
          path="/services"
          element={<Navigate to="/senior-services" replace />}
        />
        <Route
          path="/job-openings/link-employee"
          element={<Navigate to="/hiring" replace />}
        />

        {/* Redirect if page not found */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
