import {
  Box,
  Card,
  Grid,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  CardGiftcard,
  Event,
  LocalActivity,
  Podcasts,
  RateReview,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import moment from "moment-timezone";
import React, { useRef, useState } from "react";
import { useMember } from "../../../providers/MemberProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";

import AnnouncementCard from "../../../components/AnnouncementCard";
import AnnouncementContent from "../../../components/AnnouncementContent";
import DiscountsBanner from "../../../components/DiscountsBanner";
import HomeCard from "../../../components/HomeCard";
import HomeCardHeader from "../../../components/HomeCardHeader";
import NewMemberChecklist from "../../../components/NewMemberChecklist";
import PushDrawer from "../../../components/PushDrawer";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";
import ReviewVisitMember from "../../../components/ReviewVisitMember";
import ShareFriends from "../../../components/ShareFriends";
import VisitCard from "../../../components/VisitCard";
import VisitListItem from "../../../components/VisitListItem";
import WaitlistCard from "../../../components/WaitlistCard";
import EventPreviewList from "../../../components/events/EventPreviewList";
import ReferralsList from "../../../components/referralsList";
import ContentHeader from "../../ContentHeader";

const HomePage = () => {
  const { data, setData, isServiceArea, visitsLoading, nextVisit, prevVisit } =
    useMember();
  const { showSnackbar } = useSnackbar();
  const [view, setView] = useState("list"); // This state manages which view is displayed
  const [visits, setVisits] = useState([]);
  const [calendarView, setCalendarView] = useState("week");
  const loadedVisitIds = useRef(new Set());
  const timeZoneId = data.member.timeZoneId;
  const [calendarDate, setCalendarDate] = useState(moment());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [stars, setStars] = useState(0);

  const [showNext, setShowNext] = useState();
  const [showPrev, setShowPrev] = useState();
  const [showAnnouncements, setShowAnnouncements] = useState();
  const [showShare, setShowShare] = useState();

  console.log("isServiceArea: ", isServiceArea);

  // We need to get their status
  const noLocationSet = data.member.location === undefined;
  const checklistComplete = data?.member?.checklistComplete !== undefined;
  console.log(
    "data?.member?.checklistComplete: ",
    data?.member?.checklistComplete
  );

  console.log("checklist Complete: ", checklistComplete);

  const renderHomePage = () => {
    return (
      <>
        {!isServiceArea && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                variant="outlined"
                sx={{
                  padding: { xs: 2, sm: 4 },
                  position: "relative",
                  // Ensure the card stretches to full height of the container
                }}
              >
                <WaitlistCard />
              </Card>
            </Grid>
          </Grid>
        )}
        {isServiceArea && checklistComplete && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Stack gap={2}>
                {/* Next visit */}
                <HomeCard
                  disabled={!nextVisit}
                  onClick={() => setShowNext(true)}
                >
                  <HomeCardHeader
                    title={nextVisit ? "Upcoming Visit" : "Schedule a visit"}
                    hideIcon={!nextVisit}
                    subtitle={
                      nextVisit
                        ? `${
                            isMobile ? "Tap" : "Click"
                          } to view details, reschedule, cancel, and more`
                        : `${
                            isMobile
                              ? "Tap the blue plus button to get started"
                              : "Click the blue 'Book a Visit' button in the lower right."
                          }`
                    }
                    IconComponent={Event}
                    isMobile={isMobile}
                  />
                  {nextVisit && (
                    <VisitListItem
                      visit={nextVisit}
                      ref={null}
                      compact={true}
                    />
                  )}
                </HomeCard>

                {/* Prev visit */}
                {!prevVisit?.rating && prevVisit && (
                  <HomeCard onClick={() => setShowPrev(true)}>
                    <HomeCardHeader
                      title="Review"
                      subtitle={
                        (isMobile ? "Tap" : "Click") +
                        " to review your last visit"
                      }
                      IconComponent={RateReview}
                      isMobile={isMobile}
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      zIndex="100"
                      sx={{ pt: 1 }}
                    >
                      <Rating
                        size="large"
                        value={stars}
                        name="visit-rating"
                        icon={<StarRounded fontSize="inherit" />}
                        emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                        sx={{ fontSize: "3rem" }} // Adjust the size as needed
                        onChange={(event, newStars) => {
                          setStars(newStars);
                          setShowPrev(true);
                        }}
                        precision={1}
                      />
                    </Box>
                    {/* <ReviewVisitMember
            visit={prevVisit}
            visitId={prevVisit?.id}
            handleClose={() => setShowPrev(false)}
          /> */}
                  </HomeCard>
                )}

                {/* Local events */}
                {data?.events && Object.keys(data?.events).length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: "15px",
                      border: `1px solid rgba(0, 0, 0, 0.12)`,

                      bgcolor: "white",
                    }}
                    // onClick={nextVisit ? () => setShowNext(true) : null}
                  >
                    <Box sx={{ pt: 1, px: 2 }}>
                      <HomeCardHeader
                        title="Local events"
                        subtitle={
                          (isMobile ? "Tap" : "Click") +
                          " to signup for events in your area"
                        }
                        IconComponent={LocalActivity}
                        isMobile={isMobile}
                        hideIcon={true}
                      />
                    </Box>
                    <EventPreviewList
                      timeZoneId={timeZoneId}
                      events={data?.events}
                      userId={data?.auth.uid}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Stack gap={2}>
                {/* Share */}
                <HomeCard onClick={() => setShowShare(true)}>
                  <HomeCardHeader
                    title="Refer a friend"
                    subtitle={
                      (isMobile ? "Tap" : "Click") + " to share with a friend"
                    }
                    IconComponent={CardGiftcard}
                    isMobile={isMobile}
                  />
                  <Typography sx={{ pt: 1 }}>
                    You will both receive 1-hour for free once they sign up.
                    Unlimited uses!
                  </Typography>
                  <ReferralsList userId={data?.auth?.uid} />
                </HomeCard>

                {/* Announcements */}
                {data?.announcements &&
                  Object.keys(data?.announcements).length > 0 && (
                    <HomeCard onClick={() => setShowAnnouncements(true)}>
                      <HomeCardHeader
                        title="Announcements"
                        subtitle={
                          (isMobile ? "Tap" : "Click") +
                          " to see the latest updates"
                        }
                        IconComponent={Podcasts}
                        isMobile={isMobile}
                      />
                      <Box sx={{ mt: 2, borderRadius: "15px" }}>
                        <AnnouncementCard announcements={data?.announcements} />
                      </Box>
                    </HomeCard>
                  )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <>
        <PushDrawer
          title={"Upcoming visit"}
          minWidth={"600px"}
          open={showNext}
          onOpen={() => setShowNext(true)}
          onClose={() => {
            setShowNext(false);
          }}
          hideBackdrop={false}
        >
          {nextVisit && (
            <VisitCard
              key={nextVisit?.id}
              visit={nextVisit}
              visitId={nextVisit?.id}
              employees={data?.employees}
              handleClose={() => {
                setShowNext(false);
              }}
            />
          )}
        </PushDrawer>
        <PushDrawer
          title={"Review visit"}
          width={"400px"}
          open={showPrev}
          onOpen={() => setShowPrev(true)}
          onClose={() => {
            setShowPrev(false);
          }}
          hideBackdrop={false}
        >
          {prevVisit && (
            <ReviewVisitMember
              visit={prevVisit}
              visitId={prevVisit?.id}
              handleClose={() => setShowPrev(false)}
              stars={stars}
            />
          )}
        </PushDrawer>
        <PushDrawer
          title={"Share with friends"}
          width={"400px"}
          open={showShare}
          onOpen={() => setShowShare(true)}
          onClose={() => {
            setShowShare(false);
          }}
          hideBackdrop={false}
        >
          {<ShareFriends />}
        </PushDrawer>
        <ResponsiveDrawer
          title={"Share with friends"}
          width={"400px"}
          open={showAnnouncements}
          initialHeight="98vh"
          onOpen={() => setShowAnnouncements(true)}
          onClose={() => {
            setShowAnnouncements(false);
          }}
          hideBackdrop={false}
        >
          {
            <AnnouncementContent
              userCollection={"members"}
              userId={data?.auth?.uid}
              handleClose={() => setShowAnnouncements(false)}
            />
          }
        </ResponsiveDrawer>
      </>
      <ContentHeader title={`Home Page`} logo={true}></ContentHeader>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <>
          <DiscountsBanner />

          {!checklistComplete && <NewMemberChecklist />}
          {!noLocationSet && renderHomePage()}
        </>
      </Box>
    </>
  );
};

export default HomePage;
