import { AccessTime, EventRounded, LocationOn } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { formatTime } from "../../services/dateServices";
import PostMedia from "../feed/PostMedia";
import EventStatusChip from "./EventStatusChip";

const EventInformation = ({ event, timeZoneId, userId = "" }) => {
  const formatDateRange = (eventDays) => {
    const dates = Object.values(eventDays).map((day) =>
      moment(day.startTime.toDate()).tz(timeZoneId)
    );
    dates.sort((a, b) => a.diff(b));

    if (dates.length === 1) {
      return dates[0].format("MMM Do"); // Single date, e.g., "December 23rd"
    }

    const firstDate = dates[0];
    const lastDate = dates[dates.length - 1];

    if (firstDate.month() === lastDate.month()) {
      if (dates.length === 2) {
        return `${firstDate.format("MMM Do")} - ${lastDate.format("Do")}`; // Same month range, e.g., "December 21st - 23rd"
      } else {
        return dates.map((date) => date.format("Do")).join(", "); // List of dates in same month, e.g., "21st, 23rd"
      }
    } else {
      return `${firstDate.format("MMM Do")} - ${lastDate.format("MMM Do")}`; // Different month range, e.g., "Dec 21st - Jan 1st"
    }
  };

  console.log("event?.signUpList: ", event?.signUpList);

  if (!event) return <div>No event selected</div>;

  const { title, description, location, eventDays } = event;

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="div" gutterBottom>
            {title}
          </Typography>

          <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
            {description}
          </Typography>
          <EventStatusChip
            signUpList={event.signUpList}
            maxSignUps={event.maxSignUps}
            userId={userId}
            eventDays={event.eventDays}
            timeZoneId={timeZoneId}
          />
          <List dense={true} disablePadding>
            <ListItem disableGutters>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText primary={location?.city || "Not specified"} />
            </ListItem>

            <ListItem disableGutters>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <AccessTime />
              </ListItemIcon>
              <ListItemText
                primary={
                  `${event.estimatedServiceTime} minutes` || "Not specified"
                }
              />
            </ListItem>

            <ListItem disableGutters>
              <ListItemIcon sx={{ minWidth: "32px" }}>
                <EventRounded />
              </ListItemIcon>
              <ListItemText
                primary={formatDateRange(eventDays) || "Not specified"}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <PostMedia mediaUrls={event.mediaUrls} roundCorners={true} />
        </Grid>
      </Grid>

      {/* <Divider sx={{ my: 2 }} /> */}

      <Box
        sx={{
          backgroundColor: "#F5F5F5", // Light gray background
          borderRadius: "15px",
          px: 3,
          py: 1,
          my: 2,
        }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          fontWeight={"600"}
          sx={{ mb: 2 }}
        >
          Event Schedule
        </Typography>
        {Object.entries(eventDays)
          .sort((a, b) =>
            moment(a[1].startTime.toDate()).diff(
              moment(b[1].startTime.toDate())
            )
          )
          .map(([dayKey, dayDetails], index, array) => (
            <>
              <Grid container spacing={2} key={dayKey} Divider>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Date:</strong>{" "}
                    {moment(dayDetails.startTime.toDate())
                      .tz(timeZoneId)
                      .format("dddd, MMMM Do")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Time:</strong>{" "}
                    {formatTime(dayDetails.startTime, timeZoneId)} -{" "}
                    {formatTime(dayDetails.endTime, timeZoneId)}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary">
                    <strong>Deadline:</strong> {formatDeadline(dayDetails)}
                  </Typography> */}
                </Grid>
              </Grid>
              {index !== array.length - 1 && <Divider sx={{ my: 2 }} />}
            </>
          ))}
      </Box>
    </>
  );
};

export default EventInformation;
