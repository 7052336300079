import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import { db } from "../firebase";

const BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
const BITS = [16, 8, 4, 2, 1];

export function decodeGeoHash(geohash) {
  const bounds = decodeGeoHashToBounds(geohash);
  if (bounds.length === 0) return [0, 0];
  const lat = (bounds.sw.lat + bounds.ne.lat) / 2;
  const lng = (bounds.sw.lng + bounds.ne.lng) / 2;

  return [lat, lng];
}

export const getCityStateFromZip = async (zipCode) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;
  console.log("Request URL:", url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log("data: ", data);

    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;
      
      // Initialize variables to hold city and state
      let city = "";
      let state = "";

      // Extract city and state from address components
      for (let component of addressComponents) {
        if (component.types.includes("locality")) {
          city = component.long_name; // City name
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name; // State abbreviation
        }
      }

      if (city && state) {
        return `${city}, ${state}`;
      }

      return "City or state not found";
    } else {
      return zipCode;
    }
  } catch (error) {
    console.error("Error fetching city:", error);
    return zipCode;
  }
};


function decodeGeoHashToBounds(geohash) {
  if (!geohash) return [];

  let isEven = true;
  const lat = [-90.0, 90.0];
  const lon = [-180.0, 180.0];

  for (let i = 0; i < geohash.length; i++) {
    const c = geohash[i];
    const cd = BASE32.indexOf(c);
    for (let j = 0; j < 5; j++) {
      const mask = BITS[j];
      if (isEven) {
        refineInterval(lon, cd, mask);
      } else {
        refineInterval(lat, cd, mask);
      }
      isEven = !isEven;
    }
  }
  const latCenter = (lat[0] + lat[1]) / 2;
  const lonCenter = (lon[0] + lon[1]) / 2;

  return {
    sw: { lat: lat[0], lng: lon[0] },
    ne: { lat: lat[1], lng: lon[1] },
    center: { lat: latCenter, lng: lonCenter },
  };
}

function refineInterval(interval, cd, mask) {
  if (cd & mask) {
    interval[0] = (interval[0] + interval[1]) / 2;
  } else {
    interval[1] = (interval[0] + interval[1]) / 2;
  }
}

export function isPointInPolygon(point, polygon) {
  let x, y;

  // Check if point is an array, assume [0] is lat and [1] is lng
  if (Array.isArray(point)) {
    x = point[0];
    y = point[1];
  } else {
    // If point is an object
    x = point.lat;
    y = point.lng;
  }

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].lat,
      yi = polygon[i].lng;
    let xj = polygon[j].lat,
      yj = polygon[j].lng;

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const convertStateNameToAbbreviation = (input) => {
  const inputUpperCase = input.toUpperCase();

  const stateNames = Object.keys(stateAbbreviations);
  const stateAbbreviationValues = Object.values(stateAbbreviations);

  // Check if input is already an abbreviation
  if (stateAbbreviationValues.includes(inputUpperCase)) {
    return inputUpperCase;
  }

  // Convert full state name to abbreviation
  const foundState = stateNames.find(
    (stateName) => stateName.toUpperCase() === inputUpperCase
  );

  return foundState ? stateAbbreviations[foundState] : input;
};

// Function to get members in an area
export const getMembersInArea = async (
  { geohash, center },
  radiusInMiles = 5
) => {
  let searchCenter;

  if (geohash) {
    searchCenter = decodeGeoHash(geohash);
  } else if (center && center.lat && center.lng) {
    searchCenter = [center.lat, center.lng];
  } else {
    console.error("Either geohash or center with lat/lng is required");
    return [];
  }

  console.log("searchCenter: ", searchCenter);

  const radiusInM = radiusInMiles * 1609.34; // Convert miles to meters
  const bounds = geohashQueryBounds(searchCenter, radiusInM);

  console.log("bounds: ", bounds);

  let membersLocations = [];

  try {
    for (const [start, end] of bounds) {
      const q = query(
        collection(db, "members"), // Adjust according to your collection name
        where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const memberData = doc.data();
        let coords = decodeGeoHash(memberData?.location?.geohash);
        let memberCoordinates = {
          lat: coords[0],
          lng: coords[1],
          status: memberData.status,
        };

        membersLocations.push(memberCoordinates);
      });
    }

    return membersLocations;
  } catch (error) {
    console.error("Error getting members: ", error);
    return [];
  }
};
