import React, { useEffect, useState } from "react";
import { onSnapshot, query, collection, where } from "firebase/firestore";
import { db } from "../firebase";
import {
  List,
  ListItemButton,
  Avatar,
  ListItemText,
  Chip,
  Typography,
  Divider,
  Alert,
} from "@mui/material";
import moment from "moment-timezone";

function ReferralsList({ userId }) {
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "referrals"),
      where("referrerId", "==", userId)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const referralsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReferrals(referralsArray);
    });

    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, [userId]);

  console.log("referrals: ", referrals);
  console.log("userId: ", userId);

  return (
    <>
      <Divider sx={{ width: "100%", mt: 2 }}>Your friends</Divider>
      {referrals.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {referrals.map((member) => (
            <ListItemButton
              key={member.id}
              sx={{ borderRadius: "15px", width: "100%" }}
            >
              {/* <Avatar sx={{ marginRight: 2 }} src={member?.avatarUrl}></Avatar> */}
              <ListItemText primary={member.refereeName} />
              <Alert icon={false} sx={{ py: 0 }}>
                Joined {moment(member.createdAt.toDate()).format("MMM YYYY")}
              </Alert>
            </ListItemButton>
          ))}
        </List>
      ) : (
        <Alert
          severity="warning"
          icon={false}
          sx={{ mt: 2, borderRadius: "15px" }}
        >
          The friends you invited haven't signed up yet. Spread the word and
          check back!
        </Alert>
      )}
    </>
  );
}

export default ReferralsList;
