import React, { useState } from "react";
import moment from "moment-timezone";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  Grid,
  Card,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Collapse,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Add, Delete, InfoOutlined, Remove } from "@mui/icons-material";
import ActionFooter from "./ActionFooter";

const AdjustTimeWindow = ({ onSave, timeZoneId, windows, name, loading }) => {
  console.log("windows: ", windows);

  // Convert windows to localWindows with Moment objects
  const initialWindows = Object.entries(windows).reduce((acc, [id, window]) => {
    acc[id] = {
      start: moment(window.start.toDate()).tz(timeZoneId),
      end: moment(window.end.toDate()).tz(timeZoneId),
    };
    return acc;
  }, {});

  const [localWindows, setLocalWindows] = useState(initialWindows);
  const [edited, setEdited] = useState(false);
  const [toRemove, setToRemove] = useState({}); // State to track visits to remove

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  console.log("localWindows: ", localWindows);

  // Function to modify time in 15-minute increments
  const modifyTime = (windowId, field, action) => {
    setEdited(true);
    setLocalWindows((prev) => {
      // Clone the previous state to avoid direct mutation
      const newWindows = { ...prev };
      const currentMoment = newWindows[windowId][field];

      // Adjust the time
      if (action === "add") {
        currentMoment.add(15, "minutes");
      } else {
        currentMoment.subtract(15, "minutes");
      }

      // Update the specific window's field with the new time
      newWindows[windowId][field] = currentMoment;

      return newWindows;
    });
  };

  const totalWindows = Object.keys(localWindows).length;

  const addNewTimeWindow = () => {
    setEdited(true);

    // Ensure there is at least one window
    if (totalWindows === 0) {
      console.log("No windows to calculate from.");
      return; // Exit the function early if no windows are present
    }

    const newWindowId = `window-${totalWindows + 1}`;

    // Find the latest end time among current windows
    const latestWindow = Object.values(localWindows).reduce(
      (latest, current) => {
        return current.end.isAfter(latest.end) ? current : latest;
      }
    );

    console.log("latestWindow: ", latestWindow);

    // Create a new window starting 30 minutes after the latest end time and lasting for an hour
    const newStartTime = latestWindow.end.clone().add(30, "minutes");
    const newEndTime = newStartTime.clone().add(1, "hour");

    setLocalWindows((prev) => ({
      ...prev,
      [newWindowId]: {
        start: newStartTime,
        end: newEndTime,
      },
    }));
  };

  const deleteWindow = (windowId) => {
    setEdited(true);
    setToRemove((prev) => ({ ...prev, [windowId]: true }));

    // Wait for the collapse animation to complete before removing the item
    setTimeout(() => {
      setLocalWindows((prev) => {
        const newWindows = { ...prev };
        delete newWindows[windowId];
        return newWindows;
      });
      // Also update toRemove state to clean it up
      setToRemove((prev) => {
        const updatedToRemove = { ...prev };
        delete updatedToRemove[windowId];
        return updatedToRemove;
      });
    }, 500); // 500ms matches the timeout of the Collapse component
  };

  const findPreviousWindowEndTime = (currentId) => {
    let latestEndTime = moment(0); // A very old time as default
    Object.entries(localWindows).forEach(([id, win]) => {
      if (
        id !== currentId &&
        win.end.isBefore(localWindows[currentId].start) &&
        win.end.isAfter(latestEndTime)
      ) {
        latestEndTime = win.end;
      }
    });
    return latestEndTime;
  };

  const findNextWindowStartTime = (windowId) => {
    let nextWindowStartTime = null;

    Object.entries(localWindows).forEach(([id, win]) => {
      if (id !== windowId && win.start.isAfter(localWindows[windowId].end)) {
        nextWindowStartTime = nextWindowStartTime
          ? moment.min(nextWindowStartTime, win.start)
          : win.start;
      }
    });

    return nextWindowStartTime;
  };

  // First, sort the windows based on the start time
  const sortedWindowEntries = Object.entries(localWindows).sort((a, b) => {
    return a[1].start.diff(b[1].start);
  });

  // Now map the sorted windows to JSX elements
  const windowElements = sortedWindowEntries.map(([id, window], index) => (
    <Collapse in={!toRemove[id]} timeout={500} key={id}>
      <Box sx={{ position: "relative", marginBottom: 2 }}>
        {/* Add margin for space for the delete icon */}
        <Card
          variant="outlined"
          sx={{
            p: 2,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            key={id}
            spacing={2}
            direction="column"
            sx={{
              alignItems: "centered",
              paddingRight: index === 0 ? "0px" : "24px",
            }}
          >
            {/* Start time controls */}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                onClick={() => modifyTime(id, "start", "subtract")}
                disabled={
                  window.start
                    .clone()
                    .subtract(15, "minutes")
                    .isSameOrBefore(findPreviousWindowEndTime(id)) ||
                  window.start
                    .clone()
                    .subtract(15, "minutes")
                    .isBefore(
                      moment(window.start)
                        .tz(timeZoneId)
                        .set({ hour: 6, minute: 0 })
                    )
                }
              >
                <Remove />
              </IconButton>
              <Card
                elevation={0}
                sx={{
                  width: "150px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0", // Light gray background
                  borderRadius: "10px", // Rounded edges
                  padding: "4px", // Padding around the text
                }}
              >
                <Typography component="span" fontWeight="600">
                  Start:{" "}
                </Typography>
                <Typography component="span">
                  {window.start.format("h:mm A")}
                </Typography>
              </Card>
              <IconButton
                onClick={() => modifyTime(id, "start", "add")}
                disabled={window.start
                  .clone()
                  .add(15, "minutes")
                  .isSameOrAfter(window.end)} // Disable if adding makes start same or after end
              >
                <Add />
              </IconButton>
            </Stack>

            {/* End time controls */}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                onClick={() => modifyTime(id, "end", "subtract")}
                disabled={window.end
                  .clone()
                  .subtract(15, "minutes")
                  .isSameOrBefore(window.start)} // Disable if adding makes start same or after end
              >
                <Remove />
              </IconButton>
              <Card
                width="100%"
                elevation={0}
                sx={{
                  width: "150px",
                  textAlign: "center",
                  backgroundColor: "#e0e0e0", // Light gray background
                  borderRadius: "10px", // Rounded edges
                  padding: "4px", // Padding around the text
                }}
              >
                <Typography component="span" fontWeight="600">
                  End:{" "}
                </Typography>
                <Typography component="span">
                  {window.end.format("h:mm A")}
                </Typography>
              </Card>
              <IconButton
                onClick={() => modifyTime(id, "end", "add")}
                disabled={
                  window.end
                    .clone()
                    .add(15, "minutes")
                    .isSameOrAfter(findNextWindowStartTime(id)) ||
                  window.end
                    .clone()
                    .add(15, "minutes")
                    .isAfter(
                      moment(window.end)
                        .tz(timeZoneId)
                        .set({ hour: 20, minute: 0 })
                    )
                }
              >
                <Add />
              </IconButton>
            </Stack>
          </Stack>
        </Card>
        {index !== 0 && ( // Do not show delete icon for the first window
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: "12px",
              transform: "translateY(-50%)", // Vertically center the button
            }}
            onClick={() => deleteWindow(id)}
          >
            <Delete />
          </IconButton>
        )}
      </Box>
    </Collapse>
  ));

  return (
    <>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="caption" gutterBottom>
          {name} will be notified of any changes.
        </Typography>
        <Stack direction="column" width="100%">
          {windowElements}
          <Button
            variant="outlined"
            color="primary"
            onClick={addNewTimeWindow}
            sx={{
              width: { xs: "100%", sm: "auto" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            New Time Range
          </Button>
        </Stack>
        {totalWindows === 1 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "grey.600",
              pr: 1,
              pt: 1,
              maxWidth: "400px",
            }}
          >
            <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
            <Typography variant="caption">
              Use time ranges to show breaks. Record actual hours. You are
              always paid for 1 hour minimum.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          sx={{
            width: { xs: "100%", sm: "auto" },
            height: { xs: "60px", sm: "auto" },
          }}
          disableElevation={!isMobile}
          disabled={!edited || loading}
          variant="contained"
          color="primary"
          onClick={() => onSave(localWindows)}
        >
          {loading ? <CircularProgress color="inherit" size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </>
  );
};

export default AdjustTimeWindow;
