import { Call, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import { validateEmail, validatePhone } from "../../services/formServices";
import { useNavigate } from "react-router-dom";

const ConfirmCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ phone: "", name: "" });

  const handleChange = (e) => {
    let input = e.target.value;

    // Check if input contains only digits, parentheses, dashes, and spaces
    // if (/^[\d\(\)\-\s]*$/.test(input)) {
    // Remove all non-digit characters for uniform processing
    const digits = input.replace(/\D/g, "");

    // Apply formatting for phone numbers
    let formattedInput = "";
    if (digits.length <= 3) {
      formattedInput = digits;
    } else if (digits.length <= 6) {
      formattedInput = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}`;
    } else {
      formattedInput = `(${digits.slice(0, 3)}) ${digits.slice(
        3,
        6
      )}-${digits.slice(6, 10)}`;
    }

    // Set the formatted input
    setValues((prevValues) => ({ ...prevValues, phone: formattedInput }));
    // }
    // else {
    //   // Remove parentheses, spaces, and dashes
    //   const cleanedInput = input.replace(/[()\s-]/g, "");

    //   setValues((prevValues) => ({ ...prevValues, phone: cleanedInput }));
    // }

    // Clear error for the field
    if (errors.phone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: null,
      }));
    }
  };

  const validateStep = () => {
    let tempErrors = {};

    // Remove all non-digit characters for phone validation
    const cleanedPhone = values.phone.replace(/\D/g, "");
    if (/^\d{10}$/.test(cleanedPhone)) {
      tempErrors = validatePhone({ phone: cleanedPhone });
    } else if (values.phone && /\S+@\S+\.\S+/.test(values.phone)) {
      tempErrors = validateEmail({ email: values.phone });
    } else {
      tempErrors.phone =
        "Please provide your phone number so that we can get in touch. We respect your privacy. Opt-out anytime.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNameChange = (e) => {
    const { name } = e.target;

    // Only keep letters and spaces, and limit to the first 50 characters
    let value = e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 50);
    // Update the event's value before passing it to handleChange
    e.target.value = value;

    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!validateStep() && !canSkip) {
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    let phone, email;

    if (/^[\d\(\)\-\s]*$/.test(values.phone)) {
      phone = values.phone;
    } else {
      email = values.phone;
    }

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          phone: phone || "",
          email: email || "",
          name: values.name || "",
          followUpDate: new Date(),
        },
        { merge: true }
      );

      localStorage.setItem("leadCaptured", "true");

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const renderOwner = () => (
    <ButtonBase
      fullWidth
      component="a"
      href="tel:+13603366344"
      sx={{
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        p: 1,
        height: "100%",
        borderRadius: "50px",
      }}
    >
      <Avatar
        src={
          "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F9nRhRtIUV1fdKXANdzbI4wGli4E2?alt=media&token=d1e3dfd6-5fe0-4bca-b762-2eb77b5792a6"
        }
        alt="Alex Rodriguez"
        sx={{ width: 64, height: 64 }}
      />
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1">Alex Rodriguez</Typography>
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          Owner, Linked Lives
        </Typography>
        <Typography variant="body2">Mobile: (360) 336-6344</Typography>
      </Box>
      <Call sx={{ ml: "auto", mr: 2 }} />
    </ButtonBase>
  );

  const handleSmsClick = async () => {
    setLoading(true);

    // Create the SMS URL
    const smsUrl = `sms:3603366344?&body=${encodeURIComponent(
      "I'm interested in bookmarking senior helpers for later:\n\nLinked Lives\nwww.ourlinkedlives.com\n(360) 336-6344"
    )}`;

    // Open the SMS URL
    window.location.href = smsUrl;

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          bookmarkConfirm: true,
        },
        { merge: true }
      );

      // handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleVisitHome = async () => {
    setLoading(true);

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          visitHome: true,
        },
        { merge: true }
      );

      navigate("/");
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          {/* Confirm your help */}
          Confirm Your Submission
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          {/* Your first free hour will be associated with this phone info.  */}
          We will reach out to you shortly to schedule your visit. This is not
          binding, and you can cancel your request anytime.
        </Typography>
        <Box sx={{ display: "flex", mb: 2, flexDirection: "column", gap: 2 }}>
          <TextField
            name="name"
            id="name"
            label="Name (optional)"
            fullWidth
            variant="outlined"
            value={values.name}
            onChange={handleNameChange}
            error={!!errors.name} // Show error state if there's an error for name
            helperText={errors.name} // Display the actual error message
          />
          <TextField
            name="phone"
            id="phone"
            label="Phone"
            type="tel"
            fullWidth
            variant="outlined"
            value={values.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={
              errors.phone
                ? errors.phone
                : "For visit request follow-up only. We respect your privacy and will never spam you. Opt out anytime."
            }
          />
          {renderOwner()}
          {/* <Divider>or</Divider>
          <Button
            disableElevation
            color="primary"
            variant="outlined"
            onClick={handleSmsClick}
            disabled={loading}
            startIcon={<Bookmark />}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }}
          >
            <Typography fontWeight={"500"}>Bookmark for later</Typography>
          </Button> */}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading} // || values.phone.length < 14}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Confirm</Typography>
            )}
          </Button>
          <Button
            disableElevation
            variant="text"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            <Typography
              align="center"
              onClick={handleVisitHome}
              fontWeight={"500"}
              sx={{ my: 1, cursor: "pointer", color: "black" }}
            >
              Not ready?{" "}
              <span style={{ textDecoration: "underline" }}>
                Visit homepage
              </span>
            </Typography>
          </Button>
          {/* <Button
            fullWidth
            variant="text"
            size="large"
            component="a"
            href="tel:+13603366344"
            sx={{
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
            }}
            startIcon={<Call />}
          >
            Call (360) 336-6344
          </Button> */}

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ConfirmCard;
