import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";

function EmployeeJobDescription() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.lightest,
      }}
    >
      <Container
        sx={{
          paddingY: { xs: 2, sm: 8 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            maxWidth: "600px",
          }}
        >
          <Typography variant="h4">Job Description</Typography>

          <ul>
            {[
              "Age: 18+",
              "Flexible: Set your own schedule",
              "Part time: Works great with existing jobs",
              "Competitive wages",
              "Unlimited time-off",
              // "Sales opportunites (up to $80/hr average)",
            ].map((item, index) => (
              <li key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>

          <Typography variant="h5">About Us</Typography>

          <Typography variant="body1" paragraph>
            Linked Lives helps seniors with odd jobs and tasks around their
            homes and prioritizes exceptional service and meaningful
            connections.
          </Typography>

          <Typography
            variant="body1"
            paddingX={4}
            paragraph
            sx={{ fontStyle: "italic" }}
          >
            “Our mission is to serve older adults in our community through
            dependable and personable household help.”
          </Typography>

          <Typography variant="h5">Job Summary</Typography>

          <Typography variant="body1" paragraph>
            As a Link Employee, you will help our older adult members within
            your service area with physical tasks around the house. During a
            typical 2-hour shift, you will drive to a member’s house and assist
            them with a wide list of small household tasks, ranging from
            organizing to tech help, moving boxes to light garden work. Set your
            own work hours depending on your schedule.
          </Typography>

          <Typography variant="h5">What You’ll Do</Typography>

          <Typography variant="body1">
            As a Link Employee, you will be responsible for performing member
            visits and participating in team meetings. On a weekly basis, you
            will:
          </Typography>

          <ul>
            {[
              "Check weekly schedule",
              "Drive to member’s homes",
              "Assist members with household tasks",
              "Attend monthly meetings",
              // "Recruit other Link Employees",
              // "Grow the brand",
              // "Fundraise for your education",
            ].map((item, index) => (
              <li key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>

          <Typography variant="h5">Perks & Opportunities</Typography>

          <Typography variant="body1">
            As a Link Employee, you will enjoy a variety of perks that help you
            grow both personally and professionally. Our company values
            excellence and recognizes that many of our employees will become
            future leaders for the next generation.
          </Typography>

          <ul>
            {[
              "Performance-based raise opportunities",
              "Great part-time work during college",
              "Excellent resume building",
              "Letters of recommendation",
              "Leadership opportunities",
              // "Small business mentorship",
              "Unlimited time-off",
              "Set your own schedule",
              "Profitable fundraising opportunities",
            ].map((item, index) => (
              <li key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>

          <Typography variant="h5">Skills / Requirements:</Typography>

          <Typography variant="body1">
            You will be a great fit if you have experience with handy work,
            technology, and personal interaction. We look for involvement in
            communities, academic excellence, and good problem-solving skills.
            Unofficial experience is good and formal experience is even better.
          </Typography>

          <ul>
            {[
              "18+ years old",
              "Able to work at least 6 hours per week.",
              "Provide your own transportation to Visits",
              "Handy around the house (hanging a painting, changing a lightbulb, swapping batteries)",
              "Basic tech help (setting up a facebook, organizing photo libraries, researching app’s)",
              "Good around the yard (weeding a flower bed, digging up a shrub, trimming bushes)",
              "Physically capable of lifting heavy boxes and using ladders",
              "Time management skills",
              "Dependable and reliable",
              "Strives for excellence",
              "Good personal skills",
              "Attention to detail",
            ].map((item, index) => (
              <li key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Container>
    </Box>
  );
}

export default EmployeeJobDescription;
