// -- components/employeeDashboard/Settings.js
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import React from "react";
import ResponsiveMenu from "../../../components/ResponsiveMenu";
import ContentHeader from "../../ContentHeader";
import NotificationsTab from "./NotificationsTab";
import ProfileTab from "./ProfileTab";

import {
  Badge,
  DeveloperBoard,
  Event,
  Explore,
  Logout,
  PersonPinCircle,
} from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEmployee } from "../../../providers/EmployeeProvider";
import EventsTab from "./EventsTab";
import AvailabilityTab from "./AvailabilityTab";
import EmployeeSearch from "../../../components/manageEmployees/EmployeeSearch";

const Settings = () => {
  const { data } = useEmployee();
  const navigate = useNavigate();

  const isAdmin = data?.employee?.isAdmin;

  const signOutUser = async () => {
    const auth = getAuth();
    console.log(auth.currentUser);
    try {
      await signOut(auth);
      navigate("/login");
      console.log("User signed out successfully");
      // You can also set any state or dispatch any actions here if you're using Redux or React Context for global state management.
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const menuItems = [
    {
      title: "Profile",
      component: <ProfileTab />,
      icon: <AccountBoxIcon />,
      secondaryText: "Edit your personal information",
    },
    {
      title: "Notifications",
      component: (
        <NotificationsTab
          memberId={data?.auth?.uid}
          initialNotifications={data?.employee?.private?.data?.notifications}
        />
      ),
      icon: <NotificationsIcon />,
      secondaryText: "Set your notification preferences",
    },
    {
      title: "Events",
      component: <EventsTab />,
      icon: <Event />,
      secondaryText: "Set your notification preferences",
    },
    {
      title: "Availability",
      component: <AvailabilityTab />,
      icon: <PersonPinCircle />,
      secondaryText: "Set your service area, booking days in advance",
    },
    ...(isAdmin
      ? [
          {
            title: "Employees",
            component: <EmployeeSearch />,
            icon: <Badge />,
            secondaryText: "Manage employees, view profiles, see schedules",
          },
        ]
      : []),
  ];

  console.log("menuItems: ", menuItems);

  return (
    <Box>
      <ContentHeader title={"Settings"}></ContentHeader>
      <ResponsiveMenu menuItems={menuItems} />
    </Box>
  );
};

export default Settings;
