import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Timestamp,
  arrayUnion,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import GuestHeader from "../../components/GuestHeader";
import VisitReviewList from "../../components/reviews/VisitReviewList";
import { db } from "../../firebase";
import WebFooter from "./sections/WebFooter";

function ReviewsPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let scrollTimeout;
    let scrollDepth = 0;

    const logScrollDepth = async () => {
      const timestamp = Timestamp.now();

      const logEntry = {
        page: "reviews",
        timestamp: timestamp,
        scrollDepth: scrollDepth,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              "~logs": arrayUnion(logEntry),
            },
            { merge: true }
          );
        } catch (err) {
        }
      }
    };

    const handleScroll = () => {
      const currentScrollDepth = Math.round(window.scrollY); // Measure scroll depth from the top in pixels
      scrollDepth = currentScrollDepth;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        logScrollDepth();
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1), // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Reviews");
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Visit Reviews and Testimonials - Read Feedback from Past Visits
        </title>
        <meta
          name="description"
          content="Read authentic reviews and testimonials from past visits. Discover how our senior services have helped others with tasks, tech help, and more."
        />
      </Helmet>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              // height: "100%",
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h3"}
              fontWeight="700"
              gutterBottom
              align="center"
            >
              Member Reviews
              <span style={{ color: theme.palette.primary.vibrant }}></span>
            </Typography>
            <Typography
              variant="body1"
              mb={4}
              align="center"
              sx={{ maxWidth: "600px", mx: "auto" }}
            >
              Curious what our members are saying about Linked Lives? This is a
              great place to read reviews for recent visits and get inspiration
              for how we can help.
            </Typography>
          </Box>
          <VisitReviewList />
        </Container>
      </Box>

      <WebFooter />
    </div>
  );
}

export default ReviewsPage;
