import * as React from "react";

const TaskMomLogo = ({ color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 202 37.22",
    }}
    viewBox="0 0 202 37.22"
    // {...props}
  >
    <path
      fill={color}
      class="cls-1"
      d="M28.25 12.05c-.53-2.27-1.6-4.14-2.09-4.83-.87-1.04-1.26-1.43-4.12-1.43h-3.1v23.18c0 4.14.48 4.44 4.8 4.78v1.83H7.64v-1.83c4.12-.35 4.56-.64 4.56-4.78V5.79H9.24c-2.72 0-3.49.44-4.41 1.63-.68.94-1.36 2.71-1.99 4.69H1c.34-3.6.68-7.45.78-9.82h1.45c.73 1.13 1.31 1.23 2.76 1.23h19.39c1.31 0 1.99-.3 2.81-1.23h1.41c.05 2.07.24 6.41.48 9.62l-1.84.15Z"
    />
    <path
      fill={color}
      class="cls-1"
      d="M38.38 35.58V33.9l.92-.1c1.84-.2 1.89-.69 1.21-2.32l-1.21-2.76h-7.76l-1.02 2.86c-.44 1.48-.19 1.87 1.5 2.12l1.26.2v1.68h-9.55v-1.83c2.28.12 3.2-.49 4.41-3.4l8.24-18.64 2.23-.35L47 30.94c.92 2.22 1.74 2.71 4.02 2.96v1.68H38.37Zm-2.91-16.62-3.1 7.35h6.01l-2.91-7.35ZM65.29 20.09c-1.26-3.45-2.91-5.03-5.19-5.03-1.36 0-2.57.99-2.57 2.61 0 1.73 1.21 2.66 3.73 3.8 3.93 1.78 6.59 3.45 6.59 7.35 0 4.93-4.61 7.35-8.82 7.35-2.52 0-5.14-.99-6.16-1.48-.19-1.13-.63-4.54-.78-6.26l1.6-.3c1.02 3.11 3.01 6.02 6.16 6.02 1.84 0 2.76-1.13 2.76-2.56 0-1.53-.73-2.66-3.73-4.14-3.2-1.58-6.3-3.3-6.3-7.4s3.15-6.95 8.29-6.95c2.33 0 4.12.49 5.24.74.15 1.33.58 4.49.78 5.82l-1.6.44ZM95.83 35.58c-3.78-.05-7.08 0-8.87.1-.15-.25-6.06-9.47-6.25-9.82-.44-.59-.73-.84-.97-.89-.15-.05-.34-.05-.44-.05v5.28c0 3.21.24 3.3 2.86 3.7v1.68H70.23V33.9c2.57-.39 2.81-.49 2.81-3.7V7.71c0-2.66-.24-2.91-2.81-3.5V2.68c3.06-.25 6.26-.79 9.07-1.68v22.49c1.11-.3 1.94-1.13 2.72-1.92 1.21-1.23 3.01-3.4 3.54-4.39.58-1.09-.19-1.58-1.99-1.87v-1.63l10.38-.39v1.58c-3.01.49-4.56 1.13-6.26 2.96-.78.79-1.84 2.12-3.2 3.7 1.21 1.92 5.77 8.19 7.13 9.86 1.55 1.83 2.13 2.22 4.22 2.52v1.68Z"
    />
    <path
      fill={color}
      class="cls-1"
      d="M121.1 35.58V34.2c3.98-.39 4.12-.69 4.07-5.97l.05-20.12c-3.98 9.03-10.38 23.13-12.07 27.47h-1.26c-1.65-4.49-7.13-18.05-10.81-26.98-.82 17.26-.87 21.16-.87 22.1.05 2.76 1.26 3.16 4.56 3.5v1.38H93.28V34.2c2.81-.3 3.98-.89 4.36-3.55.29-2.17 1.41-27.13 1.41-27.13h4.07l10.72 25.01 11.49-25.01h3.88c.11 6.18.19 24.71.19 24.71.19 5.28.34 5.52 4.61 5.97v1.38h-12.9ZM145.97 11.95c7.13 0 12.17 5.13 12.17 11.89 0 7.74-5.77 12.38-12.07 12.38-7.76 0-12.22-5.97-12.22-12.13 0-8.14 6.35-12.13 12.12-12.13Zm-.44 1.68c-3.78 0-7.18 2.96-7.18 9.67s3.35 11.25 8.19 11.25c3.68 0 7.08-2.66 7.08-10.01 0-5.57-2.72-10.9-8.1-10.9ZM191.87 30.4c-.07 2.27.93 2.81 2.43 3.01.87.1 2.86-.79 2.86-2.17 0-1.04-.44-2.07-.97-3.11-.1-.25-.1-.59.05-.79.53-1.13 2.28-2.42 3.3-2.42s1.45 1.23 1.45 3.01-.52 7.74-6.33 7.74c-4.27 0-6.7-1.98-6.58-5.43.07-2.06.51-6.17.59-8.24.15-3.5-1.16-5.72-4.17-5.72-1.84 0-3.59 1.04-5.38 2.57.05.59.1 1.18.1 2.12v9.57c0 3.11.48 3.35 3.2 3.65v1.38h-10.47v-1.38c3.01-.3 3.49-.49 3.49-3.75v-8.48c0-3.65-1.11-5.67-4.07-5.67-1.94 0-3.83 1.28-5.38 2.57v11.59c0 3.26.39 3.45 3.15 3.75v1.38h-10.47V34.2c3.25-.3 3.54-.49 3.54-3.75V20.24c0-3.16-.24-3.35-2.96-3.85v-1.23c2.28-.35 4.61-.99 6.74-1.97v4c1.02-.79 2.09-1.63 3.59-2.57 1.21-.74 2.33-1.28 3.88-1.28 2.33 0 4.31 1.48 5.28 4.04 1.36-1.09 2.62-1.97 3.83-2.76 1.07-.69 2.42-1.28 3.83-1.28 3.78 0 6.24 2.77 6.06 7.6-.09 2.37-.52 7.1-.59 9.47Z"
    />
  </svg>
);
export default TaskMomLogo;
