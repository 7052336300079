// --/services/memberServices.js
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment-timezone";

export const generalFaq = [
  {
    question: {
      title: "How do I book a visit?",
    },
    answer:
      "We offers easy scheduling on our website or over the phone. Login to your dashboard and click on the 'Book a Visit' button or the '+' icon. Enter your tasks and then select the day and time that works best for you. Finally, click 'confirm and book'.",
  },
  {
    question: {
      title: "What tasks can I get help with?",
    },
    answer:
      "We help with all kinds of tasks that you either used to do, don't want to do, or simply need an extra hand. This includes yard work, gardening, garage organization, tech help, seasonal decoration, heavy lifting, furniture assembly, cleaning, errands, and more. When you book a visit, you'll be able to write detailed tasks for your helper.",
  },
  {
    question: {
      title: "Can I pick my helper?",
    },
    answer:
      "Yes, you have complete control who you comes to help. Once you have a favorite employee, you can prioritize booking them for future visits. On our website, you can view our entire schedule for all helpers.",
  },
  {
    question: {
      title: "How many hours per month do members typically book?",
    },
    answer:
      "About 2 hours per month on average. Our members keep an ongoing to-do list over the weeks, and schedule a visit when ready. Some book weekly, others a couple times per year. It's flexible for your budget and needs!",
  },
  {
    question: {
      title: "How do I pay?",
    },
    answer:
      "After your first visit, we will email you an invoice. Once you pay by credit card, we store your card securely encrypted on file. For all future visits, the balance will AutoPay and simply send you a receipt for the service. It's a hassle free solution that our members love! If credit card does not work for you, reach out directly and we will work to find a solution.",
  },
  {
    question: {
      title: "Are there any minimums or contracts?",
    },
    answer:
      "Nope, we make it flexible for you! There are no contracts, no monthly minimums, and no hidden fees to use our service. For visits, we have an hour minimum and you can book in hour increments.",
  },
  {
    question: {
      title: "What if I need more than one helper?",
    },
    answer:
      "You can book multiple helpers depending on their availability. If there are multiple helpers available during the same time, you will be able to select the number of desired employees from the filter dropdown.",
  },
  {
    question: {
      title: "How soon in advance do I need to schedule?",
    },
    answer:
      "We suggest scheduling at least 4-5 days in advance for the best selection of days and times. Depending on our availability, you can book anywhere from tomorrow, up to a month in advance.",
  },
  {
    question: {
      title: "What happens if I need to cancel or reschedule?",
    },
    answer:
      "You can reschedule or cancel bookings with just a few taps on our website, or call as well. We ask that you try and cancel at least 24 hours in advance.",
  },
  {
    question: {
      title: "Are you licensed, insured, and BBB accredited?",
    },
    answer:
      "Yes, our company and all employees are insured through both the Department of Labor and Industries, and through our company's insurance policy. We are also BBB accredited, with hundreds of references available on our website.",
  },
  {
    question: {
      title: "Are there programs available for those with limited income?",
    },
    answer:
      "Yes, we offer need-based rates for those on limited or fixed income under a certain threshold. Please reach out to us to arrange a custom plan with a team member.",
  },
  {
    question: {
      title:
        "If I want a variety tasks completed in the same visit, does it cost more?",
    },
    answer:
      "It costs the same, we charge a flat hourly rate for all tasks. Even though your visits may have unique tasks, you can count on our predictable pricing without the need to wait for a quote.",
  },
  {
    question: {
      title: "Are your helpers reliable?",
    },
    answer:
      "Yes, we pride ourselves for showing up, on-time, ready to help. We invest heavily in all our employees and prioritize people that are dependable, personable, and excellent. We hold our employees to a high standard.",
  },
  {
    question: {
      title: "Can I book a helper for same-day help.. today?",
    },
    answer:
      "Yes, depending on the helper's availability. Our calendar gives you our entire monthly availability in real-time, allowing you to book help for your needs as soon as today - if there's an opening.",
  },
  {
    question: {
      title: "What happens if I forget or miss a booked visit?",
    },
    answer:
      "We understand that life can be unpredictable. If you miss a visit, please contact us so that you do not get wrongfully billed. It is likely that your scheduled helper will have already reported the absense. For cancellations within an hour of the visit start, we do charge a $20 fee that goes to the helper for the inconvenience and loss of opportunity.",
  },
];

export const createMemberAccountStripe = async (data) => {
  const functions = getFunctions();
  const createMemberAccountStripeFunction = httpsCallable(
    functions,
    "createMemberAccountStripe"
  );
  try {
    const result = await createMemberAccountStripeFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createMemberAccountStripe function:", error);
    throw error;
  }
};

export const createStripeCustomer = async (data) => {
  const functions = getFunctions();
  const createStripeCustomerFunction = httpsCallable(
    functions,
    "createStripeCustomer"
  );
  try {
    const result = await createStripeCustomerFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createStripeCustomer function:", error);
    throw error;
  }
};

export const createAccount = async (data) => {
  const functions = getFunctions();
  const createAccountFunction = httpsCallable(functions, "createAccount");
  try {
    const result = await createAccountFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAccount function:", error);
    throw error;
  }
};

export const applyReferralDiscount = async (data) => {
  const functions = getFunctions();
  const applyReferralDiscountFunction = httpsCallable(
    functions,
    "applyReferralDiscount"
  );
  try {
    const result = await applyReferralDiscountFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling applyReferralDiscount function:", error);
    throw error;
  }
};

export const addAddress = async (data) => {
  console.log("data: ", data);
  const functions = getFunctions();
  const addAddressFunction = httpsCallable(functions, "addAddress");
  try {
    const result = await addAddressFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addAddress function:", error);
    throw error;
  }
};

export const calculateVisits = async (employees, timeZoneId) => {
  console.time("-------------------- Execution Time");

  console.log("==> Calculate Visits employees: ", employees);
  console.log("==> Calculate Visits timeZoneId: ", timeZoneId);

  // employees must have key as id and property "windows"

  const HOUR_TO_MILLIS = 60 * 60 * 1000;
  const TRAVEL_TIME_MILLIS = 30 * 60 * 1000; // 30 minutes in milliseconds
  const visits = []; // Initialize your visits variable

  // Function to adjust an employee's windows based on their booking constraints
  const adjustWindowsBasedOnBookingConstraints = (
    windows,
    bookingDaysInAdvance,
    isAvailableToday
  ) => {
    if (windows && windows.length === 0) {
      console.log("EMPTY");
      return [];
    }

    const now = moment().tz(timeZoneId);
    const oneHourFromNow = moment().tz(timeZoneId).add(1, "hours");

    // Calculate how many minutes to add to round up to the next quarter-hour
    const minutes = 15 - (oneHourFromNow.minutes() % 15);
    if (minutes !== 15) {
      // This check prevents adding 15 minutes when already at the quarter-hour
      oneHourFromNow.add(minutes, "minutes");
    }

    console.log("isAvailableToday: ", isAvailableToday);

    return windows
      .map((window) => {
        const windowStart = moment(window.start.toMillis()).tz(timeZoneId);
        const windowEnd = moment(window.end.toMillis()).tz(timeZoneId);

        console.log("windowEnd: ", windowEnd.format("MM-DD hh:mm a"));

        // Create a separate variable for the start of the day
        const windowStartDay = windowStart.clone().startOf("day");
        const daysFromToday = windowStartDay.diff(
          now.clone().startOf("day"),
          "days"
        );

        // If 'isAvailableToday' is off and it's today, discard the window.
        if (!isAvailableToday && daysFromToday === 0) {
          console.log(
            "'isAvailableToday' is off and it's today, discard the window."
          );
          return null;
        }

        // If 'isAvailableToday' is on, it's today, and the window is relevant (i.e., hasn't ended yet),
        if (
          isAvailableToday &&
          daysFromToday === 0 &&
          oneHourFromNow.isBefore(windowEnd)
        ) {
          if (oneHourFromNow.isAfter(windowStart)) {
            console.log(
              "oneHourFromNow.isAfter(windowStart): ",
              oneHourFromNow.isAfter(windowStart)
            );

            console.log("windowStart: ", moment(windowStart));
            console.log("oneHourFromNow: ", moment(oneHourFromNow));

            return {
              ...window,
              start: { toMillis: () => oneHourFromNow.valueOf() },
            };
          } else {
            console.log(
              "oneHourFromNow is not after (windowStart): ",
              oneHourFromNow.isAfter(windowStart)
            );
            return window;
          }
        }

        // If the window is before the 'bookingDaysInAdvance', discard it.
        if (daysFromToday < bookingDaysInAdvance) {
          console.log(
            "the window is before the 'bookingDaysInAdvance', discard it"
          );

          return null;
        }

        // For any other scenarios, the window remains valid and unmodified.
        return window;
      })
      .filter(Boolean); // Filter out the null entries after processing.
  };

  // Preprocess the employees to adjust their windows based on booking constraints.
  const adjustedEmployees = Object.fromEntries(
    Object.entries(employees).map(([id, employee]) => {
      const adjustedWindows = adjustWindowsBasedOnBookingConstraints(
        employee.availableWindows,
        employee.bookingDaysInAdvance,
        employee.isAvailableToday
      );
      return [id, { ...employee, windows: adjustedWindows }];
    })
  );

  const getEmployeeCombos = (employees) => {
    const employeeIds = Object.keys(employees);
    const totalEmployees = employeeIds.length;

    const generateCombinations = (arr, comboSize) => {
      // generate all combinations of a given size (comboSize) from an input array (arr).
      if (comboSize === 0) return [[]];
      if (arr.length === 0) return [];

      const head = arr[0];
      const withoutHead = generateCombinations(arr.slice(1), comboSize);
      const withHead = generateCombinations(arr.slice(1), comboSize - 1).map(
        (c) => [head, ...c]
      );

      return [...withoutHead, ...withHead];
    };

    const getComboWindows = (combo, employees) => {
      const getIntersectionOfWindows = (windowA, windowB) => {
        const start = Math.max(
          windowA.start.toMillis(),
          windowB.start.toMillis()
        );
        const end = Math.min(windowA.end.toMillis(), windowB.end.toMillis());

        if (start < end) {
          return {
            start: { toMillis: () => start },
            end: { toMillis: () => end },
          };
        } else {
          return null;
        }
      };

      let overlaps = employees[combo[0]].windows;

      for (let i = 1; i < combo.length; i++) {
        const employeeWindows = employees[combo[i]].windows;

        let newOverlaps = [];

        for (let overlap of overlaps) {
          for (let window of employeeWindows) {
            const intersection = getIntersectionOfWindows(overlap, window);
            if (intersection) {
              newOverlaps.push(intersection);
            }
          }
        }

        overlaps = newOverlaps;
      }

      return overlaps;
    };

    const results = {};

    // Main loop to generate combinations and find overlaps
    for (let comboSize = 1; comboSize <= totalEmployees; comboSize++) {
      const combos = generateCombinations(employeeIds, comboSize);

      combos.forEach((combo) => {
        const windows = getComboWindows(combo, employees);
        if (windows && windows.length > 0) {
          // join all employeeId's into a dashed string and set windows as property
          results[combo.join("-")] = { windows };
        }
      });
    }

    return results;
  };

  function calculateFrequencyForVisit(visit, employeeWindows) {
    // Helper function to check if a visit (shifted by 'weeksToAdd') falls within any of the employee's windows

    function fallsWithinFutureWindow(visit, employeeWindows) {
      const frequencies = [1, 2, 3, 4];

      // Calculate the shifted visits (1 to 4 weeks ahead)
      const futureVisits = frequencies.map((weeks) => {
        return {
          start: moment
            .tz(visit.start, "America/Los_Angeles")
            .add(weeks, "weeks")
            .valueOf(),
          end: moment
            .tz(visit.end, "America/Los_Angeles")
            .add(weeks, "weeks")
            .valueOf(),
        };
      });

      const checks = futureVisits.map((futureVisit) => {
        return employeeWindows.some((window) => {
          return (
            futureVisit.start >= window.start.toMillis() &&
            futureVisit.end <= window.end.toMillis()
          );
        });
      });

      return checks;
    }

    const checks = fallsWithinFutureWindow(visit, employeeWindows);

    if (checks.every(Boolean)) {
      return [0, 1, 2, 4];
    }
    if (checks[1] && checks[3]) {
      return [0, 2, 4];
    }
    if (checks[3]) {
      return [0, 4];
    }
    return [0];
  }

  function calculateLongestDurationRounded(employeeWindows) {
    // Extract the durations of all windows for the current employee/combo
    const durations = employeeWindows.map(
      (window) => window.end.toMillis() - window.start.toMillis()
    );

    // Find the longest duration in milliseconds
    const maxDurationMillis = Math.max(...durations);

    // Convert the longest duration to hours
    const longestDuration = maxDurationMillis / HOUR_TO_MILLIS;

    // Round down the duration to get full hours
    const roundedLongestDuration = Math.floor(longestDuration);

    return roundedLongestDuration;
  }

  const employeeCombos = getEmployeeCombos(adjustedEmployees);

  // Loop through each employee (or employee combo)
  Object.keys(employeeCombos).forEach((employeeKey) => {
    const employeeWindows = employeeCombos[employeeKey].windows;
    const roundedLongestDuration =
      calculateLongestDurationRounded(employeeWindows);

    // Calculate and log available visits for each duration
    employeeWindows.forEach((window) => {
      const endMillis = window.end.toMillis();
      for (
        let durationHours = 1;
        durationHours <= roundedLongestDuration;
        durationHours++
      ) {
        const visitDurationMillis = durationHours * HOUR_TO_MILLIS;
        const totalIntervalMillis = visitDurationMillis + TRAVEL_TIME_MILLIS;

        let startMillis = window.start.toMillis();

        while (startMillis + visitDurationMillis <= endMillis) {
          const frequency = calculateFrequencyForVisit(
            { start: startMillis, end: startMillis + visitDurationMillis },
            employeeWindows
          );
          visits.push({
            employeeIds: employeeKey.split("-"),
            start: startMillis,
            end: startMillis + visitDurationMillis,
            frequency: frequency,
            duration: durationHours, // Added the roundedLongestDuration to the visit object
          });
          startMillis += totalIntervalMillis; // Move to the next possible visit start
        }
      }
    });
  });

  // Log the visits
  /*
  console.log("All available visits:");
  visits.forEach((visit) => {
    const startTime = moment(visit.start)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD hh:mm:ss A");
    const endTime = moment(visit.end)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD hh:mm:ss A");
    console.log(
      `Employees ${visit.employeeIds.join(
        ", "
      )}: Start - ${startTime}, End - ${endTime}, Duration - ${visit.duration}, frequency: ${visit.frequency.join(
        ", "
      )}`
    );
  });
  */

  console.timeEnd("-------------------- Execution Time");

  return visits;
};

export const createVisit = async (data) => {
  const functions = getFunctions();
  const createVisitFunction = httpsCallable(functions, "createVisit");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createVisit function:", error);
    throw error;
  }
};

export const updateVisitEmployees = async (data) => {
  const functions = getFunctions();
  const updateVisitEmployeesFunction = httpsCallable(
    functions,
    "updateVisitEmployees"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await updateVisitEmployeesFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateVisitEmployees function:", error);
    throw error;
  }
};

export const sendSignInLink = async (data) => {
  const functions = getFunctions();
  const sendSignInLinkFunction = httpsCallable(functions, "sendSignInLink");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await sendSignInLinkFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling sendSignInLink function:", error);
    throw error;
  }
};

export const createAuth = async (data) => {
  const functions = getFunctions();
  const createAuthFunction = httpsCallable(functions, "createAuth");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createAuthFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAuth function:", error);
    throw error;
  }
};

export const fetchCards = async (data) => {
  const functions = getFunctions();
  const fetchCardsFunction = httpsCallable(functions, "fetchCards");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await fetchCardsFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling fetchCards function:", error);
    throw error;
  }
};

export const addCardToCustomer = async (data) => {
  const functions = getFunctions();
  const addCardToCustomerFunction = httpsCallable(
    functions,
    "addCardToCustomer"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await addCardToCustomerFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addCardToCustomer function:", error);
    throw error;
  }
};

export const updateDefaultPaymentMethod = async (data) => {
  const functions = getFunctions();
  const updateDefaultPaymentMethodFunction = httpsCallable(
    functions,
    "updateDefaultPaymentMethod"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await updateDefaultPaymentMethodFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateDefaultPaymentMethod function:", error);
    throw error;
  }
};

export const createSetupIntent = async (data) => {
  const functions = getFunctions();
  const createSetupIntentFunction = httpsCallable(
    functions,
    "createSetupIntent"
  );
  try {
    const result = await createSetupIntentFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createSetupIntent function:", error);
    throw error;
  }
};

export const createPaymentMethod = async (data) => {
  const functions = getFunctions();
  const createPaymentMethodFunction = httpsCallable(
    functions,
    "createPaymentMethod"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createPaymentMethodFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createPaymentMethod function:", error);
    throw error;
  }
};

export const addMemberRole = async (data) => {
  const functions = getFunctions();
  const addMemberRoleFunction = httpsCallable(functions, "addMemberRole");
  try {
    const result = await addMemberRoleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addMemberRole function:", error);
    throw error;
  }
};

export const rescheduleVisit = async (data) => {
  const functions = getFunctions();
  const rescheduleVisitFunction = httpsCallable(functions, "rescheduleVisit");
  try {
    const result = await rescheduleVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling rescheduleVisit function:", error);
    throw error;
  }
};

export const updateMemberBillingCycle = async (data) => {
  const functions = getFunctions();
  const updateMemberBillingCycleFunction = httpsCallable(
    functions,
    "updateMemberBillingCycle"
  );
  try {
    const result = await updateMemberBillingCycleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateMemberBillingCycle function:", error);
    throw error;
  }
};

export const createAccountDoc = async (data) => {
  const functions = getFunctions();
  const createAccountDocFunction = httpsCallable(functions, "createAccountDoc");
  try {
    const result = await createAccountDocFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAccountDoc function:", error);
    throw error;
  }
};

export const addAdminData = async (data) => {
  const functions = getFunctions();
  const addAdminDataFunction = httpsCallable(functions, "addAdminData");
  try {
    const result = await addAdminDataFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addAdminData function:", error);
    throw error;
  }
};

export const setMembershipPlan = async (data) => {
  const functions = getFunctions();
  const setMembershipPlanFunction = httpsCallable(
    functions,
    "setMembershipPlan"
  );
  try {
    const result = await setMembershipPlanFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling setMembershipPlan function:", error);
    throw error;
  }
};
