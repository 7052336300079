import { Bookmark, Call, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";

const ZipcodeCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);

  const inArea = true;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    zipCode: "",
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateStep = () => {
    let tempErrors = {};

    // Add validation for zipCode not to exceed 5 digits
    if (!values.zipCode || values.zipCode.length < 5)
      tempErrors.zipCode = "Zip code must be 5 digits.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true);

    const isValid = validateStep();
    if (!isValid && !canSkip) {
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          zipCode: values.zipCode,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleZipChange = (e) => {
    // Only keep digits and limit to the first 5
    let value = e.target.value.replace(/\D/g, "").slice(0, 5);
    // Update the event's value before passing it to handleChange
    e.target.value = value;
    handleChange(e);
  };

  const handleSmsClick = async () => {
    setLoading(true);

    // Create the SMS URL
    const smsUrl = `sms:3603366344?&body=${encodeURIComponent(
      "I'm interested in bookmarking senior helpers for later:\n\nLinked Lives\nwww.ourlinkedlives.com\n(360) 336-6344"
    )}`;

    // Open the SMS URL
    window.location.href = smsUrl;

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          bookmarkZipcode: true,
        },
        { merge: true }
      );

      // handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          What's your zip code?
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Let's see which of our helpers are available in your specific area.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            name="zipCode"
            id="zipCode"
            label="Zip Code"
            type="tel" // Encourages a numeric keypad
            inputMode="numeric" // Suggests a numeric keyboard on devices that support inputMode
            fullWidth
            variant="outlined"
            value={values.zipCode}
            onChange={handleZipChange}
            error={!!errors.zipCode} // Show error state if there's an error for zipCode
            helperText={errors.zipCode} // Display the actual error message
          />

          <Divider>or</Divider>
          <Button
            disableElevation
            color="primary"
            variant="outlined"
            onClick={handleSmsClick}
            startIcon={<Bookmark />}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }}
          >
            <Typography fontWeight={"500"}>Bookmark for later</Typography>
          </Button>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values?.zipCode?.length < 5}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>
          <Button
            fullWidth
            variant="text"
            size="large"
            component="a"
            href="tel:+13603366344"
            sx={{
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
            }}
            startIcon={<Call />}
          >
            Call (360) 336-6344
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ZipcodeCard;
