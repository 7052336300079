import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import GuestHeader from "../../components/GuestHeader";
import CommonMisconceptions from "./home/CommonMisconceptions";
import FAQSection from "./sections/FAQSection";
import KeyServices from "./sections/KeyServices";
import ServicesGrid from "./sections/ServicesGrid";
import WebFooter from "./sections/WebFooter";
import CompareBeforeAfter from "./services/CompareBeforeAfter";
import { db } from "../../firebase";
import { Timestamp, arrayUnion, doc, increment, setDoc } from "firebase/firestore";

function ServicesPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let scrollTimeout;
    let scrollDepth = 0;

    const logScrollDepth = async () => {
      const timestamp = Timestamp.now();

      const logEntry = {
        page: "services",
        timestamp: timestamp,
        scrollDepth: scrollDepth,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              "~logs": arrayUnion(logEntry),
            },
            { merge: true }
          );
        } catch (err) {
        }
      }
    };

    const handleScroll = () => {
      const currentScrollDepth = Math.round(window.scrollY); // Measure scroll depth from the top in pixels
      scrollDepth = currentScrollDepth;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        logScrollDepth();
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1) // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Services");

  }, []);

  return (
    <div>
      <Helmet>
        <title>Senior Services from Tasks to Tech Help</title>
        <meta
          name="description"
          content="Our senior services provide a wide range of support, catering to the tasks that you either don’t want to or can’t do anymore."
        />
      </Helmet>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography
              component="h1" // This changes the underlying element to an h1
              variant="h4"
              fontWeight="700"
              gutterBottom
            >
              Help with all your{" "}
              <span style={{ color: theme.palette.primary.vibrant }}>
                Household Tasks
              </span>
            </Typography>
            <Typography variant="body1">
              Our senior services provide a wide range of support, catering to
              the tasks that you either don’t want to or can’t do anymore.
              Instead of piecing together multiple service providers for your
              various jobs, we provide a one-stop-shop for all your odd job
              help. Imagine the relief an all-day chore list accomplished in a
              single afternoon!
            </Typography>
          </Box>
        </Container>
      </Box>
      <ServicesGrid />

      <KeyServices />
      {/* <TaskList /> */}
      <CompareBeforeAfter />

      <Box
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            paddingY: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography variant="h4" fontWeight="700" gutterBottom>
              <span style={{ color: theme.palette.primary.vibrant }}>
                Flexible Help{" "}
              </span>
              at Your Fingertips
            </Typography>
            <Typography variant="body1" mb={4}>
              Our senior services flexibility is our greatest strength - we
              handle a wide range of tasks that can often be overlooked by your
              housekeeper or landscaper, or even by friends and family. Our
              “Links” are ready to step in, providing the strength, balance, and
              energy needed to complete your to-do list. All we ask is that you
              provide the tools or materials, whether a shovel, rake, or broom!
            </Typography>

            <Typography variant="h4" fontWeight="700" gutterBottom>
              Our Referral Network
            </Typography>
            <Typography variant="body1" mb={4}>
              What about the off-chance that we can’t help with a particular
              task? Rest assured we have a solution. Skip the time-consuming
              Google searches, review sifting, and website scrolling. Through
              our robust senior services network of trusted professionals, we
              will refer you to the right specialist for any job outside our
              expertise. It's this comprehensive approach that makes Linked
              Lives a favored one-stop solution for all your odd-jobs.
            </Typography>
            <Button
              component={Link} // use the Link component as the root node of the Button
              to="/member/signup" // use 'to' instead of 'href' for react-router's Link
              variant="contained"
              size="large"
              color="primary"
              sx={{
                width: { xs: "100%", sm: "250px" },
                textTransform: "none",
                height: "56px",
                mx: "auto",
              }}
            >
              Get Started
            </Button>
          </Box>
        </Container>
      </Box>
      {/* <UploadCSV /> */}
      <CommonMisconceptions />
      <FAQSection />

      <WebFooter />
    </div>
  );
}

export default ServicesPage;
