// --/services/employeeServices.js
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

export const onDeclineVisit = async ({
  cancellationType = "this_visit", // Renamed from 'values' for clarity
  reason,
  currentEmployeeId,
  visit,
  visitId,
  visits, // map of all loaded visit objects for the employee
  handleDecline = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  availabilityWindows,
  showSnackbar,
}) => {
  setLoading("decline");

  try {
    let updatedWindows = availabilityWindows;

    const promises = [];

    const futureVisits =
      visit?.recurrence?.recurringEventId && cancellationType === "all_visits"
        ? Object.entries(visits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate())
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    for (const visitItem of futureVisits) {
      updatedWindows = subtractAndMergeWindows({
        availability: updatedWindows,
        removeWindow: {
          start: moment(visitItem.start.toDate())
            .subtract(30, "minutes")
            .toDate(), // Subtract 30 minutes from start
          end: moment(visitItem.end.toDate()).add(30, "minutes").toDate(), // Add 30 minutes to end
        },
      });

      const visitValues = {
        employeeId: currentEmployeeId,
        visitId: visitItem.id, // Assuming each visit has a unique ID
        visit: visitItem,
        declineReason: reason,
        cancellationType: cancellationType,
      };

      // Update responseStatus and declineReason in Firestore for each future visit
      // const visitItemDocRef = doc(db, "visits", visitItem.id);
      // updateDoc(visitItemDocRef, {
      //   [`employees.${currentEmployeeId}.responseStatus`]: "declined",
      //   [`employees.${currentEmployeeId}.declineReason`]: reason,
      // });

      console.log("DeclineVisit visit values:, ", visitValues);

      // fire and forget the GCF
      // Add the pickupVisit promise to the array without waiting for it to complete
      // promises.push(declineVisit(visitValues));

      declineVisit(visitValues).catch((error) => {
        console.error("Error in declineVisit:", error);
        // Optionally, log this error to a monitoring service
      });

      // update local state (hopefully obsolete with new custom calendar component)
      handleDecline({ employeeId: currentEmployeeId, visitId: visitItem.id });

      // Break the loop if it's only for the current visit
      if (cancellationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }
    // Update the employee's availability after processing all visits
    const employeeAvailabilityDataRef = doc(
      db,
      "employees",
      currentEmployeeId,
      "public",
      "availability"
    );

    await updateDoc(employeeAvailabilityDataRef, {
      windows: updatedWindows,
    });

    // Wait for firebase operation to return
    // await Promise.all(promises);
    console.log("all promises complete");
    // Optionally wait for handleClaimOpen promises if needed
  } catch (error) {
    // Handle errors here
    showSnackbar("Error declining visit. Try again", "error");
    console.error("Error processing decline:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const onPickupVisit = async ({
  confirmationType = "this_visit", // Renamed from 'values' for clarity
  currentEmployeeId,
  visit,
  visitId,
  employeeData,
  openVisits, // map of all loaded visit objects for the employee
  handlePickup = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  availabilityWindows,
  showSnackbar,
}) => {
  setLoading("pickup");

  try {
    console.log("EmployeeVisitCard -- onPickupVisit");

    console.log("onPickupVisit parameters:", {
      confirmationType,
      currentEmployeeId,
      visit,
      visitId,
      employeeData,
      openVisits,
      handlePickup: !!handlePickup, // Checking if the function is provided
      setLoading: !!setLoading, // Checking if the function is provided
      availabilityWindows,
      showSnackbar: !!showSnackbar, // Checking if the function is provided
    });

    let updatedWindows = availabilityWindows; //data?.employee?.public?.availability?.windows;
    // const promises = [];

    const futureOpenVisits =
      visit?.recurrence?.recurringEventId && confirmationType === "all_visits"
        ? Object.entries(openVisits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate()) // Sorting the visits
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    for (const visitItem of futureOpenVisits) {
      updatedWindows = addAndMergeWindows({
        availability: updatedWindows,
        addWindow: {
          start: visitItem.start.toDate(),
          end: visitItem.end.toDate(),
        },
      });

      // promises.push(
      pickupVisit({
        responseStatus: "accepted", //visitItem.id === visitId ? "accepted" : "needsAction",
        employeeId: currentEmployeeId,
        employeeData,
        availability: updatedWindows,
        visitId: visitItem.id,
        visitData: openVisits[visitItem.id],
        confirmationType,
      }).catch((error) => {
        console.error("Error in pickupVisit:", error);
        // Optionally, log this error to a monitoring service
      });
      // );

      // Break the loop if it's only for the current visit
      if (confirmationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }

    // Update the employee's availability after processing the visits
    const employeeAvailabilityDataRef = doc(
      db,
      "employees",
      currentEmployeeId,
      "public",
      "availability"
    );

    await updateDoc(employeeAvailabilityDataRef, {
      windows: updatedWindows,
    });

    // await Promise.all(promises);

    handlePickup();
  } catch (error) {
    // Handle errors here
    showSnackbar("Error claiming visit. Try again", "error");
    console.error("Error processing claims:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const onAcceptVisit = async ({
  confirmationType = "this_visit", // Renamed from 'values' for clarity
  currentEmployeeId,
  visit,
  visitId,
  visits, // map of all loaded visit objects for the employee
  handleAccept = () => {}, // Pass handleAccept as a callback
  setLoading, // Pass setLoading as a callback
  showSnackbar,
}) => {
  setLoading("accept");

  console.log("onAcceptVisit parameters:", {
    confirmationType,
    currentEmployeeId,
    visit,
    visitId,
    visits,
    handleAccept: !!handleAccept, // Checking if the function is provided
    setLoading: !!setLoading, // Checking if the function is provided
    showSnackbar: !!showSnackbar, // Checking if the function is provided
  });

  try {
    const futureVisits =
      visit?.recurrence?.recurringEventId && confirmationType === "all_visits"
        ? Object.entries(visits || {})
            .filter(
              ([id, v]) =>
                v.recurrence?.recurringEventId ===
                  visit?.recurrence.recurringEventId &&
                v.start.toDate() >= visit?.start.toDate()
            )
            .map(([id, v]) => ({ ...v, id }))
            .sort((a, b) => a.start.toDate() - b.start.toDate()) // Sorting the visits
        : [{ ...visit, id: visitId }]; // Fallback to only the current visit if non-recurring

    console.log("futureVisits: ", futureVisits);

    for (const visitItem of futureVisits) {
      const updateData = {
        [`employees.${currentEmployeeId}.responseStatus`]: "accepted",
      };

      console.log("updateData: ", updateData);

      if (confirmationType === "all_visits") {
        updateData[`employees.${currentEmployeeId}.belongsToSeries`] = true;
      }

      // Update responseStatus and declineReason in Firestore for each future visit
      const visitItemDocRef = doc(db, "visits", visitItem.id);
      await updateDoc(visitItemDocRef, updateData);
      handleAccept({ employeeId: currentEmployeeId, visitId: visitItem.id });

      // Trigger GCF that removes the expiration
      acceptVisit({ visitId: visitItem.id }).catch((error) => {
        console.error("Error in acceptingVisit:", error);
      });

      // Break the loop if it's only for the current visit
      if (confirmationType !== "all_visits" && visitItem.id === visitId) {
        break;
      }
    }
  } catch (error) {
    // Handle errors here
    showSnackbar("Error accepting visit. Try again", "error");
    console.error("Error processing accept:", error);
  } finally {
    setLoading(false); // Reset the flag regardless of the outcome
  }
};

export const createEmployee = async (data) => {
  const functions = getFunctions();
  const createEmployeeFunction = httpsCallable(functions, "createEmployee");
  try {
    const result = await createEmployeeFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createEmployee function:", error);
    throw error;
  }
};

export const declineVisit = async (data) => {
  const functions = getFunctions();
  const declineVisitFunction = httpsCallable(functions, "declineVisit");
  try {
    const result = await declineVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling declineVisit function:", error);
    throw error;
  }
};

export const pickupVisit = async (data) => {
  const functions = getFunctions();
  const pickupVisitFunction = httpsCallable(functions, "pickupVisit");
  try {
    const result = await pickupVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling pickupVisit function:", error);
    throw error;
  }
};

export const acceptVisit = async (data) => {
  const functions = getFunctions();
  const acceptVisitFunction = httpsCallable(functions, "acceptVisit");
  try {
    const result = await acceptVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling acceptVisit function:", error);
    throw error;
  }
};

export const subtractAndMergeWindows = ({ availability, removeWindow }) => {
  // Convert the availability map to an array of objects, each containing the window and its corresponding key
  const availabilityArray = Object.keys(availability).map((key) => ({
    key,
    window: {
      start: moment.unix(availability[key].start.seconds),
      end: moment.unix(availability[key].end.seconds),
    },
  }));

  // Convert removeWindow's timestamps to moment objects for easy comparison
  const removeWindowConverted = {
    start: moment(removeWindow.start),
    end: moment(removeWindow.end),
  };

  const resultWindows = {};

  availabilityArray.forEach(({ key, window }) => {
    if (
      window.end.isBefore(removeWindowConverted.start) ||
      window.start.isAfter(removeWindowConverted.end)
    ) {
      // If the current window is completely outside the removing window, keep it unchanged.
      resultWindows[key] = {
        start: window.start.toDate(), // converting back to Date to be consistent with the return format
        end: window.end.toDate(),
      };
    } else {
      // If there's any kind of overlap, we're considering this as a "change" in the window,
      // so it should get a new UID regardless of the case.

      if (
        window.start.isBefore(removeWindowConverted.start) &&
        window.end.isAfter(removeWindowConverted.end)
      ) {
        // The removing window is entirely within the current window, split it.
        const newKeyFirstHalf = uuidv4();
        const newKeySecondHalf = uuidv4();

        resultWindows[newKeyFirstHalf] = {
          start: window.start.toDate(),
          end: removeWindowConverted.start.toDate(),
        };

        resultWindows[newKeySecondHalf] = {
          start: removeWindowConverted.end.toDate(),
          end: window.end.toDate(),
        };
      } else {
        // Partial overlap cases.
        const newKey = uuidv4(); // New UID for the changed window.

        if (window.start.isBefore(removeWindowConverted.start)) {
          resultWindows[newKey] = {
            start: window.start.toDate(),
            end: removeWindowConverted.start.toDate(),
          };
        } else if (window.end.isAfter(removeWindowConverted.end)) {
          resultWindows[newKey] = {
            start: removeWindowConverted.end.toDate(),
            end: window.end.toDate(),
          };
        }
        // If the removing window completely covers the current window, it gets removed,
        // so we don't add anything to the results.
      }
    }
  });

  // Convert back to the required format with Timestamps if necessary.
  const finalResult = {};
  for (const [key, window] of Object.entries(resultWindows)) {
    finalResult[key] = {
      start: Timestamp.fromDate(new Date(window.start)), // Assuming Timestamp.fromDate takes a Date object
      end: Timestamp.fromDate(new Date(window.end)),
    };
  }

  return finalResult;
};

export const addAndMergeWindows = ({ availability, addWindow }) => {
  console.time("addAndMergeWindowsExecutionTime"); // Start timing here

  console.log("addWindow: ", addWindow);
  // Convert the availability map to an array of objects, each containing the window and its corresponding key
  const availabilityArray = Object.keys(availability).map((key) => ({
    key,
    window: {
      start: moment.unix(availability[key].start.seconds),
      end: moment.unix(availability[key].end.seconds),
    },
  }));

  // Convert addWindow's timestamps to moment objects for easy comparison
  const addWindowConverted = {
    key: uuidv4(), // Assign a new UID right away for the new window
    window: {
      start: moment(addWindow.start),
      end: moment(addWindow.end),
    },
  };

  // Add the new window to the array
  availabilityArray.push(addWindowConverted);

  // Sort the windows by their start time
  availabilityArray.sort(
    (a, b) => a.window.start.valueOf() - b.window.start.valueOf()
  );

  const mergedWindows = [];
  let previousWindow = availabilityArray[0];

  // Start merging windows
  for (let i = 1; i < availabilityArray.length; i++) {
    const current = availabilityArray[i];

    if (previousWindow.window.end.isSameOrAfter(current.window.start)) {
      // Overlapping windows need to be merged
      const newStart = previousWindow.window.start;
      const newEnd = moment.max(previousWindow.window.end, current.window.end);

      previousWindow = {
        key: uuidv4(), // Assigning a new UID since we're creating a new merged window
        window: {
          start: newStart,
          end: newEnd,
        },
      };
    } else {
      // No overlap, so we can add the previous window to our results
      mergedWindows.push(previousWindow);
      previousWindow = current;
    }
  }

  // Push the last window after finishing the loop
  mergedWindows.push(previousWindow);

  // Construct the final result with the Timestamps
  const resultWindows = {};
  for (const { key, window } of mergedWindows) {
    resultWindows[key] = {
      start: Timestamp.fromDate(window.start.toDate()),
      end: Timestamp.fromDate(window.end.toDate()),
    };
  }

  console.timeEnd("addAndMergeWindowsExecutionTime"); // End timing here

  return resultWindows;
};

export const addEmployeeRole = async (data) => {
  const functions = getFunctions();
  const addEmployeeRoleFunction = httpsCallable(functions, "addEmployeeRole");
  try {
    const result = await addEmployeeRoleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addEmployeeRole function:", error);
    throw error;
  }
};

export const updateEmployeeLocation = async (data) => {
  const functions = getFunctions();
  const updateEmployeeLocationFunction = httpsCallable(
    functions,
    "updateEmployeeLocation"
  );
  try {
    const result = await updateEmployeeLocationFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateEmployeeLocation function:", error);
    throw error;
  }
};
