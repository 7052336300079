import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase";
import { useMember } from "../../providers/MemberProvider";

const AddressCard = ({
  addressId,
  address,
  isDefault,
  onEdit,
  onDelete,
  onSetDefault,
}) => {
  const theme = useTheme();
  const { data, loading } = useMember();

  const handleRadioChange = async () => {
    try {
      // Create a reference to the specific 'data' document inside the 'private' subcollection of the member
      const memberDataDocRef = doc(db, "members", data.auth.uid);

      await updateDoc(memberDataDocRef, {
        location: {
          city: address.city,
          state: address.state,
          zipCode: address.zipCode, // Assuming you have zipCode in your address object
          geohash: address.geohash.substring(0, 6),
        },
        timeZoneId: address.timeZoneId,
        status: "active",
      });

      const memberPrivateDataDocRef = doc(
        db,
        "members",
        data.auth.uid,
        "private",
        "data"
      );

      // Update the 'data' document with the new defaultAddress value
      await updateDoc(memberPrivateDataDocRef, {
        defaultAddress: addressId,
      });

      console.log("Default address set successfully:", addressId);
    } catch (error) {
      console.error("Error setting default address:", error);
    }
  };

  const showDelete = Object.keys(data?.account?.addresses).length > 1;
  console.log("showDelete: ", showDelete);

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        ...(isDefault ? { borderColor: theme.palette.primary.main } : {}),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(1),
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={isDefault}
            onChange={handleRadioChange}
            value="defaultAddress"
            name="defaultAddress"
            inputProps={{ "aria-label": "Default address" }}
          />
          <Typography
            variant="body1"
            fontWeight="600"
            color={isDefault ? "primary" : "textPrimary"}
          >
            {isDefault ? "Default Address" : ""}
          </Typography>
        </div>

        <div>
          {/* <IconButton onClick={() => onEdit(addressId, address)}>
              <EditIcon />
            </IconButton> */}
          {showDelete && (
            <IconButton onClick={() => onDelete(addressId)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
      <CardContent>
        <Typography variant="h6" fontWeight="600">
          {address.line1 || "N/A"}
        </Typography>
        <Typography variant="body1">{address.line2 || ""}</Typography>
        <Typography variant="body1">
          {address.city || "N/A"}, {address.state || "N/A"}{" "}
          {address.zipCode || "N/A"}
        </Typography>
        {/* <Typography
            variant="body1"
            style={{ marginTop: "10px", fontWeight: "bold" }}
          >
            People with access
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Susan M. (you)
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    susan.m@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Billing
                </Typography>
              </div>
            </div>

            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Duncan M.
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    duncan.m@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Editor
                </Typography>
              </div>
            </div>

            <div style={{ marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="body2" align="left">
                    Bob P.
                  </Typography>
                  <Typography
                    variant="caption"
                    align="left"
                    color="textSecondary"
                  >
                    bob.p@example.com
                  </Typography>
                </div>
                <Typography variant="body2" align="right">
                  Viewer
                </Typography>
              </div>
            </div>
          </div> */}
      </CardContent>
    </Card>
  );
};

export default AddressCard;
