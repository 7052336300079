import { Close } from "@mui/icons-material";
import {
  Box,
  Paper,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const ResponsiveDrawer = ({
  title,
  children,
  open = false,
  onOpen = () => {},
  onClose = () => {},
  minWidth = "600px",
  initialHeight = null,
  hideBackdrop,
  height = "98vh",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const elevation = isMobile ? 4 : 0;

  const [drawerHeight, setDrawerHeight] = useState({ xs: height, sm: "100%" }); // state to manage the drawer's height
  const [borderRadius, setBorderRadius] = useState(theme.spacing(2));

  useEffect(() => {
    if (initialHeight) {
      setDrawerHeight(initialHeight);
    }
  }, [initialHeight]);

  // useEffect that runs only once when the component is mounted
  useEffect(() => {
    if (isMobile) {
      setDrawerHeight(height); // sets drawer height to 98vh on mobile devices
    }
  }, [isMobile]);

  const anchor = isMobile ? "bottom" : "right";

  // const handleScroll = (e) => {
  //   const { scrollTop, scrollHeight } = e.currentTarget;

  //   if (scrollTop >= scrollHeight * 0.02) {
  //     // trigger after 10% scroll
  //     setDrawerHeight(height);
  //     setBorderRadius(0); // reset border radius
  //   }
  // };

  const renderDrawerHeader = (title) => (
    <Box
      sx={{
        position: "relative", // Added to position children absolutely within this Box.
        display: "flex",
        alignItems: "center", // Vertically aligns items in the center.
        padding: 2,
        justifyContent: "space-between", // Distributes space between the children (IconButton and the title).
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        height: "50px",
      }}
    >
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>

      {/* Title container to help center the title text */}
      <Box
        sx={{
          position: "absolute", // Absolute positioning is used here to center the title.
          top: "50%", // Positions the top edge of the element at the center of the parent.
          left: "50%", // Positions the left edge of the element at the center of the parent.
          transform: "translate(-50%, -50%)", // Shifts the element back by 50% of its own width and height to achieve true centering.
          textAlign: "center", // Ensures the text within is centered.
        }}
      >
        <Typography variant="h6">{title}</Typography> {/* This is your title */}
      </Box>
      {isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: "50%",
            transform: "translateX(-50%)",
            width: 50,
            height: 5,
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 2.5,
          }}
        />
      )}
    </Box>
  );

  const handleClose = () => {
    if (!isMobile) {
      setDrawerHeight(initialHeight || height); // sets drawer height to 98vh on mobile devices
    } else {
      setDrawerHeight(height);
    }
    onClose();
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      open={open}
      anchor={anchor}
      onOpen={onOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          className: "hideScrollbar",
          overflowY: "hidden",
          maxWidth: 600,
          width: "auto",
          // margin: "auto",
          borderTopLeftRadius: { xs: "15px", sm: "0px" }, // Rounded top-left corner
          borderTopRightRadius: { xs: "15px", sm: "0px" }, // Rounded top-right corner
          minWidth: { sm: minWidth }, // Minimum width of 600px on 'sm' screens and larger.
          boxSizing: "border-box",
          height: isMobile ? drawerHeight : "100%",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: !isMobile && hideBackdrop ? "transparent" : undefined,
          },
        },
      }}
    >
      {renderDrawerHeader(title)}
      {children}
    </SwipeableDrawer>
  );
};

export default ResponsiveDrawer;
