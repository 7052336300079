import React, { useState, useEffect } from "react";
import {
  Dialog,
  Slide,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  MobileStepper,
  IconButton,
  Typography,
  useTheme,
  Skeleton,
  useMediaQuery,
  Fade,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from "@mui/icons-material";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import SwipeableViews from "react-swipeable-views";
import { useEmployee } from "../providers/EmployeeProvider";
import { useMember } from "../providers/MemberProvider";

const AnnouncementDialog = ({
  userCollection,
  userId,
  open,
  setOpen,
  handleClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [announcements, setAnnouncements] = useState([]); // Local state to hold announcements data
  const [seenAnnouncements, setSeenAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for loading indication

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxSteps = announcements.length;
  const step = announcements[activeStep];

  const employeeContext = useEmployee();
  const memberContext = useMember();

  const { data, setData } =
    userCollection === "employees" ? employeeContext : memberContext;

  useEffect(() => {
    if (data.announcements) {
      setAnnouncements(data.announcements);
      setIsLoading(false);
    }
  }, [data.announcements]);

  const handleNext = () => {
    const seenUpdateId = announcements[activeStep + 1].id;
    setSeenAnnouncements((prevSeen) =>
      Array.from(new Set([...prevSeen, seenUpdateId]))
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);

    // Mark the announcement as seen when changing steps
    const seenUpdateId = announcements[step]?.id; // Safely trying to access the id
    if (seenUpdateId) {
      setSeenAnnouncements(
        (prevSeen) => Array.from(new Set([...prevSeen, seenUpdateId])) // Prevent duplicates
      );
    }
  };

  // When dialog is closed, perform the Firestore update for all seen announcements
  const handleDialogClose = async () => {
    const currentAnnouncementId = announcements[activeStep]?.id;

    // Add the current announcement to the seen list, if it's not already there.
    // Using a Set to prevent duplicates.
    const allSeenAnnouncements = Array.from(
      new Set([...seenAnnouncements, currentAnnouncementId])
    );

    if (allSeenAnnouncements.length > 0) {
      const userPrivateDataRef = doc(
        db,
        userCollection,
        userId,
        "private",
        "data"
      );

      // Start the update process, but don't await its completion.
      // This is a "fire-and-forget" approach.
      updateDoc(userPrivateDataRef, {
        announcements: arrayRemove(...allSeenAnnouncements),
      }).catch((error) => {
        // Even if we're not awaiting, we should still handle and log any error.
        console.error("Error updating user's viewed announcements: ", error);
      });
    }
    handleClose();
  };

  return (
    <>
      {announcements.length > 0 && !isLoading && step.imgPath && (
        <Dialog
          open={open}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth={isMobile ? "xl" : "sm"}
          onClose={handleDialogClose}
          TransitionComponent={Fade}
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{
            display: "flex", // Makes the Dialog a flex container
            flexDirection: "column", // Ensures the content is vertically stacked
            height: isMobile ? "100vh" : "auto", // Full height for mobile view
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1, // Allows the box to take all available space pushing the button block to the bottom
              overflow: "auto", // In case the content overflows the container
              marginX: isMobile ? "8px" : "32px",
            }}
          >
            <Card
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                borderRadius: isMobile ? "0px" : "auto",
              }}
            >
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className="hideScrollbar" // <-- add this class to the necessary element
              >
                {announcements.map((step, index) => (
                  <Box key={index} sx={{ overflow: "hidden" }}>
                    <Box
                      sx={{
                        display: "flex", // establishes a flex container
                        justifyContent: "center", // centers items horizontally in the container
                        alignItems: "center", // centers items vertically in the container
                        marginTop: isMobile ? "48px" : "16px",
                        marginX: "auto",
                        marginBottom: isMobile ? "16px" : "8px",
                        width: "30vh", // set the maximum width
                        height: "30vh", // set the maximum height
                        overflow: "hidden", // ensure the image doesn't spill out
                        borderRadius: "50%", // this shapes the container to be a circle
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative", // Set the parent box to relative
                          width: "100%", // Take the full width of the parent container
                          height: "100%", // Take the full height of the parent container
                          overflow: "hidden", // Ensure no overflow from this container
                        }}
                      >
                        {/* Skeleton positioned absolutely and always rendered */}
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          sx={{
                            position: "absolute", // Positioned absolutely within the parent Box
                            top: 0, // Align to the top of the parent Box
                            left: 0, // Align to the left of the parent Box
                            height: "100%", // Full height of the container
                            width: "100%", // Full width of the container
                          }}
                        />

                        {/* Image also positioned absolutely */}
                        <CardMedia
                          component="img"
                          image={step.imgPath}
                          alt={`Step ${index + 1} content`}
                          sx={{
                            position: "absolute", // Positioned absolutely within the parent Box
                            top: 0, // Align to the top of the parent Box
                            left: 0, // Align to the left of the parent Box
                            height: "100%", // Full height of the container
                            width: "100%", // Full width of the container
                            objectFit: "cover", // Ensure the aspect ratio is maintained
                          }}
                        />
                      </Box>
                    </Box>

                    <CardHeader
                      title={<Typography variant="h6">{step.title}</Typography>}
                      sx={{ textAlign: "left", marginBottom: -3 }}
                    />
                    <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
                      <Typography variant="body1">{step.content}</Typography>
                    </CardContent>
                  </Box>
                ))}
              </SwipeableViews>
            </Card>
          </Box>

          {/* Button Stepper Block */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              boxShadow: isMobile
                ? "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
                : "none",
              // No flexGrow here as this part does not need to take extra space
            }}
          >
            <Button
              fullWidth={isMobile}
              onClick={handleDialogClose}
              sx={{
                order: isMobile ? 0 : 1,
                color: "grey",
                height: isMobile ? "50px" : "auto",
                width: isMobile ? "100%" : "150px", // Add this line
              }}
            >
              Skip
            </Button>

            {maxSteps > 1 && (
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                sx={{
                  order: 3,
                  flexGrow: 1,
                  backgroundColor: "transparent",
                  justifyContent: "center",
                }}
                nextButton={
                  <IconButton
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                }
                backButton={
                  <IconButton
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                }
              />
            )}

            <Button
              fullWidth={isMobile}
              disableElevation
              variant="contained"
              onClick={
                activeStep === maxSteps - 1 ? handleDialogClose : handleNext
              }
              sx={{
                order: isMobile ? -1 : 3,
                height: isMobile ? "50px" : "auto",
                marginBottom: isMobile ? 1 : 0,
                width: isMobile ? "100%" : "150px", // Add this line
              }}
            >
              {activeStep === maxSteps - 1 ? "Let's go!" : "Next"}
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default AnnouncementDialog;
