import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  ListItemAvatar,
  ListItemText,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { db } from "../../firebase";
import AvatarStatusBadge from "../AvatarStatusBadge";
import ColorAvatar from "../ColorAvatar";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const VisitReviewCarousel = ({
  START_LIMIT = 10,
  employeeId = undefined,
  STAR_MIN = 5,
  spacing = "normal",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);
  const [visitsData, setVisitsData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [autoAdvanceInterval, setAutoAdvanceInterval] = useState(3000); // 5 seconds

  const carouselRef = useRef(null);

  const maxSteps = visitsData.length;

  const logCarouselActivity = async (action) => {
    const logEntry = {
      component: "carousel",
      timestamp: Timestamp.now(),
      direction: action,
      prevStep: activeStep,
    };
  
    let storedSessionId = localStorage.getItem("sessionId");
    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            "~logs": arrayUnion(logEntry),
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error logging carousel activity: ", err);
      }
    }
  };
  

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    setAutoAdvanceInterval(15000); // Set the interval to 15 seconds
    logCarouselActivity('next'); // Log the carousel action
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
    setAutoAdvanceInterval(15000); // Set the interval to 15 seconds
    logCarouselActivity('back'); // Log the carousel action
  };

  useEffect(() => {
    // Function to reset the interval back to 5 seconds
    const resetInterval = () => setAutoAdvanceInterval(3000);

    // Set a timeout to reset the interval after 15 seconds
    const timeoutId = setTimeout(resetInterval, 15000);

    // Clear the timeout if the component unmounts or if the interval is reset
    return () => clearTimeout(timeoutId);
  }, [activeStep]);

  // Generate the array of ratings based on STAR_MIN
  const ratingArray = Array.from(
    { length: 6 - STAR_MIN },
    (_, i) => i + STAR_MIN
  );

  useEffect(() => {
    const visitQuery = [
      collection(db, "visits"),
      where("rating", "in", ratingArray),
      orderBy("created", "desc"),
      limit(START_LIMIT),
    ];

    if (employeeId) {
      visitQuery.push(where("employeeArr", "array-contains", employeeId));
    }

    const q = query(...visitQuery);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedReviews = [];
      snapshot.forEach((doc) => {
        fetchedReviews.push({ id: doc.id, ...doc.data() });
      });
      setVisitsData(fetchedReviews);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [employeeId, ratingArray, START_LIMIT]);

  useEffect(() => {
    let touchStartX = 0;
    let touchStartY = 0;
  
    const handleTouchStart = (event) => {
      if (event.touches.length > 0) {
        touchStartX = event.touches[0].clientX;
        touchStartY = event.touches[0].clientY;
      }
    };
  
    const handleTouchEnd = (event) => {
      if (event.changedTouches.length > 0) {
        const touchEndX = event.changedTouches[0].clientX;
        const touchEndY = event.changedTouches[0].clientY;
  
        const deltaX = Math.abs(touchEndX - touchStartX);
        const deltaY = Math.abs(touchEndY - touchStartY);
  
        // Only handle if the touch movement is predominantly horizontal
        if (deltaX > deltaY) {
          console.log("Handling horizontal touch");
          logCarouselActivity('swipe'); // Log the carousel action
          setAutoAdvanceInterval(15000);
        }
      }
    };
  
    const refCurrent = carouselRef.current;
    if (refCurrent) {
      refCurrent.addEventListener("touchstart", handleTouchStart);
      refCurrent.addEventListener("touchend", handleTouchEnd);
    }
  
    // Clean up event listeners on component unmount
    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("touchstart", handleTouchStart);
        refCurrent.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [carouselRef.current]);
  

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  if (loading) return <Typography>Loading...</Typography>;

  function formatTimeDifference(startTimestamp, endTimestamp) {
    const startTime = moment(startTimestamp.toDate());
    const endTime = moment(endTimestamp.toDate());

    const duration = moment.duration(endTime.diff(startTime));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    let result = "";

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""}`;
      if (minutes > 0) {
        result += ` ${minutes} minute${minutes > 1 ? "s" : ""}`;
      }
    } else {
      result = `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    return result;
  }

  return (
    <Box
      sx={{ maxWidth: "600px", width: "100%", mx: "auto", boxSizing: "border-box" }}
      ref={carouselRef}
    >
      {visitsData.length > 0 && (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={autoAdvanceInterval} // Set the interval time for auto slide
        >
          {visitsData
            .sort((a, b) => b.start.seconds - a.start.seconds)
            .map((visit, index) => (
              <Box key={visit.id} sx={{px: 1}}>
                <Box sx={{ my: spacing === "large" ? "300px" : 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <ColorAvatar
                      avatarUrl={visit.avatarUrl}
                      name={visit.displayName}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        {visit.displayName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 0.6,
                        }}
                      >
                        {/* <Typography variant="body2">
                        {visit.location.city},{" "}
                        {convertStateNameToAbbreviation(visit.location.state)}
                      </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                          {/* •{" "} */}
                          {moment(new Date(visit.start.seconds * 1000)).format(
                            "MMM D, YYYY"
                          )}{" "}
                          • {formatTimeDifference(visit.start, visit.end)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      my: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 4, // Number of lines to show before truncating
                    }}
                  >
                    {visit.description}
                  </Typography>

                  {visit.rating && (
                    <Box
                      sx={{
                        backgroundColor: "#F5F5F5",
                        p: 1,
                        borderRadius: "15px",
                        my: 1,
                      }}
                    >
                      <Rating
                        size="small"
                        value={visit.rating}
                        precision={0.1}
                        readOnly
                      />
                      <Typography
                        variant="body1"
                        sx={
                          {
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                            // display: "-webkit-box",
                            // WebkitBoxOrient: "vertical",
                            // WebkitLineClamp: 4, // Number of lines to show before truncating
                          }
                        }
                      >
                        {visit.review}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      ml: "auto",
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {Object.entries(visit.employees)
                      .filter(
                        ([, employee]) => employee.responseStatus === "accepted"
                      )
                      .sort(([keyA, employeeA], [keyB, employeeB]) =>
                        employeeA.displayName.localeCompare(
                          employeeB.displayName
                        )
                      )
                      .map(([key, employee], index) => {
                        return (
                          <Box display="flex" justifyContent="flex-end">
                            <Stack
                              key={key}
                              direction="row"
                              gap={1}
                              alignItems="center"
                            >
                              <ListItemAvatar>
                                <AvatarStatusBadge
                                  status={employee.responseStatus}
                                  avatarUrl={
                                    visit.employees[key]?.avatarUrl ||
                                    visit?.employees[key]?.avatarUrl
                                  }
                                  displayName={visit?.employees[key]?.firstName}
                                  index={index}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${visit?.employees[key]?.displayName}`}
                                secondary="completed"
                              />
                            </Stack>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            ))}
        </AutoPlaySwipeableViews>
      )}

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{ textTransform: "none" }}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{ textTransform: "none" }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

      {/* {visitsData.length > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            onClick={() =>
              setActiveStep((prev) =>
                prev === 0 ? visitsData.length - 1 : prev - 1
              )
            }
          >
            Previous
          </Button>
          <Button
            onClick={() =>
              setActiveStep((prev) =>
                prev === visitsData.length - 1 ? 0 : prev + 1
              )
            }
          >
            Next
          </Button>
        </Box>
      )} */}
    </Box>
  );
};

export default VisitReviewCarousel;
