import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import PhoneField from "../fields/PhoneField";
import { doc, setDoc } from "firebase/firestore";

const ContactUs = ({ handleClose = () => {} }) => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    function shuffleArray(array) {
      let shuffledArray = array.slice(); // Create a copy of the array to avoid modifying the original
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
          shuffledArray[j],
          shuffledArray[i],
        ]; // Swap elements
      }
      return shuffledArray;
    }

    // Shuffle and slice the array to get 4 random URLs on component mount
    setSelectedImages(shuffleArray(imageUrls).slice(0, 4));
  }, []); // Empty dependency array to ensure this runs only once

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // Replace with your actual image paths
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FXdunSNWrqyLSunAspuE3ee5LGfT2.jpeg?alt=media&token=13abfc97-0ade-473d-9e5c-20d0479c4c5d",
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FtrZkasnGwGh4KZ6jc7qOc6U1cfz2?alt=media&token=827f342c-a9bd-4d6d-8455-38c90f823ec2",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af",
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0",
  ];

  const handleSave = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setLoading(true);

    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.name || "",
      phone: values?.phone || "",
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "interest_form",
        timeline: {
          interest_form: new Date(),
        },
        utmSource: utmSource || "",
        message: values?.message || "",
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date) ? [k, cleanData(v)] : [k, v]
          )
      );
    };
    

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    // Save the lead to Firebase
    await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
      merge: true,
    });

    console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _contactSubmitted: new Date(),
            // _zipCode: values?.zipCode,
            _source: "interest_form",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    setLoading(false);
    setSubmitted(true);

    handleClose();
  };

  const validateStep = () => {
    let tempErrors = {};
    if (!values.name) tempErrors.name = "Name is required";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", sm: "600px" },
        mx: "auto",
        p: { xs: 2, sm: 4 },
        overflow: "hidden",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <AvatarGroup spacing={30}>
          {selectedImages.map((url, index) => (
            <Avatar
              key={index}
              alt={`Avatar ${index + 1}`}
              src={url}
              sx={{ width: 128, height: 128 }} // Customize size as needed
            />
          ))}
          {/* <Avatar
            sx={{
              width: 128,
              height: 128,
              bgcolor: "grey.400", // Default grey background
            }}
          >
            <Add sx={{ color: "white" }}/>
          </Avatar> */}
        </AvatarGroup>

        <Typography align="center" variant="h3" sx={{ mb: 4, mt: 2 }}>
          {/* Get 1-hour free! */}
          Contact us
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Discover why over 200+ seniors chose Linked Lives as their personal,
          on-demand helpers with anything from gardening to computer help. Fill
          out our form and we'll give you a call.
        </Typography>
        {/* <Typography
          variant="body2"
          align="left"
          fontStyle="italic"
          sx={{ mb: 4 }}
        >
          Reach out on behalf of friends, family, and neighbors as well!
        </Typography> */}

        {submitted ? (
          <Box sx={{ height: "120px" }}>
            <Alert>We will be reaching out shortly!</Alert>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 1,
                width: "100%",
              }}
            >
              <TextField
                name="name" // Add this line
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
              <PhoneField
                phone={values.phone}
                error={errors.phone}
                handleChange={handleChange}
              />
            </Box>
            <TextField
              sx={{ mt: "16px" }} // Assuming the theme's spacing unit is 8px, 2 * 8px = 16px
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              name="message"
              label="Message"
              value={values.message}
              onChange={handleChange}
              placeholder="Write a brief message about your interest in Linked Lives."
            />
            <Button
              variant={
                values.name && values.phone.replace(/\D/g, "").length === 10
                  ? "contained"
                  : "outlined"
              }
              fullWidth
              disabled={loading}
              onClick={handleSave}
              sx={{ height: "56px", mt: 2, mb: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </>
        )}

        {/* <Typography align="center" variant="body2" sx={{ mt: 2 }}>
          It's time to tackle your to-do list.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default ContactUs;
