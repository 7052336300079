import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import FoundersPhoto from "../../assets/linked-lives-three-founding-brothers-low-res.jpg";
import CounterStats from "../../components/CounterStats";
import CredibilityGrid from "../../components/CredibilityGrid";
import InfiniteScrollAvatar from "../../components/popup/InfiniteScrollAvatars";
import { db } from "../../firebase";

const AboutCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          aboutViewed: true,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}

        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Why Linked Lives?
        </Typography>

        <Typography align="left" color={"text.secondary"} sx={{ mb: 0 }}>
          Our hard working employees provide seniors with excellent, local, and dependable
          household help. Experience the transformative power of community,
          family, and intergenerational relationships.
        </Typography>
        <Box sx={{ width: "calc(100vw - 48px)", overflow: "visible", left: "-24px", position: "relative" }}>
          <InfiniteScrollAvatar />
        </Box>

        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
          sx={{ mt: 2 }}
        >
          Family owned
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          We're a family-run business founded from the collective vision of us
          three brothers. With hearts to serve, we started out assisting seniors
          in our home town back in high school and through college.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
            }}
          >
            <CardMedia
              component="img"
              image={FoundersPhoto}
              alt="Founders of Linked Lives"
              sx={{
                width: { xs: "100%", sm: "100%" },
                height: { sm: "auto" },
                aspectRatio: { xs: "inherit", sm: "inherit" },
                borderRadius: "15px",
                flex: 1,
              }}
            />
            <Typography
              variant="body2"
              align="center"
              style={{ marginTop: "8px" }}
            >
              Alex, David, and Evan Rodriguez, brothers and founders of Linked
              Lives.
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              align="left"
              fontWeight={"600"}
              fontSize={"1.15rem"}
              gutterBottom
              sx={{ mt: 2 }}
            >
              Member testimonials
            </Typography>
            <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
            Our members value the peace of mind knowing they have reliable and capable help as needed. We're loved for our efficiency, versatility, and personable service!
            </Typography>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                paddingTop: "56.25%",
                borderRadius: "30px", // Add this line to round the borders of the container
                mt: 2,
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/cvfh96mkcdw?autoplay=1&mute=1&controls=1&loop=1&playlist=cvfh96mkcdw"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </Box>
          </Box>

          <CounterStats />
          <CredibilityGrid />
          {/* <InfiniteScrollAvatars /> */}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default AboutCard;
