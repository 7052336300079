import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { AddAPhoto, Delete } from "@mui/icons-material";

// Place in the parent functions
/*

const [selectedFiles, setSelectedFiles] = useState([]);

const handleFilesChange = useCallback((files) => {
  setSelectedFiles(files);
}, []);

// !!!!! CRITICAL !!!! //

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase"; // Import your Firebase configurations


const uploadMedia = async (userId) => {
  if (selectedFiles.length === 0) return [];
  return Promise.all(
    selectedFiles.map((file) => {
      const mediaRef = ref(storage, `media/${userId}/${file.name}`);
      return uploadBytes(mediaRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    })
  );
};

// .../ somewhere in your handleSubmit parent function \.... //

// ===>    
const mediaUrls = await uploadMedia( !!! userId );

*/

const MediaUpload = ({
  onFilesChange = () => {},
  accept = "image/*,video/*",
  multiple = true,
}) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [mediaPreviews, setMediaPreviews] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = [...mediaFiles, ...acceptedFiles];
    setMediaFiles(newFiles);

    // Generate previews
    const newPreviews = acceptedFiles.map((file) => {
      // Similar logic as in handleMediaChange
      if (file.type.startsWith("video/")) {
        return Promise.resolve(URL.createObjectURL(file));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
        });
      }
    });

    Promise.all(newPreviews).then((newPreviews) => {
      setMediaPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
      onFilesChange(newFiles); // Invoke callback here after updating state
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteFile = (index) => {
    const newFiles = mediaFiles.filter((_, idx) => idx !== index);
    setMediaFiles(newFiles);
    setMediaPreviews(mediaPreviews.filter((_, idx) => idx !== index));
    onFilesChange(newFiles);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" },
        }}
      >
        <Box
          sx={{
            my: 2,
            borderRadius: "15px",
            transition: "background-color .2s ease-in-out",
            "&:hover": {
              backgroundColor: "#F5F5F5", // Change this to your desired color
            },
          }}
        >
          <div {...getRootProps()} style={dropzoneStyle(isDragActive)}>
            <input {...getInputProps()} accept="image/*,video/*" />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddAPhoto sx={{ mr: 1 }} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>
                  {isMobile
                    ? "Tap here to select files"
                    : "Drag 'n' drop files here, or click to select"}
                </p>
              )}
            </div>
          </div>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mediaPreviews.map((preview, index) => (
          <Grid item xs={4} key={index}>
            <Box position="relative">
              <IconButton
                onClick={() => handleDeleteFile(index)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  },
                  zIndex: 10,
                }}
              >
                <Delete />
              </IconButton>
              {mediaFiles[index] &&
              mediaFiles[index].type.startsWith("video/") ? (
                <video
                  src={preview}
                  alt="Video Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                  controls
                />
              ) : (
                <img
                  src={preview}
                  alt="Image Preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Styling for the dropzone
const dropzoneStyle = (isDragActive) => ({
  border: "2px dashed #eeeeee",
  borderRadius: "15px",
  textAlign: "center",
  color: isDragActive ? "#2196f3" : "#757575",
  cursor: "pointer", // Cursor as pointer
  transition: "border .24s ease-in-out",
  padding: "16px",
});

export default MediaUpload;
