import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import StyledProgress from "../StyledProgress";
import CreateMemberAndAccountForm from "../../forms/CreateMemberAndAccountForm";
import { useSnackbar } from "../../providers/SnackbarProvider";
import {
  createAuth,
  createMemberAccountStripe,
  sendSignInLink,
} from "../../services/memberServices";
import { setUserRole } from "../../services/adminServices";
import { Event, PersonAdd } from "@mui/icons-material";
import { useEmployee } from "../../providers/EmployeeProvider";

const CreateMember = ({
  open,
  handleClose,
  handleOpenMember,
  setSelectedMember,
  userUid = "",
  userName = "",
  userAvatar = "",
}) => {
  const { data } = useEmployee();
  const [creatingMember, setCreatingMember] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const { showSnackbar } = useSnackbar(); // This is hypothetical; adjust based on your notification system
  const [showIntro, setShowIntro] = useState(true); // New state to control intro screen display
  const [showOutro, setShowOutro] = useState(false); // New state to control intro screen display

  const updateProgress = (newProgress, message) => {
    setProgress(newProgress);
    setProgressMessage(message);
  };

  const handleCreateMemberSubmit = async (values) => {
    setCreatingMember(true);

    try {
      const memberEmail = values.email;

      updateProgress(10, "Creating auth information...");
      const memberId = await createAuth({ memberEmail: memberEmail });

      updateProgress(25, "Assigning member role...");
      await setUserRole({ uid: memberId, role: "member" });

      updateProgress(70, "Creating account...");
      await createMemberAccountStripe({ ...values, memberId, memberEmail });

      updateProgress(85, "Sending sign-in link...");
      const uid = memberId;
      const replyTo = "alex@ourlinkedlives.com";
      await sendSignInLink({ uid, replyTo });
      setSelectedMember({ id: memberId });

      updateProgress(100, "All set! Redirecting..."); // Progress is at 100% after final step
      setShowOutro(true);
      setCreatingMember(false);
    } catch (error) {
      // Directly use the message from the Firebase error.
      const errorMessage = error.message || "An unexpected error occurred.";
      showSnackbar(errorMessage, "error");
      setCreatingMember(false);
    }
  };

  if (!open) return null;

  if (showIntro) {
    return (
      <>
        <DialogContent>
          <Box textAlign="center" sx={{ my: 4 }}>
            <PersonAdd color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Add a New Member
            </Typography>
            <Chip
              label="$50 Bonus"
              color="success"
              variant="outlined"
              sx={{ mb: 2 }}
            />

            <Typography align="left" variant="body1" sx={{ mb: 2 }}>
              Add a new member from your app so that you can get rewarded for
              helping them join.
            </Typography>
            <Typography align="left" variant="body1" sx={{ mb: 2 }}>
              Once this new member completes their first visit, you will receive
              your bonus in your next paycheck.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={() => setShowIntro(false)}
            variant="contained"
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </>
    );
  }

  if (showOutro) {
    return (
      <>
        <DialogContent>
          <Box textAlign="center" sx={{ my: 4 }}>
            <Event color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Success!
            </Typography>
            <Typography align="left" variant="body1" sx={{ mb: 2 }}>
              Great work bringing on a new member! Now, let's visit the member's
              profile to book the member's first visit.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={() => {
              handleOpenMember();
              handleClose();
              setShowOutro(false);
              setShowIntro(true);
            }}
            variant="contained"
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Go to Member
          </Button>
        </DialogActions>
      </>
    );
  }

  return creatingMember ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      bgcolor="background.paper"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="80%"
        minWidth="300px"
      >
        <Typography variant="h6" gutterBottom>
          {progressMessage}
        </Typography>
        <StyledProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  ) : (
    <CreateMemberAndAccountForm
      handleSubmit={handleCreateMemberSubmit}
      isAdmin={true}
      userUid={userUid}
      userName={userName}
      userAvatar={userAvatar}
    />
  );
};

export default CreateMember;
