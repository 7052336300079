import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  Timestamp,
  arrayUnion,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import CounterStats from "../../components/CounterStats";
import CredibilityGrid from "../../components/CredibilityGrid";
import GuestHeader from "../../components/GuestHeader";
import InfiniteScrollAvatars from "../../components/popup/InfiniteScrollAvatars";
import VisitReviewCarousel from "../../components/reviews/VisitReviewCarousel";
import { db } from "../../firebase";
import { useMember } from "../../providers/MemberProvider";
import FAQSection from "./sections/FAQSection";
import HowItWorks from "./sections/HowItWorks";
import LandingPage from "./sections/LandingPage";
import LandingVideoSection from "./sections/LandingVideoSection";
import SatisfactionGuarantee from "./sections/SatisfactionGuarantee";
import WebFooter from "./sections/WebFooter";
import AppOverview from "./waitlist/AppOverview";

function Home() {
  const auth = getAuth();
  const { member, loading } = useMember();
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { cityState } = useParams();

  let city = "";
  let state = "";

  if (cityState) {
    const parts = cityState.split("-");

    // State is always the last part
    state = parts[parts.length - 1].toUpperCase();

    // City is all parts except the last one, joined back with spaces
    city = parts
      .slice(0, -1)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  }

  const pageTitle = `Find Trusted Senior Help ${
    city && `in ${city}`
  } with Linked Lives`;
  const pageDescription = `Discover our excellent senior help service${
    city ? ` in ${city}` : " with 100's of chores and tasks"
  }${
    state ? `, ${state}` : ""
  }. Our trusted helpers will take care of your tasks, chores, and to-dos. Sign up to book your first visit today.`;

  console.log("city/state", [city, state]);

  useEffect(() => {
    if (loading) {
      console.log("Home.js -- loading");
    } else if (member && !loading) {
      console.log("Home.js -- Member is logged in:", member);
    } else {
      console.log("Home.js -- No member is logged in.");
    }
  }, [member]);

  useEffect(() => {
    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1), // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Home");
  }, []);

  useEffect(() => {
    let scrollTimeout;
    let scrollDepth = 0;

    const logScrollDepth = async () => {
      const timestamp = Timestamp.now();

      const logEntry = {
        page: "home",
        timestamp: timestamp,
        scrollDepth: scrollDepth,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              "~logs": arrayUnion(logEntry),
            },
            { merge: true }
          );
        } catch (err) {}
      }
    };

    const handleScroll = () => {
      const currentScrollDepth = Math.round(window.scrollY); // Measure scroll depth from the top in pixels
      scrollDepth = currentScrollDepth;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        logScrollDepth();
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <GuestHeader />
      <LandingPage city={city} state={state} />
      <CredibilityGrid />
      <CounterStats />
      <LandingVideoSection />

      <Box
        sx={{
          px: 2,
          display: "flex",
          alignItems: "center",
          zIndex: "10",
          position: "relative",
          mb: { xs: 4, sm: 10 },
        }}
      >
        <Button
          component={Link} // use the Link component as the root node of the Button
          to="/services" // use 'to' instead of 'href' for react-router's Link
          variant="contained"
          size="large"
          color="primary"
          sx={{
            width: { xs: "100%", sm: "250px" },
            textTransform: "none",
            height: "56px",
            mx: "auto",
          }}
        >
          See all services
        </Button>
      </Box>

      {/* <ReviewSection /> */}
      <InfiniteScrollAvatars />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              // height: "100%",
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h3"}
              fontWeight="700"
              gutterBottom
              align="center"
            >
              Member Reviews
              <span style={{ color: theme.palette.primary.vibrant }}></span>
            </Typography>
            <Typography
              variant="body1"
              mb={4}
              align="center"
              sx={{ maxWidth: "600px", mx: "auto" }}
            >
              Curious what our members are saying about Linked Lives? This is a
              great place to read reviews for recent visits and get inspiration
              for how we can help.
            </Typography>
          </Box>
          <VisitReviewCarousel START_LIMIT={10} />
          <Box sx={{ mx: 1, mb: 4, mx: "auto" }}>
            <Button
              color="success"
              disableElevation
              component={Link} // use the Link component as the root node of the Button
              to="/reviews" // use 'to' instead of 'href' for react-router's Link
              variant="contained"
              size="large"
              // color="primary"
              sx={{
                width: { xs: "100%", sm: "250px" },
                textTransform: "none",
                height: "56px",
                mx: "auto",
              }}
            >
              Read all reviews
            </Button>
          </Box>
        </Container>
      </Box>

      {/* <FreeHourOffer /> */}
      <HowItWorks city={city} />
      <SatisfactionGuarantee />
      <AppOverview />
      {/* <AsSeenInSection /> */}
      <FAQSection />
      <WebFooter />
    </div>
  );
}

export default Home;
