import { useTheme } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import React from "react";

const StyledProgress = ({ variant, value, color = "theme.palette.primary.main"}) => {
  const theme = useTheme();

  return (
    <LinearProgress
      variant={variant}
      value={value}
      sx={{
        height: 10,
        transition: "transform 0.5s ease-in-out", // This line ensures the progress bar's visual update will transition smoothly
        borderRadius: 5,
        width: "100%", // Ensures the progress bar takes full width of its container
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: color, // Use custom color or default to primary color

        },
      }}
    />
  );
};

export default StyledProgress;
