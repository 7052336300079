import { Box, CardMedia, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import FoundersPhoto from "../../../assets/linked-lives-three-founding-brothers.png";

const ThreeBrothersSection = () => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.primary.lightest }}>
        <Container
          sx={{
            paddingY: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "column" },
              alignItems: "center",
              maxWidth: "800px",
              mx: "auto",
              mb: { xs: 0, sm: 2 },
              mt: { xs: 0, sm: 4 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: { xs: 1, sm: 4 },
                mb: 2,
                flex: 1,
                maxWidth: "100%",
              }}
            >
              <Typography
                variant="h4"
                fontWeight="700"
                gutterBottom
                sx={{ mb: 4 }}
              >
                Started by{" "}
                <span style={{ color: theme.palette.primary.vibrant }}>
                  Three Brothers
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "left",
                  maxWidth: "800px",
                }}
              >
                <CardMedia
                  component="img"
                  image={FoundersPhoto}
                  alt="Founders of Linked Lives"
                  sx={{
                    width: { xs: "100%", sm: "100%" },
                    height: { sm: "auto" },
                    aspectRatio: { xs: "inherit", sm: "inherit" },
                    borderRadius: "15px",
                    flex: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginTop: "8px" }}
                >
                  Alex, David, and Evan Rodriguez, brothers and founders of
                  Linked Lives.
                </Typography>
              </Box>
              <Typography variant="body1" mb={4} mt={4}>
                Linked Lives is a family-run business founded from the
                collective vision of three caring brothers. With hearts to
                serve, we started out assisting seniors in our home town. As
                Linked Lives has grown, we continue to stay dedicated to
                upholding our local business beginnings.
              </Typography>
              <Typography variant="body1" mb={4}>
                From childhood, us founding brothers learned the value of
                lending a helping hand, assisting our friends, parents, and
                grandparents with a wide range of tasks. The gratitude we
                received wasn't just rewarding—it sparked an idea. We realized
                that our local community was filled with others in need of
                similar odd job help.
              </Typography>
              <Typography variant="body1" mb={4}>
                Thus, in 2022, out of this understanding and dedication, Linked
                Lives was born. Our mission? To foster meaningful,
                intergenerational relationships through excellent, local, and
                dependable service.
              </Typography>
              <Typography variant="body1" mb={0}>
                "Why 'Linked Lives'?" It's because we embody the warmth of the
                community, offer a promise of trustworthy assistance, and
                provide the comfort of feeling truly supported, regardless of
                the circumstances.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ThreeBrothersSection;
