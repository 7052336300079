import React, { useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  useMediaQuery,
} from "@mui/material";
import GuestHeader from "../../components/GuestHeader";
import { getAuth } from "firebase/auth";
import { useMember } from "../../providers/MemberProvider";
import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import WebFooter from "./sections/WebFooter";
import HiringLanding from "./sections/HiringLanding";
import EmployeeJobDescription from "./sections/EmployeeJobDescription";
import JobApplicationForm from "../../forms/JobApplicationForm";


function HiringPage() {
  const auth = getAuth();
  const { member, loading } = useMember();
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <GuestHeader />
      <HiringLanding />
      <EmployeeJobDescription />
      <div id="apply" />
      <JobApplicationForm />
      <WebFooter />
    </div>
  );
}

export default HiringPage;
