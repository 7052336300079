import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import ColumnView from "./ColumnView";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const WeekView = ({
  initialDay,
  visits,
  employeeId,
  timeZoneId,
  availability,
  isMobile,
  calendarDate,
  setCalendarDate,
  height,
}) => {
  const initialSunday = moment(initialDay).day(0);

  const [currentWeek, setCurrentWeek] = useState(initialSunday);
  const [weeks, setWeeks] = useState([]);
  const [swipeIndex, setSwipeIndex] = useState(3);

  console.log("WeekView height: ", height);

  useEffect(() => {
    const newWeeks = [
      moment(initialSunday).subtract(3, "week"), // Two weeks before
      moment(initialSunday).subtract(2, "week"), // Two weeks before
      moment(initialSunday).subtract(1, "week"), // One week before
      moment(initialSunday), // Current week
      moment(initialSunday).add(1, "week"),
    ];
    setWeeks(newWeeks);
    setCurrentWeek(moment(initialSunday));
    setCalendarDate(moment(initialSunday));
  }, []);

  const handleSwipe = (index) => {
    const newCurrentWeek = weeks[index];
    setCurrentWeek(newCurrentWeek);
    setCalendarDate(newCurrentWeek);

    setSwipeIndex(index);

    // Dynamically load weeks as needed
    if (index === 0) {
      setWeeks([newCurrentWeek.clone().subtract(1, "week"), ...weeks]);
    } else if (index === weeks.length - 1) {
      setWeeks([...weeks, newCurrentWeek.clone().add(1, "week")]);
    }
  };

  const goToWeek = (offset) => {
    const newIndex = swipeIndex + offset;
    if (newIndex >= 0 && newIndex < weeks.length) {
      handleSwipe(newIndex);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          userSelect: "none", // Disable text selection
          // pointerEvents: "none", // Disable pointer events like drag, drop, etc.
          "& *": {
            // Apply the same styles to all child elements
            userSelect: "none",
            // pointerEvents: "none",
          },
        }}
      >
        <IconButton onClick={() => goToWeek(-1)}>
          <ArrowBackIos color="primary" />
        </IconButton>
        <Typography
          align="center"
          variant="h6"
          sx={{ mx: 2, minWidth: "180px" }}
        >
          {currentWeek.format("MMMM D")} –{" "}
          {currentWeek.clone().add(6, "days").format("D")}
        </Typography>
        <IconButton onClick={() => goToWeek(1)}>
          <ArrowForwardIos color="primary" />
        </IconButton>
      </Box>
      <SwipeableViews index={swipeIndex} onChangeIndex={handleSwipe}>
        {weeks.map((week, index) => (
          <ColumnView
            key={index}
            numberOfDays={7}
            startDay={week.toDate()}
            visits={visits}
            employeeId={employeeId}
            timeZoneId={timeZoneId}
            truncateView={false}
            height={height}
            availability={availability}
          />
        ))}
      </SwipeableViews>
    </>
  );
};

export default WeekView;
