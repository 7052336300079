import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Photo1 from "../../../assets/senior-help-customer-service.png";

function ContactSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box py={5} bgcolor={theme.palette.grey[100]}>
      <Container>
        <Grid
          container
          spacing={isMobile ? 2 : 6}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <img
              src={Photo1}
              alt="Tech Support"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              style={{ padding: theme.spacing(4), borderRadius: "8px" }}
            >
              <Typography variant="h5" gutterBottom>
                Reach out directly
              </Typography>
              <Typography variant="body1" paragraph>
                Our team is here to assist you. Whether you have a question
                about our services, need assistance, or have feedback, we'd love
                to hear from you.
              </Typography>
              <Typography variant="h6">
                Phone:{" "}
                <a
                  href="tel:+13603366344"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                  }}
                >
                  (360) 336-6344
                </a>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Email:{" "}
                <a
                  href="mailto:info@ourLinkedLives.com"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                  }}
                >
                  info@ourLinkedLives.com
                </a>
              </Typography>
              {/* <Typography variant="body1">
                Feel free to reach out anytime. We're here to help!
              </Typography> */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactSection;
