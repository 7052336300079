import React, { useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  useMediaQuery,
  GridItem,
} from "@mui/material";
import garagePhoto from "../../../assets/senior-help-organize-garage.jpeg";
import satisfactionBadge from "../../../assets/senior-help-satisfaction-guarantee.png";
import helpArrivesImg from "../../../assets/senior-help-arrives.png";
import bookVisitImg from "../../../assets/senior-help-book-visit.png";
import customerServiceImg from "../../../assets/senior-help-customer-service.png";

import CardGrid from "../../../components/CardGrid";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme

function HowItWorks({ city = "" }) {
  // const theme = useTheme(); // hook to get current theme
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const theme = useTheme();

  const items = [
    {
      imageName: customerServiceImg,
      header: "Step 1: Sign Up",
      subText:
        "Spend 5 minutes to become a member online or over the phone. Please call if you have any specific questions.",
      buttonText: "Get Started",
      buttonDestination: "/member/signup",
    },
    {
      imageName: bookVisitImg,
      header: "Step 2: Schedule Your Visit",
      subText:
        "Describe the tasks you need help with, select your preferred employee, and pick the day and time that works best for you!",
      buttonText: "Schedule",
      buttonDestination: "/member/signup",
    },
    {
      imageName: helpArrivesImg,
      header: "Step 3: Trusted Help Arrives",
      subText:
        "Our trusted and hardworking employees will make quick work of your to-do list. Life is better with an extra pair of helping hands!",
      buttonText: "Sign up",
      buttonDestination: "/member/signup",
    },
    // ... Add more items as needed
  ];

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.lightest,
        paddingTop: "72px",
        paddingBottom: "32px",
      }}
    >
      <Container sx={{ pb: 2 }}>
        <Typography
          component="h2" // This changes the underlying element to an h1
          variant="h4"
          fontWeight="700"
          gutterBottom
        >
          Enjoy our Senior Helpers{" "}
          <span
            style={{
              color: theme.palette.primary.vibrant,
              fontStyle: "italic",
            }}
          >
            This Week!
          </span>
        </Typography>
        <Typography variant="body1" sx={{ my: 2 }}>
          {`From gardening to tech help, you’re just a few steps away from
          realizing all of your “to-do list” hopes and dreams! Our senior help
          service${city && ` based in ${city}`} is tailored to meet your
          household needs. With a Linked Lives Membership, we’re just a call
          away when you need an extra pair of hands.`}
        </Typography>
      </Container>
      <CardGrid items={items} />
    </div>
  );
}

export default HowItWorks;
