import {
  Close,
  HomeRepairServiceRounded,
  HomeRounded,
  Menu,
  MyLocation,
  PaidRounded,
  ReviewsRounded,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";
import Logo from "../svgs/Logo";
import WheelPopup from "./popup/WheelPopup";

function GuestHeader() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  // const location = useLocation();

  const [zipCode, setZipCode] = useState("");
  const [showZipCode, setShowZipCode] = useState(false);

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState("");

  const [sessionId, setSessionId] = useState("");

  const getSystemData = () => {
    return {
      device: navigator.userAgent || "unknown device",
      screenSize: `${window.screen.width || "unknown"}x${
        window.screen.height || "unknown"
      }`,
      language: navigator.language || "unknown",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown",
      effectiveType: navigator.connection
        ? navigator.connection.effectiveType || "unknown"
        : "unknown",
      type: navigator.connection
        ? navigator.connection.type || "unknown"
        : "unknown",
      windowSize: `${window.innerWidth || "unknown"}x${
        window.innerHeight || "unknown"
      }`,
    };
  };

  useEffect(() => {
    const initializeSession = async () => {
      const systemData = getSystemData();

      // Grab the facebook campaign ID
      const params = new URLSearchParams(window.location.search);
      const utmSource = params.get("utm_source") || "";

      if (utmSource) {
        localStorage.setItem("utm_source", utmSource);
      }

      let storedSessionId = localStorage.getItem("sessionId");
      if (!storedSessionId) {
        storedSessionId = uuidv4();
        localStorage.setItem("sessionId", storedSessionId);

        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              created: new Date(),
              utmSource,
              "~systemData": systemData, // Store system data
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
      setSessionId(storedSessionId);
    };

    initializeSession();
  }, []);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setLocationError(error.message);
        }
      );
    } else {
      setLocationError("Geolocation is not supported by this browser.");
    }
  };

  console.log("location: ", location);

  // useEffect(() => {
  //   fetchLocation(); // Fetch location when component mounts
  // }, []);

  const handleZipCodeChange = (event) => {
    const input = event.target.value;
    // Allow only digits and limit to 5 characters
    if (/^\d{0,5}$/.test(input)) {
      setZipCode(input);
      if (input.length === 5) {
        localStorage.setItem("zipCode", input);
        setShowZipCode(true);
      }
    }
  };

  const clearZipCode = () => {
    setZipCode("");
    setShowZipCode(false);
    localStorage.removeItem("zipCode");
  };

  const loadZipCode = () => {
    const storedZip = localStorage.getItem("zipCode");
    if (storedZip) {
      setZipCode(storedZip);
      setShowZipCode(true);
    }
  };

  // useEffect(() => {
  //   loadZipCode();
  // }, []);

  const tabs = [
    { link: "/", icon: <HomeRounded />, text: "Home" },
    { link: "/services", icon: <HomeRepairServiceRounded />, text: "Services" },
    { link: "/reviews", icon: <ReviewsRounded />, text: "Reviews" },
    { link: "/pricing", icon: <PaidRounded />, text: "Pricing" },
    { link: "/about", icon: <PaidRounded />, text: "About" },
  ];

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleSignInClick = async () => {
    if (auth.currentUser) {
      try {
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to fetch Member data:", error);
      }
    } else {
      navigate("/member/login");
    }
  };

  const renderZipCode = () => {
    return showZipCode ? (
      <Chip
        label={zipCode}
        icon={<MyLocation style={{ color: "rgba(0, 0, 0, 0.26)" }} />}
        onClick={(event) => {
          event.stopPropagation();
          clearZipCode();
        }}
        variant="filled"
        sx={{
          width: { xs: "auto", sm: "100px" },
          height: "40px",
          cursor: "pointer",
        }} // Add cursor style for better UX
      />
    ) : (
      <TextField
        label="Zip Code"
        variant="outlined"
        autoFocus
        value={zipCode}
        onChange={handleZipCodeChange}
        onClick={(event) => event.stopPropagation()}
        size="small"
        sx={{
          width: { xs: "auto", sm: "100px" },
          height: "40px",
          ".MuiInputBase-input": {
            // Targeting the input element for styling
            textAlign: "center", // Centering the text
          },
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          maxLength: 5,
        }}
      />
    );
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      style={{ width: isMobile ? "100%" : "250px" }}
    >
      <nav>
        {tabs.map((tab, index) => (
          <ListItemButton
            key={index}
            component={Link}
            to={tab.link}
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              {/* <ListItemIcon>{tab.icon}</ListItemIcon>  */}
              <ListItemText primary={tab.text} />
            </Box>
          </ListItemButton>
        ))}
      </nav>
      <Box p={2} gap={2} display="flex" flexDirection="column">
        {/* {renderZipCode()} */}
        <Button
          component={Link}
          disableElevation
          to="/member/signup"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          sx={{
            // textTransform: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "60px",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          {/* <Typography sx={{ fontWeight: "500" }}>Book a Visit</Typography> */}
          {/* <Typography sx={{ fontWeight: "800" }}>(360) 336-6344</Typography> */}
          Sign up
        </Button>
        <Button
          component={Link}
          disableElevation
          onClick={handleSignInClick}
          to="/member/login"
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          mb={2}
          sx={{
            height: "60px",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          Login
        </Button>
      </Box>
    </div>
  );

  return (
    <>
      {/* <ReminderPopup /> */}
      <AppBar
        position="sticky"
        style={{ backgroundColor: "white" }}
        elevation={0}
      >
        <Toolbar
          sx={{
            height: isMobile ? "80px" : "100px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {isMobile ? (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Box width={150}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Logo color="#000" />
                </Link>
              </Box>

              <IconButton aria-label="menu" onClick={toggleDrawer(!drawerOpen)}>
                {drawerOpen ? <Close /> : <Menu />}
              </IconButton>

              <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{
                  style: {
                    top: "80px",
                    borderRadius: "20px",
                    position: "absolute",
                    left: "8px",
                    right: "8px",
                    // width: "auto",
                    mx: "10px",
                    zIndex: 1, // This makes sure the Drawer stays below the AppBar
                  },
                }}
              >
                {list()}
              </Drawer>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              sx={{ mx: { md: 0, lg: 2 } }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: { md: 150, lg: 200 },
                    height: { md: "26px", lg: "32px" },
                  }}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Logo color="#000" />
                  </Link>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                flexGrow={1}
                maxWidth="700px"
              >
                {tabs.map((tab, index) => (
                  <Link
                    key={index}
                    to={tab.link}
                    className={
                      location.pathname === tab.link ? "activeTab" : ""
                    }
                    style={{
                      textDecoration: "none",
                      color: "black",
                      position: "relative",
                    }}
                  >
                    <Typography
                      component="span"
                      fontWeight="400"
                      sx={{ fontSize: { md: "1.15rem", lg: "1.5rem" } }}
                    >
                      {tab.text}
                    </Typography>
                    {location.pathname === tab.link && (
                      <Box
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "1px",
                          backgroundColor: "black",
                        }}
                      ></Box>
                    )}
                  </Link>
                ))}
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                {/* {renderZipCode()} */}
                <Button
                  onClick={handleSignInClick}
                  variant="outlined"
                  size="large"
                  // disableElevation
                  color="primary"
                  mr={2}
                  sx={{
                    width: "125px",
                    height: "60px",
                    textTransform: "none",
                    fontSize: "1.15rem",
                    boxShadow:
                      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  Login
                </Button>
                <Button
                  component={Link}
                  size="large"
                  to="/member/signup"
                  // disableElevation
                  variant="contained"
                  color="primary"
                  sx={{
                    // width: "175px",
                    width: "125px",
                    textTransform: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "60px",
                    fontSize: "1.15rem",
                    boxShadow:
                      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  {/* <Typography sx={{ fontWeight: "500" }}>Book a Visit</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  (360) 336-6344
                </Typography> */}
                  Sign up
                </Button>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <WheelPopup />
    </>
  );
}

export default GuestHeader;
