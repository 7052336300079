export const validatePhone = (values) => {
  let errors = {};

  if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
    errors.phone = "Your phone number must be 10 digits.";
  return errors;
};

export const validateEmail = (values) => {
  let errors = {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!values.email) {
    errors.email = "Email address is required.";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Enter a valid email address.";
  }

  return errors;
};
