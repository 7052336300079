import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useMember } from "../../providers/MemberProvider";

import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ResponsiveDrawer from "../../components/ResponsiveDrawer";
import VisitCard from "../../components/VisitCard";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const MemberCalendar = ({
  visits,
  setVisits,
  timeZoneId,
  view,
  setView,
  calendarDate,
  setCalendarDate,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  moment.tz.setDefault("America/Los_Angeles");
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const anchor = isMobile ? "bottom" : "right";

  const { data } = useMember(); // Assuming you've wrapped your app in the EmployeeProvider context
  console.log("HEHEHE data: ", data);

  const currentMemberId = data?.auth?.uid;

  const roundToMinutes = (date) => {
    const roundedDate = moment(date);
    const seconds = roundedDate.seconds();

    if (seconds >= 30) {
      roundedDate.add(1, "minute").seconds(0);
    } else {
      roundedDate.seconds(0);
    }

    return roundedDate;
  };

  const handleEventClick = (event, e) => {
    console.log("handle event click");
    setSelected(event); // Here the reference should be the same as in the events array
    console.log(event);
    console.log("setting open true");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const darkenHSLAColor = (color, percentDarker) => {
    // Regular expressions to match HSLA or HSL colors
    const hslaRegex =
      /hsla?\(\s*(\d+),\s*([\d.]+)%,\s*([\d.]+)%,?\s*([\d.]+)?\)/;
    const match = color.match(hslaRegex);

    if (!match) {
      // If there's no match, return the original string
      return color;
    }

    // Extract the individual HSLA components
    const hue = match[1];
    const saturation = match[2];
    let lightness = parseFloat(match[3]);
    const alpha = match[4]; // This will be undefined for HSL colors

    // Calculate the new lightness, ensuring it's within the 0-100% range
    lightness = Math.max(0, Math.min(100, lightness - percentDarker));

    if (alpha) {
      // If alpha was defined, return an HSLA string
      return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
    } else {
      // Otherwise, return an HSL string
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor;
    let borderColor;
    let color;
    let opacity;
    let width = "100%"; // Modify this value if needed based on event type or other conditions

    if (event.type === "visit") {
      opacity = 1; // default opacity

      backgroundColor = theme.palette.primary.main; // assuming blue[500] maps to your primary color
      color = "white";
    }

    return {
      className: `event-type-${event.type}`, // This will give classes like 'event-type-visit', 'event-type-availability', etc.
      style: {
        backgroundColor,
        borderColor,
        color,
        opacity,
      },
    };
  };

  const customFormatTime = (date) => {
    // Format the time. 'h:mm a' gives you the hour with the minute and AM/PM part.
    let time = moment(date).format("h:mm a");

    // Remove ':00' part, if present. This line finds ':00' and removes it.
    time = time.replace(/:00/, "");

    // Remove the space between the hour and the am/pm indicator, and convert to lowercase.
    // This line finds the space character and replaces it with an empty string, effectively removing it.
    time = time.replace(/\s/g, "").toLowerCase();

    return time;
  };

  const EventComponent = ({ event }) => {
    // let view = "week";
    const formattedStartTime = customFormatTime(event.start);
    const formattedEndTime = customFormatTime(event.end);

    // Adjust styles based on the current view.
    let titleVariant = "subtitle1";
    let customStyle = { lineHeight: "1" };

    console.log("VIEW: ", view);

    if (view === "week" || view === "month" || view === "agenda") {
      titleVariant = "caption"; // smaller font size for these views
      customStyle.fontSize = "0.75rem"; // or any other styling based on your UI/UX requirements
    }

    return (
      <div>
        <Typography
          style={{ fontWeight: 600, ...customStyle }}
          variant={titleVariant}
        >
          {event.title}
        </Typography>
        <br />
        <Typography style={customStyle} variant="caption">
          {formattedStartTime} - {formattedEndTime}
        </Typography>
      </div>
    );
  };

  const formats = {
    timeGutterFormat: (date, culture, localizer) => {
      return customFormatTime(date);
    },
  };

  const handleViewChange = (newView) => {
    console.log("newView: ", newView);
    setView(newView); // this updates the view state when the calendar view changes
  };

  // Function to determine if the event is resizable
  const isEventResizable = (event) => {
    return false;
    // if (event.end < moment()) return;

    // // Events of type "visit" are not resizable
    // return event.type !== "visit" && event.type !== "openVisit";
  };

  // Function to determine if the event is draggable
  const isEventDraggable = (event) => {
    return false;
    // if (event.end < moment()) return;

    // // Events of type "visit" are not draggable
    // return event.type !== "visit" && event.type !== "openVisit";
  };

  const handleNavigate = (date) => {
    setCalendarDate(date);
  };

  const getVisitData = (type, id) => {
    if (type === "visit") {
      console.log("type: ", type);
      console.log("id: ", id);
      console.log("data visits: ", data.visits);

      console.log(":LISJF:ijs;flkjad;flkjads: ", data?.visits[id]);
      return data?.visits[id];
    }
  };

  // Helper function to determine if the event is in the past
  const isEventPast = () => {
    return selected.start < moment();
  };

  return (
    <>
      <div style={{ backgroundColor: "white", height: "85vh" }}>
        <DndProvider backend={HTML5Backend}>
          <DnDCalendar
            id="my-calendar-id"
            localizer={localizer}
            events={visits}
            onView={setView}
            view={view}
            // onEventDrop={onEventDrop}
            // onEventResize={onEventResize}
            resizableAccessor={isEventResizable} // controls whether the event is resizable
            draggableAccessor={isEventDraggable} // controls whether the event is draggable
            // date={calendarDate}
            onNavigate={handleNavigate}
            resizable
            selectable
            // onSelectSlot={onSelectSlot}
            onSelectEvent={handleEventClick}
            formats={formats}
            defaultView={window.innerWidth < 768 ? Views.DAY : Views.WEEK}
            views={
              window.innerWidth < 768
                ? [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA] //[Views.DAY, Views.AGENDA]
                : [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]
            }
            eventPropGetter={eventStyleGetter}
            style={{ height: "100%" }}
            min={moment().tz(timeZoneId).set({ hour: 8, minute: 0 }).toDate()}
            max={moment().tz(timeZoneId).set({ hour: 18, minute: 0 }).toDate()}
            components={{
              event: EventComponent,
              toolbar: CustomToolbar,
              week: {
                header: CustomWeekHeader,
              },
            }}
          />
        </DndProvider>
      </div>
      <ResponsiveDrawer
        title={"Visit Details"}
        minWidth={"600px"}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {selected && selected?.type === "visit" && data.visits[selected.id] && (
          <VisitCard
            key={selected.id}
            visit={data?.visits[selected.id]}
            visitId={selected.id}
            employees={data?.employees}
            handleClose={handleClose}
          />
        )}
      </ResponsiveDrawer>
    </>
  );
};

export default MemberCalendar;

class CustomToolbar extends React.Component {
  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  render() {
    const {
      localizer: { messages },
      label,
    } = this.props;

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Centers horizontally
          alignItems: "center", // Centers vertically
          position: "relative", // Position absolute helps in stretching the container
          marginY: 1,
          // top: 0, // Stretch from the top of the screen
          // right: 0,
          // bottom: 0, // Stretch to the bottom of the screen
          // left: 0,
        }}
      >
        <IconButton color="primary" onClick={() => this.navigate("PREV")}>
          <ChevronLeft />
        </IconButton>
        <Box sx={{ width: "240px", textAlign: "center" }}>
          {" "}
          {/* You can adjust the width as needed */}
          <Typography variant="h6" noWrap>
            {label}
          </Typography>
        </Box>{" "}
        <IconButton color="primary" onClick={() => this.navigate("NEXT")}>
          <ChevronRight />
        </IconButton>
      </Box>
    );
  }
}

const CustomWeekHeader = ({ date }) => {
  return (
    <div style={{ height: "60px", textAlign: "center", padding: "4px" }}>
      <Typography variant="caption" component="div">
        {moment(date).format("ddd")} {/* Day of the week */}
      </Typography>
      <Typography variant="body1" component="div" style={{ fontWeight: "500" }}>
        {moment(date).format("D")} {/* Day of the month */}
      </Typography>
    </div>
  );
};
