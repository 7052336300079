import {
  AccessTime,
  ExpandMore,
  GroupOutlined,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  Accordion,
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import { db } from "../firebase";
// import { touchRippleClasses } from "@mui/core";

const ReviewVisitMember = ({ visit, visitId, handleClose, stars = null }) => {
  const [rating, setRating] = useState(stars);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setRating(stars);
  }, [stars]);

  // Utility functions for date and time formatting
  const formatDate = (date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  const formatTimeRange = (startDate, endDate) => {
    const startTime = moment(startDate).format("h:mm A");
    const endTime = moment(endDate).format("h:mm A");
    return `${startTime} - ${endTime}`;
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    // [`& .${touchRippleClasses.rippleVisible}`]: {
    //   color: theme.palette.primary.main, // This sets the ripple color
    //   opacity: 0.6,
    // },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
  }));

  const handleSubmit = async () => {
    setLoading(true); // Start the loading state

    // Reference to the visit document
    const visitDocRef = doc(db, "visits", visitId);

    try {
      await updateDoc(visitDocRef, {
        rating: rating,
        review: review,
      });
      console.log("Document successfully updated!");
      setLoading(false); // Stop the loading state
      setShowConfirmation(true); // Show the confirmation
    } catch (error) {
      console.error("Error updating document: ", error);
      setLoading(false); // Stop the loading if there's an error
    }
  };

  // useEffect(() => {
  //   if (showConfirmation) {
  //     const timer = setTimeout(() => {
  //       setShowConfirmation(false); // Hide the confirmation
  //       handleClose(); // Close the entire component
  //     }, 4000); // 4 seconds delay
  //     return () => clearTimeout(timer); // Clear timer if component is unmounted
  //   }
  // }, [showConfirmation, handleClose]);

  const formatEmployeeNames = (employees) => {
    console.log("employees:", employees);

    if (!employees || Object.keys(employees).length === 0) return;

    const names = Object.values(employees).map((emp) =>
      emp.displayName.slice(0, -3)
    );

    if (names.length === 1) return ` with ${names[0]}`;
    if (names.length === 2) return ` with ${names[0]} and ${names[1]}`;
    if (names.length > 2) {
      const last = names.pop();
      return ` with ${names.join(", ")} and ${last}`;
    }

    return ""; // Fallback case if no employees
  };

  const employeeNames = formatEmployeeNames(visit?.employees);

  console.log("visit: ", visit);

  return (
    <>
      <DialogContent>
        {/* Centered Rating Section */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : showConfirmation ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
              marginX: "32px",
            }}
          >
            <ConfirmationBurst size="xlarge" />
            <Typography variant="h6" marginTop={2}>
              Received!
            </Typography>
            <Typography>
              Thanks for taking the time to let us know how we're doing. We're
              always working to provide the best service for you!
            </Typography>
          </Box>
        ) : (
          <>
            {/* Visit Details Accordion */}
            <Accordion disableGutters elevation={0}>
              <AccordionSummary
                sx={{ marginBottom: "16px" }}
                expandIcon={<ExpandMore color="primary" />}
              >
                <Typography color="primary">Visit Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItem>
                  <ListItemIcon>
                    <AccessTime color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        {formatDate(moment(visit?.start?.toDate()).toDate())}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body1">
                        {formatTimeRange(
                          moment(visit?.start?.toDate()).toDate(),
                          moment(visit?.end?.toDate()).toDate()
                        )}
                      </Typography>
                    }
                  />
                </ListItem>

                {/* Display all employees and their avatars */}
                {visit?.employees &&
                  Object.values(visit.employees).map((employee, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <GroupOutlined color="primary" />
                      </ListItemIcon>
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {employee.avatarUrl && (
                          <Avatar
                            src={employee.avatarUrl}
                            alt="Employee Avatar"
                          />
                        )}
                        <Typography>{employee.displayName}</Typography>{" "}
                        {/* assuming each employee has a 'name' property */}
                      </Box>
                    </ListItem>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Box
              sx={{
                display: "flex",
                position: "sticky",
                top: isMobile ? "32vh" : undefined,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                marginY: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h6" align="center">
                {`How was your visit?`}
              </Typography>

              {/* Star Rating */}
              <Rating
                size="large"
                value={rating}
                name="visit-rating"
                icon={<StarRounded fontSize="inherit" />}
                emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                sx={{ fontSize: "3rem" }} // Adjust the size as needed
                onChange={(event, newRating) => {
                  setRating(newRating);
                }}
                precision={1}
              />
              {/* Comment Section */}
              <Fade in={!!rating}>
                <TextField
                  label="Leave a comment (optional)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </Fade>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!rating || loading} // Disable the button if loading
            sx={{
              width: "100%",
              height: "60px",
            }}
            onClick={showConfirmation ? handleClose : handleSubmit}
            variant="contained"
            color="primary"
          >
            {showConfirmation ? "Go Back" : "Submit review"}
          </Button>
          {!showConfirmation && (
            <Typography variant="caption" sx={{ mt: 1 }}>
              Your visit rating, comment, and task description will become
              visible to others on the platform.
            </Typography>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ReviewVisitMember;
