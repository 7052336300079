import Schedule from "./tabs/Schedule";

import {
  Handshake,
  HandshakeOutlined,
  Home,
  HomeOutlined,
  RecentActors,
  RecentActorsOutlined,
  SettingsOutlined,
  SettingsRounded,
  StarsOutlined,
  StarsRounded,
  Today,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContentView from "../ContentView";
import Sidebar from "../Sidebar";

import MemberTab from "../../components/MemberTab/MemberTab";
import { useEmployee } from "../../providers/EmployeeProvider";
import { SnackbarProvider } from "../../providers/SnackbarProvider";
import BottomNav from "../BottomNav";
import NewEmployeeWelcome from "./modals/NewEmployeeWelcome";
import HomePage from "./tabs/HomePage";
import Settings from "./tabs/Settings";
import Social from "./tabs/Social";
import ReviewsTab from "./tabs/ReviewsTab";

const EmployeeDashboard = () => {
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const { data } = useEmployee();

  const isAdmin = data?.employee?.isAdmin || false; // Safety check in case 'employee' is undefined

  const accountSetup = data?.employee?.status !== undefined;

  const tabs = [
    {
      label: "Home",
      color: "#eceff1",
      component: HomePage,
      icon: <Home />,
      outlineIcon: <HomeOutlined />,
    },
    {
      label: "Schedule",
      component: Schedule,
      icon: <Today />,
      outlineIcon: <TodayOutlined />,
      disabled: !accountSetup,
    },
    // {
    //   label: "Activity",
    //   component: Social,
    //   color: "#eceff1",
    //   icon: <Handshake />,
    //   outlineIcon: <HandshakeOutlined />,
    //   disabled: !accountSetup,
    // },
    {
      label: "Reviews",
      component: ReviewsTab,
      icon: <StarsRounded />,
      outlineIcon: <StarsOutlined />,
      disabled: !accountSetup,
    },
    {
      label: "Members",
      component: MemberTab,
      icon: <RecentActors />,
      outlineIcon: <RecentActorsOutlined />,
      disabled: !accountSetup,
    },
    {
      label: "Settings",
      component: Settings,
      icon: <SettingsRounded />,
      outlineIcon: <SettingsOutlined />,
      disabled: !accountSetup,
    },

    // { label: "FAQ", component: FAQ, icon: <LiveHelp /> },
    // { label: 'Book a Visit', component: ScheduleVisit, icon: <Add /> },
  ];

  const mobileOnlyTabs = isAdmin
    ? ["Home", "Schedule", "Reviews", "Members", "Settings"]
    : ["Home", "Schedule", "Reviews", "Members", "Settings"];

  const [selectedTab, setSelectedTab] = useState(tabs[0].label);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Just a mock check, replace this with your actual logic for checking if the user is new
    const isNewUser = true; // Replace with logic to check if user is new
    if (isNewUser) {
      // setWelcomeModalOpen(true);
    }
  }, []);

  const handleTabChange = (tabLabel) => {
    // Scroll to the top when tab changes
    window.scrollTo(0, 0);
    setSelectedTab(tabLabel);
  };

  return (
    <SnackbarProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100%",
        }}
      >
        {isMobile ? (
          <>
            <ContentView tabs={tabs} selectedTab={selectedTab} />
            <BottomNav
              tabs={tabs}
              selectedTab={selectedTab}
              setTab={handleTabChange}
              mobileOnlyTabs={mobileOnlyTabs}
            />
          </>
        ) : (
          <>
            <Sidebar
              tabs={tabs}
              selectedTab={selectedTab}
              onChangeTab={handleTabChange}
            />
            <ContentView tabs={tabs} selectedTab={selectedTab} />
          </>
        )}
        <NewEmployeeWelcome
          open={welcomeModalOpen}
          handleClose={() => setWelcomeModalOpen(false)}
        />
      </Box>
    </SnackbarProvider>
  );
};

export default EmployeeDashboard;
