import { Typography } from "@mui/material";
import React from "react";

const EventConfirmation = () => {
  return (
    <>
      <Typography variant="body2" gutterBottom>
        You're on the sign up list! You will receive updates via text or email
        on the day of the event to confirm your participation.
      </Typography>
      {/* <Typography variant="body1" component="div" sx={{ fontWeight: "bold" }}>
        <Info
          sx={{
            verticalAlign: "middle",
            mr: 1,
            color: "primary.main",
          }}
        />
        Check back here for your estimated time and position in the list.
      </Typography> */}
      {/* Add more details or components as needed */}
    </>
  );
};

export default EventConfirmation;
