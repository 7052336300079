// -- components/memberDashboard/ProfileTab.js
import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import AvatarUpload from "../../../components/AvatarUpload";
import ChangePassword from "../../../components/ChangePassword";
import { auth, db } from "../../../firebase";
import { useMember } from "../../../providers/MemberProvider";
import ResponsiveDialog from "../../../components/ResponsiveDialog";

const ProfileTab = () => {
  const { data } = useMember();
  const [loading, setLoading] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPasswordChangeDialogOpen, setPasswordChangeDialogOpen] =
    useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);

  const [editProfileDialogOpen, setEditProfileDialogOpen] = useState(false);
  const [editData, setEditData] = useState({
    firstName: data.member.firstName,
    lastName: data.member.lastName,
    email: data?.member.private?.data.email,
    phone: data?.member.private?.data.phone,
    avatarUrl: data.member.avatarUrl,
  });

  useEffect(() => {
    if (auth.currentUser) {
      // Check if the user is signed in with Google
      const providerData = auth.currentUser.providerData;
      const isGoogle = providerData.some(
        (provider) => provider.providerId === "google.com"
      );
      console.log("google user?: ", isGoogle);
      setIsGoogleUser(isGoogle);
    }
  }, [auth.currentUser]);

  // Helper function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handlePhoneChange = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    setEditData((prev) => ({ ...prev, phone: e.target.value }));
  };

  if (!data) return null;

  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      const capitalizedFirstName = capitalizeFirstLetter(editData.firstName);
      const capitalizedLastName = capitalizeFirstLetter(editData.lastName);

      // Reference to the main member document
      const memberDocRef = doc(db, "members", data.auth.uid);

      // Update the main document
      await updateDoc(memberDocRef, {
        firstName: capitalizedFirstName,
        lastName: capitalizedLastName,
        avatarUrl: editData.avatarUrl,
      });

      // Reference to the private subdocument
      const memberPrivateDocRef = doc(
        db,
        "members",
        data.auth.uid,
        "private",
        "data"
      );

      // Update the private subdocument
      await updateDoc(memberPrivateDocRef, {
        phone: editData.phone, // Assuming phone is stored in the private subdocument
      });

      console.log("Profile updated successfully");
      setEditProfileDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating profile: ", error);
    }
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Profile
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          Keep your personal information current. Update your profile details,
          change your password, or set a new avatar.
        </Typography>
        <Stack spacing={2}>
          <Box
            display="flex"
            sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
          >
            <Avatar
              src={data?.member?.avatarUrl}
              alt={`${data?.member.firstName} ${data?.member.lastName}`}
              // optional: you can set a size for the avatar
              sx={{ width: 128, height: 128 }}
            />
          </Box>
          <Typography variant="body1">
            <strong>Name:</strong>
            <br /> {data?.member.firstName} {data?.member.lastName}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong>
            <br />
            {data?.member?.private?.data?.email ? (
              data.member.private.data.email
            ) : (
              <Skeleton variant="text" width={200} />
            )}
          </Typography>
          <Typography variant="body1">
            <strong>Phone:</strong>
            <br />{" "}
            {data?.member?.private?.data?.phone ? (
              data.member.private.data.phone
            ) : (
              <Skeleton variant="text" width={200} />
            )}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "block" },
            flexDirection: { xs: "column-reverse", sm: "row" },

            width: { xs: "100%", sm: "auto" },
          }}
        >
          {!isGoogleUser && (
            <Button
              onClick={() => setPasswordChangeDialogOpen(true)}
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
                textTransform: "none",
                mr: { xs: 0, sm: 1 },
              }}
              disableElevation={true}
              variant="outlined"
              color="primary"
            >
              Change Password
            </Button>
          )}

          <Button
            onClick={() => setEditProfileDialogOpen(true)}
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
              textTransform: "none",
              mb: { xs: 1, sm: 0 },
            }}
            variant="contained"
            color="primary"
          >
            Edit Profile
          </Button>
        </Box>
      </DialogActions>

      <ResponsiveDialog
        open={editProfileDialogOpen}
        onClose={() => setEditProfileDialogOpen(false)}
        width="600px"
        title={"Edit profile"}
        dialogActions={
          <>
            <Button
              onClick={() => setEditProfileDialogOpen(false)}
              color="primary"
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateProfile}
              color="primary"
              variant="contained"
              disabled={loading} // Disable the button when loading is true
              endIcon={loading ? <CircularProgress size={20} /> : null} // Show loading icon when loading
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText marginBottom={2}>
            To update your personal information, please edit the fields below
            and press save to commit your changes.
          </DialogContentText>
          <Stack spacing={2}>
            <div>
              <AvatarUpload
                avatarUrl={editData.avatarUrl}
                onAvatarUpload={(url) =>
                  setEditData((prev) => ({ ...prev, avatarUrl: url }))
                }
              />
            </div>
            <TextField
              label="First Name"
              value={editData.firstName}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, firstName: e.target.value }))
              }
              fullWidth
            />
            <TextField
              label="Last Name"
              value={editData.lastName}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, lastName: e.target.value }))
              }
              fullWidth
            />
            {/* <TextField
              label="Email"
              value={editData.email}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, email: e.target.value }))
              }
              fullWidth
            /> */}
            <TextField
              label="Phone"
              value={editData.phone}
              onChange={handlePhoneChange}
              fullWidth
            />
          </Stack>
        </DialogContent>
      </ResponsiveDialog>

      <Dialog
        open={isPasswordChangeDialogOpen}
        onClose={() => setPasswordChangeDialogOpen(false)}
      >
        <DialogTitle>Change Password</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setPasswordChangeDialogOpen(false)}
          sx={{
            position: "absolute",
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <ChangePassword
            disableCancel={false}
            setOpen={setPasswordChangeDialogOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileTab;
