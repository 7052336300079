import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import React, { useState } from "react";
import ApplyDiscountForm from "./ApplyDiscountForm";
import ResponsiveDialog from "./ResponsiveDialog";

const DiscountsList = ({
  discounts,
  timeZoneId,
  memberId,
  memberPhone = "",
  isAdmin = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openApplyDiscount, setOpenApplyDiscount] = useState(false);

  const getIcon = (type) => {
    switch (type) {
      case "percent":
        return <PercentIcon />;
      case "dollars":
        return <AttachMoneyIcon />;
      case "hours":
        return <AccessTimeIcon />;
      default:
        return null;
    }
  };

  const formatDate = (timestamp) => {
    return timestamp
      ? moment(timestamp.toDate()).tz(timeZoneId).format("MM/DD/YY")
      : "";
  };

  const formatAmount = (amount, type) => {
    if (type === "hours") {
      return `${amount} hour${amount !== 1 ? "s" : ""}`;
    }
    return `${amount} ${type}`;
  };

  const isExpired = (discount) => {
    if (!discount.expiration) return null;
    const discountDate = moment(discount.expiration.toDate()).tz(timeZoneId);
    const expired = discountDate.isBefore(moment().tz(timeZoneId));
    return expired;
  };

  // Early return if discounts data is not loaded
  if (!discounts) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <DialogContent>
        <List>
          {Object.entries(discounts).map(([key, discount], index) => {
            const expired = isExpired(discount);
            return (
              <>
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    my: 1,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    {!isMobile && (
                      <Icon sx={{ mr: 1 }}>{getIcon(discount.type)}</Icon>
                    )}

                    <Box>
                      <Typography variant="subtitle2">
                        {discount.description}
                      </Typography>
                      <Typography variant="body2">
                        {formatAmount(discount.amount, discount.type)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flexShrink: 1,
                      display: "flex",
                      flexDirection: "column",
                      ml: 1,
                    }}
                  >
                    {discount.used ? (
                      <Alert icon={false} color="primary" sx={{ ml: 1, py: 0 }}>
                        <Typography variant="caption">
                          Used: {formatDate(discount.used)}
                        </Typography>
                      </Alert>
                    ) : expired ? (
                      <Alert icon={false} color="error" sx={{ ml: 1, py: 0 }}>
                        <Typography variant="caption">
                          Expired: {formatDate(discount.expiration)}
                        </Typography>
                      </Alert>
                    ) : (
                      <Alert icon={false} color="success" sx={{ ml: 1, py: 0 }}>
                        <Box display="flex" flexDirection={"column"}>
                          <Typography variant="caption">
                            Created: {formatDate(discount.created)}
                          </Typography>
                          <Typography variant="caption">
                            Expires:{" "}
                            {discount.expiration
                              ? formatDate(discount.expiration)
                              : "never"}
                          </Typography>
                        </Box>
                      </Alert>
                    )}
                  </Box>
                </Box>
                {index !== Object.keys(discounts).length - 1 && <Divider />}
              </>
            );
          })}
        </List>
      </DialogContent>

      {isAdmin && (
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={() => setOpenApplyDiscount(true)}
            variant="contained"
            sx={{
              minWidth: { xs: "100%", sm: "200px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            Create discount
          </Button>
        </DialogActions>
      )}

      {isAdmin && (
        <ResponsiveDialog
          open={openApplyDiscount}
          onClose={() => setOpenApplyDiscount(false)}
          title={"Apply discount"}
          width={"600px"}
          desktopAnchor={"right"}
          anchor={"right"}
          fullHeight={true}
          showBackdrop={false}
        >
          <ApplyDiscountForm memberId={memberId} memberPhone={memberPhone}/>
        </ResponsiveDialog>
      )}
    </>
  );
};

export default DiscountsList;
