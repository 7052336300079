import { Expand, Star, StarOutline } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Avatar,
  Box,
  Stack,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import LocationMapWithDrawing from "./LocationMapWithDrawing";
import MapDisplay from "./MapDisplay";

// Sample employee data
// const employees = [
//   {
//     uid: "1",
//     firstName: "John",
//     lastName: "Doe",
//     age: 25,
//     gender: "male",
//     avatarUrl: "/path-to-avatar1.jpg", // URL of the avatar image
//     bio: "I am experienced in tech help and gardening. I often help my grandparents with things around the house, and sometimes, I help their neighbors too. Everyone is very appreciative of my hard working ethic. Please hire me!",
//     rating: 4.5,
//     skills: [
//       "Tech Help",
//       "Gardening",
//       "Organizing",
//       "Weeding",
//       "Lawnmowing",
//       "Errands",
//       "Cleaning",
//     ],
//     totalVisits: 15,
//     reviews: [
//       {
//         date: "2023-09-18",
//         rating: 5,
//         name: "Alice S.",
//         avatar: "/path-to-alice-avatar.jpg",
//         review: "John was extremely helpful and knowledgeable!",
//         chips: ["Hardworking", "Friendly", "On-time"],
//       },
//       {
//         date: "2023-09-22",
//         rating: 4,
//         name: "John M.",
//         avatar: "/path-to-alice-avatar.jpg",
//         review:
//           "John helped with my gardening! I really appreciated his helpful attitude. He was a little quiet and didn not talk much",
//         chips: ["Quiet", "Attention-to-detail", "On-time", "Hardworking"],
//       },
//       {
//         date: "2023-09-22",
//         rating: 4,
//         name: "John M.",
//         avatar: "/path-to-alice-avatar.jpg",
//         review:
//           "John helped with my gardening! I really appreciated his helpful attitude. He was a little quiet and didn not talk much",
//         chips: ["Quiet", "Attention-to-detail", "On-time", "Hardworking"],
//       },
//       // More review objects
//     ],
//   },
//   // Add more employee objects as needed
// ];

const EmployeeCard = ({ employee }) => {
  const [showReviews, setShowReviews] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false); // New state for Favorite
  const [mapHeight, setMapHeight] = useState("200px"); // New state for Favorite
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ReviewChips = ({ chips }) => (
    <Box mt={1} mb={1}>
      {chips.map((chip) => (
        <Chip
          label={chip}
          variant="outlined"
          size="small"
          style={{ margin: "2px" }}
          key={chip}
        />
      ))}
    </Box>
  );

  const toggleMapHeight = () => {
    if (mapHeight === "400px") {
      setMapHeight("200px");
    } else {
      setMapHeight("400px");
    }
  };

  const Review = ({ review, isLastItem }) => (
    <>
      <ListItem alignItems="flex-start">
        <Grid
          container
          alignItems="flex-start"
          gap={2}
          style={{ padding: "0px 16px" }}
        >
          <Grid item>
            <ListItemAvatar>
              <Avatar src={review.avatar} alt={review.name} />
            </ListItemAvatar>
          </Grid>
          <Grid item>
            <ListItemText
              primary={review.name}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {Array.from({ length: 5 }, (_, index) => {
                      const keyUnique = `${review.name}-star-${index}`; // Making a unique key
                      return index < review.rating ? (
                        <StarIcon key={keyUnique} />
                      ) : (
                        <StarBorderIcon key={keyUnique} />
                      );
                    })}
                  </Typography>

                  <Typography variant="body2" color="textPrimary">
                    {review.date}
                  </Typography>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm>
            <Box mt={1}>{review.review}</Box>
            <ReviewChips chips={review.chips} />
          </Grid>
        </Grid>
      </ListItem>
      {!isLastItem && <Divider variant="middle" />}
    </>
  );

  return (
    <Card elevation={0}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "flex-start" },
            gap: 2,
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: "center", // Conditional styling based on 'isMobile'
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Avatar
              src={employee.avatarUrl}
              alt={employee.firstName}
              style={{ width: 115, height: 115 }}
            />
            <Stack
              direction="column"
              minWidth="100px"
              sx={{
                alignItems: "flex-start", // Conditional styling based on 'isMobile'
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  {employee.firstName} {employee.lastName.charAt(0) + "."}
                </Typography>
                {/* <Tooltip title="Set as favorite Link">
                <IconButton onClick={() => setIsFavorite(!isFavorite)}>
                  {isFavorite ? (
                    <Star color="primary" /> // Changed to StarIcon for filled state
                  ) : (
                    <StarOutline color="action" /> // Changed to StarBorderIcon for outlined state
                  )}
                </IconButton>
              </Tooltip>
              <Button
                startIcon={<CalendarTodayIcon />}
                variant="text"
                color="primary"
              >
                Book Visit
              </Button> */}
              </Box>
              <Typography variant="body2" color="textSecondary">
                Age: {employee.age}, {employee.gender}
              </Typography>
              {/* Positioning the 'Book Visit' button below Age line */}
              {/* {employee.skills.map((skill) => (
              <Chip
                key={skill}
                label={skill}
                size="small"
                style={{ margin: "4px" }}
              />
            ))} */}
              <Typography variant="body2" color="textSecondary">
                {/* Total visits: {employee.totalVisits} */}
              </Typography>
              {employee?.rating && (
                <Typography variant="body2" color="textSecondary">
                  Rating: {employee.rating.toFixed(1)}
                </Typography>
              )}
              {/* Making only this text clickable to toggle reviews */}
              {employee?.reviews && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component={Link}
                  underline="hover"
                  style={{
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                  }}
                  onClick={() => setShowReviews(!showReviews)}
                >
                  {showReviews ? "Hide " : "See all "}
                  {employee.reviews.length} reviews
                </Typography>
              )}
            </Stack>
          </Stack>
          <Box
            sx={{
              flex: "1 1 auto", // Grow and shrink, but respect content and min-width
              minWidth: "150px", // Minimum width before wrapping
              maxWidth: "100%", // Maximum width
              width: { xs: "100%", sm: "40%" }, // Default width
            }}
          >
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              About me:
            </Typography>
            <Typography
              variant="body1"
              component="div"
              style={{ marginTop: "8px" }}
            >
              {employee.bio}
            </Typography>
          </Box>
          {employee?.location?.bounds && (
            <Box
              sx={{
                height: mapHeight,
                transition: "height 0.3s ease-in-out", // Smooth transition for height
                borderRadius: "15px",
                overflow: "hidden",
                bgcolor: "white",
                display: "flex", // Enable Flexbox
                alignItems: "center", // Align items vertically in the center
                justifyContent: "center", // Center content horizontally (if needed)
                minWidth: { xs: "100%", sm: "400px" },
                position: "relative", // Needed for absolute positioning of the overlay
                position: "relative",
                cursor: "pointer", // Set cursor to pointer to indicate clickable area

                "&:hover .overlay": {
                  opacity: 1, // Opacity 1 on hover
                },
              }}
              onClick={toggleMapHeight}
            >
              {mapHeight === "200px" && (
                <Box
                  className="overlay"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    opacity: 0, // Opacity 0 by default
                    transition: "opacity 0.3s ease-in-out", // Smooth transition for opacity
                    pointerEvents: "none", // Ignore pointer events when not visible
                  }}
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    {isMobile ? "Tap" : "Click"} to expand
                  </Typography>
                  <Expand sx={{ marginLeft: 1 }} />
                </Box>
              )}
              <MapDisplay
                geohash={employee?.location?.geohash}
                savedBounds={employee?.location?.bounds}
                width={isMobile ? "100%" : "400px"}
                height={"400px"}
              />
            </Box>
          )}
        </Box>
        <Collapse in={showReviews}>
          <Paper
            variant="outlined"
            sx={{ bgcolor: "#F5F5F5", maxHeight: "250px", overflowY: "auto" }}
            p={8}
          >
            {employee?.reviews?.length > 0 && (
              <List>
                {employee.reviews.map((review, index, reviewsArray) => (
                  <Review
                    key={`${review.date}-${review.name}-${index}`}
                    review={review}
                    isLastItem={index === reviewsArray.length - 1}
                  />
                ))}
              </List>
            )}
          </Paper>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default EmployeeCard;
