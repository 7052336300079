import { Alert, Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LeadFunnel from "../forms/LeadFunnel";
import Logo from "../svgs/Logo";
import VisitFunnel from "../forms/VisitFunnel";

const LeadFunnelPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <>
      {isMobile ? (
        <Dialog open="true" fullScreen={true}>
          {/* <Alert sx={{m: 1}}>Not ready yet? Save for later</Alert> */}
          <Box width={150} sx={{ p: 2 }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo color="#000" />
            </Link>
          </Box>
          {/* <LeadFunnel handleClose={() => navigate("/")} /> */}
          <VisitFunnel handleClose={() => navigate("/")} />
        </Dialog>
      ) : (
        <Dialog
          open="true"
          transitionDuration={0}
          fullScreen={true}
          PaperProps={{
            sx: {
              padding: { xs: 0, sm: "calc((100vw - 650px) / 2)" },
            },
          }}
        >
          <Box width={150} sx={{ pt: 2, pb: 2, mx: "auto" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo color="#000" />
            </Link>
          </Box>
          {/* <LeadFunnel handleClose={() => navigate("/")} /> */}
          <VisitFunnel handleClose={() => navigate("/")} />
        </Dialog>
      )}
    </>
  );
};

export default LeadFunnelPage;
