import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import { onDeclineVisit } from "../services/employeeServices";
import EmployeeDeclineVisit from "./EmployeeDeclineVisit";
import { CircularProgress } from "@mui/material";

const DeclineVisitButton = ({
  responseStatus,
  visit,
  visitId,
  visits,
  currentEmployeeId,
  customStyles,
  handleDecline,
  loading,
  setLoading,
}) => {
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleDeclineClick = () => {
    setDeclineDialogOpen(true);
  };

  return (
    <>
      <Button
        style={customStyles}
        disabled={loading}
        onClick={() => responseStatus !== "declined" && handleDeclineClick()}
      >
        {loading === "decline" ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          "Decline"
        )}
      </Button>
      <EmployeeDeclineVisit
        open={declineDialogOpen}
        setOpen={setDeclineDialogOpen}
        visit={visit}
        visitId={visitId}
        handleDecline={handleDecline}
        loading={loading}
        setLoading={setLoading}
        employeeId={currentEmployeeId}
      />
    </>
  );
};

export default DeclineVisitButton;
