import { Check, DoubleArrow } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCityStateFromZip } from "../../../services/locationServices";

function PricingCards({
  onClick = () => {},
  dense = false,
  buttonText = "Get Started",
  loading = false,
  loadingPlan = null,
  currentPlan = null,
  zipCode = 98221,
}) {
  const theme = useTheme();
  const [city, setCity] = useState("");

  // Pricing override structure
  const pricingOverrides = {
    98277: {
      Basic: { membershipFee: 0, hourlyRate: 30 },
      Premium: { membershipFee: 20, hourlyRate: 25 },
    },
    99163: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    99164: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    33606: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    33605: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    33604: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    33607: {
      Basic: { membershipFee: 0, hourlyRate: 40 },
      Premium: { membershipFee: 25, hourlyRate: 35 },
    },
    // Additional zip codes and overrides can be added here
  };

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const result = await getCityStateFromZip(zipCode);
        console.log("result: ", result);
        setCity(result);
      } catch (error) {
        console.error("Error in useEffect:", error);
        setCity(zipCode); // Fallback to zipCode in case of an error
      }
    };

    fetchCity();
  }, [zipCode]); // Dependency array ensures it runs when zipCode changes

  // Define the benefits for each plan
  const basicBenefits = [
    // "Join hundreds of happy members",
    "Licensed and insured",
    // "Lock in your lifetime hourly rate",
    "Choose your helper",
    "1-hour visit minimum",
    "Flat-rate regardless of tasks",
    "Reliable help on-demand",
    "Earn a free hour per referral",
    // "Schedule 3-4 days in advance",
    // "Hard working and efficient",
    // "Our money back guarantee",
    // "End anytime with no fees",
    // "$50 signup fee for lifetime access",
    // "Excellent customer service",
    // "Mix and match any tasks to fill the visit",
    // "Senior-friendly online scheduling",
    // "Schedule recurring visits",
    // "Same day visits",
    // ... more benefits
  ];

  const premiumBenefits = [
    "Everything from basic, plus...",
    "Discounted hourly rate",
    "Pays for itself in 2 hours",
    "Schedule recurring visits",
    // "Access to referral network",
    // "Same day visits",
    // ... more benefits
  ];

  const eliteBenefits = [
    "Everything from premium, plus...",
    "Unlimited, free 15-minute drop-ins",
    // ... more benefits
  ];

  // Define the plan details using numeric values for fees and rates
  let plans = [
    {
      tier: 0,
      name: "Linked Lives",
      membershipFee: 0, // Numeric value for membership fee
      hourlyRate: 40, // Numeric value for hourly rate
      benefits: basicBenefits,
      button: buttonText,
    },
    // {
    //   tier: 1,
    //   name: "Premium Plan",
    //   membershipFee: 20, // Numeric value for membership fee
    //   hourlyRate: 30, // Numeric value for hourly rate
    //   benefits: premiumBenefits,
    //   button: buttonText,
    // },
  ];

  // Apply pricing overrides based on zipCode
  // if (pricingOverrides[zipCode]) {
  //   plans = plans.map((plan) => {
  //     const override = pricingOverrides[zipCode][plan.name];
  //     return override ? { ...plan, ...override } : plan;
  //   });
  // }

  // Function to format membership fee
  const formatMembershipFee = (fee) =>
    fee === 0 ? "a $50 signup fee" : `$${fee} monthly dues`;

  // Function to format hourly rate
  const formatHourlyRate = (rate) => `$${rate}/hr`;

  const renderBenefits = (benefits) => {
    return (
      <List dense={true}>
        {benefits.map((benefit, idx) => (
          <ListItem key={idx} sx={{ px: 0, alignItems: "flex-start" }}>
            <Check color="primary" sx={{ mr: 1 }} />
            {benefit === "Join hundreds of happy members" ? (
              <Typography variant="body1">
                Join hundreds of{" "}
                <Link
                  component="span"
                  to="/reviews"
                  style={{
                    fontWeight: "600",
                    color: theme.palette.primary.main,
                  }}
                >
                  happy members
                </Link>
              </Typography>
            ) : benefit === "Lock in your lifetime hourly rate" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">{benefit}</Typography>
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 0.5 }}
                >
                  {["2023 rate: $35/hr", "2022 rate: $30/hr"].map(
                    (rate, index) => (
                      <Chip
                        size="small"
                        sx={{ borderRadius: "5px" }}
                        key={index}
                        label={rate}
                        variant="filled"
                      />
                    )
                  )}
                </Box>
              </Box>
            ) : (
              <Typography variant="body1">{benefit}</Typography>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  // Function to render the button
  const renderButton = (plan) => {
    const isDefaultOnClick = onClick.toString() === (() => {}).toString();

    const buttonProps = {
      fullWidth: true,
      variant: currentPlan === plan.tier ? "contained" : "contained",
      disabled: loading || currentPlan === plan.tier,
      color: "primary",
      sx: {
        marginY: dense ? 1 : 2,
        width: "100%",
        height: { xs: "60px" },
      },
      onClick: () => onClick(plan),
    };

    if (!isDefaultOnClick) {
      return (
        <Button {...buttonProps}>
          {loading && loadingPlan === plan.tier ? (
            <CircularProgress size={24} />
          ) : currentPlan === plan.tier ? (
            "current plan"
          ) : (
            plan.button
          )}
        </Button>
      );
    } else {
      return (
        <>
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              marginY: dense ? 1 : 2,
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
              position: "relative", // Needed for the overlay
              overflow: "hidden", // Ensures the effect stays within the button boundaries
              // animation: "pulse 3s infinite ease-in-out",
              // "@keyframes pulse": {
              //   "10%": { transform: "scale(1)" },
              //   "35%": { transform: "scale(1.03)" },
              //   "50%": { transform: "scale(1)" },
              // },
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: "-150%", // Start off the button
                width: "100%", // Make it wide enough to cover the button
                height: "100%",
                background:
                  "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
                transform: "skewX(-20deg)", // Slight skew to angle the wipe
                animation: "wipe 3s infinite", // Smooth, infinite animation
              },
              "@keyframes wipe": {
                "0%": { left: "-200%" },
                "100%": { left: "200%" }, // Move it fully across
              },
            }}
            component={Link}
            to="/member/signup"
            startIcon={<DoubleArrow />}
          >
            Get started
          </Button>
          {/* <Divider>or</Divider>

          <Button
            fullWidth
            variant="outlined"
            size="large"
            component={Link}
            to="/contact"
            sx={{
              marginY: dense ? 1 : 2,
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
            }}
            onClick={() => onClick(plan)}
            startIcon={<Call />}
          >
            Schedule a call
          </Button> */}
        </>
      );
    }
  };

  return (
    <Box sx={{ maxWidth: "500px", mx: "auto" }}>
      <Grid container spacing={{ xs: 2, sm: 4 }} alignItems="flex-start">
        {plans.map((plan, idx) => (
          <Grid item xs={12} md={12} key={idx}>
            <Card
              variant="outlined"
              sx={{
                p: { xs: 2, sm: 4 },
                backgroundColor:
                  plan.tier === 1 ? theme.palette.primary.lightest : "white",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for transform and shadow
                // "&:hover": {
                // transform: "translateY(-4px)", // Slightly raise the card
                // boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)", // Elegant drop shadow
                // },
              }}
            >
              {(!dense || (dense && plan.tier === 1)) && (
                <Box
                  sx={{
                    mx: { xs: -2, sm: -4 },
                    mt: { xs: -2, sm: -4 },
                    p: 1,
                    mb: 2,
                    backgroundColor:
                      plan.tier === 1 ? theme.palette.primary.main : "none",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6" color="white">
                    {plan.tier === 1 ? "Premium Membership" : "."}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column" },
                  justifyContent: "space-between",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">{plan.name}</Typography>
                  </Box> */}
                {zipCode && (
                  <Chip
                    sx={{ mx: "auto", px: "20px" }}
                    variant="outlined"
                    color="success"
                    label={city || zipCode}
                  />
                )}
                <Typography
                  variant="body1"
                  fontSize="32px"
                  fontWeight="600"
                  align="center"
                  sx={{ mt: dense ? 1 : 2 }}
                >
                  {formatHourlyRate(plan.hourlyRate)}
                </Typography>
                <Typography variant="body1" fontSize="16px" align="center">
                  <Typography variant="body1">
                    Join hundreds of{" "}
                    <Link
                      component="span"
                      to="/reviews"
                      style={{
                        fontWeight: "600",
                        color: theme.palette.primary.main,
                      }}
                    >
                      happy members
                    </Link>
                    {" "}with a $50 signup
                  </Typography>
                  {/* {formatMembershipFee(plan.membershipFee)} */}
                </Typography>
                {/* <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 0.5, mx: "auto" }}>
                  {["2023 rate: $35/hr", "2022 rate: $30/hr"].map((rate, index) => (
                    <Typography sx={{color: "white", borderRadius: "10px", background: "rgba(0,0,0,0.2)", border: "1px solid rgba(0,0,0,0.2)", px: 1, py: 0.5}} variant="caption" key={index}>{rate}</Typography>
                  ))}
                </Box> */}
                {/* {plan.tier === 0 && ( 
                <Typography
                  variant="caption"
                  fontStyle={"italic"}
                  align="center"
                  sx={{ color: "rgba(0,0,0,0.6)" }}
                >
                  {plan.tier === 0
                    // ? "Needs based rates as low as $20/hr for qualifying seniors."
                    ? "Enjoy lifetime membership for just $40"
                    : "Membership billed annually"}
                </Typography>
                 )} */}
                <Divider sx={{ mt: 2, mb: 1 }} />

                {renderBenefits(plan.benefits)}
              </Box>
              {renderButton(plan)}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PricingCards;
