/* global google */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  FormHelperText,
  Typography,
  useTheme,
  DialogContent,
  DialogActions,
  ListItemButton,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import LinearProgress from '@mui/material/LinearProgress';

import { Close, Star, StarBorder } from "@mui/icons-material/";

import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/system";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

import AvatarUpload from "../components/AvatarUpload";
import StyledProgress from "../components/StyledProgress";

import { keyframes } from "@emotion/react";
import ActionFooter from "../components/ActionFooter";
import MemberSearchBar from "../components/MemberTab/MemberSearchBar";
import EmployeeSearchBar from "../components/manageEmployees/EmployeeSearchBar";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CreateMemberAndAccountForm = ({
  handleSubmit,
  isAdmin = false,
  userUid = "",
  userName = "",
  userAvatar = "",
}) => {
  const theme = useTheme();

  const totalSteps = 3;
  const authSession = getAuth();

  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    adSources: [],
    termsAccepted: false,
    additionalNotes: "",
    directions: "",
    avatarUrl: "",
    email: "",
    stripeId: "",
    membership: "",
    created: "",
    autoPay: true,
  });
  const [memberReferral, setMemberReferral] = useState({
    referralUid: "",
    referralName: "",
    referralAvatar: "",
  });
  const [employeeReferral, setEmployeeReferral] = useState({
    referralUid: userUid,
    referralName: userName,
    referralAvatar: userAvatar,
  });
  const [errors, setErrors] = useState({});
  const addressRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [adSources, setAdSources] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const referralParam = query.get("referral");

    if (referralParam) {
      // Create a reference to the user's document in the Firestore database
      const userDocRef = doc(db, "members", referralParam); // 'users' is the collection name

      // Fetch the document for the referred user
      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            // Assuming the user document has fields 'firstName' and 'lastName'
            const referrerData = docSnap.data();
            setMemberReferral({
              referralName: `${referrerData.firstName} ${referrerData.lastName}`,
              referralUid: referralParam,
            });
          } else {
            // Handle the case where there is no user with the given memberReferralUid
            console.log("No such user!");
          }
        })
        .catch((error) => {
          // Handle any errors in fetching the user document
          console.error("Error fetching user:", error);
        });
    }
  }, []);

  console.log("values referral uid: ", memberReferral.referralUid);

  // Styles for each card
  const cardStyles = {
    opacity: 0,
    animation: `${fadeInMoveDown} 0.5s forwards`,
    marginBottom: theme.spacing(2),
  };

  // This effect runs whenever currentUser or currentUser.photoURL changes
  useEffect(() => {
    if (
      authSession?.currentUser?.photoURL &&
      values.avatarUrl === "" &&
      !isAdmin
    ) {
      setValues((prevValues) => ({
        ...prevValues, // spread previous state
        avatarUrl: authSession.currentUser.photoURL, // update just the avatarUrl field
      }));
      console.log("setting avatar from google");
    }
  }, [authSession?.currentUser, authSession?.currentUser?.photoURL]);

  const initAutocomplete = () => {
    console.log("Google object:", typeof google);
    console.log("addressRef:", addressRef.current);

    if (typeof google === "undefined" || !addressRef.current) {
      console.log("Google Maps or address input not found");
      return;
    }

    console.log("Initializing Autocomplete");
    const autocompleteInstance = new google.maps.places.Autocomplete(
      addressRef.current,
      {
        types: ["address"],
      }
    );
    autocompleteInstance.setFields(["address_components"]);
    autocompleteInstance.addListener("place_changed", () =>
      onPlaceSelected(autocompleteInstance)
    );
  };

  const onPlaceSelected = (autocompleteInstance) => {
    console.log("Place selected!"); // Add this line

    const place = autocompleteInstance.getPlace();
    if (!place.address_components) return;
    const addressComponents = {};
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name;
    });
    const line1 = addressComponents.street_number
      ? `${addressComponents.street_number} ${addressComponents.route}`
      : addressComponents.route;
    const line2 = "";
    const city = addressComponents.locality;
    const state = addressComponents.administrative_area_level_1;
    const zipCode = addressComponents.postal_code;

    setValues((prevValues) => ({
      ...prevValues,
      line1,
      line2,
      city,
      state,
      zipCode,
    }));

    setErrors({}); // Clear all errors
  };

  const loadScript = (callback) => {
    if (typeof google !== "undefined") {
      console.log("Google Maps API already loaded");
      if (typeof callback === "function") {
        return setTimeout(callback, 0); // Delay the callback execution
      }
      return; // If callback is not a function, just return
    }

    const GOOGLE_MAPS_SCRIPT_ID = "google-maps-script";
    const existingScript = document.getElementById(GOOGLE_MAPS_SCRIPT_ID);
    if (existingScript) {
      console.log("Script already exists in the document");
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6k5ohWTgieFep-vs8Wbe6SKWySlSAx9g&libraries=places&callback=initAutocomplete`;
    script.id = GOOGLE_MAPS_SCRIPT_ID;
    script.onload = () => console.log("Google Maps Script loaded successfully");
    script.onerror = () => console.error("Error loading Google Maps Script");
    document.body.appendChild(script);
  };

  useEffect(() => {
    console.log("AddAddress component mounted");
    loadScript(initAutocomplete);
  }, []);

  useEffect(() => {
    window.initAutocomplete = initAutocomplete;
    loadScript();

    // Add z-index override for .pac-container
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.innerHTML = `.pac-container { z-index: 20000 !important; }`; // Set a value that ensures the dropdown is above the modal

    console.log("running");

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      window.initAutocomplete = null; // Clear the global reference
    };
  }, []);

  const validateStep = () => {
    let tempErrors = {};
    switch (step) {
      case 1:
        if (!values.firstName) tempErrors.firstName = "First Name is required";
        if (!values.lastName) tempErrors.lastName = "Last Name is required";
        if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
          tempErrors.phone = "Your phone number must be 10 digits.";
        if (values.membership === "")
          tempErrors.membership = "Selecting a plan is required";
        if (isAdmin) {
          // Email validation
          if (!values.email) {
            tempErrors.email = "Email is required";
          } else if (
            // Simple regex for general email format
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)
          ) {
            tempErrors.email = "Invalid email address";
          }
        }
        break;
      case 2:
        if (!values.line1) tempErrors.line1 = "Address Line 1 is required";
        if (!values.city) tempErrors.city = "City is required";
        if (!values.state) tempErrors.state = "State is required";
        if (!values.zipCode) tempErrors.zipCode = "Zip Code is required";
        break;
      case 3:
        // if (!values.adSources || values.adSources.length === 0)
        //   tempErrors.adSources = "You must select an ad source.";
        if (!values.termsAccepted || values.termsAccepted === false)
          tempErrors.termsAccepted =
            "You must accept the terms and conditions.";
        // if (!values.additionalNotes || values.additionalNotes === "")
        //   tempErrors.additionalNotes = "You must leave an additional note.";
        break;
      default:
        break;
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNext = async () => {
    if (validateStep()) {
      if (step < 3) {
        setStep((prevStep) => prevStep + 1);
      } else {
        handleSubmit({
          ...values,
          memberReferralUid: memberReferral.referralUid,
          memberReferralName: memberReferral.referralName,
          employeeReferralUid: employeeReferral.referralUid,
          employeeReferralName: employeeReferral.referralName,
        });
      }
    }
  };

  const handleBack = () => {
    if (step > 1) setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleMemberReferralChange = (member) => {
    setMemberReferral({
      referralUid: member.id,
      referralName: `${member.firstName} ${member.lastName}`,
      referralAvatar: member.avatarUrl,
    });
  };

  const handleEmployeeReferralChange = (employee) => {
    console.log("employee within handle change: ", employee);
    setEmployeeReferral({
      referralUid: employee.id,
      referralName: `${employee.firstName} ${employee.lastName}`,
      referralAvatar: employee.avatarUrl,
    });
  };

  const handlePhoneChange = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    handleChange(e);
  };

  console.log("AUTOPAY: ", values.autoPay);

  const formProgress = ((step - 1) / (totalSteps - 1)) * 90 + 5;

  const handleAdSourceChange = (event) => {
    const value = event.target.name;
    const checked = event.target.checked;

    // Clear adSources error when a checkbox is changed
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.adSources;
      return newErrors;
    });

    if (checked) {
      setValues((prevValues) => ({
        ...prevValues,
        adSources: [...prevValues.adSources, value],
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        adSources: prevValues.adSources.filter((item) => item !== value),
      }));
    }
  };

  useEffect(() => {
    const fetchAdSources = async () => {
      try {
        const docRef = doc(db, "public", "adSources");
        const docSnap = await getDoc(docRef);
        let adSources = [
          "Friend",
          "Employee",
          "Newspaper",
          "Facebook",
          "Google Ads",
          "Google Search",
        ]; // Static values you want to include
        if (docSnap.exists()) {
          const matchedAdSources = docSnap.data()[values.zipCode] || [];
          // Concatenate fetched ad sources with static values, ensuring no duplicates
          adSources = Array.from(new Set([...matchedAdSources, ...adSources]));
        }
        setAdSources(adSources); // Set the combined array to state
      } catch (error) {
        console.error("Error fetching ad sources:", error);
      }
    };

    fetchAdSources();
  }, [values.zipCode]); // Dependency array includes values.zipCode to re-run the effect when it changes

  const addressDisplay = step === 2 ? "block" : "none";
  console.log("addressDisplay: ", addressDisplay);

  ////////////////////////////// SEARCH MEMBERS FOR REFERRAL LOOKUP //////////////////////////////

  const handleReferralClear = (type) => {
    console.log("running referral clear");
    if (type === "member") {
      setMemberReferral({
        referralUid: "",
        referralName: "",
        referralAvatar: "",
      });
    } else if (type === "employee") {
      setEmployeeReferral({
        referralUid: "",
        referralName: "",
        referralAvatar: "",
      });
    }
  };

  console.log("employeeReferral: ", employeeReferral);

  const renderReferral = (referral, type) => {
    console.log("referral, type: ", [referral, type]);
    return (
      <ListItemButton
        key={referral.referralUid}
        sx={{
          borderRadius: "15px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          boxSizing: "border-box",
        }}
      >
        <Avatar sx={{ marginRight: 2 }} src={referral.referralAvatar}></Avatar>
        <ListItemText primary={referral.referralName} />
        {/* <Chip label={member.status} /> */}
        <IconButton
          onClick={() => handleReferralClear(type)}
          sx={{ marginRight: 2 }}
          onMouseUp={(event) => event.stopPropagation()}
        >
          <Close />
        </IconButton>
      </ListItemButton>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <DialogContent>
        <Box
          mb={3}
          sx={{
            textAlign: "center", // Center the text of all child elements
            width: "100%",
          }}
        >
          <StyledProgress variant={"determinate"} value={formProgress} />
          <Typography
            variant="caption"
            align="center"
          >{`Step ${step} of ${totalSteps}`}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width="100%" // This will make the Box take up 100% of the parent's width
          maxWidth={"600px"} // This will ensure the Box does not exceed the specified maxWidth
          sx={{
            // Apply the animation only if this is the current step
            ...(step === 1 && {
              opacity: 0,
              animation: `${fadeInMoveDown} 1s forwards`,
            }),
            pb: 4,
          }}
        >
          {step === 1 && memberReferral?.referralName && !isAdmin && (
            <Alert severity="success">
              {memberReferral?.referralName} has gifted you a free 1-hour visit
              or $35 off.
            </Alert>
          )}
          {step === 1 && (
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  fullWidth
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
                <TextField
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </div>
              <TextField
                fullWidth
                name="phone"
                type="tel"
                label="Phone Number"
                value={values.phone}
                onChange={handlePhoneChange}
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <TextField
                fullWidth
                name="email"
                type="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <FormControl sx={{ width: "100%" }} error={!!errors.membership}>
                <InputLabel id="membership-label">Membership</InputLabel>
                <Select
                  labelId="membership-label"
                  id="membership-select"
                  name="membership" // The name attribute should match the key in the state
                  value={values.membership}
                  label="Membership"
                  onChange={handleChange}
                  renderValue={(selected) => {
                    const selectedItem = {
                      0: "Basic: No monthly fees, $40/hour",
                      1: "Premium: $25/month + $35/hour",
                    }[selected];
                    return selectedItem || "Select a tier";
                  }}
                >
                  <MenuItem value={0}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText
                      primary="Basic Plan"
                      secondary="No monthly fees, $40/hour"
                    />
                  </MenuItem>
                  <MenuItem value={1}>
                    <ListItemIcon>
                      <Star />
                    </ListItemIcon>
                    <ListItemText
                      primary="Premium Plan"
                      secondary="$25/month + $35/hour, additional benefits"
                    />
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errors.membership || (
                    <span>
                      Learn more about our plans{" "}
                      <a
                        href="/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        here
                      </a>
                    </span>
                  )}
                </FormHelperText>
              </FormControl>
            </>
          )}
          {step === 1 && isAdmin && (
            <>
              {/* <TextField
                fullWidth
                name="stripeId"
                label="Stripe ID"
                value={values.stripeId}
                onChange={handleChange}
              /> */}
              {/* <TextField
                fullWidth
                name="created"
                label="Date Created"
                type="date" // This enables the browser's native date picker
                value={values.created} // Ensure the date is controlled, default to an empty string if undefined
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true, // This ensures the label doesn't overlap with the placeholder text
                }}
              /> */}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={values.autoPay}
                    onChange={handleChange}
                    name="autoPay"
                    color="primary"
                  />
                }
                label="AutoPay"
              /> */}
            </>
          )}
          <TextField
            name="line1"
            fullWidth
            label="Address Line 1"
            value={values.line1}
            onChange={handleChange}
            error={!!errors.line1}
            helperText={errors.line1}
            inputRef={addressRef}
            sx={{ display: addressDisplay }}
          />
          {step === 2 && (
            <>
              <TextField
                name="line2"
                label="Address Line 2"
                value={values.line2}
                onChange={handleChange}
              />
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                />
                <TextField
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  error={!!errors.state}
                  helperText={errors.state}
                />
                <TextField
                  name="zipCode"
                  label="Zip Code"
                  value={values.zipCode}
                  onChange={handleChange}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode}
                />
              </div>
              <Accordion
                elevation={0}
                style={{
                  boxShadow: "none",
                  padding: 0,
                  border: "none",
                  margin: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ padding: 0, border: "none", margin: 0 }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Additional Directions</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: 0, border: "none", margin: 0 }}
                >
                  <TextField
                    fullWidth
                    label="Additional directions to find your address"
                    variant="outlined"
                    name="directions"
                    value={values.directions}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {step === 3 && (
            <Box gap={1}>
              <>
                <Typography variant="h6">
                  Review the Terms and Conditions
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.termsAccepted}
                      onChange={handleChange}
                      name="termsAccepted"
                    />
                  }
                  label={
                    <span>
                      I accept the{" "}
                      <Link href="/terms-and-conditions" target="_blank">
                        Terms and Conditions
                      </Link>
                    </span>
                  }
                />
                {errors.termsAccepted && (
                  <div>
                    <Typography variant="caption" color="error">
                      {errors.termsAccepted}
                    </Typography>
                  </div>
                )}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  How did you hear about us?
                </Typography>
                {false ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Grid container spacing="0px">
                    {adSources.map((source, index) => (
                      <Grid item xs={6} key={source}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values?.adSources?.includes(source)}
                              onChange={handleAdSourceChange}
                              name={source}
                            />
                          }
                          label={source}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {errors.adSources && (
                  <Typography variant="caption" color="error">
                    {errors.adSources}
                  </Typography>
                )}
              </>
              {isAdmin && values.adSources.includes("Friend") && (
                <>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Which friend referred you?
                  </Typography>
                  {!memberReferral.referralUid ? (
                    <MemberSearchBar
                      onMemberSelect={handleMemberReferralChange}
                    />
                  ) : (
                    renderReferral(memberReferral, "member")
                  )}
                </>
              )}
              {isAdmin && values.adSources.includes("Employee") && (
                <>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Which employee referred you?
                  </Typography>
                  {!employeeReferral.referralUid ? (
                    <EmployeeSearchBar
                      onEmployeeSelect={handleEmployeeReferralChange}
                    />
                  ) : (
                    renderReferral(employeeReferral, "employee")
                  )}
                </>
              )}

              <TextField
                fullWidth
                label="Additional Notes"
                variant="outlined"
                name="additionalNotes"
                value={values.additionalNotes}
                onChange={handleChange}
                multiline
                rows={4}
                style={{ marginTop: "20px" }}
                // error={!!errors.additionalNotes}
                // helperText={errors.additionalNotes}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={handleBack}
          disabled={step === 1}
          sx={{
            pr: 2,
            width: "50%",
            width: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }} // Take up half the available width
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          sx={{
            width: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }} // Take up half the available width
        >
          {step === totalSteps ? "Submit" : "Next"}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateMemberAndAccountForm;
