import {
  ChatOutlined,
  DirectionsOutlined,
  EventRepeatOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material"; // Import icons
import {
  Chip,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import {
  formatAddress,
  formatDate,
  formatTimeRange,
} from "../services/dateServices";

import {
  AccessTime,
  GroupOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { db } from "../firebase";
import { useMember } from "../providers/MemberProvider";
import { useSnackbar } from "../providers/SnackbarProvider";
import AddEmployeeDialog from "./AddEmployeeDialog";
import AvatarStatusBadge from "./AvatarStatusBadge";
import CancelVisitDialog from "./CancelVisitDialog";
import PushDrawer from "./PushDrawer";
import RatingChip from "./RatingChip";
import RescheduleVisitMember from "./RescheduleVisitMember";
import ResponsiveDialog from "./ResponsiveDialog";
import ReviewVisitMember from "./ReviewVisitMember";
import VisitCardMenu from "./VisitCardMenu";
import VisitRefundRequest from "./VisitRefundRequest";
import VisitTimelineView from "./VisitTimelineView";

const VisitCard = ({ visit, visitId, employees, handleClose = () => {} }) => {
  const { data } = useMember();
  const theme = useTheme();
  const { showSnackbar, showLoading } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editableDescription, setEditableDescription] = useState(
    visit?.description
  );
  const [showReview, setShowReview] = useState(false);
  const [fetchPrivateData, setFetchPrivateData] = useState(true);
  const [privateData, setPrivateData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddEmployeeDialog, setShowAddEmployeeDialog] = useState(false);
  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [showRefundDialog, setShowRefundDialog] = useState(false);

  const timeZoneId = visit.timeZoneId;

  console.log("====> Employees Needed: ", visit?.employeesNeeded);
  console.log("====> visitId: ", visitId);

  useEffect(() => {
    const fetchPrivateData = async () => {
      try {
        // Assuming 'visitId' is the ID for the visit document
        const privateDataRef = doc(db, "visits", visitId, "private", "data");
        const privateDataSnap = await getDoc(privateDataRef);

        if (privateDataSnap.exists()) {
          setPrivateData(privateDataSnap.data());
          console.log("Private Data: ", privateDataSnap.data());
        } else {
          console.log("No private data found");
        }
      } catch (error) {
        console.error("Error fetching private data:", error);
      } finally {
        setFetchPrivateData(false);
      }
    };

    if (visitId) {
      fetchPrivateData();
    }
  }, [visitId]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      // This function is called when the component is unmounted
      handleSave();
    };
  }, [editableDescription, visitId, db]);

  const handleReviewOpen = () => {
    setShowReview(true);
  };

  const handleReviewClose = () => {
    setShowReview(false);
  };

  const isPastEvent = moment().isAfter(moment(visit?.start?.toDate()));
  const isReviewed = typeof visit?.rating === "number" && !isNaN(visit?.rating);
  const isRefunded = visit.flags && visit.flags.includes("refundIssued");
  const needsReschedule = visit?.status === "reschedule";

  const canReschedule =
    (isPastEvent && visit.status === "reschedule") || !isPastEvent;
  const isActive = !isPastEvent && visit.status === "confirmed";
  const canCancel = !isPastEvent && visit.status !== "cancelled";
  const canReview = isPastEvent && !isReviewed && !needsReschedule;

  const canRefund =
    isPastEvent &&
    !isRefunded &&
    visit.status !== "cancelled" &&
    visit.status !== "reschedule";

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const debouncedSave = useCallback(
    debounce(async (newDescription) => {
      console.log("Updating visit with ID:", visitId);

      const visitRef = doc(db, "visits", visitId);

      try {
        await updateDoc(visitRef, { description: newDescription });
        console.log("Description updated successfully");
      } catch (error) {
        console.error("Error updating description: ", error);
      }
    }, 5000),
    [visitId, db] // added dependencies here
  );

  const handleSave = async () => {
    console.log("Updating visit with ID:", visitId);
    const visitRef = doc(db, "visits", visitId);

    try {
      await updateDoc(visitRef, { description: editableDescription });
      console.log("Description updated successfully");
    } catch (error) {
      console.error("Error updating description: ", error);
    }
  };

  const getResponseStatusText = (status) => {
    switch (status) {
      case "accepted":
        return "confirmed";
      case "declined":
        return "declined";
      case "needsAction":
        return "assigned"; // This is the special case you've shown above
      case "refunded":
        return "refunded"; // This is the special case you've shown above
      default:
        return "unknown status";
    }
  };

  // Function to compare visit window with employee windows
  const isAnyWindowDifferent = (visit) => {
    if (!visit || !visit.employees || !visit.start || !visit.end) {
      return false; // Return false if visit or necessary properties are missing
    }

    const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
    const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

    // Check if any employee's window is different
    return Object.values(visit.employees).some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
      })
    );
  };

  // Determining how to display time format
  const employeeCount = visit?.employees && Object.keys(visit.employees).length;
  const windowsAreDifferent = isAnyWindowDifferent(visit);
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  // Function to find the earliest start and latest end times
  const findTimeBounds = () => {
    const visitStart = moment(visit?.start.toDate()).tz(timeZoneId);
    const visitEnd = moment(visit?.end.toDate()).tz(timeZoneId);

    let earliest = visitStart.clone();
    let latest = visitEnd.clone();

    Object.values(visit?.employees).forEach((employee) => {
      if (!employee.windows) return null;

      Object.values(employee.windows).forEach((window) => {
        const windowStart = moment(window?.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window?.end.toDate()).tz(timeZoneId);
        if (windowStart.isBefore(earliest)) {
          earliest = windowStart;
        }
        if (windowEnd.isAfter(latest)) {
          latest = windowEnd;
        }
      });
    });

    // Add buffer
    // earliest.subtract(15, "minutes");
    // latest.add(15, "minutes");

    return { earliest, latest };
  };

  const renderEmployeePositions = (
    employees,
    visitEmployees,
    isOpenVisit,
    employeesNeeded
  ) => {
    let positions = [];

    // If there are actual employees, we render them.
    if (visitEmployees) {
      positions = Object.entries(visitEmployees)
        .filter(
          ([, employee]) =>
            employee.responseStatus === "needsAction" ||
            employee.responseStatus === "accepted" ||
            employee.responseStatus === "refunded"
        )
        .map(([key, employee], index) => {
          return (
            <Stack key={key} direction="row" gap={1} alignItems="center">
              <ListItemAvatar>
                <AvatarStatusBadge
                  status={employee.responseStatus}
                  avatarUrl={
                    employees[key]?.avatarUrl ||
                    visit?.employees[key]?.avatarUrl
                  }
                  displayName={visit?.employees[key]?.firstName}
                  index={index}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${visit?.employees[key]?.displayName}`}
                secondary={getResponseStatusText(employee.responseStatus)}
              />
              {shouldShowTimes && (
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "15px",
                    py: 1,
                    px: 2,
                    my: 1,
                  }}
                >
                  {employee.windows
                    ? Object.values(employee.windows).map(
                        (window, windowIndex) => (
                          <React.Fragment key={windowIndex}>
                            {formatTime(
                              moment(window.start.toDate()).tz(timeZoneId)
                            )}{" "}
                            -{" "}
                            {formatTime(
                              moment(window.end.toDate()).tz(timeZoneId)
                            )}
                            <br />
                          </React.Fragment>
                        )
                      )
                    : `${formatTime(
                        moment(visit?.start?.toDate()).tz(timeZoneId)
                      )} - 
               ${formatTime(moment(visit?.end?.toDate()).tz(timeZoneId))}`}
                </Typography>
              )}
            </Stack>
          );
        });
    }

    // If the visit is open and it's still in the future, we add placeholders for the open positions.
    if (isOpenVisit && !isPastEvent) {
      positions = positions.concat(
        Array.from({ length: employeesNeeded }, (_, index) => (
          <Stack
            key={`open_position_${index}`}
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ListItemAvatar>
              <AvatarStatusBadge
                status={"needsAction"}
                avatarUrl={""}
                displayName={""}
                index={-1}
              />
            </ListItemAvatar>
            <ListItemText primary={"Awaiting"} secondary={"open position"} />
          </Stack>
        ))
      );
    }

    return positions;
  };

  // Custom formatting function
  const formatTime = (momentObj) => {
    return momentObj.minute() === 0
      ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
      : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
  };

  return (
    <>
      <PushDrawer
        title={"Review"}
        minWidth={"600px"}
        open={showReview}
        onOpen={handleReviewOpen}
        onClose={handleReviewClose}
        hideBackdrop={true}
      >
        <ReviewVisitMember
          visit={visit}
          visitId={visitId}
          handleClose={handleReviewClose}
        />
      </PushDrawer>

      <ResponsiveDialog
        open={rescheduleDialogOpen}
        onClose={() => setRescheduleDialogOpen(false)}
        title={`Reschedule visit`}
        width={"800px"}
        fullHeight={true}
        maxHeight="583px"
        backgroundCloses={false}
      >
        {rescheduleDialogOpen && (
          <RescheduleVisitMember
            visitInput={{ id: visitId, ...visit }}
            employees={employees}
            handleClose={() => setRescheduleDialogOpen(false)}
          />
        )}
      </ResponsiveDialog>

      {/* Visit Details View */}
      <DialogContent>
        <List sx={{ marginTop: "-24px" }}>
          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <AccessTime color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack direction="row" alignItems={"center"} gap={1}>
                  <Typography variant="h6">
                    {formatDate(moment(visit?.start?.toDate()).toDate())}
                  </Typography>
                  {visit.status === "reschedule" && (
                    <Chip
                      label="Reschedule"
                      color="success"
                      size="small"
                      sx={{
                        zIndex: 1,
                        borderRadius: "5px",
                        // ...(isMobile ? chipStyles : desktopChipStyles),
                      }}
                    />
                  )}
                  {visit.status === "cancelled" && (
                    <Chip
                      label="Cancelled"
                      color="error"
                      size="small"
                      sx={{
                        zIndex: 1,
                        borderRadius: "5px",
                        // ...(isMobile ? chipStyles : desktopChipStyles),
                      }}
                    />
                  )}
                  {isRefunded && (
                    <Chip
                      label="Refund issued"
                      color="success"
                      size="small"
                      sx={{
                        zIndex: 1,
                        borderRadius: "5px",
                        // ...(isMobile ? chipStyles : desktopChipStyles),
                      }}
                    />
                  )}
                </Stack>
              }
              secondary={
                !shouldShowTimes && (
                  <Typography variant="body1">
                    {Object.entries(visit.employees)[0]?.[1]?.windows
                      ? Object.values(
                          Object.entries(visit.employees)[0][1].windows
                        ).map((window, index) => (
                          <React.Fragment key={index}>
                            {formatTime(
                              moment(window.start.toDate()).tz(timeZoneId)
                            )}{" "}
                            -{" "}
                            {formatTime(
                              moment(window.end.toDate()).tz(timeZoneId)
                            )}
                            <br />
                          </React.Fragment>
                        ))
                      : formatTimeRange(
                          moment(visit?.start?.toDate()).toDate(),
                          moment(visit?.end?.toDate()).toDate()
                        )}
                  </Typography>
                )
              }
            />
          </ListItem>

          {visit.recurrence?.frequency > 0 && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <EventRepeatOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    {visit.recurrence.frequency > 1
                      ? `Every ${visit.recurrence.frequency}-weeks on ${moment(
                          visit?.start?.toDate()
                        ).format("dddd")}`
                      : `Every week on ${moment(visit?.start?.toDate()).format(
                          "dddd"
                        )}`}
                  </Typography>
                }
              />
            </ListItem>
          )}

          {/* Visit Notes */}
          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <FormatListBulletedOutlined color="primary" />
            </ListItemIcon>

            {isPastEvent ? (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "grey.100",
                  px: 2, // Adjust padding as needed
                  py: 1.5,
                  borderRadius: "15px", // To match TextField's default style
                  // Add more styling as needed to match the TextField appearance
                }}
              >
                {editableDescription || "No tasks specified"}
              </Typography>
            ) : (
              <TextField
                fullWidth
                minRows={4}
                multiline={true}
                variant="outlined"
                value={editableDescription}
                onChange={(e) => {
                  setEditableDescription(e.target.value);
                  debouncedSave(e.target.value);
                }}
                placeholder="Click to add tasks"
                sx={{
                  backgroundColor: "grey.100",
                }}
              />
            )}
          </ListItem>

          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <LocationOnOutlined color="primary" />
            </ListItemIcon>
            {privateData?.address && (
              <ListItemText
                sx={{ marginRight: 4 }}
                primary={
                  fetchPrivateData ? (
                    <Skeleton variant="text" />
                  ) : (
                    formatAddress(privateData.address)
                  )
                }
              />
            )}
          </ListItem>
          {privateData?.address?.directions && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <DirectionsOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                sx={{ marginRight: 4 }}
                primary={privateData?.address?.directions}
              />
            </ListItem>
          )}
          {visit?.rating && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <ChatOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={<RatingChip rating={visit.rating} />}
                secondary={visit.review}
              />
            </ListItem>
          )}

          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <GroupOutlined color="primary" />
            </ListItemIcon>

            <Stack direction="column" spacing={0}>
              {renderEmployeePositions(
                employees || {},
                visit?.employees,
                visit?.isOpenVisit,
                visit?.employeesNeeded
              )}
            </Stack>
          </ListItem>
        </List>

        {shouldShowTimes && <VisitTimelineView visit={visit} />}

        <VisitCardMenu
          canReschedule={canReschedule}
          canCancel={canCancel}
          canReview={canReview}
          canRefund={canRefund}
          canAddHelp={isActive}
          setShowAddEmployeeDialog={setShowAddEmployeeDialog}
          setRescheduleDialogOpen={setRescheduleDialogOpen}
          setShowConfirmDialog={setShowConfirmDialog}
          setShowRefundDialog={setShowRefundDialog}
          handleReviewOpen={handleReviewOpen}
        />
      </DialogContent>

      <CancelVisitDialog
        open={showConfirmDialog}
        setOpen={setShowConfirmDialog}
        handleCancel={handleClose}
        visit={visit}
        visitId={visitId}
        timeZoneId={timeZoneId}
      />

      <AddEmployeeDialog
        open={showAddEmployeeDialog}
        setOpen={setShowAddEmployeeDialog}
        visitId={visitId}
        employeesNeeded={visit?.employeesNeeded}
      />

      <VisitRefundRequest
        open={showRefundDialog}
        setOpen={setShowRefundDialog}
        visitId={visitId}
        memberId={visit?.member}
        billed={visit?.billed || false}
        employees={visit.employees}
      />
    </>
  );
};

export default VisitCard;
