import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import theme from "../theme";
import { MEMBER_COUNT, TASK_COUNT, VISIT_COUNT } from "../services/variableServices";

const CountUp = ({ end, duration = 1500 }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const ref = useRef(null);
  const observer = useRef(null);

  const countDecimals = (val) => {
    if (Math.floor(val) === val) return 0;
    return val.toString().split(".")[1]?.length || 0;
  };

  const formatNumber = (val, decimals) => {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  };

  const easeOutQuint = (x) => {
    return 1 - Math.pow(1 - x, 5);
  };

  useEffect(() => {
    const target = ref.current;
    const decimals = countDecimals(end);

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let startTimestamp = null;

            const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const elapsed = timestamp - startTimestamp;
              const progress = Math.min(elapsed / duration, 1);
              const easedProgress = easeOutQuint(progress);
              const interimValue = easedProgress * (end - 0) + 0;
              setCurrentValue(formatNumber(interimValue, decimals));

              if (progress < 1) {
                window.requestAnimationFrame(step);
              }
            };

            window.requestAnimationFrame(step);
          }
        });
      },
      { root: null, rootMargin: "0px", threshold: 0 }
    );

    if (target) {
      observer.current.observe(target);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [end, duration]);

  return (
    <Typography variant="h2" ref={ref} color={theme.palette.primary.vibrant}>
      {currentValue}
    </Typography>
  );
};

const Counter = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        gap: { xs: 2, sm: 4 },
        m: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f5f5f5",
          width: { xs: "auto", sm: "300px" },
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "15px",

          p: 5,
        }}
      >
        <CountUp end={MEMBER_COUNT} duration={1000} />
        <Typography fontWeight={"450"} fontSize={"1.5rem"}>
          Senior members
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f5f5f5",
          width: { xs: "auto", sm: "300px" },
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          p: 5,
          borderRadius: "15px",
        }}
      >
        <CountUp end={VISIT_COUNT} duration={1500} />
        <Typography fontWeight={"450"} fontSize={"1.5rem"}>
          Visits performed
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f5f5f5",
          width: { xs: "auto", sm: "300px" },
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          p: 5,
          borderRadius: "15px",
        }}
      >
        <CountUp end={TASK_COUNT} duration={2000} />
        <Typography fontWeight={"450"} fontSize={"1.5rem"}>
          Tasks completed
        </Typography>
      </Box>
    </Box>
  );
};

export default Counter;
