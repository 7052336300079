import DeleteForever from "@mui/icons-material/DeleteForever";
import HistoryRounded from "@mui/icons-material/HistoryRounded";
import PersonAdd from "@mui/icons-material/PersonAdd";
import RateReview from "@mui/icons-material/RateReview";
import MoneyOff from "@mui/icons-material/MoneyOff"; // Import refund-related icon

import {
  Box,
  Card,
  Grid,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { CurrencyExchangeRounded, EventBusy } from "@mui/icons-material";

const VisitCardMenu = ({
  canReschedule,
  canCancel,
  canReview,
  canRefund,
  canAddHelp,
  setShowAddEmployeeDialog,
  setRescheduleDialogOpen,
  setShowConfirmDialog,
  setShowRefundDialog,
  handleReviewOpen,
}) => {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (action) => {
    setSelectedItem(action);
    action(); // Perform the specific action
  };

  const menuItems = [
    {
      icon: <HistoryRounded color="primary" />,
      primary: "Reschedule",
      secondary: "Change the date or time of your upcoming visit.",
      action: () => {
        setRescheduleDialogOpen(true);
      },
      disabled: !canReschedule,
    },
    {
      icon: <EventBusy color="primary" />,
      primary: "Cancel",
      secondary: "Remove a visit or visit series from your calendar.",
      action: () => {
        setShowConfirmDialog(true);
      },
      disabled: !canCancel,
    },
    {
      icon: <RateReview color="primary" />,
      primary: "Review",
      secondary: "Leave feedback on your experience with our service.",
      action: () => {
        handleReviewOpen();
      },
      disabled: !canReview,
    },
    {
      icon: <CurrencyExchangeRounded color="primary" />,
      primary: "Refund",
      secondary: "Request a partial or complete refund.",
      action: () => {
        setShowRefundDialog(true);
      },
      disabled: !canRefund,
    },
    {
      icon: <PersonAdd color="primary" />,
      primary: "Extra Help",
      secondary: "Request extra team members for your visit.",
      action: () => {
        setShowAddEmployeeDialog(true);
      },
      disabled: !canAddHelp,
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {menuItems.map((item, index) => (
          <Grid item xs={6} key={index} sx={{ display: "flex" }}>
            <Box
              sx={{
                borderRadius: "15px",
                width: "100%",
                backgroundColor: item.disabled
                  ? theme.palette.grey[100]
                  : "white",
              }}
            >
              <ListItemButton
                onClick={() => handleItemClick(item.action)}
                disabled={item.disabled}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: theme.spacing(2),
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.primary.main}`,
                  flexGrow: 1,
                  height: "100%",
                  transition: "border-color 0.3s ease", // Updated transition
                  "&:hover": {
                    borderColor: theme.palette.grey[500], // Drop shadow on hover
                  },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Icon
                    sx={{ minWidth: "auto", marginRight: theme.spacing(1) }}
                  >
                    {item.icon}
                  </Icon>
                  <Typography
                    variant="subtitle2"
                    fontSize={"1rem"}
                    color="primary"
                    fontWeight={"600"}
                  >
                    {item.primary}
                  </Typography>
                </Box>
                <Typography variant="body2">{item.secondary}</Typography>
              </ListItemButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VisitCardMenu;
