import { Add, Check, CheckCircle, Lock, Remove } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions"; // Import Firebase functions
import React, { useState } from "react";
import { db, functions } from "../../firebase";
import { MEMBER_COUNT } from "../../services/variableServices";

const SignupFeePayment = ({
  customerId,
  memberId,
  handleClose,
  zipCode,
  signupFeeStatus = "incomplete",
  userInterface = "admin",
  locationStatus = "active",
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const idempotencyKey = memberId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [signupFee, setSignupFee] = useState(50);
  const [payOption, setPayOption] = useState("card");
  const [isCardComplete, setIsCardComplete] = useState(false); // New state to track card completeness

  const handleSubmit = () => {
    // Paying now with card
    if (payOption === "card") {
      handlePayment();
    } else if (payOption === "invoice") {
      handleCreateInvoice();
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    // Reference to the Cloud Functions
    const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");
    const updateDefaultPaymentMethod = httpsCallable(
      functions,
      "updateDefaultPaymentMethod"
    );

    try {
      const { data } = await createPaymentIntent({
        customerId,
        amount: signupFee,
        idempotencyKey,
      }); // pass the fee in cents
      const clientSecret = data.clientSecret;

      if (!clientSecret) throw new Error("Failed to get client secret.");

      const cardElement = elements.getElement(CardElement);

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
        setup_future_usage: "off_session", // Save the payment method for future use
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
        console.error(paymentResult.error);
      } else if (paymentResult.paymentIntent.status === "succeeded") {
        await handleChangeSignupFeeStatus("paid");

        // Step 3: Update the default payment method on Stripe
        const paymentMethodId = paymentResult.paymentIntent.payment_method;
        const updateResponse = await updateDefaultPaymentMethod({
          paymentMethodId,
          customerId,
        });

        if (
          updateResponse.data.result ===
          "Default payment method updated successfully"
        ) {
          console.log("Default payment method updated successfully");
        } else {
          setError("Failed to update default payment method.");
        }

        // Payment succeeded
        setSuccess(true);
      }
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSignupFeeStatus = async (status) => {
    console.log("memberId: ", memberId);

    // Update member private fee paid
    const memberDocRef = doc(db, "members", memberId, "private", "data");

    // Update the property
    await setDoc(memberDocRef, { signupFeeStatus: status }, { merge: true });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success"; // Replace with actual color or theme color
      case "invoice":
        return "primary"; // Replace with actual color or theme color
      default:
        return "error"; // Gray or Incomplete
    }
  };

  const handleCreateInvoice = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    // Reference to the Cloud Functions
    const createInvoiceItem = httpsCallable(functions, "createInvoiceItem");

    try {
      const { data } = await createInvoiceItem({
        customerId,
        amount: signupFee,
        description: "Signup fee for lifetime Linked Lives membership",
        taxable: false,
        createInvoice: true,
        idempotencyKey,
      });

      handleChangeSignupFeeStatus("invoice");

      console.log("Invoice item created successfully:", data);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const benefits = [
    // "One-time $50 signup fee",
    "Unlock lifetime membership access",
    "Reliable help on demand at $40/hr",
    "Peace of mind with household tasks",
    "Earn a free hour per referral",
    // "Deals and promotions",
    // "1-hour visit minimum",
    // "Schedule 3-4 days in advance",
    // "Availability within the week",
    // "Our money back guarantee",
    // "End anytime with no fees",
    // "Excellent customer service",
    `Trusted by over ${Math.floor(MEMBER_COUNT / 10) * 10}+ seniors`,
    "BBB Accredited, licensed, insured"
    // "Mix and match any tasks to fill the visit",
    // "Senior-friendly online scheduling",
    // "Join over 300+ seniors"
    // "Schedule recurring visits",
    // "Same day visits",
    // ... more benefits
  ];

  return (
    <>
      <DialogContent>
        <>
          {locationStatus === "waitlist" && (
            <Alert
              severity="error"
              sx={{
                borderRadius: "15px",
                mb: 1,
              }}
            >
              We're not yet in your area quite yet.
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography align="left" fontWeight={"600"} fontSize={"1.15rem"}>
              Complete your signup fee
            </Typography>
            {userInterface === "admin" && (
              <Alert
                color={getStatusColor(signupFeeStatus)}
                icon={false}
                sx={{
                  ml: 1,
                  px: 1,
                  py: 0.5,
                  borderRadius: "15px",
                }}
              >
                {signupFeeStatus}
              </Alert>
            )}
          </Box>
          <Typography align="left" color={"text.secondary"} sx={{ mb: 1 }}>
            Please enter your payment method to pay our one-time $50 signup fee.
            {/* that grants you lifetime membership access to our serivce. */}
          </Typography>
        </>

        {/* Sign-Up Fee Adjustment Controls */}
        {userInterface === "admin" && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginBottom: 2,
                backgroundColor: "#f5f5f5",
                p: 4,
                borderRadius: "15px",
              }}
            >
              <IconButton
                onClick={() => setSignupFee((prev) => Math.max(0, prev - 5))}
                disabled={signupFee <= 0}
                size="large"
              >
                <Remove />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mx: 2,
                  minWidth: "100px",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "500" }}>
                  $
                </Typography>
                <Typography variant="h4" fontWeight="700">
                  {signupFee}
                </Typography>
              </Box>

              <IconButton
                onClick={() => setSignupFee((prev) => Math.min(50, prev + 5))}
                disabled={signupFee >= 50}
                size="large"
              >
                <Add />
              </IconButton>
            </Box>

            {/* Add a Checkbox for Payment Options */}
            <Box
              sx={{
                marginBottom: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payOption === "invoice"}
                    onChange={(e) =>
                      setPayOption(e.target.checked ? "invoice" : "card")
                    }
                  />
                }
                label="Pay within 7 days with invoice"
              />
            </Box>
          </>
        )}
        {userInterface === "member" && (
          <List dense={true}>
            {benefits.map((benefit, idx) => (
              <ListItem key={idx}>
                <Check color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{benefit}</Typography>
              </ListItem>
            ))}
          </List>
        )}

        <Collapse in={payOption === "card"} sx={{ my: 2 }}>
          <Box
            sx={{
              boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.15)",
              border: "1px solid hsl(190,100%,30%)",
              p: 2,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardElement
              options={{
                disableLink: true,
                style: {
                  base: {
                    fontSize: "16px", // Adjust the font size here
                  },
                },
              }}
              onChange={(e) => setIsCardComplete(e.complete)} // Set the state based on card details completeness
            />
          </Box>
          <Box
            sx={{
              ml: 1,
              mt: 0.5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">
              Secured by <strong>Stripe</strong>
            </Typography>
            {userInterface === "admin" && (
              <Typography sx={{ mr: 1 }} variant="caption">
                (Postcode: {zipCode})
              </Typography>
            )}
          </Box>
        </Collapse>
        {error && (
          <Typography
            variant="body2"
            color="error"
            align="center"
            sx={{ marginTop: 2 }}
          >
            {error}
          </Typography>
        )}

        {userInterface === "member" && (
          <Box sx={{ mb: 2 }}>
            <Typography align="left" variant="caption">
              The day after each visit, we will bill this card and send an email
              receipt. No need to pay employees directly, but tips are
              appreciated for great work. Update card anytime.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color={success ? "success" : "primary"}
            onClick={success ? handleClose : handleSubmit}
            disabled={
              !stripe ||
              !elements ||
              loading ||
              (payOption === "card" && !isCardComplete)
            }
            sx={{
              height: "60px",
              textTransform: "none",
              width: "100%",
            }}
            startIcon={
              success ? (
                <CheckCircle />
              ) : payOption === "card" && isCardComplete ? (
                <Lock />
              ) : null
            }
          >
            {loading ? (
              <>
                <CircularProgress color="inherit" size={24} sx={{ mr: 1 }} />{" "}
                Processing...
              </>
            ) : success ? (
              "Success (click to close)"
            ) : payOption === "invoice" ? (
              `Send invoice for $${signupFee}`
            ) : payOption === "card" && !isCardComplete ? (
              "Please enter your card information"
            ) : (
              `Pay $${signupFee} signup fee`
            )}
          </Button>
          {!(payOption === "card" && success) && userInterface === "admin" && (
            <Button
              variant="text"
              onClick={() => {
                handleChangeSignupFeeStatus("paid");
                setSuccess(true);
              }}
              fullWidth
              disabled={loading}
              sx={{
                height: "60px",
                textTransform: "none",
              }}
            >
              Mark as paid
            </Button>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default SignupFeePayment;
