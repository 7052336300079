import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import GuestHeader from "../../components/GuestHeader";
import ContactUs from "../../components/popup/ContactUs";
import ContactSection from "./sections/ContactSection";
import WebFooter from "./sections/WebFooter";

function ContactPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', {
        value: 2,
        currency: 'USD',
      });
    }
  }, []);

  return (
    <div>
      <GuestHeader />
      <ContactUs />
      <ContactSection />
      <WebFooter />
    </div>
  );
}

export default ContactPage;
