import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { processVisitRefund } from "../services/visitServices";
import AvatarStatusBadge from "./AvatarStatusBadge"; // Import your AvatarStatusBadge component
import ResponsiveDialog from "./ResponsiveDialog";
import { sendSMSClient } from "../services/communicationServices";

const VisitRefundRequest = ({
  visitId,
  memberId,
  employees,
  billed,
  open,
  setOpen,
}) => {
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("employeeSelection"); // New state to track the current view
  const [refundReasons, setRefundReasons] = useState([]); // State to track selected refund reasons
  const [refundResult, setRefundResult] = useState();
  const predefinedReasons = [
    "Unhappy with service provided",
    "Employee(s) did not show up",
    "Employee(s) were unprofessional",
    "Employee(s) arrived late or left early",
  ]; // Example reasons
  const [customReason, setCustomReason] = useState(""); // State for custom reason

  // Auto-select the employee if there's only one "accepted"
  useEffect(() => {
    const acceptedEmployees = Object.entries(employees).filter(
      ([_, employee]) => employee.responseStatus === "accepted"
    );
    if (acceptedEmployees.length === 1) {
      const [singleEmployeeId] = acceptedEmployees[0];
      setSelectedEmployees({ [singleEmployeeId]: true });
      setView("reasonSelection"); // Skip to reason selection
    }
  }, [employees]);

  // Function to handle individual employee selection
  const handleToggle = (employeeId) => {
    setSelectedEmployees({
      ...selectedEmployees,
      [employeeId]: !selectedEmployees[employeeId],
    });
  };

  // Function to handle 'Refund' button click
  const handleRefund = async () => {
    setLoading(true);
    setView("refundProcessing"); // Change view on successful refund

    // Extract employee IDs of the selected employees
    const employeesToRefund = Object.keys(selectedEmployees).filter(
      (key) => selectedEmployees[key]
    );

    // Prepare data to be sent to the Cloud Function
    const refundData = {
      employeeIds: employeesToRefund,
      reason: [...refundReasons, customReason].filter(Boolean), // Include custom reason if it's not empty
      visitId: visitId,
      billed,
      memberId,
      // Include any other necessary data like 'billed' status if applicable
    };
    try {
      // Call the Cloud Function
      const response = await processVisitRefund(refundData);

      setRefundResult(response?.refundResult); // HANDLE: cancelled, recreated, deleted, cancelled, refunded
      setView("refundProcessed"); // Change view on successful refund
    } catch (error) {
      console.error("Error processing refund:", error);
      setView("refundError");

      // Prepare the message content
      const smsBody = `Refund Error: ${
        error.message
      }\nRefund Data: ${JSON.stringify(refundData)}`;

      sendSMSClient({ to: "3607081269", body: smsBody });

      // Optionally, handle specific errors (e.g., showing a message to the user)
    } finally {
      setLoading(false);
    }
  };

  // Function to determine if any employee is selected
  const isAnyEmployeeSelected = () => {
    return Object.values(selectedEmployees).some((value) => value);
  };

  // Function to check if any reason is selected (including custom reason)
  const isAnyReasonSelected = () => {
    return refundReasons.length > 0 || customReason !== "";
  };

  const getRefundStatusMessage = (status) => {
    switch (status) {
      case "DraftRemoved":
        return "The invoice was in a draft state and the item has been removed.";
      case "InvoiceRecreated":
        return "The invoice has already been sent but is not paid. A new invoice has been created without the refunded item.";
      case "InvoiceDeleted":
        return "The invoice was open but only had one item, so the entire invoice has been deleted.";
      case "RefundIssued":
        return "The invoice had already been charged. A refund has been issued and will appear in 5-10 business days.";
      case "PendingRemoved":
        return "The pending line item has been removed.";
      default:
        return "The refund has been processed.";
    }
  };

  // Function to handle moving to the reason selection view
  const handleProceedToReason = () => {
    if (isAnyEmployeeSelected()) {
      setView("reasonSelection");
    }
  };

  // Function to handle the selection of refund reasons
  const handleReasonSelection = (reason) => {
    setRefundReasons((prev) => {
      if (prev.includes(reason)) {
        return prev.filter((r) => r !== reason);
      } else {
        return [...prev, reason];
      }
    });
  };

  const renderEmployeeSelection = () => {
    return (
      <>
        <DialogContent>
          <Typography variant="body1">
            {Object.entries(employees).filter(
              ([_, employee]) => employee.responseStatus === "accepted"
            ).length === 1
              ? `Confirm your refund for your past visit with ${
                  employees[
                    Object.entries(employees).find(
                      ([_, employee]) => employee.responseStatus === "accepted"
                    )[0]
                  ].displayName
                } We hope that we can do better next time!`
              : "Select the employees you wish to refund for this visit. We hope that we can do better next time!"}
          </Typography>
          <List>
            {Object.entries(employees)
              .filter(([_, employee]) => employee.responseStatus === "accepted")
              .map(([key, employee], index) => (
                <ListItemButton key={key} onClick={() => handleToggle(key)}>
                  <ListItemAvatar>
                    <AvatarStatusBadge
                      status={employee.responseStatus}
                      avatarUrl={employee.avatarUrl}
                      displayName={employee.firstName}
                      index={index}
                      showBadge={false}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={employee.displayName} />
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggle(key)}
                    checked={selectedEmployees[key] || false}
                  />
                </ListItemButton>
              ))}
          </List>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            Go back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProceedToReason}
            disabled={!isAnyEmployeeSelected()} // Disable if no employee is selected or if loading
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderReasonSelection = () => {
    const showGoBackButton =
      Object.keys(employees).filter(
        (key) => employees[key].responseStatus === "accepted"
      ).length > 1;
    return (
      <>
        <DialogContent>
          <Typography variant="body1" fontWeight={"bold"} sx={{ mb: 1, mt: 2 }}>
            Please let us know your reason for refund.
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {predefinedReasons.map((reason) => (
                <FormControlLabel
                  key={reason}
                  control={
                    <Checkbox
                      checked={refundReasons.includes(reason)}
                      onChange={() => handleReasonSelection(reason)}
                    />
                  }
                  label={reason}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Typography variant="body1" fontWeight={"bold"} sx={{ mt: 2, mb: 1 }}>
            Comments:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="Enter a custom reason or message"
            multiline
            rows={2} // Optional: Adjust based on required input size
          />
        </DialogContent>

        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          {showGoBackButton && (
            <Button
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={() => setView("employeeSelection")}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "56px" },
              }}
            >
              Go back
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefund}
            disabled={!isAnyReasonSelected() || loading} // Disable if no employee is selected or if loading
            sx={{
              minWidth: { xs: showGoBackButton ? "50%" : "100%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Process Refund"
            )}
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderRefundProcessed = () => {
    let refundStatusMessage =
      "We have not billed you for this visit yet. The pending line item has been removed.";
    if (!billed) {
      refundStatusMessage = getRefundStatusMessage(
        refundResult?.status || null
      );
    }

    return (
      <>
        <DialogContent>
          <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
            Refund Successful
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Your refund has been successfully processed.
          </Typography>
          <Alert severity="info">{refundStatusMessage}</Alert>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  const renderRefundProcessing = () => {
    return (
      <>
        <DialogContent>
          <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
            Refund Processing
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mb: 10,
              gap: 2,
            }}
          >
            <Typography variant="body2">
              This may take up to 10 seconds, please wait.
            </Typography>
            <CircularProgress />
          </Box>
        </DialogContent>
      </>
    );
  };

  // Add a new function to render the error view
  const renderRefundError = () => {
    return (
      <>
        <DialogContent>
          <Typography variant="h6" color="error" sx={{ mb: 2 }}>
            Additional Assistance Required
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We apologize, in order to issue this refund, we need to manually
            adjust it it in our system. Our team has already been notified and
            will reach out to you within 1-2 business days with a resolution.
            Please call or email for any further assistance.
          </Typography>
          <Box sx={{ backgroundColor: "#f5f5f5", p: 2, borderRadius: "15px" }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Email:{" "}
              <a href="mailto:alex@ourlinkedlives.com">
                alex@ourlinkedlives.com
              </a>
            </Typography>
            <Typography variant="body1">
              Phone: <a href="tel:+13603366344">(360) 336-6344</a>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={`Refund Visit`}
      width="500px"
    >
      {view === "employeeSelection" && renderEmployeeSelection()}
      {view === "reasonSelection" && renderReasonSelection()}
      {view === "refundProcessing" && renderRefundProcessing()}
      {view === "refundProcessed" && renderRefundProcessed()}
      {view === "refundError" && renderRefundError()}
    </ResponsiveDialog>
  );
};

export default VisitRefundRequest;

// Helper function to get response status text
const getResponseStatusText = (status) => {
  switch (status) {
    case "accepted":
      return "confirmed";
    case "declined":
      return "declined";
    case "needsAction":
      return "assigned"; // This is the special case you've shown above
    default:
      return "unknown status";
  }
};
