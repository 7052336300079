import {
  Avatar,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { ArrowForwardIosRounded } from "@mui/icons-material";
import { formatDateRange } from "../../services/dateServices";
import ResponsiveDrawer from "../ResponsiveDrawer";
import EventDetails from "./EventDetails";
import EventStatusChip from "./EventStatusChip";

const EventPreviewList = ({
  timeZoneId,
  events,
  userId,
  onJoinEvent = () => {},
}) => {
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [openEventDetails, setOpenEventDetails] = useState(false);

  const handleEventClick = (eventId) => {
    setSelectedEventId(eventId);
    setOpenEventDetails(true);
  };

  const handleJoin = () => {
    onJoinEvent();
  };

  // Check if events is not undefined and has keys
  if (!events || Object.keys(events).length === 0) {
    return <Typography>Loading events...</Typography>; // or any other placeholder
  }

  const eventsEntries = Object.entries(events);
  const totalEvents = eventsEntries.length;

  return (
    <>
      <List disablePadding disableGutters>
        {Object.entries(events).map(([eventId, event], index) => (
          <>
            {/* Only insert Divider if not the last item */}
            {index === totalEvents - 1 && <Divider sx={{ my: 1 }} />}
            <ListItemButton
              disableGutters
              key={event.id}
              onClick={() => handleEventClick(eventId)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "15px",
                p: 1,
                m: 1,
              }}
            >
              {event.mediaUrls && event.mediaUrls.length > 0 && (
                <ListItemAvatar sx={{ mr: 2 }}>
                  <Avatar
                    src={event.mediaUrls[0]}
                    alt={event.title}
                    sx={{
                      width: 128,
                      height: 128,
                      borderRadius: "10px",
                    }} // Adjust width, height, and borderRadius as needed
                  />
                </ListItemAvatar>
              )}
              <ListItemText
                disableGutters
                sx={{ my: 0 }}
                primary={<Typography variant="h6">{event.title}</Typography>}
                secondary={
                  <>
                    <Typography variant="body1" color="text.secondary">
                      {formatDateRange(event.eventDays)}
                    </Typography>
                    <EventStatusChip
                      signUpList={event.signUpList}
                      maxSignUps={event.maxSignUps}
                      userId={userId}
                      eventDays={event.eventDays}
                      timeZoneId={timeZoneId}
                    />
                  </>
                }
              />
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <ArrowForwardIosRounded />
              </ListItemIcon>
            </ListItemButton>
          </>
        ))}
      </List>
      <ResponsiveDrawer
        open={openEventDetails}
        onClose={() => setOpenEventDetails(false)}
        title={"Event details"}
        width="600px"
      >
        {selectedEventId && (
          <EventDetails
            event={{ id: selectedEventId, ...events[selectedEventId] }}
            timeZoneId={timeZoneId}
          />
        )}
      </ResponsiveDrawer>
    </>
  );
};

export default EventPreviewList;
