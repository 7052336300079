import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, TextField } from "@mui/material";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";

const SmsVerification = ({
  onBack,
  navigate,
  onRequestCode,
  values,
  smsStatus,
  smsError,
}) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (code.length === 4) {
      verifyCode();
    }
  }, [code]);

  const verifyCode = async () => {
    setLoading(true);
    try {
      // Call your Firebase Function for token validation
      const functions = getFunctions();
      const validateSmsCode = httpsCallable(functions, "validateSmsCode");

      const { data: validationResponse } = await validateSmsCode({
        code,
        email: values.email,
      });

      console.log(
        "validationResponse.customToken: ",
        validationResponse.customToken
      );

      if (validationResponse.status !== "success") {
        throw new Error(validationResponse.message);
      }

      const auth = getAuth();
      await signInWithCustomToken(auth, validationResponse.customToken);
      navigate("/dashboard");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Filter out non-digit characters
    const filteredValue = inputValue.replace(/\D/g, "");

    // Slice the first 4 digits
    const newCode = filteredValue.slice(0, 4);
    setCode(newCode);
  };

  const renderChip = () => {
    if (loading) {
      return (
        <Chip
          icon={<CircularProgress size={20} sx={{ mx: 2 }} color="primary" />}
          label="Signing in..."
          color="primary"
          sx={{ mb: 2 }}
        />
      );
    }

    if (!error && !loading) {
      return (
        <>
          {smsStatus === "sending" && (
            <Chip
              icon={<CircularProgress size={20} sx={{ mx: 2 }} />}
              label="Sending text message..."
              sx={{ mb: 2 }}
            />
          )}
          {smsStatus === "success" && (
            <Chip
              icon={<CheckCircle />}
              label="Text message sent"
              color="success"
              sx={{ mb: 2 }}
            />
          )}
          {smsStatus === "error" && (
            <Chip label={smsError} color="error" sx={{ mb: 2 }} />
          )}
        </>
      );
    }

    if (error && !loading) {
      return <Chip label={error} color="error" sx={{ mb: 2 }} />;
    }
  };

  return (
    <Box textAlign="center">
      {renderChip()}

      <TextField
        autoFocus
        fullWidth
        value={code}
        onChange={handleInputChange}
        placeholder="_ _ _ _"
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          maxLength: 4,
          autocomplete: "one-time-code",
        }}
        sx={{
          "& input": {
            textAlign: "center",
            fontSize: "1.5rem",
          },
        }}
      />

      <Button
        variant="text"
        onClick={onBack}
        sx={{
          width: "100%",
          height: "56px",
          mt: 4,
          color: "gray", // sets the text color to gray
          backgroundColor: "none", // sets the background color to none initially
          transition: "background-color 0.3s", // smooth transition for background color change
          "&:active": {
            backgroundColor: "lightgray", // changes background color when pressed
          },
        }}
      >
        Back
      </Button>
    </Box>
  );
};

export default SmsVerification;
