import React from "react";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";

const ColorAvatar = ({ name, avatarUrl, size = "60px" }) => {
  const theme = useTheme();

  const getColor = (chars) => {
    // Ensure the string is exactly two characters
    if (chars.length < 2) {
      chars = chars[0] ? chars[0].repeat(2) : null; // Repeat the character if it exists, else set to null
    } else if (chars.length > 2) {
      chars = chars.substring(0, 2); // Truncate to the first two characters
    }

    if (!chars) return null; // Return null if chars is null or empty

    const colors = [
      theme.palette.complementaryContrast.color1,
      theme.palette.complementaryContrast.color2,
      theme.palette.complementaryContrast.color3,
      theme.palette.complementaryContrast.color4,
      theme.palette.complementaryContrast.color5,
      theme.palette.complementaryContrast.color6,
    ];

    // Convert HSL string to an array of numbers [H, S, L]
    const hslToArray = (hsl) => hsl.match(/\d+/g).map(Number);

    // Linear interpolation between two values
    const lerp = (a, b, amount) => a + (b - a) * amount;

    // Blend two HSL colors
    const blendHsl = (hsl1, hsl2, ratio) => {
      const [h1, s1, l1] = hslToArray(hsl1);
      const [h2, s2, l2] = hslToArray(hsl2);

      const h = Math.round(lerp(h1, h2, ratio));
      const s = Math.round(lerp(s1, s2, ratio));
      const l = Math.round(lerp(l1, l2, ratio));

      return `hsl(${h}, ${s}%, ${l}%)`;
    };

    // Convert character to number (0-25)
    const charToNumber = (char) => char.toUpperCase().charCodeAt(0) - 65;
    const num1 = charToNumber(chars[0]);
    const num2 = charToNumber(chars[1]);

    // Determine primary and secondary colors
    const primaryColorIndex =
      Math.floor((num1 * 26 + num2) / 113) % colors.length;
    const secondaryColorIndex =
      (primaryColorIndex + 1 + (num2 % 5)) % colors.length;

    const primaryColor = colors[primaryColorIndex];
    const secondaryColor = colors[secondaryColorIndex];
    const blendRatio = (num2 + 1) / 26;

    return blendHsl(primaryColor, secondaryColor, blendRatio);
  };

  const getInitials = (name) => {
    // Ensure 'name' is defined and is a string
    if (typeof name !== "string" || name.length === 0) return "";

    const parts = name.split(" ").filter(Boolean);
    if (parts.length === 1) return parts[0].charAt(0);

    return parts
      .slice(0, 2)
      .map((part) => part.charAt(0))
      .join("");
  };

  // Generate initials
  const initials = getInitials(name);

  // Determine the background color based on initials
  const bgColor = getColor(name);

  return (
    <Avatar
      src={avatarUrl}
      sx={{
        width: size,
        height: size,
        bgcolor: bgColor ? bgColor : theme.palette.grey[300], // Fallback color if getColor returns null
      }}
    >
      {initials}
    </Avatar>
  );
};

export default ColorAvatar;
