/* global google */

import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterSimple from "../components/FooterSimple";
import ResponsiveDialog from "../components/ResponsiveDialog";
import StyledProgress from "../components/StyledProgress";
import CreateMemberAndAccountForm from "../forms/CreateMemberAndAccountForm";
import { createMemberAccountStripe } from "../services/memberServices";

const CreateMemberAndAccountPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState(
    "Creating your profile..."
  );

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      updateProgress(90, "Creating your account..."); // Progress is at 33% after first step

      await createMemberAccountStripe(values);

      updateProgress(100, "All set! Redirecting..."); // Progress is at 100% after final step

      redirectToDashboard();
    } catch (error) {
      console.error("Error creating member:", error);
      clearInterval(window.progressInterval); // Clear the interval on error
      setProgress(0);
      setProgressMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop the spinner after form submission completes
    }
  };

  // Testing the progressing with a use effect, set loading to true to test.
  // useEffect(() => {
  //   // Define the milestones for the progress updates
  //   const progressMilestones = [
  //     { progress: 33, message: "Creating your account..." },
  //     { progress: 66, message: "Setting up your billing..." },
  //     { progress: 100, message: "All set! Redirecting..." },
  //   ];

  //   // Initialize the current milestone to the first one
  //   let currentMilestoneIndex = 0;

  //   // Function to update the progress to the next milestone
  //   const advanceToNextMilestone = () => {
  //     if (currentMilestoneIndex < progressMilestones.length) {
  //       // Get the next milestone
  //       const { progress, message } = progressMilestones[currentMilestoneIndex];

  //       // Trigger the progress update
  //       updateProgress(progress, message);

  //       // Move to the next milestone
  //       currentMilestoneIndex++;
  //     } else {
  //       // If we've hit all milestones, stop the interval
  //       clearInterval(progressInterval);
  //     }
  //   };

  //   // Start the interval to advance to the next milestone every 2 seconds
  //   const progressInterval = setInterval(advanceToNextMilestone, 2000);

  //   // Cleanup the interval on component unmount
  //   return () => {
  //     clearInterval(progressInterval);
  //   };
  // }, []);

  const updateProgress = (targetProgress, message) => {
    // Update the progress message
    setProgressMessage(message);

    // Clear any existing interval
    clearInterval(window.progressInterval);

    // Set up a new interval
    window.progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        // Determine the gap between the current progress and the target
        const gap = targetProgress - oldProgress;

        // Adjust the speed: the closer we are to the target, the smaller the increment
        // This creates a "slowing down" effect as we approach the target
        const speedFactor = gap / 4; // Adjust this factor to control the "slowing down" effect

        // Random increment value, influenced by the speedFactor
        // The increment is smaller when the gap is smaller
        const randomIncrement = (Math.random() * 2 + 1) * speedFactor;

        // Calculate the new progress
        let newProgress = oldProgress + randomIncrement;

        // If the new progress reached the target, clear the interval
        if (newProgress >= targetProgress) {
          clearInterval(window.progressInterval);
          newProgress = targetProgress; // Ensure we don't exceed the target progress
        }

        // Ensure the new progress doesn't exceed 100%
        return Math.min(newProgress, 100);
      });
    }, 200); // Adjust the interval timing based on preference
  };

  const redirectToDashboard = async () => {
    try {
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to fetch Member data:", error);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh" // This ensures it takes up the full viewport height
          bgcolor="background.paper" // Just for ensuring the background isn't the same color as the progress bar
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="50vw" // Adjust if the viewport is too narrow, affecting the width of the progress bar
            minWidth="300px" // Minimum width for narrower screens
          >
            <Typography variant="h6" gutterBottom>
              {progressMessage}
            </Typography>
            <StyledProgress variant="determinate" value={progress} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            bgcolor: "white",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            position: "relative",
            px: 2,
          }}
        >
          <ResponsiveDialog
            open={true}
            onClose={() => {}}
            fullHeight={isMobile ? null : "calc(100vh - 50px)"}
            title={`Sign up`}
            width="600px"
            showAction={false}
            showBackdrop={false}
            // maxHeight="583px"
            anchor="right"
            showLogo={true}
          >
            <CreateMemberAndAccountForm handleSubmit={handleSubmit} />
          </ResponsiveDialog>
          {!isMobile && <FooterSimple />}
        </Box>
      )}
    </>
  );
};

export default CreateMemberAndAccountPage;
