import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";

import { Box, Divider, Typography } from "@mui/material";
import { Timestamp, arrayUnion, doc, increment, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import RoleSelection from "../forms/signUp/RoleSelection";
import SimplePage from "./SimplePage";

const GeneralSignUp = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gLoading, setGLoading] = useState(false);
  const [userCredential, setUserCredential] = useState(null); // Add this state to store user credentials
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1) // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Signup");
  }, []);

  const DividerWithText = (props) => {
    return (
      <Box display="flex" alignItems="center" my={2}>
        <Box flex={1}>
          <Divider />
        </Box>
        <Typography
          variant="body2"
          component="span"
          color="textSecondary"
          mx={2}
        >
          {props.children}
        </Typography>
        <Box flex={1}>
          <Divider />
        </Box>
      </Box>
    );
  };

  const navigate = useNavigate();
  const nextUrl = "/member/create-account";

  const query = new URLSearchParams(window.location.search);
  const referral = query.get("referral");
  // Construct the URL with or without the referral query parameter
  const urlWithOptionalReferral = referral
    ? `${nextUrl}?referral=${referral}`
    : nextUrl;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleGoogleSignUp = async () => {
    setGLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const credential = await signInWithPopup(auth, provider);
      setUserCredential(credential); // Store the user credential
      setSelectedRole("Pending"); // Set role selection to pending after successful sign up
    } catch (err) {
      setErrors({ general: "Google sign up failed. Please try again." });
    } finally {
      setGLoading(false);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newError = {}; // initialize newError object here
    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      setUserCredential(credential); // Store the user credential
      setSelectedRole("Pending"); // Set role selection to pending after successful sign up
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimplePage>
      {/* {!selectedRole ? (
        <Box
          variant="outlined"
          sx={{
            padding: { xs: 1, sm: 3 },
            borderRadius: "15px",
            // border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
            overflow: "hidden",
            maxWidth: "400px",
            width: "100%",
            minHeight: "300px",
            margin: "0 auto",
            position: "relative",
            display: "flex", // Use flex layout
            flexDirection: "column", // Stack children vertically
            boxSizing: "border-box",
          }}
        >
          {" "}
          <Typography variant="h5" align="center" gutterBottom>
            Sign Up
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            Already have an account?{" "}
            <Link
              to="/login"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Login
            </Link>
          </Typography>
          <form onSubmit={handleSignUp} noValidate>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={loading || gLoading}
              sx={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
            >
              {loading ? <CircularProgress size={24} /> : "Sign Up"}
            </Button>
          </form>
          <DividerWithText>or</DividerWithText>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Google />} // Add the Google icon here
            onClick={handleGoogleSignUp}
            fullWidth
            disabled={loading || gLoading}
            sx={{ width: "100%", height: "56px" }} // Added marginTop to push the button to the bottom
          >
            {gLoading ? <CircularProgress size={24} /> : "Sign Up with Google"}
          </Button>
          {/* Role Selection */}
      {/* </Box>
      ) : ( */}
      <RoleSelection userCredential={userCredential} />
    </SimplePage>
  );
};

export default GeneralSignUp;
