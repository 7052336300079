import {
  AccountBalance,
  Grading,
  HomeRepairServiceRounded,
  Loyalty,
  PersonPin,
} from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event"; // For "Link Visits"
import {
  Box,
  Card,
  CardContent,
  Grid,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

function MembershipDetails() {
  const theme = useTheme();

  // Array of objects for each card
  const cardDetails = [
    {
      title: "Schedule Visits",
      icon: (
        <EventIcon
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "You can transparently view all monthly availability and then book days or weeks in advance, online or over the phone with a 1-hour minimum.",
    },
    {
      title: "Pick Your Helper",
      icon: (
        <PersonPin
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "You have full control of who helps you. Browse profiles, read reivews, and develop friendships with your favorite helpers!",
    },
    {
      title: "Fits Your Budget",
      icon: (
        <Loyalty sx={{ color: theme.palette.primary.dark }} fontSize="large" />
      ),
      body: "Our members book 2 hours per month on average, depending on budget and needs. We can accomodate anywhere from weekly help, to here-and-there.",
    },

    {
      title: "No Monthly Fees",
      icon: (
        <Grading sx={{ color: theme.palette.primary.dark }} fontSize="large" />
      ),
      // body: "There are no contracts or monthly fees. We're here to help as needed, whether that's once every season or multiple times a week! Your lifetime membership is just a one time payment to cover our administrative costs.",
      body: "There are no contracts, monthly fees, or minimums. Our 300+ members love the peace of mind that comes with our excellent help on-demand.",
      // body: "There are no contracts or monthly fees, just our flat hourly rate. Our members love the peace of mind that comes with our help on-demand.",
    },
    {
      title: "Easy Payment",
      icon: (
        <AccountBalance
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      // body: "After your first visit, we'll email you an invoice to set up your payment account. You can pay with a debit or credit card, and you can change your payment method at any time.",
      body: "The day after your visit, we'll bill your preferred payment method or email an invoice. No need to pay helpers directly, but tips are loved for great work.",
    },
    {
      title: "Tools and Supplies",
      icon: (
        <HomeRepairServiceRounded
          sx={{ color: theme.palette.primary.dark }}
          fontSize="large"
        />
      ),
      body: "Please be prepared with any tools and supplies, whether it's a broom, shovel, or mop. This helps us work efficiently and provide a wide range of services.",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Box
        sx={{
          paddingY: { xs: 4, sm: 8 },
          backgroundColor: theme.palette.primary.dark,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "auto", // Centers the box while maintaining a max width
          maxWidth: 800, // Max width of the text box
          paddingX: theme.spacing(2), // Horizontal padding
        }}
      >
        <Typography variant="h4" gutterBottom align="center" color="white">
          Service Details
        </Typography>
        <Typography
          variant="body1"
          fontWeight="400"
          mb={3}
          align="left"
          color="white"
        >
          Our over 300+ members enjoy excellent help around the house. From the
          occasional odd-job to routine chores, we have you covered. We look forward to getting to know you!
          {/* And with our satisfaction guarantee, you can
          experience the joys of a Linked Lives helper for yourself, whenever
          you are ready to schedule your first visit! */}
        </Typography>

        <Grid
          container
          spacing={2}
          alignItems="stretch"
          justifyContent="center"
          display="flex"
        >
          {cardDetails.map((detail, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: theme.palette.primary.lightest,
                  display: "flex",
                  flexDirection: "column",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for transform and shadow
                  "&:hover": {
                    transform: "translateY(-4px)", // Slightly raise the card
                    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)", // Elegant drop shadow
                  },
                  height: "100%", // Ensure card takes full height of the grid item
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <ListItemIcon>{detail.icon}</ListItemIcon>
                  <Typography variant="h6">{detail.title}</Typography>
                  <Typography variant="body2" mt={1}>
                    {detail.body}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default MembershipDetails;
