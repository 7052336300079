import OddJobsIcon from "@mui/icons-material/Build";
import OrganizationIcon from "@mui/icons-material/Category";
import DecorationIcon from "@mui/icons-material/Celebration";
import FurnitureIcon from "@mui/icons-material/Chair";
import CleaningIcon from "@mui/icons-material/CleaningServices";
import TechHelpIcon from "@mui/icons-material/Computer";
import HeavyLiftingIcon from "@mui/icons-material/FitnessCenter";
import GarageHelpIcon from "@mui/icons-material/Garage";
import YardWorkIcon from "@mui/icons-material/Grass";
import GardeningIcon from "@mui/icons-material/LocalFlorist";
import CompanionshipIcon from "@mui/icons-material/People";
import ErrandsIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import StyledChecklist from "../../components/styled/StyledChecklist";
import { db } from "../../firebase";
import BackButton from "../buttons/BackButton";

const TASKS_LIST = [
  {
    name: "Gardening",
    Icon: GardeningIcon,
    description: "Transform your garden with an extra hand",
    examples: ["Weed garden beds", "Plant flowers", "Water plants"],
  },
  {
    name: "Yard Work",
    Icon: YardWorkIcon,
    description: "Keep your yard in check with regular upkeep",
    examples: ["Spread bark", "Rake leaves", "Prune bushes"],
  },
  {
    name: "Cleaning",
    Icon: CleaningIcon,
    description: "Give your home a chance to sparkle",
    examples: ["Scrub cabinets", "Vacuum carpets", "Mop floors"],
  },
  {
    name: "Garage Help",
    Icon: GarageHelpIcon,
    description: "Reclaim extra space in your garage",
    examples: [
      "Move heavy boxes",
      "Organize storage",
      "Clean nooks and crannies",
    ],
  },
  {
    name: "Organization",
    Icon: OrganizationIcon,
    description: "Declutter those stubborn areas",
    examples: ["Organize closets", "Declutter rooms", "Sort through boxes"],
  },
  {
    name: "Heavy Lifting",
    Icon: HeavyLiftingIcon,
    description: "Save your back while we move heavy items",
    examples: ["Re-arrange furniture", "Carry heavy boxes", "Move rugs"],
  },
  {
    name: "Tech Help",
    Icon: TechHelpIcon,
    description: "Solve your tech problems with our savvy help",
    examples: [
      "Setup TV streaming",
      "Computer and phone help",
      "Connect printer",
    ],
  },
  {
    name: "Decoration",
    Icon: DecorationIcon,
    description: "Celebrate festivities with seasonal decorations",
    examples: ["Setup Christmas tree", "String lights", "Hang artwork"],
  },
  {
    name: "Errands",
    Icon: ErrandsIcon,
    description: "Free your time by letting us run basic errands",
    examples: ["Grocery shop", "Pick up flowers", "Mail packages"],
  },
  {
    name: "Furniture",
    Icon: FurnitureIcon,
    description: "Get help with furniture assembly and moving.",
    examples: ["Assemble storage racks", "Move couch", "Clean arm chair"],
  },
  {
    name: "Companion",
    Icon: CompanionshipIcon,
    description: "Enjoy friendly company and conversation.",
    examples: ["Chat over coffee", "Play board games", "Go for walks"],
  },
  {
    name: "Odd Jobs",
    Icon: OddJobsIcon,
    description: "Handle small tasks around the house with ease.",
    examples: [
      "Winterize deck furniture",
      "Change light bulbs",
      "Re-pot plants",
    ],
  },
];

const TasksCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    items: "",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          tasks: values.items,
        },
        { merge: true }
      );
      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleChoiceChange = (source) => {
    setValues((prevValues) => {
      const isSelected = prevValues.items.includes(source);
      return {
        ...prevValues,
        items: isSelected
          ? prevValues.items.filter((s) => s !== source)
          : [...prevValues.items, source],
      };
    });
  };

  return (
    <>
      <DialogContent>
        <BackButton
          handleBack={handleBack}
          showBack={showBack}
          loading={loading}
        />

        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          How can we help?
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          As your go-to solution for household help, we offer a variety of
          services within the same visit. What tasks do you need help with?
        </Typography>
        <StyledChecklist
          items={TASKS_LIST}
          columns={isMobile ? 1 : 2}
          checkedItems={values?.items}
          onChange={handleMultipleChoiceChange}
        />
        {errors.items && (
          <Typography variant="caption" color="error">
            {errors.items}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values?.items?.length === 0}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>{values?.items?.length === 0 ? "Select tasks" : "Continue"}</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                // onClick={handleNext}
                onClick={handleSubmit}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default TasksCard;
