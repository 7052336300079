import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
  DialogContent,
  CircularProgress,
  InputAdornment,
  DialogActions,
  Dialog,
} from "@mui/material";
import { doc, setDoc, serverTimestamp, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment-timezone";
import { sendSMSClient } from "../services/communicationServices";

const ApplyDiscountForm = ({ memberId, memberPhone = "" }) => {
  const [discountType, setDiscountType] = useState("dollars");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [expiration, setExpiration] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessView, setIsSuccessView] = useState(false);

  console.log("memberPhone: ", memberPhone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const discountKey = new Date().getTime().toString();
    const newDiscount = {
      [discountKey]: {
        type: discountType,
        amount: parseFloat(amount),
        description,
        created: serverTimestamp(),
        used: false,
        expiration: expiration
          ? Timestamp.fromDate(moment(expiration).toDate())
          : null,
      },
    };

    try {
      await setDoc(
        doc(db, "members", memberId, "admin", "data"),
        { discounts: newDiscount },
        { merge: true }
      );

      const formattedMessage = formatDiscountMessage(
        discountType,
        amount,
        expiration
      );

      sendSMSClient({
        phone: memberPhone,
        body: formattedMessage,
      });

      setSuccessMessage("Discount applied successfully!");
      setIsSuccessView(true);
    } catch (error) {
      console.error("Error applying discount:", error);
      setErrorMessage("Failed to apply discount. Please try again.");
      setIsSuccessView(false);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setAmount("");
    setDescription("");
    setExpiration("");
    setSuccessMessage("");
    setIsSuccessView(false);
  };

  const formatDiscountMessage = (type, amount, expirationDate) => {
    let discountMessage = "";
    let expirationMsg = "";

    if (expirationDate) {
      const formattedDate = moment(expirationDate).format("MMM Do"); // Formats date to 'Jan 1st' style
      expirationMsg = ` This offer is valid until ${formattedDate}.`;
    }

    switch (type) {
      case "dollars":
        discountMessage = `You've received a discount of $${amount} off your next visit.${expirationMsg}`;
        break;
      case "percent":
        discountMessage = `You've received a discount of ${amount}% off your next visit.${expirationMsg}`;
        break;
      case "hours":
        discountMessage = `You've received a discount of ${amount} free hour${
          amount > 1 ? "s" : ""
        } off your next visit.${expirationMsg}`;
        break;
      default:
        discountMessage = `You've received a special discount!${expirationMsg}`;
    }
    return `Linked Lives: ${discountMessage}`;
  };

  const getAmountAdornment = () => {
    switch (discountType) {
      case "dollars":
        return {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        };
      case "percent":
        return {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        };
      case "hours":
        return {
          endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
        };
      default:
        return null;
    }
  };

  return (
    <>
      {!isSuccessView ? (
        <>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                value={discountType}
                label="Type"
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value="dollars">$-off</MenuItem>
                <MenuItem value="percent">%-off</MenuItem>
                <MenuItem value="hours">Hours-off</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              fullWidth
              margin="normal"
              InputProps={getAmountAdornment()}
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Expiration Date"
              type="date"
              value={expiration}
              onChange={(e) => setExpiration(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!discountType || !amount || !description || loading}
              sx={{
                minWidth: { xs: "100%", sm: "200px" },
                height: { xs: "60px", sm: "56px" },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Apply Discount"}
            </Button>
          </DialogActions>
        </>
      ) : (
        // Success view
        <>
          <DialogContent>
            <Box sx={{ minHeight: "300px" }}>
              {successMessage && (
                <Box mt={2}>
                  <Alert severity="success">{successMessage}</Alert>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Button variant="contained" color="primary" onClick={resetForm}>
              Apply Another Discount
            </Button>
          </DialogActions>
        </>
      )}
      {errorMessage && (
        <Box mt={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
    </>
  );
};

export default ApplyDiscountForm;
