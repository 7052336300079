// --/services/adminServices.js

import { getFunctions, httpsCallable } from "firebase/functions";

export const uploadTaskListCSV = async (data) => {
  const functions = getFunctions();
  const uploadTaskListCSVFunction = httpsCallable(
    functions,
    "uploadTaskListCSV"
  );
  console.log("-----");
  console.log(data);
  try {
    const result = await uploadTaskListCSVFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling uploadTaskListCSV function:", error);
    throw error;
  }
};

export const setUserRole = async (data) => {
  const functions = getFunctions();
  const setUserRoleFunction = httpsCallable(functions, "setUserRole");
  try {
    const result = await setUserRoleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling setUserRole function:", error);
    throw error;
  }
};

export const createEvent = async (data) => {
  const functions = getFunctions();
  const createEventFunction = httpsCallable(functions, "createEvent");
  try {
    const result = await createEventFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createEvent function:", error);
    throw error;
  }
};
