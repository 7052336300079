import { Box, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import GridButtons from "../GridButtons";
import MapDisplay from "../MapDisplay";
import ResponsiveDialog from "../ResponsiveDialog";

const MemberCard = ({
  memberData,
  accountData,
  employeesData,
  visitsData,
  actions,
  adminData,
  privateData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openMapView, setOpenMapView] = useState(false);

  // if (!memberData) {
  //   return <p>No data available</p>;
  // }

  return (
    <>
      <DialogContent>
        <Box>
          {actions && <GridButtons actions={actions} />}

          {/* Display other member details as needed */}
        </Box>
      </DialogContent>
      <ResponsiveDialog
        open={openMapView}
        onClose={() => setOpenMapView(false)}
        title={"View service area"}
        desktopAnchor={"right"}
        anchor={"bottom"}
        width={"600px"}
        showBackdrop={false}
        zIndex={1300}
      >
        <DialogContent>
          <MapDisplay
            geohash={memberData?.location?.geohash}
            savedBounds={memberData?.location?.bounds}
            width={"100%"}
            height={"400px"}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default MemberCard;
