import { Call, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { ConfirmationBurst } from "../../animations/ConfirmationBurst";
import { db } from "../../firebase";

const ResultsCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
  inArea = true,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          resultsViewed: true,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}

        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          {inArea ? "We're in your area!" : "Join our waitlist"}
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          {inArea
            ? "Great news, we provide our services in your area! Now, let's find the best way to connect."
            : "We're not quite in your area yet. Join our waitlist to get updated when launch within the next few months!"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            mt: 8,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <CheckCircle color="success" sx={{ fontSize: "48px" }} /> */}
          <ConfirmationBurst size="xlarge" />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          <Button
            fullWidth
            variant="text"
            size="large"
            component="a"
            href="tel:+13603366344"
            sx={{
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
            }}
            startIcon={<Call />}
          >
            Call (360) 336-6344
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ResultsCard;
