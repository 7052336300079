import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  useTheme,
  Typography,
  Collapse,
  useMediaQuery,
  Box,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout"; // <-- Import the Logout Icon
import HomeIcon from "@mui/icons-material/Home"; // <-- Import the Logout Icon
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Logo from "../svgs/Logo";

const Sidebar = ({ tabs, selectedTab, onChangeTab }) => {
  const [open, setOpen] = useState(true); // <-- Moved open state into the Sidebar

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md")); // This checks for the 'md' screen size or smaller

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // This effect updates the 'open' state when the screen size changes to 'md' or smaller
  useEffect(() => {
    if (isMdDown) {
      setOpen(false); // Collapse the sidebar if the screen size is 'md' or smaller
    } else {
      setOpen(true); // Expand the sidebar if the screen size is larger than 'md'
    }
  }, [isMdDown]);

  const auth = getAuth();
  // console.log("investigating sidebar...")
  // console.log(auth.currentUser);

  const signOutUser = async () => {
    const auth = getAuth();
    console.log(auth.currentUser);
    try {
      navigate("/");
      await signOut(auth);
      console.log("User signed out successfully");
      // You can also set any state or dispatch any actions here if you're using Redux or React Context for global state management.
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? "282px" : "92px", // Adjust widths as per your design requirement
        flexShrink: 0,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        "& .MuiDrawer-paper": {
          width: open ? "250px" : "60px", // Adjust widths as per your design requirement
          padding: "16px 16px", // <-- This is the padding added to the inside of the drawer.
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={handleDrawerToggle}>
          {open ? (
            theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )
          ) : theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Collapse in={open}>
        <div>
          <Box mb={2} width="200px">
            <Logo color="#000" />
          </Box>
          {/* <Typography variant="h5" gutterBottom>
            Dashboard
          </Typography> */}
        </div>
      </Collapse>
      <List sx={{ py: 2 }}>
        {tabs.map((tab) => (
          <ListItemButton
            // button
            key={tab.label}
            selected={selectedTab === tab.label}
            onClick={() => onChangeTab(tab.label)}
            sx={{ borderRadius: "8px", mb: "10px", height: "48px" }}
            disabled={tab.disabled}
            // height={40 px}
          >
            <ListItemIcon>{tab.icon}</ListItemIcon>
            {open && (
              <ListItemText
                primary={tab.label}
                primaryTypographyProps={{ fontSize: "1rem" }}
              />
            )}
          </ListItemButton>
        ))}
      </List>
      <Box sx={{ mt: "auto" }}>
        {" "}
        {/* This will push the button to the bottom */}
        <ListItemButton
          // button
          onClick={signOutUser}
          sx={{ borderRadius: "8px", mb: "10px", height: "48px" }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {open && (
            <ListItemText
              primary="Sign Out"
              primaryTypographyProps={{ fontSize: "1rem" }}
            />
          )}
        </ListItemButton>
        <ListItemButton
          // button
          onClick={() => navigate("/")}
          sx={{ borderRadius: "8px", mb: "30px", height: "48px" }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          {open && (
            <ListItemText
              primary="Go home"
              primaryTypographyProps={{ fontSize: "1rem" }}
            />
          )}
        </ListItemButton>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
