import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  Timestamp,
  arrayUnion,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import GettingStartedCard from "../../components/GettingStartedCard";
import MemberSignUp from "../../components/MemberSignUp";
import SimplePage from "../../components/SimplePage";
import { db } from "../../firebase";

const MemberSignUpPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1), // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Signup");
  }, []);

  return (
    <SimplePage>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            px: { xs: "auto", md: 0, lg: 4 },
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <MemberSignUp />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GettingStartedCard />
        </Grid>
      </Grid>
    </SimplePage>
  );
};

export default MemberSignUpPage;
