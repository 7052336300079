import {
  Timestamp,
  collection,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";

const VisitContext = createContext();

const VisitProvider = ({ employees, member, memberId, address, children }) => {
  const [visitData, setVisitData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setVisitData((prevState) => ({
      ...prevState, // Copy all existing key-value pairs from the previous state
      timeZoneId: member?.timeZoneId,
      member,
      memberId,
      address,
    }));
  }, [member, memberId, address]);

  // Function to subtract windows from visits
  function subtractWindowsFromVisits(windows, visits) {
    const adjustedWindows = [];
    let visitIndex = 0;
    let windowIndex = 0;

    // Convert objects to arrays and sort them
    const windowsArray = Object.values(windows).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );
    const visitsArray = Object.values(visits).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );

    while (windowIndex < windowsArray.length) {
      const window = windowsArray[windowIndex];

      if (visitIndex < visitsArray.length) {
        const visit = visitsArray[visitIndex];

        // console.log("-----");

        // console.log("window.start: ", moment(window.start.toDate()));
        // console.log("window.end: ", moment(window.end.toDate()));

        // console.log("visit.start: ", moment(visit.start.toDate()));
        // console.log("visit.end: ", moment(visit.end.toDate()));

        // console.log("-----");

        if (visit.end.toMillis() <= window.start.toMillis()) {
          // Visit ends before window starts, move to next visit
          visitIndex++;
        } else if (visit.start.toMillis() >= window.end.toMillis()) {
          // Visit starts after window ends, add whole window to adjustedWindows
          adjustedWindows.push(window);
          windowIndex++;
        } else {
          // Visit is within the window. Split the window.
          if (window.start.toMillis() < visit.start.toMillis()) {
            adjustedWindows.push({ start: window.start, end: visit.start });
          }
          // Set the start of the window to the end of the visit for the next loop iteration
          // If the adjusted window has zero length or is negative, skip to the next window
          if (window.end.toMillis() > visit.end.toMillis()) {
            window.start = visit.end;
            visitIndex++;
          } else {
            windowIndex++;
          }
        }
      } else {
        // No more visitsArray to check against, just add the remaining windowsArray
        adjustedWindows.push(window);
        windowIndex++;
      }
    }

    return adjustedWindows;
  }

  // Function to pad visits to give travel time
  function padVisits(visits, paddingMinutes) {
    return Object.entries(visits).reduce((acc, [id, visit]) => {
      if (!visit.start || !visit.end) {
        return acc;
      }

      acc[id] = {
        start: new Date(
          visit.start.toDate().getTime() - paddingMinutes * 60 * 1000
        ),
        end: new Date(
          visit.end.toDate().getTime() + paddingMinutes * 60 * 1000
        ),
      };

      // Convert back to Firebase Timestamp
      acc[id].start = Timestamp.fromMillis(acc[id].start);
      acc[id].end = Timestamp.fromMillis(acc[id].end);

      return acc;
    }, {});
  }

  console.log("------ visitData (VisitProvider): ", visitData);

  useEffect(() => {
    if (!employees || Object.keys(employees).length === 0) {
      // Clear only the employees part
      // setVisitData((prev) => ({ ...prev, employees: {} }));
      // setLoading(false);
      return;
    }

    setLoading(true);
    const unsubscribes = [];

    // Get the current day's midnight timestamp
    const midnightToday = moment().startOf("day").toDate();
    const midnightTimestamp = Timestamp.fromDate(midnightToday);

    Object.keys(employees)
      .slice(0, 20)
      .forEach((employeeId) => {
        // Access additional properties from the employees prop
        const {
          bookingDaysInAdvance,
          isAvailableToday,
          avatarUrl,
          firstName,
          lastName,
          skills,
        } = employees[employeeId];

        // Fetch availability
        const availabilityRef = doc(
          db,
          "employees",
          employeeId,
          "public",
          "availability"
        );
        unsubscribes.push(
          onSnapshot(availabilityRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              setVisitData((prev) => {
                const currentEmployeeData = prev.employees
                  ? prev.employees[employeeId] || {}
                  : {};
                const updatedAvailability = docSnapshot.data();
                const visits = currentEmployeeData.visits || [];

                console.log("=========================> AVAILABILITY");
                console.log("currentEmployeeData: ", currentEmployeeData);
                console.log("updatedAvailability: ", updatedAvailability);
                console.log("visits: ", visits);

                // Process visits and availability

                console.log("====> AVAILABILITY SUBTRACT");
                const paddedVisits = padVisits(visits, 30);
                console.log("paddedVisits: ", paddedVisits);
                console.log(
                  "updatedAvailability.windows: ",
                  updatedAvailability.windows
                );
                console.log("AVAILABILITY SUBTRACT <====");

                let availableWindows = subtractWindowsFromVisits(
                  updatedAvailability.windows || [],
                  paddedVisits
                );

                console.log("availableWindows: ", availableWindows);

                return {
                  ...prev,
                  employees: {
                    ...prev.employees,
                    [employeeId]: {
                      ...currentEmployeeData,
                      availability: updatedAvailability,
                      availableWindows,
                      bookingDaysInAdvance,
                      isAvailableToday,
                      avatarUrl,
                      firstName,
                      lastName,
                      skills,
                    },
                  },
                };
              });
            }
          })
        );

        // Fetch visits
        const visitsQuery = query(
          collection(db, "visits"),
          where("employeeArr", "array-contains", employeeId),
          where("status", "==", "confirmed"),
          where("start", ">=", midnightTimestamp) // Only get visits from today onwards
        );
        unsubscribes.push(
          onSnapshot(visitsQuery, (snapshot) => {
            // Construct visits as a map with id as key
            const visits = snapshot.docs.reduce((acc, doc) => {
              acc[doc.id] = doc.data();
              return acc;
            }, {});

            setVisitData((prev) => {
              const currentEmployeeData = prev.employees
                ? prev.employees[employeeId] || {}
                : {};
              const availability = currentEmployeeData.availability || {
                windows: [],
              };

              console.log("=========================> VISITS");
              console.log("currentEmployeeData: ", currentEmployeeData);
              console.log("availability.windows: ", availability.windows);

              // Process visits and availability
              let availableWindows = [];

              if (
                availability.windows &&
                Object.keys(availability.windows).length > 0
              ) {
                console.log("====> VISITS SUBTRACT");
                const paddedVisits = padVisits(visits, 30);
                console.log("paddedVisits: ", paddedVisits);
                console.log(
                  "updatedAvailability.windows: ",
                  availability.windows
                );
                console.log("VISITS SUBTRACT <====");

                availableWindows = subtractWindowsFromVisits(
                  availability.windows,
                  paddedVisits
                );
              }

              console.log("--availableWindows: ", availableWindows);
              console.log("--visits: ", visits);

              return {
                ...prev,
                employees: {
                  ...prev.employees,
                  [employeeId]: {
                    ...currentEmployeeData,
                    visits,
                    availableWindows,
                    bookingDaysInAdvance,
                    isAvailableToday,
                  },
                },
              };
            });
          })
        );
      });

    setLoading(false);
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [employees]);

  return (
    <VisitContext.Provider value={{ visitData, setVisitData, loading }}>
      {children}
    </VisitContext.Provider>
  );
};

export default VisitProvider;

export const useVisits = () => {
  return useContext(VisitContext);
};
