import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import { onAcceptVisit } from "../services/employeeServices";
import EmployeeAcceptVisit from "./EmployeeAcceptVisit";
import { CircularProgress } from "@mui/material";

const AcceptVisitButton = ({
  responseStatus,
  visit,
  visitId,
  visits,
  currentEmployeeId,
  customStyles,
  handleAccept,
  loading,
  setLoading,
}) => {
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleAcceptClick = () => {
    if (visit?.recurrence?.frequency > 0) {
      setAcceptDialogOpen(true);
    } else {
      onAcceptVisit({
        confirmationType: "this_visit",
        currentEmployeeId,
        visit,
        visitId, // Assuming each visit has an id property
        visits,
        handleAccept,
        setLoading,
        showSnackbar,
      });
    }
  };

  return (
    <>
      <Button
        style={customStyles}
        disabled={loading}
        onClick={() => responseStatus !== "accepted" && handleAcceptClick()}
      >
        {loading === "accept" ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          "Accept"
        )}
      </Button>
      <EmployeeAcceptVisit
        open={acceptDialogOpen}
        setOpen={setAcceptDialogOpen}
        visit={visit}
        visitId={visitId}
        handleAccept={handleAccept}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

export default AcceptVisitButton;
