import {
  Bolt,
  EventAvailable,
  ListAlt,
  NotificationsActive,
  WavingHand,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const AppOverview = () => {
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2FApp%20-%20schedule%20visit.mp4?alt=media&token=e69568b4-8d87-4a0e-a0ca-fafb71f5948e";

  const bulletPoints = [
    {
      header: "Always a Real Person",
      text: "Give us a call to setup your visits! We love hearing from you and can help take care of everything.",
      Icon: WavingHand,
    },
    {
      header: "Easy Scheduling Online",
      text: "For those that enjoy the convenience of online, browse, book, reschedule, and cancel visits.",
      Icon: Bolt,
    },
    {
      header: "Track Your To-Do's",
      text: "Add notes as you remember them, customizing each visit throughout the week to meet your unique needs.",
      Icon: ListAlt,
    },
    {
      header: "Automatic Visit Reminders",
      text: "With reminders sent to both you and your helper, you'll always be prepared for your upcoming visits.",
      Icon: NotificationsActive,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row" },
        maxWidth: "1000px",
        mx: "auto",
        alignItems: "flex-start",
        position: "relative",
        backgroundColor: "hsla( 190, 100%, 95%, 1)",
        p: 4,
        borderRadius: "50px",
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 0, sm: 10 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 3, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Overwhelmed with To-Do's?
        </Typography>
        <Typography
          component="div"
          sx={{ mt: { xs: 1, sm: 4 } }}
          variant="h6"
          fontWeight="400"
        >
          Goodbye to feeling overwhelmed and hello to help made simple. An extra hand
          is only a tap away with the Linked Lives senior friendly app.
        </Typography>
        <List sx={{ maxWidth: "420px", mx: "auto", mt: { xs: 1, sm: 3 } }}>
          {bulletPoints.map(({ header, text, Icon }, index) => (
            <ListItem key={index} sx={{ alignItems: "center" }}>
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {header}
                  </Typography>
                }
                secondary={text}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          top: { xs: 80, sm: 120 },
          position: { xs: "block", sm: "sticky" },
          display: "flex",
          alignItems: "flex-start",
          borderRadius: "10px",
          overflow: "hidden",
          mx: { xs: "auto", sm: 2 },
          mt: { xs: 2, sm: 10 },
        }}
      >
        <Box
          component="video"
          autoPlay
          loop
          muted // This is often required for autoplay to work in most browsers
          playsInline
          preload="auto"
          sx={{
            maxWidth: "300px",
            height: "auto",
            // aspectRatio: "4 / 5",
          }}
        >
          <source src={videoUrl} type="video/mp4" />
        </Box>
      </Box>
    </Box>
  );
};

export default AppOverview;
