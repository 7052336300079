import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import StyledChecklist from "../../components/styled/StyledChecklist";
import { db } from "../../firebase";
import BackButton from "../buttons/BackButton";

const moment = require("moment"); // Ensure you have moment installed

const generateDates = () => {
  const dates = [];
  const startDate = moment().add(1, "days"); // Start from tomorrow
  for (let i = 0; i < 14; i++) {
    dates.push(startDate.clone().add(i, "days").format("ddd MMM D"));
  }
  return dates;
};

const DATE_LIST = generateDates().map((date) => ({
  name: date,
}));

const DateCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    items: [],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          dates: values?.items || "",
        },
        { merge: true }
      );
      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleChoiceChange = (source) => {
    setValues((prevValues) => {
      const isSelected = prevValues.items.includes(source);
      return {
        ...prevValues,
        items: isSelected
          ? prevValues.items.filter((s) => s !== source)
          : [...prevValues.items, source],
      };
    });
  };

  // const handleMultipleChoiceChange = (source) => {
  //   setValues({ items: [source] });
  // };

  return (
    <>
      <DialogContent>
        <BackButton
          handleBack={handleBack}
          showBack={showBack}
          loading={loading}
        />

        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Which dates work best?
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Pick one or several days from our availability below that work best for your schedule.
        </Typography>
        <Alert sx={{mb: 2}}>Your service estimate: $50 - $90</Alert>

        <StyledChecklist
          items={DATE_LIST}
          columns={isMobile ? 2 : 3}
          checkedItems={values?.items}
          onChange={handleMultipleChoiceChange}
        />
        {errors.items && (
          <Typography variant="caption" color="error">
            {errors.items}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values?.items?.length === 0}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>
                {values?.items?.length === 0 ? "Select an option" : "Continue"}
              </Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                // onClick={handleNext}
                onClick={handleSubmit}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default DateCard;
