import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  DialogContent,
} from "@mui/material";
import PushDrawer from "./PushDrawer"; // Ensure this is the correct path to your component
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

const ResponsiveMenu = ({ menuItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Modified to accept index
  const handleDrawerOpen = (index) => {
    setSelectedTab(index); // Update the selectedTab state
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  console.log("selectedTab: ", selectedTab);

  return (
    <Box>
      {!isMobile ? (
        // Desktop Tabs
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="menu tabs"
          sx={{ marginY: 2 }}
        >
          {menuItems.map((item, index) => (
            <Tab key={index} label={item.title} />
          ))}
        </Tabs>
      ) : (
        // Mobile List
        <List component="nav">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemButton onClick={() => handleDrawerOpen(index)}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText
                  primary={item.title}
                  secondary={item.secondaryText}
                />
                <ArrowForwardIos />
              </ListItemButton>
              {index < menuItems.length - 1 && <Divider />}{" "}
              {/* Divider between items */}
            </React.Fragment>
          ))}
        </List>
      )}

      {/* Content based on selected tab or menu item */}
      <Box maxWidth="600px" p={2}>
        {!isMobile && menuItems[selectedTab]?.component}
      </Box>

      {/* Mobile PushDrawer */}
      {isMobile && (
        <PushDrawer
          title={menuItems[selectedTab].title}
          minWidth={"600px"}
          open={drawerOpen}
          onOpen={() => handleDrawerOpen(selectedTab)} // Pass the current selectedTab index
          onClose={handleDrawerClose}
          hideBackdrop={false}
        >
          {menuItems[selectedTab]?.component}
        </PushDrawer>
      )}
    </Box>
  );
};

export default ResponsiveMenu;
