import { KeyboardArrowLeft } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import React from "react";

const BackButton = ({
  handleBack = () => {},
  showBack = false,
  loading = false,
}) => {
  return (
    <>
      {showBack && (
        <ButtonBase
          onClick={handleBack}
          disabled={loading}
          sx={{ color: "text.secondary", my: 1 }}
        >
          <KeyboardArrowLeft /> Back
        </ButtonBase>
      )}
    </>
  );
};

export default BackButton;
