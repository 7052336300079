import React, { useState } from "react";
import {
  Box,
  Grid,
  ListItemButton,
  Typography,
  Icon,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const GridButtons = ({ actions }) => {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (actionFunction) => {
    setSelectedItem(actionFunction);
    actionFunction(); // Perform the specific action
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {actions.map((item, index) => (
          <Grid item xs={6} sm={6} key={index} sx={{ display: "flex" }}>
            <Box
              sx={{
                borderRadius: "15px",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemButton
                onClick={() => handleItemClick(item.action)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: theme.spacing(2),
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.primary.main}`,
                  flexGrow: 1,
                  height: "100%",
                  transition: "border-color 0.3s ease",
                  "&:hover": {
                    borderColor: theme.palette.grey[500],
                  },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Icon
                    sx={{ minWidth: "auto", marginRight: theme.spacing(1) }}
                  >
                    {item.icon}
                  </Icon>
                  <Typography
                    variant="subtitle2"
                    fontSize={"1rem"}
                    color="primary"
                    fontWeight={"600"}
                  >
                    {item.primary}
                  </Typography>
                </Box>
                {item?.chip?.label && (
                  <Chip
                    label={item?.chip?.label}
                    color={item?.chip?.color || "default"}
                    sx={{ mb: 1 }}
                  />
                )}
                <Typography variant="body2">{item.secondary}</Typography>
              </ListItemButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridButtons;
