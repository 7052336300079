import { Cancel, Search } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import _ from "lodash"; // Ensure lodash is installed for debounce functionality
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

function MemberSearchBar({ onMemberSelect = () => {} }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionCache, setSessionCache] = useState({});

  const handleChange = (event) => {
    setSearchTerm(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
  };

  useEffect(() => {
    console.log("running useEffect");
    if (searchTerm === "") {
      // Aggregate all unique members from the cache.
      const allCachedMembers = Object.values(sessionCache).flat();
      const uniqueCachedMembersMap = {};

      allCachedMembers.forEach((member) => {
        uniqueCachedMembersMap[member.id] = member;
      });

      const uniqueCachedMembers = Object.values(uniqueCachedMembersMap);

      setMembers(uniqueCachedMembers);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setMembers(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchMembers, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  const searchMembers = async (searchText) => {
    console.log("running searchMembers");

    if (!searchText.trim()) {
      setMembers([]); // or set it to default members list if you have it.
      return;
    }

    setLoading(true);

    // Queries for each field
    const firstNameQuery = query(
      collection(db, "members"),
      where("firstName", ">=", searchTerm),
      where("firstName", "<=", searchTerm + "\uf8ff")
    );
    const lastNameQuery = query(
      collection(db, "members"),
      where("lastName", ">=", searchTerm),
      where("lastName", "<=", searchTerm + "\uf8ff")
    );

    try {
      // Fetch data separately
      const [firstNameSnapshot, lastNameSnapshot] = await Promise.all([
        getDocs(firstNameQuery),
        getDocs(lastNameQuery),
      ]);

      const resultSet = {}; // Using an object to store members with unique IDs

      // Process each snapshot and add it to the object
      const processSnapshot = (snapshot) => {
        snapshot.forEach((doc) => {
          const memberData = { id: doc.id, ...doc.data() };
          resultSet[memberData.id] = memberData; // uniqueness is maintained based on member IDs
        });
      };

      processSnapshot(firstNameSnapshot);
      processSnapshot(lastNameSnapshot);

      // Convert the object values to an array
      const uniqueMembers = Object.values(resultSet);

      setMembers(uniqueMembers);
      setLoading(false);

      // Storing data in session cache
      setSessionCache((prevState) => {
        return { ...prevState, [searchTerm]: uniqueMembers };
      });
    } catch (error) {
      console.error("Error fetching members: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (member) => {
    // Call the callback function passed through props
    onMemberSelect(member);
  };

  const renderMemberList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return members.map((member) => (
      <ListItemButton
        key={member.id}
        onClick={() => handleListItemClick(member)}
        sx={{ borderRadius: "15px" }}
      >
        <Avatar sx={{ marginRight: 2 }} src={member.avatarUrl}></Avatar>
        <ListItemText
          primary={`${member.firstName} ${member.lastName}`}
          secondary={`${member?.location?.city}, ${member?.location?.state}`}
        />
        {/* <Chip label={member.status} /> */}
      </ListItemButton>
    ));
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for a member..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearchTerm("")}
                  edge="end"
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <List>{renderMemberList()}</List>
    </>
  );
}

export default MemberSearchBar;
