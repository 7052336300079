import { Avatar, Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const InfiniteScrollAvatars = () => {
  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FdXA5IwQcnUe0HyNvSKXXqWPb6ug2?alt=media&token=d063516b-e919-474b-9959-368245a60f97", // caz
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0", // evan
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02", // eli
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // tyler
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b", // carlo
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843", // john
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FVpWaaQKGZ1YqJ6WuRi12SUQUC992.jpg?alt=media&token=cc3ff9c2-e8d4-4302-9811-3d207625d841", //brandon
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FXdunSNWrqyLSunAspuE3ee5LGfT2.jpeg?alt=media&token=13abfc97-0ade-473d-9e5c-20d0479c4c5d", // kylie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e", // ethan
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af", // zach
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85", // sam
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F8jBCWIZIhZRMaOqwKJGsvsiXmkt1?alt=media&token=f4202b0e-082a-4375-b729-119c4be7e59c", // addie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af", // adam
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FSDWtwf3thNZQIqeIYXXIxH8lBLq2?alt=media&token=2f5786e8-1906-4a53-9776-16c6360afb8b", // jack
  ];

  const theme = useTheme();
  const containerRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const [scrollTimer, setScrollTimer] = useState(null);

  useEffect(() => {
    const container = containerRef.current;
    const maxScroll = container.scrollWidth / 2;

    const handleAutoScroll = () => {
      if (!isAutoScrolling) return;
      container.scrollLeft = (container.scrollLeft + 1) % maxScroll;
    };

    const scrollInterval = setInterval(handleAutoScroll, 30);

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isAutoScrolling]);

  useEffect(() => {
    const container = containerRef.current;

    const handleManualScroll = () => {
      if (scrollTimer) clearTimeout(scrollTimer);
      setIsAutoScrolling(false);

      // Resume auto-scrolling after 2 seconds of inactivity
      const newTimer = setTimeout(() => setIsAutoScrolling(true), 1);
      setScrollTimer(newTimer);
    };

    container.addEventListener("scroll", handleManualScroll);

    return () => {
      container.removeEventListener("scroll", handleManualScroll);
      if (scrollTimer) clearTimeout(scrollTimer);
    };
  }, [scrollTimer]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        overflow: "hidden",
        mt: 4,
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          // overflow: "hidden",
          scrollBehavior: "smooth",
          scrollBehavior: "smooth",
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": { display: "none" }, // For Chrome, Safari, and Edge
        }}
      >
        <Box sx={{ display: "flex" }}>
          {[...imageUrls, ...imageUrls].map((url, index) => (
            <Avatar
              key={index}
              src={url}
              sx={{ width: 128, height: 128, m: 1 }}
            />
          ))}
        </Box>
      </Box>
      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "10%",
          height: "100%",
          background: `linear-gradient(to right, ${theme.palette.background.default}, rgba(255, 255, 255, 0))`,
          pointerEvents: "none",
        }}
      /> */}
      {/* <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "10%",
          height: "100%",
          background: `linear-gradient(to left, ${theme.palette.background.default}, rgba(255, 255, 255, 0))`,
          pointerEvents: "none",
        }}
      /> */}
    </Box>
  );
};

export default InfiniteScrollAvatars;
