// -- components/ChangePassword.js
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { getAuth, updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../firebase";
import { useSnackbar } from "../providers/SnackbarProvider";

const ChangePassword = ({ setOpen, disableCancel }) => {
  const [showPassword, setShowPassword] = useState(true); // Setting it true by default
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const { showLoading, showSnackbar } = useSnackbar();

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  useEffect(() => {
    if (auth.currentUser) {
      // Check if the user is signed in with Google
      const providerData = auth.currentUser.providerData;
      const isGoogle = providerData.some(
        (provider) => provider.providerId === "google.com"
      );
      console.log("google user?: ", isGoogle);
      setIsGoogleUser(isGoogle);
    }
  }, [auth.currentUser]);

  const handlePasswordChange = async () => {
    // Reset status messages
    showLoading("Changing password...");

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      return showSnackbar("Passwords do not match.", "error");
    }

    const password = passwordRef.current.value;
    const auth = getAuth();

    try {
      await updatePassword(auth.currentUser, password);
      showSnackbar("Successfully changed password.", "success");
      setOpen(false);

      try {
        const memberPrivateDataDocRef = doc(
          db,
          "members",
          auth.currentUser.uid,
          "private",
          "data"
        );

        await updateDoc(memberPrivateDataDocRef, {
          hasLoggedIn: true,
        });
      } catch (error) {
        // Fails silently as per requirement, but for debugging, you can log the error:
        console.log("Error updating member document:", error); // Optional, for debugging purposes
      }
    } catch (error) {
      showSnackbar("Failed to change password." + error.message, "error");
    }
  };

  // Handler for toggling password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Box>
      {/* <Typography>Choose a strong password.</Typography> */}
      <TextField
        margin="normal"
        inputRef={passwordRef}
        fullWidth
        label="New Password"
        variant="outlined"
        name="password"
        helperText="Choose a strong password."
        type={showPassword ? "text" : "password"} // Toggle between text/password
        // your value and change handler for the password field...
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword} // Use the toggle function
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        inputRef={confirmPasswordRef}
        fullWidth
        label="Confirm New Password"
        variant="outlined"
        name="password"
        type={showPassword ? "text" : "password"} // Toggle between text/password
        // your value and change handler for the password field...
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword} // Use the toggle function
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Stack
        direction={{ xs: "column", sm: "row-reverse" }}
        spacing={1}
        marginTop={2}
      >
        <Button
          variant="contained"
          disableElevation
          fullWidth
          sx={{ height: { xs: "50px", sm: "auto" } }}
          onClick={handlePasswordChange}
          color="primary"
        >
          Set Password
        </Button>
        {!disableCancel && (
          <Button
            onClick={() => setOpen(false)}
            sx={{ height: { xs: "50px", sm: "auto" } }}
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ChangePassword;
