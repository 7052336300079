import {
  Feedback,
  PostAdd,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ReportIcon from "@mui/icons-material/Report";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import { useEmployee } from "../../providers/EmployeeProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { sendSMSClient } from "../../services/communicationServices";
import { addVisitFlag } from "../../services/schedulingServices";
import ResponsiveDialog from "../ResponsiveDialog";
import CreatePost from "../feed/CreatePost";

const AdditionalHelpList = ({ visitId, memberName, onSubmit = () => {} }) => {
  const [openDialog, setOpenDialog] = useState("");
  const [loading, setLoading] = useState(false); // Assuming loading state is needed for actions
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { data } = useEmployee();
  const employeeId = data?.auth?.uid;

  const [checklist, setChecklist] = useState({
    triedDoor: false,
    calledTwice: false,
    waited15Min: false,
  });

  const [rating, setRating] = useState();
  const [review, setReview] = useState("");

  const updateChecklist = (item) => {
    setChecklist({ ...checklist, [item]: !checklist[item] });
  };

  const isCancellationChecklistActionEnabled =
    checklist.triedDoor && checklist.calledTwice && checklist.waited15Min;

  const handleDialogClose = () => {
    setOpenDialog("");
    setLoading(false); // Reset loading when dialog closes
  };

  const handleMemberCancellationAction = async () => {
    if (!isCancellationChecklistActionEnabled) return; // Prevent action if conditions are not met

    setLoading(true);
    // Implement action logic here

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} cancelled last minute.`,
    });

    await addVisitFlag({ visitId, flag: "memberCancellation" });
    updateEmployeeRating("memberCancellation", "member cancellation");

    showSnackbar("Feedback received");
    handleDialogClose();
    onSubmit();
  };

  const handleSafetyConcernAction = async () => {
    setLoading(true);
    // Implement action logic here

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} has been reported for safety concerns.`,
    });

    await addVisitFlag({ visitId, flag: "safetyConcern" });
    updateEmployeeRating("safetyConcern", "safety concern");

    showSnackbar("Feedback received");
    handleDialogClose();
    onSubmit();
  };

  const handleSpecialAssistanceAction = async () => {
    setLoading(true);

    sendSMSClient({
      phone: "3607081269",
      body: `${memberName} requires special assistance.`,
    });

    await addVisitFlag({ visitId, flag: "specialAssistance" });
    updateEmployeeRating("specialAssistance", "requires special assistance");

    showSnackbar("Feedback received");
    handleDialogClose();
    onSubmit();
  };

  const handleFeedback = async () => {
    setLoading(true); // Start the loading state

    updateEmployeeRating(rating, review);

    handleDialogClose();
    onSubmit();
  };

  const handleCreatePost = () => {
    // Reference to the visit document
    updateEmployeeRating("createdPost", "created public post");

    handleDialogClose();
    onSubmit();
  };

  const updateEmployeeRating = async (rating, review) => {
    const visitDocRef = doc(db, "visits", visitId);

    // Prepare the nested fields for update
    const updateFields = {
      [`employees.${employeeId}.rating`]: rating || 5,
      [`employees.${employeeId}.review`]: review || "Post created",
    };

    try {
      await updateDoc(visitDocRef, updateFields);
      console.log("Document successfully updated with rating and review!");
      setLoading(false); // Stop the loading state
    } catch (error) {
      console.error("Error updating document: ", error);
      setLoading(false); // Stop the loading if there's an error
    }
  };

  const iconStyles = {
    color: theme.palette.grey[700],
    width: "32px",
    height: "32px",
  };

  const listItems = [
    {
      id: "feedback",
      icon: <Feedback color="primary" sx={{ ...iconStyles }} />,
      primary: "Give Feedback",
      secondary: "Share your experience about the visit for internal use only.",
      dialogTitle: "Visit Feedback",
      dialogContent: (
        <>
          <Typography sx={{ mb: 2 }}>
            Rate and leave a comment about your visit experience for internal
            use only.
          </Typography>
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              // backgroundColor: "#f5f5f5", // Light gray background
              // border: "1px solid rgba(0, 0, 0, 0.6)", // Add black outline if current day
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Rating
              size="large"
              value={rating}
              name="visit-rating"
              icon={<StarRounded fontSize="inherit" />}
              emptyIcon={<StarOutlineRounded fontSize="inherit" />}
              sx={{ fontSize: "3rem" }} // Adjust the size as needed
              onChange={(event, newRating) => {
                setRating(newRating);
              }}
              precision={1}
            />
            <TextField
              label="Write about your visit..."
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={review}
              onChange={(e) => setReview(e.target.value)}
              sx={{ mt: 2 }} // Add margin top for spacing
            />
            <Typography variant="caption" sx={{ mt: 1 }}>
              Feedback is not shared with the member.
            </Typography>
          </Box>
        </>
      ),
      dialogAction: handleFeedback, // Assuming handleSubmit is the function to handle feedback submission
    },
    {
      id: "createPost",
      icon: <PostAdd color="primary" sx={{ ...iconStyles }} />,
      primary: "Create Public Post",
      secondary:
        "Create a public social media post to share about an excellent visit.",
      dialogAction: handleCreatePost, // Define the function to handle post submission
    },
    {
      id: "memberCancellation",
      icon: <EventBusyIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Member Cancellation",
      secondary: "Member did not show up or cancelled at the door.",
      dialogTitle: "Member Cancellation",
      dialogContent: (
        <>
          <Typography sx={{ mb: 2 }}>
            Marking this visit as cancelled last minute will charge the member a
            cancellation fee and pay you for one hour of your time.
          </Typography>
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              backgroundColor: "#f5f5f5", // Light gray background
            }}
          >
            <Typography gutterBottom fontWeight="bold">
              Confirm completion:
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.triedDoor}
                    onChange={() => updateChecklist("triedDoor")}
                  />
                }
                label="Tried the door 3 different times"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.calledTwice}
                    onChange={() => updateChecklist("calledTwice")}
                  />
                }
                label="Called the member twice"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checklist.waited15Min}
                    onChange={() => updateChecklist("waited15Min")}
                  />
                }
                label="Waited at least 15 minutes"
              />
            </FormGroup>
          </Box>
        </>
      ),
      dialogAction: handleMemberCancellationAction,
    },
    {
      id: "safetyConcern",
      icon: <ReportIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Report Safety Concern",
      secondary: "Report unsafe conditions or member behavior during a visit.",
      dialogTitle: "Safety Concern",
      dialogContent:
        "If you've encountered any safety issues or concerning behavior, please report it here to ensure a safe environment for everyone.",
      dialogAction: handleSafetyConcernAction,
    },
    {
      id: "specialAssistance",
      icon: <HealthAndSafetyIcon color="primary" sx={{ ...iconStyles }} />,
      primary: "Requires Special Assistance",
      secondary:
        "Member requires enhanced care needs beyond our standard services.",
      dialogTitle: "Special Assistance",
      dialogContent:
        "If a member's needs surpass our standard service scope, please indicate here for additional support or specialized assistance.",
      dialogAction: handleSpecialAssistanceAction,
    },
  ];

  const renderDialogActions = (onClick, item) => (
    <>
      <Button
        disabled={loading}
        onClick={handleDialogClose}
        color="primary"
        variant="text"
        sx={{
          height: { xs: "60px", sm: "auto" },
          minWidth: { xs: "50%", sm: "150px" },
        }}
      >
        No
      </Button>
      <Button
        onClick={onClick}
        disabled={
          loading ||
          (item.id === "memberCancellation" &&
            !isCancellationChecklistActionEnabled)
        }
        color="primary"
        variant="outlined"
        sx={{
          height: { xs: "60px", sm: "auto" },
          minWidth: { xs: "50%", sm: "150px" },
        }}
      >
        {loading ? <CircularProgress color="inherit" size={24} /> : "Continue"}
      </Button>
    </>
  );

  return (
    <>
      <ResponsiveDialog
        open={openDialog === "createPost"}
        onClose={handleDialogClose}
        title={`Create a post`}
        width="600px"
        fullHeight={true}
        showBackdrop={false}
        desktopAnchor={"right"}
      >
        <CreatePost
          user={{ ...data?.employee, id: data?.auth?.uid }}
          addNewPost={handleCreatePost}
          placeholder={"Write about your visit..."}
          textRequired={true}
          mediaRequired={true}
        />
      </ResponsiveDialog>
      <List>
        {listItems.map((item, index) => (
          <React.Fragment key={item.id}>
            <ListItemButton
              sx={{ borderRadius: "15px" }}
              onClick={() => setOpenDialog(item.id)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  color: theme.palette.primary.main,
                  variant: "h6",
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
                primary={item.primary}
                secondary={item.secondary}
              />
            </ListItemButton>
            {index !== listItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>

      {listItems.map(
        (item) =>
          item.id !== "createPost" && (
            <ResponsiveDialog
              key={item.id}
              onClose={handleDialogClose}
              open={openDialog === item.id}
              title={item.dialogTitle}
              dialogActions={renderDialogActions(item.dialogAction, item)}
              width={"600px"}
              showBackdrop={false}
              desktopAnchor={"right"}
            >
              <DialogContent sx={{ minHeight: "100px" }}>
                {item.dialogContent}
              </DialogContent>
            </ResponsiveDialog>
          )
      )}
    </>
  );
};

export default AdditionalHelpList;
