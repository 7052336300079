import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import { storage } from "../../firebase";
import { useEmployee } from "../../providers/EmployeeProvider";
import { createEvent } from "../../services/adminServices";
import LocationMapWithDrawing from "../LocationMapWithDrawing";
import MediaUpload from "../MediaUpload";

const CreateEventForm = ({ onClose = () => {} }) => {
  const { data } = useEmployee();
  const timeZoneId = data?.employee?.timeZoneId;
  const [loading, setLoading] = useState(false);

  const [eventDetails, setEventDetails] = useState({
    title: "",
    description: "",
    estimatedServiceTime: 0,
    eventDays: {}, // This will store day objects
    location: data?.employee.location,
    status: "active",
    maxSignUps: 0,
  });

  // Pass geohash to LocationMap
  const geohash = data?.employee?.location?.geohash;

  const handleChange = (e) => {
    setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
  };

  const handleEventDayChange = (e, dayKey) => {
    setEventDetails({
      ...eventDetails,
      eventDays: {
        ...eventDetails.eventDays,
        [dayKey]: {
          ...eventDetails.eventDays[dayKey],
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleDeadlineChange = (e, dayKey) => {
    const { name, value } = e.target;
    const updatedValue = name === "hoursBefore" ? Math.max(0, value) : value;

    setEventDetails({
      ...eventDetails,
      eventDays: {
        ...eventDetails.eventDays,
        [dayKey]: {
          ...eventDetails.eventDays[dayKey],
          [name]: updatedValue,
        },
      },
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const mediaUrls = await uploadMedia(data?.auth.uid);

    console.log("mediaUrls: ", mediaUrls);

    // Format the eventDays dates and times
    const formattedEventDays = Object.keys(eventDetails.eventDays).reduce(
      (acc, dayKey) => {
        const day = eventDetails.eventDays[dayKey];
        const eventDate = day.eventDate; // Assuming this is in 'YYYY-MM-DD' format

        // Combine and format startTime and endTime with eventDate
        const startMoment = moment.tz(
          `${eventDate} ${day.startTime}`,
          timeZoneId
        );
        const endMoment = moment.tz(`${eventDate} ${day.endTime}`, timeZoneId);

        // Calculate the signUpDeadline
        const deadlineMoment = startMoment
          .clone()
          .subtract(day.hoursBefore, "hours");

        acc[dayKey] = {
          ...day,
          startTime: startMoment.format(),
          endTime: endMoment.format(),
          signUpDeadline: deadlineMoment.format(),
        };
        return acc;
      },
      {}
    );

    // Create a new event object with formatted dates and times
    const formattedEvent = {
      ...eventDetails,
      mediaUrls,
      eventDays: formattedEventDays,
      timeZoneId,
    };

    await createEvent(formattedEvent);
    setLoading(false);
    onClose();
  };

  // Example to add a new day object to eventDays
  const addEventDay = () => {
    const dayKey = `day_${Object.keys(eventDetails.eventDays).length + 1}`;
    setEventDetails({
      ...eventDetails,
      eventDays: {
        ...eventDetails.eventDays,
        [dayKey]: {
          startTime: "",
          endTime: "",
          signUpDeadline: "",
          serviceOrder: [],
        },
      },
    });
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFilesChange = useCallback((files) => {
    setSelectedFiles(files);
    console.log("files: ", files);
  }, []);

  const uploadMedia = async (userId) => {
    if (selectedFiles.length === 0) return [];
    return Promise.all(
      selectedFiles.map((file) => {
        const mediaRef = ref(storage, `media/${userId}/${file.name}`);
        return uploadBytes(mediaRef, file).then((snapshot) =>
          getDownloadURL(snapshot.ref)
        );
      })
    );
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h4" component="h1" gutterBottom>
          Create New Event
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Title"
            name="title"
            fullWidth
            margin="normal"
            value={eventDetails.title}
            onChange={handleChange}
          />
          <MediaUpload onFilesChange={handleFilesChange} />

          <TextField
            label="Description"
            name="description"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={eventDetails.description}
            onChange={handleChange}
          />
          <TextField
            label="Max sign ups"
            name="maxSignUps"
            type="number"
            fullWidth
            margin="normal"
            value={eventDetails.maxSignUps}
            onChange={handleChange}
          />
          <TextField
            label="Estimated Service Time (in minutes)"
            name="estimatedServiceTime"
            type="number"
            fullWidth
            margin="normal"
            value={eventDetails.estimatedServiceTime}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">minutes</InputAdornment>
              ),
            }}
          />
          {/* Google Map component */}
          <LocationMapWithDrawing
            geohash={geohash}
            onBoundsChange={(bounds) => {
              setEventDetails({
                ...eventDetails,
                location: { ...eventDetails.location, bounds },
              });
            }}
          />
          {/* Dynamic fields for eventDays */}
          {Object.keys(eventDetails.eventDays).map((dayKey, index) => (
            <Box key={dayKey} sx={{ marginBottom: 2 }}>
              <Typography variant="h6">Day {index + 1}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 0, sm: 1 },
                }}
              >
                <TextField
                  label="Event Date"
                  name="eventDate"
                  type="date"
                  fullWidth
                  margin="normal"
                  value={eventDetails.eventDays[dayKey].eventDate}
                  onChange={(e) => handleEventDayChange(e, dayKey)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Deadline"
                  name="hoursBefore"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={eventDetails.eventDays[dayKey].hoursBefore}
                  onChange={(e) => handleDeadlineChange(e, dayKey)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        hours before start
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 0, sm: 1 },
                }}
              >
                <TextField
                  label="Start Time"
                  name="startTime"
                  type="time"
                  fullWidth
                  margin="normal"
                  value={eventDetails.eventDays[dayKey].startTime}
                  onChange={(e) => handleEventDayChange(e, dayKey)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Time"
                  name="endTime"
                  type="time"
                  fullWidth
                  margin="normal"
                  value={eventDetails.eventDays[dayKey].endTime}
                  onChange={(e) => handleEventDayChange(e, dayKey)}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
          ))}
          <Button
            onClick={addEventDay}
            variant="outlined"
            color="primary"
            style={{ margin: "10px 0" }}
          >
            Add Event Day
          </Button>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          sx={{
            height: { xs: "60px", sm: "auto" },
            width: { xs: "100%", sm: "150px" },
          }}
        >
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            "Create Event"
          )}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateEventForm;
