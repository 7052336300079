import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import VisitReviewList from "../../../components/reviews/VisitReviewList";
import { useEmployee } from "../../../providers/EmployeeProvider";
import ContentHeader from "../../ContentHeader";

function ReviewsTab() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data } = useEmployee();

  return (
    <Box>
      <ContentHeader title={"Reviews"} />
      <VisitReviewList employeeId={data?.auth?.uid} STAR_MIN={0} />
    </Box>
  );
}

export default ReviewsTab;
