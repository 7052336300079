import {
  Alert,
  Box,
  Button,
  Chip,
  DialogContent,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  AddCircleOutline,
  AutoAwesome,
  CheckCircle,
  Close,
  EventNote,
  MoreVert,
} from "@mui/icons-material";
import { Timestamp, doc, setDoc, updateDoc } from "firebase/firestore";

import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AdjustTime from "../../../components/AdjustTime";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import WeekView from "../../../components/calendar/WeekView";
import { db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import { addAndMergeWindows } from "../../../services/employeeServices";
import ContentHeader from "../../ContentHeader";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background: theme.palette.grey[200], // Light grey background
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main, // Selected button has a distinct color
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  border: "none",
  borderRadius: theme.shape.borderRadius, // Rounded borders for modern look
}));

const Schedule = () => {
  const { data } = useEmployee(); // Assuming you've wrapped your app in the EmployeeProvider context
  const timeZoneId = data?.employee.timeZoneId;
  moment.tz.setDefault(timeZoneId);
  const { showSnackbar, showLoading } = useSnackbar();

  const currentTimeInTimeZone = moment().tz(timeZoneId); // gets the current time in the specific timezone

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [startTime, setStartTime] = useState(currentTimeInTimeZone);
  const [endTime, setEndTime] = useState(currentTimeInTimeZone);
  console.log("start,end: ", [startTime, endTime]);

  const boxRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(0);

  useEffect(() => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current.getBoundingClientRect().height);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [calendarDate, setCalendarDate] = useState(moment().tz(timeZoneId));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Check if the defaultAvailability is an empty map
  const shouldShowBanner =
    data?.employee?.public?.defaultAvailability &&
    Object.keys(data.employee.public.defaultAvailability.windows || {})
      .length === 0;

  //SetWeeklyDefault
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const now = moment().tz(timeZoneId);

  // // load OPEN VISITS within AREA
  // useEffect(() => {
  //   if (!data?.members || Object.keys(data?.members).length == 0) {
  //     console.log("No members loaded");
  //     return;
  //   }

  //   // if (!data?.visits || Object.keys(data?.visits).length == 0) {
  //   //   console.log("No visits loaded");
  //   //   return;
  //   // }

  //   if (!data?.openVisits || Object.keys(data?.openVisits).length == 0) {
  //     console.log("No openVisits loaded");
  //     loadedOpenVisitIds.current.clear();
  //     setOpenVisits([]);
  //     return;
  //   }

  //   // Gather IDs of open visits that need to be added
  //   const openVisitEntries = Object.entries(data.openVisits).filter(
  //     ([id, openVisit]) =>
  //       openVisit.status === "confirmed" && !loadedOpenVisitIds.current.has(id)
  //   );

  //   // Prepare the list of scheduled visits with 30-minute buffer
  //   const scheduledVisits = Object.values(data.visits || {}).map((visit) => ({
  //     start: moment(visit.start.toDate())
  //       .tz(visit.timeZoneId)
  //       .subtract(30, "minutes"),
  //     end: moment(visit.end.toDate()).tz(visit.timeZoneId).add(30, "minutes"),
  //   }));

  //   console.log("OPEN VISITS idsToAdd: ", openVisitEntries);

  //   // Filter out conflicting open visits and those that have already started
  //   const filteredOpenVisits = openVisitEntries.filter(([id, openVisit]) => {
  //     const now = moment().tz(timeZoneId); // Current time in the relevant timezone
  //     const openStart = moment(openVisit.start.toDate()).tz(timeZoneId);
  //     const openEnd = moment(openVisit.end.toDate()).tz(timeZoneId);

  //     const isNotStartedYet = openStart.isAfter(now); // Check if the visit hasn't started yet
  //     const isNotConflicting = !scheduledVisits.some(
  //       (scheduledVisit) =>
  //         openStart.isBefore(scheduledVisit.end) &&
  //         openEnd.isAfter(scheduledVisit.start)
  //     );

  //     return isNotStartedYet && isNotConflicting;
  //   });

  //   const idsToRemove = new Set(
  //     [...loadedOpenVisitIds.current].filter(
  //       (id) => !Object.keys(openVisitEntries).has(id)
  //     )
  //   );

  //   console.log("VISITS idsToRemove: ", idsToRemove);

  //   // Map filtered IDs to their full visit details
  //   const newOpenVisits = filteredOpenVisits.map(([id, visit]) => {
  //     const member = data?.members ? data?.members[visit.member] : undefined;
  //     return {
  //       id: id,
  //       start: moment(visit.start?.toDate()).tz(timeZoneId).toDate(),
  //       end: moment(visit.end?.toDate()).tz(timeZoneId).toDate(),
  //       type: "openVisit",
  //       title: `${member?.firstName} ${member?.lastName}` || "Unknown",
  //       status: visit.status,
  //       employees: visit.employees,
  //     };
  //   });

  //   console.log("Creating openVisit: ", newOpenVisits);

  //   setOpenVisits(
  //     (prevVisits) =>
  //       // Iterate over the previous openVisits and construct a new array
  //       prevVisits.reduce(
  //         (acc, visit) => {
  //           // If the visit isn't in the removal set, keep it
  //           if (!idsToRemove.has(visit.id)) {
  //             acc.push(visit);
  //           }
  //           return acc;
  //         },
  //         [...newOpenVisits]
  //       ) // Start with the new openVisits
  //   );
  // }, [data?.openVisits, data?.visits, data?.members]);

  const currentEmployeeId = data?.auth?.uid;

  const handleCreateAvailability = async (combinedStart, combinedEnd) => {
    try {
      // Clone the current date to ensure we're not mutating the original object
      const startDate = roundToNearest15Min(
        combinedStart
        // createTimestampFromDateAndTime(selectedDate, startTime)
      ).toDate();

      const endDate = roundToNearest15Min(
        combinedEnd
        // createTimestampFromDateAndTime(selectedDate, endTime)
      ).toDate();

      const uniqueKey = uuidv4(); // Generate a unique key using uuidv4

      const newAvailability = {
        start: startDate,
        end: endDate,
      };

      if (currentEmployeeId) {
        const employeeAvailabilityRef = doc(
          db,
          "employees",
          currentEmployeeId,
          "public",
          "availability"
        );

        // First, ensure that the path to the 'windows' object is valid and non-undefined. Otherwise, default to an empty object.
        const windows = data?.employee?.public?.availability?.windows || {};

        const updatedWindows = addAndMergeWindows({
          availability: windows,
          addWindow: newAvailability,
        });

        await updateDoc(employeeAvailabilityRef, {
          windows: updatedWindows, // This line now overwrites the entire 'windows' field
        });

        // Write the new availability to Firestore using the uniqueKey
        // await up dateDoc(employeeAvailabilityRef, {
        //   [`windows.${uniqueKey}`]: newAvailability,
        // });

        console.log("Availability created successfully:", newAvailability);
        // Close the drawer
        closeDrawer();
      }
    } catch (error) {
      console.error("Error creating availability:", error);
    }
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  // Utility function to round time to the nearest 15 minutes
  const roundToNearest15Min = (date) => {
    const momentDate = moment(date).tz(timeZoneId);
    console.log("momentDate: ", momentDate);
    const remainder = 15 - (momentDate.minute() % 15);
    console.log("remainder: ", remainder);
    const roundedDate = momentDate
      .add(remainder === 15 ? 0 : remainder, "minutes")
      .seconds(0)
      .milliseconds(0);
    console.log("roundedDate: ", roundedDate);
    return roundedDate;
  };

  const handleMenuItemClick = (action) => {
    if (action) {
      action();
    }
    handleMenuClose(); // This will be called after the specific action, ensuring the menu closes after any item is clicked
  };

  console.log("calendarDate: ", calendarDate);

  const handleSetWeeklyDefault = async () => {
    try {
      handleCloseDialog();
      showLoading("Setting weekly availability...");
      console.log("calendarDate: ", calendarDate);

      // Use moment.js to wrap the date object
      const momentDate = moment(calendarDate).tz(timeZoneId);

      console.log("momentDate: ", momentDate.format());

      // Determine the most recent Sunday.
      const recentSunday = momentDate.clone().day(0); // This ensures we are cloning the momentDate and modifying the clone

      console.log("recentSunday: ", recentSunday.format());

      // Calculate the date range of the current week
      const startOfWeek = recentSunday.clone().startOf("day"); // start of this Sunday at 00:00:00
      const endOfWeek = recentSunday.clone().add(6, "days").endOf("day"); // end of the following Saturday at 23:59:59.999

      console.log("startOfWeek: ", startOfWeek.format()); // for clearer logging, show the formatted date-time string
      console.log("endOfWeek: ", endOfWeek.format()); // for clearer logging, show the formatted date-time string

      const availability = data?.employee.public.availability || {};
      console.log("availability", availability);

      // Assuming availability is an object with keys as identifiers and values as window objects
      const weeklyWindows = Object.entries(availability.windows)
        .filter(([key, window]) => {
          const windowStart = moment(window.start.toDate()).tz(timeZoneId); // Convert to moment object
          console.log("windowStart", windowStart.format("MM-DD hh:mma"));
          return windowStart.isBetween(startOfWeek, endOfWeek, "day", "[]"); // Check if start is within the week
        })
        .map(([key, window]) => ({ ...window, id: key })); // Map to an array of window objects, including their keys as IDs

      console.log("weeklyWindows: ", weeklyWindows);

      const employeeDefaultRef = doc(
        db,
        "employees",
        currentEmployeeId,
        "public",
        "defaultAvailability"
      );

      // Create an object that will hold our newDefault
      const newDefault = {};

      // Prepare the windows for update
      weeklyWindows.forEach((window) => {
        const uniqueKey = uuidv4(); // Generate a unique key for each window

        // Format the windows correctly
        newDefault[`${uniqueKey}`] = {
          start: window.start, // assuming start is a Timestamp
          end: window.end, // assuming end is a Timestamp
        };
      });

      // Update the Firestore document with the new windows
      await setDoc(employeeDefaultRef, { windows: { ...newDefault } });

      console.log("Weekly default schedule set.");

      // 5. Set availability now

      // This object will accumulate all the windows, past and future.
      let allWindows = {};
      const existingWindows = availability.windows;

      // Filter the existing windows to retain only those that are before the start of the current week.
      for (let key in existingWindows) {
        const window = existingWindows[key];
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId); // Convert Firestore Timestamp to moment

        // If the window ends before the start of the current week, we keep it.
        if (windowEnd.isBefore(startOfWeek)) {
          allWindows[`${key}`] = window; // Keep the same structure for Firestore update
        }
      }

      // Now, prepare the windows for the next 9 weeks
      for (let i = 0; i < 9; i++) {
        weeklyWindows.forEach((window) => {
          const futureStart = moment
            .tz(window.start.toDate(), timeZoneId)
            .add(i, "weeks");
          const futureEnd = moment
            .tz(window.end.toDate(), timeZoneId)
            .add(i, "weeks");

          const updatedAvailability = {
            start: Timestamp.fromDate(futureStart.toDate()),
            end: Timestamp.fromDate(futureEnd.toDate()),
          };

          const uniqueKey = uuidv4();

          // Add the new window to our allWindows object
          allWindows[`${uniqueKey}`] = updatedAvailability;
        });
      }

      // Prepare the complete object to be set in Firestore, including old and new windows
      const completeAvailability = {
        windows: allWindows, // All the windows are now under a single 'windows' field
      };

      // Now we have an object with all the windows (past and next 9 weeks) that we want to store in Firestore
      const employeeAvailabilityRef = doc(
        db,
        "employees",
        currentEmployeeId,
        "public",
        "availability"
      );

      // Update the Firestore document with the merged windows
      await setDoc(employeeAvailabilityRef, completeAvailability);

      console.log("Extended 9-week schedule set.");
      showSnackbar("Success setting weekly schedule", "success");
    } catch (error) {
      showSnackbar("Error setting weekly schedule", "error");
      console.error("Error setting weekly default schedule:", error);
    }
  };

  const renderDrawerHeader = () => (
    <Box
      sx={{
        position: "relative", // Added to position children absolutely within this Box.
        display: "flex",
        alignItems: "center", // Vertically aligns items in the center.
        padding: 2,
        justifyContent: "space-between", // Distributes space between the children (IconButton and the title).
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        height: "50px",
      }}
    >
      <IconButton onClick={closeDrawer}>
        <Close />
      </IconButton>

      {/* Title container to help center the title text */}
      <Box
        sx={{
          position: "absolute", // Absolute positioning is used here to center the title.
          top: "50%", // Positions the top edge of the element at the center of the parent.
          left: "50%", // Positions the left edge of the element at the center of the parent.
          transform: "translate(-50%, -50%)", // Shifts the element back by 50% of its own width and height to achieve true centering.
          textAlign: "center", // Ensures the text within is centered.
        }}
      >
        <Typography variant="h6">Add Availability</Typography>{" "}
        {/* This is your title */}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 8,
          left: "50%",
          transform: "translateX(-50%)",
          width: 50,
          height: 5,
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          borderRadius: 2.5,
        }}
      />
    </Box>
  );

  console.log("calendarDate: ", calendarDate);

  return (
    <>
      <Box sx={{ height: "100%" }}>
        {shouldShowBanner && (
          <Alert
            onClick={() => handleMenuItemClick(handleOpenDialog)}
            severity="warning"
            sx={{
              position: {xs: "fixed", sm: "relative"},
              width: "100%",
              borderRadius: "0px",
              mb: "16px",
              mt: { xs: "-16px", sm: "-32px" },
              mx: { xs: "-16px", sm: "-32px" },
              cursor: "pointer",
            }}
          >
            Please set your default availability.
          </Alert>
        )}
        <ContentHeader title={"Schedule"}>
          {/* <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewToggle}
            aria-label="View type"
          >
            <StyledToggleButton value="day" aria-label="day view">
              {isMobile ? <ViewDayRounded /> : "Day"}
            </StyledToggleButton>
            <StyledToggleButton value="week" aria-label="week view">
              {isMobile ? <ViewWeekRounded /> : "Week"}
            </StyledToggleButton>
            <StyledToggleButton value="month" aria-label="month view">
              {isMobile ? <ViewModuleRounded /> : "Month"}
            </StyledToggleButton>
          </StyledToggleButtonGroup> */}

          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVert />
          </IconButton>
        </ContentHeader>
        <Box
          sx={{
            position: { xs: "fixed", sm: "relative" },
            top: { xs: shouldShowBanner ? 108 : 56, sm: 0 },
            bottom: { xs: 126, sm: 0 },
          }}
        >
          <Box
            sx={{
              height: { xs: "100%", sm: "550px" },
              width: { xs: "96vw", sm: "auto" },
              mb: { xs: 0, sm: 4 },
              position: "relative",
            }}
            ref={boxRef}
          >
            {/* <MyCalendar
            visits={visits}
            setVisits={setVisits}
            openVisits={openVisits}
            setOpenVisits={setOpenVisits}
            availability={availability}
            setAvailability={setAvailability}
            timeZoneId={timeZoneId}
            view={view}
            setView={setView}
            calendarDate={calendarDate}
            setCalendarDate={setCalendarDate}
          /> */}
            <WeekView
              initialDay={calendarDate}
              calendarDate={calendarDate}
              setCalendarDate={setCalendarDate}
              visits={data?.visits}
              employeeId={currentEmployeeId}
              timeZoneId={timeZoneId}
              availability={data?.employee?.public?.availability?.windows}
              isMobile={false}
              height={boxHeight - 48}
            />
          </Box>
        </Box>
        <ResponsiveDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          title={"Set Weekly Availability"}
          dialogActions={
            <>
              <Button
                onClick={handleCloseDialog}
                color="primary"
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  height: { xs: "60px", sm: "auto" },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSetWeeklyDefault}
                color="primary"
                variant="contained"
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  height: { xs: "60px", sm: "auto" },
                }}
              >
                Continue
              </Button>
            </>
          }
        >
          <DialogContent>
            <Chip
              icon={<AutoAwesome />}
              label="Recommended"
              sx={{
                borderRadius: "5px",
                backgroundImage:
                  "linear-gradient(45deg, #0086b3 30%, #19CD69 90%)",
                color: "white",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
            />
            <DialogContentText>
              This action will use this week's availability as a template to set
              your availability for all future weeks.
            </DialogContentText>
            <List>
              <ListItem disableGutters disablePadding>
                <ListItemIcon>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText primary="Automatically fills in future availability based on this week." />
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemIcon>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText primary="Enable members to book recurring visits with you." />
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemIcon>
                  <CheckCircle />
                </ListItemIcon>
                <ListItemText primary="Consistent weekly schedule that can be edited at any time." />
              </ListItem>
            </List>
            <DialogContentText color="textSecondary">
              Note: This action will overwrite all future availability. If you
              previously made edits for time-off, you'll need to manually update
              them after applying this change. You will also be removed from
              future visits that no longer match your availability.
            </DialogContentText>
          </DialogContent>
        </ResponsiveDialog>
        <SwipeableDrawer
          anchor="bottom"
          disableSwipeToOpen={true}
          open={drawerOpen}
          onClose={closeDrawer}
          onOpen={openDrawer}
          PaperProps={{
            sx: {
              maxWidth: { xs: 600, sm: 400 },
              width: "auto",
              margin: "auto",
              borderTopLeftRadius: "15px", // Rounded top-left corner
              borderTopRightRadius: "15px", // Rounded top-right corner
            },
          }}
        >
          {renderDrawerHeader()}
          <AdjustTime
            timeZoneId={timeZoneId}
            onSave={handleCreateAvailability}
          />
        </SwipeableDrawer>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => handleMenuItemClick(openDrawer)}>
          <ListItemIcon>
            <AddCircleOutline />
          </ListItemIcon>
          <ListItemText primary="Add Availability" />
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(handleOpenDialog)}>
          <ListItemIcon>
            <EventNote />
          </ListItemIcon>
          <ListItemText primary="Set Weekly Default" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Schedule;
