import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  collection,
  doc,
  endAt,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment-timezone";
import { Timestamp } from "firebase/firestore";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices";
import { geohashQueryBounds, distanceBetween } from "geofire-common"; // make sure you have 'geofire-common' installed

const VisitContext = createContext();

const RescheduleProvider = ({ visit, employeesProp = {}, children }) => {
  const [employees, setEmployees] = useState(employeesProp);
  const [visitData, setVisitData] = useState({});
  const [loading, setLoading] = useState(true);

  // State to track availability and visits for each employee
  const [employeeAvailabilityData, setEmployeeAvailabilityData] = useState({});

  // Using a ref to store current unsubscribe functions to avoid triggering effect updates
  const unsubscribesRef = useRef([]);

  // !!!!! I think that these are unused actually, and that the filters are set in RescheduleVisitPublic
  // Define the initial filters inside useEffect to ensure they are updated with the latest visit data
  useEffect(() => {
    console.log("running Z");

    const initialFilters = {
      duration: 2,
      frequency:
        visit?.seriesEmployeesNeeded > 0 ? visit.recurrence.frequency : 0,
      employeeIds: [],
      numEmployees: "1",
    };

    console.log("setting frequency to: ", initialFilters.frequency);
    console.log("visit: ", visit);

    setVisitData({
      visit,
      filters: initialFilters,
    });

    // You may also control loading state here if required
    setLoading(false);
  }, [visit]);

  // Set lookup for local employees within visit?.location?.geohash
  useEffect(() => {
    console.log("running A");
    // Skip this effect if 'employees' prop is provided and not empty
    if (Object.keys(employees).length > 0) {
      console.log("employees provided, skipping load employees by geohash");
      setVisitData((prevVisitData) => ({
        ...prevVisitData,
        employees: employees, // Set with provided employees
      }));
      return;
    }

    // Reset unsubscribe functions from possible previous use
    unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribesRef.current = [];

    if (!visit?.location?.geohash) {
      return;
    }

    const center = decodeGeoHash(visit?.location?.geohash);

    // Define the radius within which you want to find employees, in meters.
    const radiusInM = 10 * 1609.34; // e.g., 10 miles to meters

    const bounds = geohashQueryBounds(center, radiusInM);

    // This Map helps avoid duplicate employees and easily manage the real-time data
    const allEmployees = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "employees"),
        where("status", "==", "active"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const employeeData = change.doc.data();

            const employeeMaxTravelMiles = employeeData.maxTravelMiles;
            let employeeCoordinates = decodeGeoHash(
              employeeData?.location?.geohash
            );

            const employeeDistance =
              distanceBetween(center, employeeCoordinates) * 0.621371; // convert to miles

            // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
            // Assuming memberCoordinates are a { lat, lng } pair
            if (employeeData?.location?.bounds !== undefined) {
              if (isPointInPolygon(center, employeeData.location.bounds)) {
                allEmployees.set(change.doc.id, employeeData);
              }
            } else if (employeeDistance <= employeeMaxTravelMiles) {
              allEmployees.set(change.doc.id, employeeData);
            }
          } else if (change.type === "removed") {
            // Employee has been removed from the query results, remove them from the Map
            allEmployees.delete(change.doc.id);
          }
        });

        // Extract a plain object from the Map
        const aggregatedEmployees = Object.fromEntries(allEmployees);
        // console.log("aggregatedEmployees: ", aggregatedEmployees);

        // Update state with all aggregated employees
        setVisitData((prevVisitData) => ({
          ...prevVisitData,
          employees: aggregatedEmployees,
        }));

        // You may also handle "no service" cases here, depending on your business logic
        if (Object.keys(aggregatedEmployees).length === 0) {
          // console.log("no employees here");
        }
      });
    });

    // Cleanup function
    return () => {
      // Unsubscribe from all listeners
      unsubscribe.forEach((unsub) => unsub());

      // Clear the employees data from state or reset to initial state
      setVisitData((prevVisitData) => ({
        ...prevVisitData,
        employees: {}, // Resetting employees data
      }));
    };
  }, [visit?.location?.geohash, employees]); // Depend on selectedMember so it re-runs the effect when it changes

  // Function to subtract windows from visits
  function subtractWindowsFromVisits(windows, visits) {
    const adjustedWindows = [];
    let visitIndex = 0;
    let windowIndex = 0;

    // Convert objects to arrays and sort them
    const windowsArray = Object.values(windows).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );
    const visitsArray = Object.values(visits).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );

    while (windowIndex < windowsArray.length) {
      const window = windowsArray[windowIndex];

      if (visitIndex < visitsArray.length) {
        const visit = visitsArray[visitIndex];

        if (visit.end.toMillis() <= window.start.toMillis()) {
          // Visit ends before window starts, move to next visit
          visitIndex++;
        } else if (visit.start.toMillis() >= window.end.toMillis()) {
          // Visit starts after window ends, add whole window to adjustedWindows
          adjustedWindows.push(window);
          windowIndex++;
        } else {
          // Visit is within the window. Split the window.
          if (window.start.toMillis() < visit.start.toMillis()) {
            adjustedWindows.push({ start: window.start, end: visit.start });
          }
          // Set the start of the window to the end of the visit for the next loop iteration
          // If the adjusted window has zero length or is negative, skip to the next window
          if (window.end.toMillis() > visit.end.toMillis()) {
            window.start = visit.end;
            visitIndex++;
          } else {
            windowIndex++;
          }
        }
      } else {
        // No more visitsArray to check against, just add the remaining windowsArray
        adjustedWindows.push(window);
        windowIndex++;
      }
    }

    return adjustedWindows;
  }

  // Function to pad visits to give travel time
  function padVisits(visits, paddingMinutes) {
    return Object.entries(visits).reduce((acc, [id, visit]) => {
      if (!visit.start || !visit.end) {
        return acc;
      }

      acc[id] = {
        start: new Date(
          visit.start.toDate().getTime() - paddingMinutes * 60 * 1000
        ),
        end: new Date(
          visit.end.toDate().getTime() + paddingMinutes * 60 * 1000
        ),
      };

      // Convert back to Firebase Timestamp
      acc[id].start = Timestamp.fromMillis(acc[id].start);
      acc[id].end = Timestamp.fromMillis(acc[id].end);

      return acc;
    }, {});
  }

  console.log("------ visitData: ", visitData);

  // Fetch availability and visits independently
  useEffect(() => {
    console.log("running B");

    if (!visitData.employees || Object.keys(visitData.employees).length === 0)
      return;

    const unsubscribes = [];

    Object.keys(visitData.employees)
      .slice(0, 20)
      .forEach((employeeId) => {
        // Fetch availability
        const availabilityRef = doc(
          db,
          "employees",
          employeeId,
          "public",
          "availability"
        );
        unsubscribes.push(
          onSnapshot(availabilityRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              setEmployeeAvailabilityData((prevData) => ({
                ...prevData,
                [employeeId]: {
                  ...prevData[employeeId],
                  availability: docSnapshot.data(),
                },
              }));
            }
          })
        );

        // Fetch visits
        const visitsQuery = query(
          collection(db, "visits"),
          where("employeeArr", "array-contains", employeeId),
          where("status", "==", "confirmed")
        );
        unsubscribes.push(
          onSnapshot(visitsQuery, (snapshot) => {
            const visits = snapshot.docs.reduce((acc, doc) => {
              acc[doc.id] = doc.data();
              return acc;
            }, {});
            setEmployeeAvailabilityData((prevData) => ({
              ...prevData,
              [employeeId]: {
                ...prevData[employeeId],
                visits,
              },
            }));
          })
        );
      });

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [visitData.employees]);

  // Calculate employeeAvailability when either availability or visits data changes
  useEffect(() => {
    console.log("running C");

    setVisitData((prev) => {
      const updatedEmployeeAvailability = { ...prev.employeeAvailability };
      Object.entries(employeeAvailabilityData).forEach(([employeeId, data]) => {
        if (data.availability && data.visits) {
          // Access additional properties from the employees prop
          const {
            bookingDaysInAdvance,
            isAvailableToday,
            avatarUrl,
            firstName,
            lastName,
          } = visitData.employees[employeeId];

          const paddedVisits = padVisits(data.visits, 30);
          const availableWindows = subtractWindowsFromVisits(
            data.availability.windows || [],
            paddedVisits
          );

          updatedEmployeeAvailability[employeeId] = {
            ...data,
            availableWindows,
            bookingDaysInAdvance,
            isAvailableToday,
            avatarUrl,
            firstName,
            lastName,
          };
        }
      });

      return {
        ...prev,
        employeeAvailability: updatedEmployeeAvailability,
      };
    });
  }, [employeeAvailabilityData]);

  /*
  // Get all employee availability and calculate available windows
  useEffect(() => {
    if (!visitData.employees || Object.keys(visitData.employees).length === 0)
      return;

    const unsubscribes = [];

    Object.keys(visitData.employees)
      .slice(0, 20)
      .forEach((employeeId) => {
        // Access additional properties from the employees prop
        const {
          bookingDaysInAdvance,
          isAvailableToday,
          avatarUrl,
          firstName,
          lastName,
        } = visitData.employees[employeeId];

        // Fetch availability
        const availabilityRef = doc(
          db,
          "employees",
          employeeId,
          "public",
          "availability"
        );
        unsubscribes.push(
          onSnapshot(availabilityRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              setVisitData((prev) => {
                const currentEmployeeData = prev.employees
                  ? prev.employees[employeeId] || {}
                  : {};
                const updatedAvailability = docSnapshot.data();
                const visits = currentEmployeeData.visits || [];

                console.log("=========================> AVAILABILITY");
                console.log("currentEmployeeData: ", currentEmployeeData);
                console.log("updatedAvailability: ", updatedAvailability);
                console.log("visits: ", visits);

                // Process visits and availability

                console.log("====> AVAILABILITY SUBTRACT");
                const paddedVisits = padVisits(visits, 30);
                console.log("paddedVisits: ", paddedVisits);
                console.log(
                  "updatedAvailability.windows: ",
                  updatedAvailability.windows
                );
                console.log("AVAILABILITY SUBTRACT <====");

                let availableWindows = subtractWindowsFromVisits(
                  updatedAvailability.windows || [],
                  paddedVisits
                );

                console.log("availableWindows: ", availableWindows);

                return {
                  ...prev,
                  employeeAvailability: {
                    ...prev.employeeAvailability,
                    [employeeId]: {
                      ...currentEmployeeData,
                      availability: updatedAvailability,
                      availableWindows,
                      bookingDaysInAdvance,
                      isAvailableToday,
                      avatarUrl,
                      firstName,
                      lastName,
                    },
                  },
                };
              });
            }
          })
        );

        // Fetch visits
        const visitsQuery = query(
          collection(db, "visits"),
          where("employeeArr", "array-contains", employeeId),
          where("status", "==", "confirmed")
        );
        unsubscribes.push(
          onSnapshot(visitsQuery, (snapshot) => {
            // Construct visits as a map with id as key
            const visits = snapshot.docs.reduce((acc, doc) => {
              acc[doc.id] = doc.data();
              return acc;
            }, {});

            setVisitData((prev) => {
              console.log("=========================> VISITS");
              const currentEmployeeData = prev.employeeAvailability
                ? prev.employeeAvailability[employeeId] || {}
                : {};
              const availability = currentEmployeeData.availability || {
                windows: [],
              };

              console.log("currentEmployeeData: ", currentEmployeeData);
              console.log("availability.windows: ", availability.windows);

              // Process visits and availability
              let availableWindows = [];

              if (
                availability.windows &&
                Object.keys(availability.windows).length > 0
              ) {
                console.log("====> VISITS SUBTRACT");
                const paddedVisits = padVisits(visits, 30);
                console.log("paddedVisits: ", paddedVisits);
                console.log(
                  "updatedAvailability.windows: ",
                  availability.windows
                );
                console.log("VISITS SUBTRACT <====");

                availableWindows = subtractWindowsFromVisits(
                  availability.windows,
                  paddedVisits
                );
              }

              console.log("--availableWindows: ", availableWindows);
              console.log("--visits: ", visits);

              return {
                ...prev,
                employeeAvailability: {
                  ...prev.employeeAvailability,
                  [employeeId]: {
                    ...currentEmployeeData,
                    visits,
                    availableWindows,
                    bookingDaysInAdvance,
                    isAvailableToday,
                  },
                },
              };
            });
          })
        );
      });

    setLoading(false);
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [visitData.employees]);
*/

  return (
    <VisitContext.Provider value={{ visitData, setVisitData, loading }}>
      {children}
    </VisitContext.Provider>
  );
};

export default RescheduleProvider;

export const useReschedule = () => {
  return useContext(VisitContext);
};
