import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Photo3 from "../../../assets/garage-organizing.jpeg";
import Photo4 from "../../../assets/hanging-picture.jpeg";
import Photo1 from "../../../assets/tech-help.jpeg";
import Photo2 from "../../../assets/weeding.jpeg";

const KeyServices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const articles = [
    {
      image: Photo2,
      title: "Garden Projects and Weeding",
      description:
        "Need a hand in the garden? Our “Links” tackle everything from weeding to general maintenance, freeing you up to simply enjoy your outdoor oasis. Let us handle the dirty work so your garden—and your weekends—can blossom.",
    },
    {
      image: Photo3,
      title: "Garage Organization",
      description:
        "Drowning in clutter? Our “Links” excel at garage organization, transforming your chaotic storage areas into neatly arranged spaces. Say goodbye to mess and hello to easy-to-find items.",
    },
    {
      image: Photo4,
      title: "Balance and Lifting",
      description:
        "Struggling with weighty boxes, or balance-focused tasks? Let our “Links” step in. We're all about stability and strength, here to tackle your chores that demand lifting or balance, safely and efficiently.",
    },
    {
      image: Photo1,
      title: "Technology Help",
      description:
        "Feeling overwhelmed with new devices or apps? Our “Links” can help you set up new gadgets, from smart watches to printers, and create accounts on popular apps or social platforms. Let us simplify the tech world for you.",
    },
  ];

  return (
    <Box bgcolor="white" width="100%" sx={{ paddingY: { xs: 4, sm: 4 } }}>
      <Container bgcolor="blue" sx={{ maxWidth: "800px" }}>
        <Card
          p={3}
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.lightest,
            padding: { xs: 2, sm: 4 },
            }}
        >
            <Box sx={{ maxWidth: "600px", mx: "auto", mb: 2 }}>
              <Typography align="center" variant="h4">
                Popular Services
              </Typography>
              <Typography variant="body1" mb={6}>
                We have completed 100’s of odd-jobs, tasks, chores, and more for our
                happy members. Below, you'll learn more about our most in-demand
                services.
              </Typography>
            </Box>
          <Grid container spacing={3}>
            {articles.map((article, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={article.image}
                    alt="Article thumbnail"
                    style={{
                      height: "200px",
                      width: "100%",
                      maxWidth: "400px",
                      objectFit: "cover", // This ensures the image covers the entire dimensions without distortion
                      marginBottom: "1rem",
                      borderRadius: "15px",
                    }}
                  />
                  <Typography variant="h6" paragraph>
                    {article.title}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {article.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: "10",
            position: "relative",
            mt: 2,
            px: 2,
          }}
        >
          <Button
            component={Link} // use the Link component as the root node of the Button
            to="/task-search" // use 'to' instead of 'href' for react-router's Link
            variant="contained"
            size="large"
            color="primary"
            sx={{
              width: { xs: "100%", sm: "250px" },
              textTransform: "none",
              height: "56px",
              mx: "auto",
            }}
          >
            See all services
          </Button>
        </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default KeyServices;
