import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import WaitlistDialog from "./WaitlistDialog";

import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

import { useTheme } from "@emotion/react";
import { Helmet } from "react-helmet";
import AboutUsSection from "./AboutUsSection";
import AppOverview from "./AppOverview";
import BusyMoms from "./BusyMoms";
import CompareSection from "./CompareSection";
import FriendConversation from "./FriendConversation";
import HowItWorksSection from "./HowItWorksSection";
import TaskMomHelpers from "./TaskMomHelpers";
import TaskMomPricing from "./TaskMomPricing";
import WaitlistHeader from "./WaitlistHeader";
import TaskMomGraduatedPricing from "./TaskMomGraduatedPricing";

function Hero() {
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const headerOffset = isMobile ? 80 : 100;

  // Scroll to section on hash change or page load
  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location.hash]); // Depend on location.hash to react to hash changes

  // Handle button click to scroll and update URL
  const handleScroll = (sectionId) => {
    // Prevent default action if this is triggered by an event
    // event.preventDefault(); // Uncomment if handleScroll is called with an event argument

    const element = document.getElementById(sectionId);

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }

    // Update the URL hash
    window.history.pushState({}, "", `#${sectionId}`);
  };

  const taskCategories = [
    {
      title: "Meal Preparation",
      description:
        "Chop and clean veggies, measure out spices, help clean up the kitchen. Save time with an extra helper.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fmeal-prep.jpeg?alt=media&token=e926bcbb-217e-4bd9-89e4-d46369edb9e8", // Placeholder path
    },
    {
      title: "Household Organization",
      description:
        "Tidy homes, organize spaces. From playrooms to living areas, enjoy a clutter-free home.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fplayroom-organization.jpeg?alt=media&token=613b4484-39d2-4234-8991-d6acb43de086", // Placeholder path
    },
    {
      title: "Laundry and Folding",
      description:
        "Fresh, folded, and organized laundry. No more laundry day blues, we've got this covered.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Ffolding-laundry.jpg?alt=media&token=70296858-0d5b-4ea7-ae9f-b6f50a147e09", // Placeholder path
    },
    {
      title: "Light Housekeeping",
      description:
        "Keep your home sparkling with essential housekeeping tasks. Fresh made beds, clean bathrooms, inviting spaces.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fmaking-bed.jpeg?alt=media&token=348e340a-65b8-4623-ab87-7f3b5ea37dbb", // Placeholder path
    },
    {
      title: "Groceries and Errands",
      description:
        "Your errands, done. From grocery shopping to post office drop-offs, we've got it covered.",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Frun-errands.jpg?alt=media&token=16e7b27b-462c-422f-ad5c-89aabd64bd85", // Placeholder path
    },
  ];

  const renderServices = () => {
    return (
      <>
        <Grid container sx={{ my: 4 }}>
          {taskCategories.map((category, index) => (
            <Grid
              container
              item
              xs={12}
              key={index}
              alignItems="center"
              justifyContent="center"
            >
              {index % 2 === 1 ? (
                <>
                  <Grid item xs={12} sm={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        my: 2,
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={category.imageUrl}
                        alt={category.title}
                        style={{
                          maxWidth: "400px",
                          width: "100%",
                          height: "auto",
                          aspectRatio: "3 / 2",
                          borderRadius: "15px",
                          mb: { xs: 1, sm: 2 },
                        }} // Ensures the image is square
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "50%" },
                      flexDirection: "row",
                      display: "flex",
                    }}
                    justifyContent={"center"}
                  >
                    <CardContent
                      sx={{
                        px: { xs: 0, sm: 4 },
                        py: { xs: 0, sm: 4 },
                        maxWidth: "400px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography gutterBottom variant="h4" component="div">
                        {category.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.description}
                      </Typography>
                    </CardContent>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "50%" },
                      flexDirection: "row",
                      display: "flex",
                    }}
                    justifyContent={"center"}
                  >
                    <CardContent
                      sx={{
                        px: { xs: 0, sm: 4 },
                        py: { xs: 0, sm: 4 },
                        maxWidth: "400px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography gutterBottom variant="h4" component="div">
                        {category.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {category.description}
                      </Typography>
                    </CardContent>
                  </Box>
                  <Grid item xs={12} sm={5}>
                    <CardMedia
                      component="img"
                      image={category.imageUrl}
                      alt={category.title}
                      style={{
                        maxWidth: "400px",
                        borderRadius: "15px",
                        width: "100%",
                        height: "auto",
                        aspectRatio: "3 / 2",
                        mb: { xs: 1, sm: 2 },
                      }} // Ensures the image is square
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Help for Moms - Find Your Helper with TaskMom</title>
        <meta
          name="description"
          content="TaskMom helps you discover and book amazing help for meal preparation, household organization, laundry, and more at affordable prices. Free up your time and focus on what matters most to you."
        />
        <meta
          name="keywords"
          content="taskmom, task mom, help for moms, meal preparation, household organization, laundry service, housekeeping, errands, schedule visits, personal assistant, easy scheduling, real-time availability, automatic reminders"
        />
        <meta property="og:title" content="Help for Moms - Find Your Helper" />
        <meta
          property="og:description"
          content="TaskMom is a platform where moms can find and book incredible help for household tasks, meal prep, and errands at affordable prices. Enjoy a clutter-free home and more time for family."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Ffamily-beach.jpg?alt=media&token=7f99694c-ece5-4c14-9d93-7294c2d74218"
        />
        <meta property="og:url" content="https://ourlinkedlives.com/taskmom" />
        <link rel="canonical" href="https://ourlinkedlives.com/taskmom" />
      </Helmet>
      <WaitlistHeader handleScroll={handleScroll} />

      <div id="top" />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row-reverse" },
          alignItems: "center",
          maxWidth: "1000px",
          mx: { xs: 2, sm: "auto" },
          mb: { xs: 0, sm: 6 },
          mt: { xs: 0, sm: 10 },
        }}
      >
        <CardMedia
          component="img"
          image="https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fhappy-mom.jpg?alt=media&token=3011c15e-888a-4c97-b0ed-342623b246dc"
          alt="Mom with her kids"
          sx={{
            width: { xs: "100%", sm: "50%" }, // Ensures the image takes up half the width on sm screens and up
            height: { sm: "auto" }, // Adjusts height automatically to maintain aspect ratio
            aspectRatio: { xs: "3 / 2", sm: "inherit" }, // Maintains aspect ratio on xs, adjusts on sm
            borderRadius: "15px",
            flex: 1, // This will allow it to grow and take up equal space
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 4,
            flex: 1, // This allows the box to grow and take up equal space
            maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
          }}
        >
          <Typography
            align="center"
            component="h1"
            sx={{
              fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
              fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
              lineHeight: "inherit",
              mb: 1,
              mx: "auto",
              maxWidth: { xs: "none", sm: "360px" },
              mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
            }}
          >
            Help for Busy Moms
          </Typography>
          {/* <Typography
            align="center"
            variant="h6"
            fontWeight="400"
            sx={{ mb: 2 }}
          >
            Trust your team of personal assistants to free your time for peace
            of mind!
          </Typography> */}
          <Typography
            align="center"
            variant="h6"
            fontWeight="400"
            sx={{ mb: 2 }}
          >
            TaskMom is a place to discover and book amazing help at affordable
            prices
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleScroll("howItWorks")}
            sx={{
              width: { xs: "100%", sm: "300px" },
              height: "56px",
              textTransform: "none",
              mx: "auto",
            }}
          >
            How it works
          </Button>
        </Box>
      </Box>
      {/* <Container sx={{ maxWidth: "800px !important" }}>
        <Typography sx={{ mb: 2 }}>
          There are not enough hours in the day to get everything done that run
          through a mom‘s mind. Linked Lives is for the moms with the never
          ending mental lists. We connect you with a team of doers, that can
          help with anything from folding laundry, to making beds, to cleaning
          the kitchen, and more! Let us make life easier and connect with
          incredible helpers in our community. If you're a mom with a lot of
          things on your plate, it's time to start delegating.
        </Typography>
      </Container> */}
      <div id="services" />
      <Box
        sx={{
          backgroundColor: "hsla( 190, 100%, 95%, 1)",
          width: { xs: "auto", sm: "1000px" },
          borderRadius: "30px",
          mx: "auto",
          py: 1,
          mx: { xs: 2, sm: "auto" },
        }}
      >
        <Container>{renderServices()}</Container>
      </Box>
      <div id="howItWorks" />
      <HowItWorksSection />
      <AppOverview />
      <BusyMoms handleScroll={handleScroll} />
      <div id="about" />
      <AboutUsSection />

      <TaskMomHelpers handleScroll={handleScroll} />
      <CompareSection />
      <div id="pricing" />
      <TaskMomGraduatedPricing handleScroll={handleScroll} />

      {/* <Typography sx={{ mb: 4 }}>
          When you have things to do, you're probably also watching your kids
          run around. It seems like every small task takes ages when your
          attention is getting split three ways. With your own assistant, you
          can focus on what's right in front of you and immediatly feel the
          weight of daily tasks lifted off your shoulders!
        </Typography> */}
      {/* <Typography sx={{ mb: 4 }}>
          We love moms and believe that being a mom is a full time and rewarding
          job. When things are chaotic, it can become hard to see the light.
          Moms are so selfless, always giving their time to others. With so many
          things going on, we believe that you deserve your very own personal
          assistant! We want to free moms to do more, or less. Moms are bringing
          up the youth of our communities and they need support, traditionally
          that came from close familial connections. Now that not everyone's
          living near their family, it's important to create these close
          relationships to support our moms! You'll find that all of our helpers
          are well equipped for your needs and are easy to be around. We believe
          that moms should be free to spend quality time with their kids.
          Instead of folding that load of laundry, we can free you to go for a
          walk with your toddler. Instead of cleaning the kitchen, do a puzzle
          with your child.

                    There are not enough hours in the day to get everything done that run
          through a mom‘s mind. Linked Lives connects you with a team of doers,
          that can help with anything from folding laundry, to making beds, to
          cleaning the kitchen, and more! Let us make life easier and connect
          with incredible helpers in our community.
        </Typography> */}
      <div id="signup" />
      <WaitlistDialog />
      <div id="referral" />

      <FriendConversation handleScroll={handleScroll} />
      <Typography
        variant="caption"
        color="textSecondary"
        gutterBottom
        sx={{
          mb: { xs: 1, sm: 4 },
          mt: { xs: 4, sm: 10 },
          mx: { xs: 1, sm: "auto" },
          maxWidth: "800px",
          display: "block",
        }} // Add display: 'block'
      >
        TaskMom by Linked Lives is a platform to discover, connect, and schedule
        users with potential helpers, but we do not employ helpers or assume
        responsibility for user actions. All information on profiles, posts, and
        communications is user-generated. Users are responsible for conducting
        their own diligence to ensure their helper or job is suitable and
        complies with the law.
      </Typography>
    </>
  );
}

export default Hero;
