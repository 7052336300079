import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { formatDate, formatTimeRange } from "../services/dateServices";

import { AccessTime } from "@mui/icons-material";

import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
  Timestamp,
} from "firebase/firestore";
import moment from "moment-timezone";
import { db } from "../firebase";
import { useSnackbar } from "../providers/SnackbarProvider";
import ResponsiveDialog from "./ResponsiveDialog";
import { cancelVisit } from "../services/visitServices";

const CancelVisitDialog = ({
  open,
  setOpen,
  handleCancel,
  visit,
  visitId,
  timeZoneId,
}) => {
  // State to manage the radio button selection
  const [cancellationType, setCancellationType] = React.useState("this_visit");

  const now = moment().tz(timeZoneId);
  const isWithin24Hours = visit?.start.toDate() - now < 24 * 60 * 60 * 1000;
  const startMoment = moment(visit?.start.toDate());
  const endMoment = moment(visit?.end.toDate());
  const { showSnackbar, showLoading } = useSnackbar();
  const [loading, setLoading] = useState(false);

  console.log("timeZoneId: ", timeZoneId);

  const handleRadioChange = (event) => {
    setCancellationType(event.target.value);
  };

  // Function to cancel the visit, now with a parameter for cancellation type
  const handleCancelVisit = async () => {
    try {
      setLoading(true);

      const startTime = visit?.start.toDate();
      const endTime = visit?.end.toDate();

      console.log("handleCancelVisit - startTime:", startTime);
      console.log("handleCancelVisit - endTime:", endTime);
      console.log("cancellationType:", cancellationType);

      const payload = {
        visitId,
        memberId: visit?.member,
        memberDisplayName: visit?.displayName,
        employees: visit?.employees,
        cancellationType,
        recurringEventId: visit?.recurrence?.recurringEventId || "",
        account: visit?.account,
        startTime,
        endTime,
        frequency: visit?.recurrence?.frequency || "",
        timeZoneId,
      };

      // Calling a backend function to handle the cancellation logic
      await cancelVisit(payload);

      // Display a success message based on the type of cancellation
      const message =
        cancellationType === "this_visit"
          ? "Visit successfully cancelled"
          : "All future visits successfully cancelled";

      showSnackbar(message, "success");
      handleCancel(); // Handle the UI changes after cancellation
    } catch (error) {
      console.error("Error cancelling the visit: ", error);
      showSnackbar("Failed to cancel visit", "error");
    } finally {
      setLoading(false);
    }
  };

  // Check if it's a recurring visit and render radio buttons
  const renderCancellationOptions = () => {
    if (visit?.recurrence?.frequency > 0) {
      return (
        <FormControl
          component="fieldset"
          sx={{ width: "100%", mb: "16px", borderRadius: "15px" }}
        >
          <RadioGroup
            name="cancellationType"
            value={cancellationType}
            onChange={handleRadioChange}
          >
            <List>
              {/* List item for the 'This visit' option */}
              <ListItemButton
                fullWidth
                selected={cancellationType === "this_visit"}
                onClick={() => setCancellationType("this_visit")}
                sx={{ borderRadius: "15px" }}
              >
                <Radio
                  checked={cancellationType === "this_visit"}
                  value="this_visit"
                />
                <ListItemText primary="This visit" />
              </ListItemButton>

              {/* List item for the 'This and all future visits' option */}
              <ListItemButton
                fullWidth
                selected={cancellationType === "all_visits"}
                onClick={() => setCancellationType("all_visits")}
                sx={{ borderRadius: "15px" }}
              >
                <Radio
                  checked={cancellationType === "all_visits"}
                  value="all_visits"
                />
                <ListItemText primary="This and following visits" />
              </ListItemButton>
            </List>
          </RadioGroup>
        </FormControl>
      );
    }
    return null;
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={`Cancel visit`}
      width="400px"
      dialogActions={
        <>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="outlined"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            No
          </Button>
          <Button
            onClick={handleCancelVisit}
            color="primary"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Yes, Cancel"
            )}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography variant="body1" style={{ marginBottom: "16px" }}>
          {`Are you sure you want to cancel this visit?`}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          marginY={2}
        >
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <AccessTime color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6">
                {formatDate(moment(visit?.start?.toDate()).toDate())}
              </Typography>
            }
            secondary={
              <Typography variant="body1">
                {formatTimeRange(
                  moment(visit?.start?.toDate()).toDate(),
                  moment(visit?.end?.toDate()).toDate()
                )}
              </Typography>
            }
          />
        </Box>

        {/* {isWithin24Hours && (
          <Alert severity="info" style={{ marginBottom: "16px" }}>
            Next time, please try to cancel visits more than 24 hours in
            advance. We appreciate your consideration.
          </Alert>
        )} */}
        {renderCancellationOptions()}
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default CancelVisitDialog;
