import AccessTime from "@mui/icons-material/AccessTime";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment-timezone";
import { formatDate, formatTimeRange } from "../services/dateServices";

function RescheduleOptions({ visit, onReschedule, handleClose }) {
  const [rescheduleType, setRescheduleType] = useState("this_visit");

  const handleRadioChange = (event) => {
    setRescheduleType(event.target.value);
  };

  const isRecurring = visit?.recurrence?.frequency > 0;

  return (
    <>
      <DialogContent sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{ maxWidth: "400px" }}>
          <Typography variant="body1" style={{ marginBottom: "16px" }}>
            This visit is part of a recurring visit series.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              my: 2,
              ml: 1,
            }}
          >
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <AccessTime color="primary" />
            </ListItemIcon>
            <Box>
              <ListItemText
                primary={
                  <Typography variant="h6">
                    {formatDate(moment(visit?.start?.toDate()).toDate())}
                  </Typography>
                }
                secondary={
                  <Typography variant="body1">
                    {formatTimeRange(
                      moment(visit?.start?.toDate()).toDate(),
                      moment(visit?.end?.toDate()).toDate()
                    )}
                  </Typography>
                }
              />
              {visit?.recurrence?.frequency > 0 && (
                <Typography>
                  {visit?.recurrence.frequency > 1
                    ? `Every ${visit?.recurrence.frequency}-weeks`
                    : `Every week`}
                </Typography>
              )}
            </Box>
          </Box>

          {isRecurring && (
            <FormControl
              component="fieldset"
              sx={{ width: "100%", mb: "16px", borderRadius: "15px" }}
            >
              <RadioGroup
                name="rescheduleType"
                value={rescheduleType}
                onChange={handleRadioChange}
              >
                <List>
                  <ListItemButton
                    fullWidth
                    selected={rescheduleType === "this_visit"}
                    onClick={() => setRescheduleType("this_visit")}
                    sx={{ borderRadius: "15px" }}
                  >
                    <Radio
                      checked={rescheduleType === "this_visit"}
                      value="this_visit"
                    />
                    <ListItemText primary="Reschedule this visit" />
                  </ListItemButton>

                  <ListItemButton
                    fullWidth
                    selected={rescheduleType === "all_visits"}
                    onClick={() => setRescheduleType("all_visits")}
                    sx={{ borderRadius: "15px" }}
                  >
                    <Radio
                      checked={rescheduleType === "all_visits"}
                      value="all_visits"
                    />
                    <ListItemText primary="This and all future visits" />
                  </ListItemButton>
                </List>
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={handleClose}
          color="primary"
          sx={{
            minWidth: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => onReschedule(rescheduleType)}
          color="primary"
          variant="outlined"
          sx={{
            minWidth: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
}

export default RescheduleOptions;
