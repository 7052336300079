/* global google */

import { InfoOutlined } from "@mui/icons-material/";
import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Box } from "@mui/system";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEmployee } from "../providers/EmployeeProvider";

import AvatarUpload from "../components/AvatarUpload";
import StyledProgress from "../components/StyledProgress";

import { keyframes } from "@emotion/react";
import ActionFooter from "../components/ActionFooter";
import ResponsiveDialog from "../components/ResponsiveDialog";
import BoundsView from "../dashboard/employee/tabs/BoundsView";
import GoogleMapsDisplay from "../dashboard/employee/tabs/GoogleMapsDisplay";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const EmployeeCreateAccountForm = ({ handleSubmit }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoading: setProviderLoading } = useEmployee();
  const [openMap, setOpenMap] = useState(false);

  const totalSteps = 4;
  const authSession = getAuth();
  console.log(authSession.currentUser);

  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    tshirtSize: "",
    maxTravelMiles: 5,
    birthday: "",
    skills: [], // Initialize the skills array in the state
    bio: "",
    avatarUrl: "", // This will store the URL of the uploaded avatar
    gender: "", // Add this line for gender
    bookingDaysInAdvance: 2,
    bounds: [],
  });
  const [errors, setErrors] = useState({});
  const addressRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);

  const skills = [
    "Outdoor",
    "Indoor",
    "Heavy Lifting",
    "Computer Help",
    "Errands",
  ];

  console.log(values);

  const initAutocomplete = () => {
    const autocompleteInstance = new google.maps.places.Autocomplete(
      addressRef.current,
      { types: ["geocode"] }
    );
    autocompleteInstance.setFields(["address_components"]);
    autocompleteInstance.addListener("place_changed", () =>
      onPlaceSelected(autocompleteInstance)
    );
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceSelected = (autocompleteInstance) => {
    console.log("Place selected!"); // Add this line

    const place = autocompleteInstance.getPlace();
    if (!place.address_components) return;
    const addressComponents = {};
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name;
    });
    const line1 = addressComponents.street_number
      ? `${addressComponents.street_number} ${addressComponents.route}`
      : addressComponents.route;
    const line2 = "";
    const city = addressComponents.locality;
    const state = addressComponents.administrative_area_level_1;
    const zipCode = addressComponents.postal_code;

    setValues((prevValues) => ({
      ...prevValues,
      line1,
      line2,
      city,
      state,
      zipCode,
    }));

    setErrors({}); // Clear all errors
  };

  const loadScript = () => {
    const scriptExists = document.getElementById("google-maps-script");
    if (scriptExists) return;

    const script = document.createElement("script");
    script.id = "google-maps-script";
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6k5ohWTgieFep-vs8Wbe6SKWySlSAx9g&libraries=places,drawing&callback=initAutocomplete`;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (step !== 2) return;

    window.initAutocomplete = initAutocomplete;
    loadScript();

    // Add z-index override for .pac-container
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.innerHTML = `.pac-container { z-index: 9999 !important; }`; // Set a value that ensures the dropdown is above the modal

    console.log("running");

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      window.initAutocomplete = null; // Clear the global reference
    };
  }, [step]);

  const validateStep = () => {
    let tempErrors = {};
    switch (step) {
      case 1:
        if (!values.firstName) tempErrors.firstName = "First Name is required";
        if (!values.lastName) tempErrors.lastName = "Last Name is required";
        if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
          tempErrors.phone = "Your phone number must be 10 digits.";
        if (!values.birthday) tempErrors.birthday = "Birthday is required";
        if (!values.tshirtSize)
          tempErrors.tshirtSize = "T-shirt Size is required";
        if (!values.gender) tempErrors.gender = "Gender is required";
        break;
      case 2:
        if (!values.line1) tempErrors.line1 = "Address Line 1 is required";
        if (!values.city) tempErrors.city = "City is required";
        if (!values.state) tempErrors.state = "State is required";
        if (!values.zipCode) tempErrors.zipCode = "Zip Code is required";
        break;
      case 3:
        if (values.bounds.length === 0)
          tempErrors.bounds = "You must draw your service map.";
        break;
      case 3:
        if (values.skills.length === 0)
          tempErrors.skills = "At least one skill is required";
        if (!values.bio) tempErrors.bio = "Bio is required";
        if (!values.bookingDaysInAdvance || values.bookingDaysInAdvance < 1)
          tempErrors.bookingDaysInAdvance =
            "Days in Advance must be at least 1 day.";

        break;
      default:
        break;
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNext = async () => {
    if (validateStep()) {
      if (step < totalSteps) {
        setStep((prevStep) => prevStep + 1);
      } else {
        handleSubmit(values);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    // Clear any error for this specific field by setting it to undefined or an empty string.
    // This way, it won't show up in your validation messages or error displays.
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // or '' depending on how you handle empty error messages.
    }));
  };

  const handlePhoneChange = (e) => {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    handleChange(e);
  };

  const formProgress = ((step - 1) / (totalSteps - 1)) * 90 + 5;

  const handleSkillClick = (skill) => {
    setValues((prevValues) => {
      // Ensure that skills is always an array
      const skills = Array.isArray(prevValues.skills)
        ? [...prevValues.skills]
        : [];

      if (skills.includes(skill)) {
        const index = skills.indexOf(skill);
        skills.splice(index, 1);
      } else {
        skills.push(skill);
      }

      return { ...prevValues, skills };
    });
  };

  const handleBirthdayChange = (e) => {
    const { value, name } = e.target;

    // Split the 'YYYY-MM-DD' format into an array of ["YYYY", "MM", "DD"]
    const dateParts = value.split("-");

    // Get the year part, which is the first item in the array
    const year = dateParts[0];

    // Count the total number of characters entered, excluding hyphens
    const totalCharacters = dateParts.join("").length;

    // If the total number of characters for the date is 8 (meaning YYYYMMDD),
    // and the year is 4 characters long, we proceed; otherwise, we prevent more input.
    if (totalCharacters <= 8 && year.length <= 4) {
      // Clear any previous error and update the value
      setErrors((prevErrors) => ({
        ...prevErrors,
        birthday: "", // assuming 'setErrors' is your function to set errors
      }));

      // Update the state with the new value
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value, // assuming 'setValues' is your function to set values
      }));
    } else if (year.length > 4) {
      // Set error if year part has more than 4 characters
      setErrors((prevErrors) => ({
        ...prevErrors,
        birthday: "Year must be 4 digits",
      }));
    }

    // If the total characters exceed 8, we don't do anything, effectively preventing more input.
  };

  return (
    <>
      <Box
        mb={3}
        sx={{
          textAlign: "center", // Center the text of all child elements
          width: "100%",
          maxWidth: "425px",
        }}
      >
        <StyledProgress variant={"determinate"} value={formProgress} />
        <Typography
          variant="caption"
          align="center"
        >{`Step ${step} of ${totalSteps}`}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%" // This will make the Box take up 100% of the parent's width
        maxWidth={"600px"} // This will ensure the Box does not exceed the specified maxWidth
        sx={{
          // Apply the animation only if this is the current step
          ...(step === 1 && {
            opacity: 0,
            animation: `${fadeInMoveDown} 1s forwards`,
          }),
        }}
      >
        {step === 1 && (
          <Box display="flex" flexDirection="column" gap={2}>
            <div style={{ display: "flex", gap: "10px" }}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </div>
            <TextField
              fullWidth
              name="phone"
              label="Phone Number"
              type="tel"
              value={values.phone}
              onChange={handlePhoneChange}
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <TextField
              fullWidth
              name="birthday"
              label="Birthday"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.birthday}
              onChange={handleBirthdayChange}
              error={!!errors.birthday}
              helperText={errors.birthday}
            />
            <div style={{ display: "flex", gap: "10px", width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  name="gender"
                  label="Gender"
                  value={values.gender}
                  onChange={handleChange}
                  error={!!errors.gender}
                  onKeyDown={(e) => {
                    if (e.key === "m") {
                      e.preventDefault(); // Prevents the default behavior of the key press
                      handleChange({
                        target: { name: "gender", value: "male" },
                      }); // Sets the value to male
                    } else if (e.key === "f") {
                      e.preventDefault();
                      handleChange({
                        target: { name: "gender", value: "female" },
                      }); // Sets the value to female
                    }
                  }}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                </Select>
                {errors.gender && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.gender}
                  </Typography>
                )}
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="tshirt-label">T-shirt Size</InputLabel>
                <Select
                  labelId="tshirt-label"
                  name="tshirtSize"
                  label="T-shirt Size"
                  value={values.tshirtSize}
                  onChange={handleChange}
                  error={!!errors.tshirtSize}
                >
                  <MenuItem value={"S"}>Small</MenuItem>
                  <MenuItem value={"M"}>Medium</MenuItem>
                  <MenuItem value={"L"}>Large</MenuItem>
                  <MenuItem value={"XL"}>X-Large</MenuItem>
                </Select>
                {errors.tshirtSize && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    {errors.tshirtSize}
                  </Typography>
                )}
              </FormControl>
            </div>
          </Box>
        )}

        {step === 2 && (
          <>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                name="line1"
                label="Address Line 1"
                value={values.line1}
                onChange={handleChange}
                error={!!errors.line1}
                helperText={errors.line1}
                inputRef={addressRef}
              />
              <TextField
                name="line2"
                label="Address Line 2"
                value={values.line2}
                onChange={handleChange}
              />
              <div style={{ display: "flex", gap: "10px" }}>
                <TextField
                  fullWidth
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                />
                <TextField
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  error={!!errors.state}
                  helperText={errors.state}
                />
                <TextField
                  fullWidth
                  name="zipCode"
                  label="Zip Code"
                  value={values.zipCode}
                  onChange={handleChange}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode}
                />
              </div>
            </Box>
          </>
        )}

        {step === 3 && (
          // <Card variant="outlined">
          <Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ marginBottom: theme.spacing(2) }}
            >
              <Typography variant="subtitle1" color="textSecondary">
                Set your service map, up to a 30 minute maximum drive away. Tap
                the map below to open.
              </Typography>
              <Tooltip
                enterTouchDelay={0}
                title="A larger map increases your visibility on members' booking apps, leading to more potential visits."
              >
                <IconButton
                  size="small"
                  style={{ marginLeft: theme.spacing(1) }}
                >
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            <GoogleMapsDisplay
              handleEdit={() => setOpenMap(true)}
              buttonText={"Save"}
              initialBounds={values.bounds || []}
              initialCenter={{
                lat: "",
                lng: "",
              }}
            />

            {/* <LocationMapWithDrawing
                onBoundsChange={(bounds) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    bounds,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    bounds: [], // assuming 'setErrors' is your function to set errors
                  }));
                }}
              /> */}
            {errors.bounds && (
              <Typography variant="caption" style={{ color: "red" }}>
                {errors.bounds}
              </Typography>
            )}
          </Box>
          // </Card>
        )}

        {step === 4 && (
          <Box display="flex" flexDirection="column">
            <AvatarUpload
              avatarUrl={values.avatarUrl}
              onAvatarUpload={(url) =>
                handleChange({ target: { name: "avatarUrl", value: url } })
              }
            />
            {/* New Card for skills */}
            <Card
              variant="outlined"
              sx={{
                borderColor: errors.skills ? "error.main" : "divider",
                marginTop: theme.spacing(2),
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: errors.skills ? "error.main" : "textSecondary",
                    }}
                  >
                    How can you help?
                  </Typography>
                  <Tooltip
                    enterTouchDelay={0}
                    title="Select as many categories as possible. A wider range means more opporunity for work."
                  >
                    <IconButton
                      size="small"
                      style={{ marginLeft: theme.spacing(1) }}
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Stack direction="row" flexWrap="wrap" mt={1}>
                  {skills.map((skill) => (
                    <Chip
                      key={skill}
                      label={skill}
                      clickable
                      sx={{ margin: "4px" }}
                      color={
                        values?.skills?.includes(skill) ? "primary" : "default"
                      }
                      onClick={() => handleSkillClick(skill)}
                    />
                  ))}
                </Stack>
              </CardContent>
            </Card>
            {errors.skills && (
              <Typography
                variant="caption"
                color="error"
                sx={{ marginTop: "3px", marginX: "14px" }}
              >
                {errors.skills}
              </Typography>
            )}
            <TextField
              type="number" // This makes the input a number type, presenting numeric keypad on mobile devices.
              sx={{ mt: 2 }} // For spacing, theme's default spacing is 8px, so mt: 2 equals 16px.
              fullWidth
              variant="outlined"
              name="bookingDaysInAdvance" // Name it appropriately based on its purpose
              label="Booking days in advance" // Clear and descriptive labels are crucial for form accessibility.
              value={values.bookingDaysInAdvance} // Ensure this is controlled via your component's state or from formik or similar.
              onChange={handleChange} // Reference your change handler
              placeholder="Enter the number of days in advance members can book your services"
              error={!!errors.bookingDaysInAdvance} // Boolean casting for error existence check.
              helperText={
                errors.bookingDaysInAdvance
                  ? errors.bookingDaysInAdvance // Display the error message if it exists.
                  : values.bookingDaysInAdvance
                  ? `Members can book you at least ${
                      values.bookingDaysInAdvance
                    } day${
                      values.bookingDaysInAdvance > 1 ? "s" : ""
                    } in advance.`
                  : "" // You can specify a default message or leave it empty if there's no need to display anything.
              } // Provide a helper text or the error message if any.
              inputProps={{
                min: "1", // Assuming you want at least a one-day advance notice. This prevents input of negative numbers.
                step: "1", // Ensures users can only increment by whole numbers.
              }}
            />
            <TextField
              sx={{ mt: "16px" }} // Assuming the theme's spacing unit is 8px, 2 * 8px = 16px
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              name="bio"
              label="Bio/Description"
              value={values.bio}
              onChange={handleChange}
              placeholder="Write a brief description about yourself. This will be visible to members when they go to book you."
              error={!!errors.bio}
              helperText={errors.bio}
            />
          </Box>
        )}
      </Box>

      <ActionFooter>
        <Button
          onClick={handleBack}
          disabled={step === 1}
          sx={{
            pr: 2,
            width: "50%",
            height: { xs: "60px", sm: "auto" },
          }} // Take up half the available width
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNext}
          sx={{ width: "50%", height: { xs: "60px", sm: "auto" } }} // Take up half the available width
        >
          {step === totalSteps ? "Submit" : "Next"}
        </Button>
      </ActionFooter>

      <ResponsiveDialog
        open={openMap}
        onClose={() => setOpenMap(false)}
        title="Edit bounds"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        {openMap && (
          <BoundsView
            canSkip={false}
            validate={false}
            buttonText="Save"
            handleNext={(bounds) => {
              setOpenMap(false);
              setValues((prevValues) => ({
                ...prevValues,
                bounds,
              }));
            }}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

export default EmployeeCreateAccountForm;
