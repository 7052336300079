import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import GuestHeader from "../../components/GuestHeader";
import WebFooter from "./sections/WebFooter";
import Hero from "./waitlist/Hero";

function ParentLanding() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.location.href = "https://task-mom.com";
  }, []);

  return (
    <div>
      <Hero />
      <WebFooter />
    </div>
  );
}

export default ParentLanding;
