import React from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function CardGrid({ items }) {
  const navigate = useNavigate();

  return (
    <Container sx={{ my: 4 }}>
      <Grid container spacing={4}>
        {items.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Box display="flex" height="100%">
              <Card
                elevation={0} // start with a smaller elevation
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform .5s, box-shadow .5s", // transition properties for smooth effects
                  "&:hover": {
                    // hover styles
                    transform: "translateY(-5px)",
                    // boxShadow: 24, // changes elevation on hover
                  },
                }}
              >
                <Box
                  sx={{
                    height: 0,
                    paddingBottom: "56.25%", // 9:16 aspect ratio
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item.imageName}
                    alt={item.header}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    fontWeight="700"
                    component="div"
                    gutterBottom
                  >
                    {item.header}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.subText}
                  </Typography>
                </CardContent>
                <Box textAlign="center" p={1}>
                  <Link
                    to={item.buttonDestination}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: 150, textTransform: "none" }}
                    >
                      {item.buttonText}
                    </Button>
                  </Link>
                </Box>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CardGrid;
