import React from "react";
import { Box, Stack, Typography, IconButton, Icon } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";

const HomeCardHeader = ({
  title,
  subtitle,
  IconComponent,
  onIconButtonClick = () => {},
  isMobile,
  hideIcon = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Stack
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon sx={{ mr: 1 }}>
            <IconComponent color="primary" />
          </Icon>
          <Typography
            variant="h6"
            fontWeight="600"
            component="div"
            sx={{
              color: "primary.main",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontStyle: "italic" }}
        >
          {subtitle}
        </Typography>
      </Stack>
      {!hideIcon && (
        <IconButton onClick={onIconButtonClick}>
          <ArrowForwardIosRounded color="primary" />
        </IconButton>
      )}
    </Box>
  );
};

export default HomeCardHeader;
