import { CampaignOutlined } from "@mui/icons-material";
import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const TestimonialVideoSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: { xs: 4, sm: 4 },
        backgroundColor: "white", //theme.palette.primary.lightest,
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h3"
          align="center"
          sx={{ mb: { xs: 2, sm: 4 }, fontSize: { xs: "24px", sm: "48px" } }}
        >
          Member Testimonials
        </Typography>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
            borderRadius: "30px", // Add this line to round the borders of the container
            mt: 2,
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/cvfh96mkcdw?autoplay=1&mute=1&controls=1&loop=1&playlist=cvfh96mkcdw"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Box>
        <Typography sx={{mt: 4, maxWidth: "500px", mx: "auto"}}>
          Curious what our members are saying most recently? Check out how
          seniors are using our service and get some ideas for yourself!
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: "10",
            position: "relative",
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            component={Link} // use the Link component as the root node of the Button
            to="/reviews" // use 'to' instead of 'href' for react-router's Link
            variant="contained"
            size="large"
            color="success"
            startIcon={<CampaignOutlined />}
            sx={{
              width: { xs: "100%", sm: "250px" },
              textTransform: "none",
              height: "56px",
              mx: "auto",
            }}
          >
            Read recent reviews
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialVideoSection;
