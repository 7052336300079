import { Avatar, Box, Card, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import EmployeeCard from "../../../components/EmployeeCard";
import { useMember } from "../../../providers/MemberProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";

import alexAvatar from "../../../assets/alex-avatar.png";
import ContentHeader from "../../ContentHeader";

const EmployeesTab = () => {
  const { data, setData, isServiceArea } = useMember();
  const { showSnackbar } = useSnackbar();

  const theme = useTheme();

  const outsideServiceArea =
    !data || !data?.employees || Object.keys(data.employees).length === 0;

  const renderWelcome = () => {
    return (
      <>
        <Card
          variant="outlined"
          sx={{
            p: 2,
            mb: 1,
            width: "100%",
            minHeight: "400px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: "600px" }}>
            <Typography variant="h6" gutterBottom>
              Meet your Local Helpers
            </Typography>
            {data?.member?.location === undefined && (
              <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                In the Employees tab, you can browse through profiles of our
                active local helpers in your area. Get to know the excellent
                individuals who make our services so special. Please go back to
                the homepage and provide your location to view the team members
                available near you.
              </Typography>
            )}
            {isServiceArea === false && (
              <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                In the Employees tab, you typically can browse through profiles
                of our active local helpers. This is where you can get to know
                the wonderful individuals who make our services exceptional.
                However, as we're in the process of expanding, our services have
                not reached your area just yet. We are working hard to bring our
                services to your town!
              </Typography>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={alexAvatar}
                alt="Alex Rodriguez"
                sx={{ width: 64, height: 64 }}
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1">Alex Rodriguez</Typography>
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  Owner, Linked Lives
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </>
    );
  };

  console.log("!isServiceArea: ", !isServiceArea);
  console.log(
    "data?.member?.location === undefined: ",
    data?.member?.location === undefined
  );

  return (
    <>
      <ContentHeader title={"Employees"} />
      {(data?.member?.location === undefined || !isServiceArea) &&
        renderWelcome()}
      <Stack sx={{ gap: { xs: 2, sm: 4 } }}>
        {isServiceArea &&
          Object.entries(data.employees).map(([uid, employee]) => (
            <Card key={uid} variant="outlined">
              <EmployeeCard employee={employee} />
            </Card>
          ))}
      </Stack>
    </>
  );
};

export default EmployeesTab;
