import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";

const NameCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    const actualValue = type === "checkbox" ? checked : value;

    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateStep = () => {
    let tempErrors = {};

    // Add validation for name not to exceed 5 digits
    if (!values.name) tempErrors.name = "Please enter your name.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true);

    let isValid = validateStep();
    if (!isValid && !canSkip) {
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }
    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          name: values.name,
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (e) => {
    // Only keep letters and spaces, and limit to the first 50 characters
    let value = e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 50);
    // Update the event's value before passing it to handleChange
    e.target.value = value;
    handleChange(e);
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          What's your name?
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Please let us know the name you prefer, so we can address you
          appropriately.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            name="name"
            id="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={values.name}
            onChange={handleNameChange}
            error={!!errors.name} // Show error state if there's an error for name
            helperText={errors.name} // Display the actual error message
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values.name === ""}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                // onClick={handleNext}
                onClick={handleSubmit}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default NameCard;
