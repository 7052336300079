// -- components/memberDashboard/Profile.js
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import AddAddress from "../../../components/AddAddress";
import { db } from "../../../firebase";
import { useMember } from "../../../providers/MemberProvider";
import AddressCard from "../AddressCard"; // adjust the path as per your directory structure
import { Add } from "@mui/icons-material";
import ResponsiveDialog from "../../../components/ResponsiveDialog";

const AddressTab = () => {
  const { data, setData, loading } = useMember();
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addresses, setAddresses] = useState({});
  const [openAddAddress, setOpenAddAddress] = useState(false);

  // Load addresses into local state when the component mounts
  useEffect(() => {
    if (data?.account?.addresses) {
      setAddresses(data.account.addresses);
    }
  }, [data?.account?.addresses]);

  const handleEditAddress = (addressId, address) => {
    console.log("Edit this address:", addressId, address);
    // TODO: Logic to edit the address
  };

  const handleDeleteAddress = (addressId) => {
    console.log("Delete this address:", addressId);
    setSelectedAddressId(addressId);
    console.log("addressId:", addressId);
    setDeleteModalOpen(true);
  };

  const confirmDeleteAddress = async () => {
    try {
      if (selectedAddressId) {
        // Manage local state
        setAddresses((prevAddresses) => {
          const newAddresses = { ...prevAddresses };
          delete newAddresses[selectedAddressId];
          return newAddresses;
        });

        // Update firebase
        const accountDocRef = doc(
          db,
          "accounts",
          data.member?.admin?.data?.accountId || data?.auth?.uid // if there's no accountId, the default is the memberId
        );

        // Delete the address from Firestore
        await updateDoc(accountDocRef, {
          [`addresses.${selectedAddressId}`]: deleteField(), // This deletes the specific key from the map
        });

        setData((currentData) => ({ ...currentData }));

        console.log("Address deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting address: ", error);
    } finally {
      setDeleteModalOpen(false);
      setSelectedAddressId(null);
    }
  };

  // if (!data) return null;

  // if (loading) return <Typography variant="body1">Loading...</Typography>;

  return (
    <>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Service Address
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          Manage and organize your saved locations. Keep your home, rental, or
          second home addresses up to date for quicker booking and navigation.
        </Typography>
        <Stack spacing={2} maxWidth="600px" margin="auto">
          <Typography variant="body1">
            <strong>Address:</strong>
          </Typography>
          {Object.keys(addresses).length > 0 ? (
            Object.entries(addresses).map(([addressId, address]) =>
              address ? (
                <AddressCard
                  key={addressId}
                  addressId={addressId}
                  address={address}
                  isDefault={
                    addressId === data?.member?.private?.data?.defaultAddress
                  }
                  onEdit={handleEditAddress}
                  onDelete={handleDeleteAddress}
                />
              ) : null
            )
          ) : (
            <Typography variant="body2">
              Service address not available.
            </Typography>
          )}
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Button
          variant="outlined"
          startIcon={<Add color="primary" />}
          onClick={() => setOpenAddAddress(true)}
          sx={{
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          Add address
        </Button>
      </DialogActions>

      <ResponsiveDialog
        open={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"Add Service Address"}
        width={"600px"}
      >
        <AddAddress handleSubmit={() => setOpenAddAddress(false)} />
      </ResponsiveDialog>

      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this address?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteAddress} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressTab;
