import { Add, InfoOutlined, Remove } from "@mui/icons-material";
import {
  Box,
  Card,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ResponsiveDialog from "../../../components/ResponsiveDialog";
import { db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { updateEmployeeLocation } from "../../../services/employeeServices";
import { convertStateNameToAbbreviation } from "../../../services/locationServices";
import BoundsView from "./BoundsView";
import GoogleMapsDisplay from "./GoogleMapsDisplay";

const AvailabilityTab = () => {
  const { data } = useEmployee();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [openMap, setOpenMap] = useState(false);


  const [bounds, setBounds] = useState();
  const [bookingDaysInAdvance, setBookingDaysInAdvance] = useState();

  const employeeId = data?.auth?.uid;

  const city = data?.employee?.location?.city;
  const state = data?.employee?.location?.state;
  const stateAbbreviation = convertStateNameToAbbreviation(state); // Convert state name to abbreviation

  useEffect(() => {
    setBounds(data?.employee?.location?.bounds);
  }, [data?.employee?.location?.bounds]);

  useEffect(() => {
    setBookingDaysInAdvance(data?.employee?.bookingDaysInAdvance);
  }, [data?.employee?.bookingDaysInAdvance]);

  const handleIncrement = async () => {
    const newBookingDays = bookingDaysInAdvance + 1;
    setBookingDaysInAdvance(newBookingDays);

    // Update booking days in Firebase
    const employeeRef = doc(db, "employees", employeeId);
    try {
      await updateDoc(employeeRef, {
        bookingDaysInAdvance: newBookingDays,
      });
    } catch (error) {
      console.error("Error updating booking days", error);
    }
  };

  const handleDecrement = async () => {
    const newBookingDays = bookingDaysInAdvance - 1;
    setBookingDaysInAdvance(newBookingDays);

    // Update booking days in Firebase
    const employeeRef = doc(db, "employees", employeeId);
    try {
      await updateDoc(employeeRef, {
        bookingDaysInAdvance: newBookingDays,
      });
    } catch (error) {
      console.error("Error updating booking days", error);
    }
  };

  return (
    <>
      <DialogContent>
        {/* Booking Days in Advance Setting */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mr: 2 }}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Booking days in advance{" "}
              <span>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3000}
                  title="Enter the number of days in advance members can book you for a visit."
                >
                  <IconButton size="small">
                    <InfoOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </span>
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              onClick={handleDecrement}
              disabled={bookingDaysInAdvance === 0}
            >
              <Remove />
            </IconButton>
            <Card
              elevation={0}
              sx={{
                width: "50px",
                p: 1,
                textAlign: "center",
                backgroundColor: "#e0e0e0",
              }}
            >
              {bookingDaysInAdvance}
            </Card>
            <IconButton
              onClick={handleIncrement}
              disabled={bookingDaysInAdvance === 30}
            >
              <Add />
            </IconButton>
          </Stack>
        </Box>
        <Typography variant="body2"></Typography>
        <Divider sx={{ my: 1 }} />

        {/* Service Map Bounds Setting */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            gap: 1,
            mb: 1,
          }}
        >
          <Box sx={{ mr: 2 }}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Your service area{" "}
              <span>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3000}
                  title="Set your service map, up to a 30 minute maximum drive away. Tap the map below to open."
                >
                  <IconButton size="small">
                    <InfoOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </span>
            </Typography>
            <Typography variant="body2"></Typography>
          </Box>
          {/* <Chip label={`${city}, ${stateAbbreviation}`} /> */}
        </Box>

        <GoogleMapsDisplay
          handleEdit={() => setOpenMap(true)}
          buttonText={"Save"}
          initialBounds={data?.employee?.location?.bounds || []}
          initialCenter={{
            lat: data?.employee?.location?.lat || "",
            lng: data?.employee?.location?.lng || "",
          }}
        />

        {/* <LocationMapWithDrawing
        geohash={data?.employee?.location?.geohash}
        initialBounds={data?.employee?.location?.bounds}
        onBoundsSave={handleBoundsChange}
        loading={loading}
        title={"Set new service area"}
      /> */}
      </DialogContent>
      <ResponsiveDialog
        open={openMap}
        onClose={() => setOpenMap(false)}
        title="Edit bounds"
        width="600px"
        anchor="bottom"
        zIndex={2100}
        fullHeight={true}
      >
        <BoundsView
          canSkip={false}
          validate={false}
          buttonText="Save"
          handleNext={() => setOpenMap(false)}
        />
      </ResponsiveDialog>
    </>
  );
};

export default AvailabilityTab;
