// -- components/employeeDashboard/MemberTab.js
import { Box, Button, Typography } from "@mui/material";

import React, { useState } from "react";
import ContentHeader from "../../dashboard/ContentHeader";

import CreateMemberAndAccountForm from "../../forms/CreateMemberAndAccountForm";
import { useEmployee } from "../../providers/EmployeeProvider";
import ResponsiveDrawer from "../ResponsiveDrawer";
import StyledProgress from "../StyledProgress";
import MemberSearch from "./MemberSearch";
import CreateMember from "./CreateMember";

const MemberTab = () => {
  const { data } = useEmployee();

  const isAdmin = data?.employee?.isAdmin || false; // Safety check in case 'employee' is undefined
  return (
    <>
      <Box>
        <ContentHeader title={"Members"}></ContentHeader>
        <MemberSearch isAdmin={isAdmin} />
      </Box>
    </>
  );
};

export default MemberTab;
