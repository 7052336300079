import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../firebase";

import { keyframes } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { addAdminData, addMemberRole } from "../services/memberServices";
import NameField from "./fields/NameField";
import PhoneField from "./fields/PhoneField";
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MemberSignUp = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const nextUrl = "/dashboard";

  // const query = new URLSearchParams(window.location.search);
  // const referralParam = query.get("referral");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateStep = () => {
    let tempErrors = {};
    if (!values.firstName) tempErrors.firstName = "First Name is required";
    if (!values.lastName) tempErrors.lastName = "Last Name is required";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";
    if (!values.termsAccepted || values.termsAccepted === false)
      tempErrors.termsAccepted = "You must accept the terms and conditions.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleLog = async () => {
    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.firstName + " " + values?.lastName || "",
      phone: values?.phone || "",
      email: values?.email || "",
      location: {
        zipCode: values?.zipCode || "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: new Date(),
        contactedCount: 0,
        source: "created_account",
        timeline: {
          created_account: new Date(),
        },
        utmSource: utmSource || "",
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date) ? [k, cleanData(v)] : [k, v]
          )
      );
    };
    

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    // CREATE A LOGS DOCUMENT HERE WITH... /////////////////
    // - tasks: values?.tasks || [],
    // - message: values?.message || "",
    // - source: sign_up

    // Save the lead to Firebase
    await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
      merge: true,
    });
    // console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _createdAccount: new Date(),
            _name: values?.firstName + " " + values?.lastName || "",
            _source: "signup",
            _phone: values?.phone || "",
            _email: values?.email || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    localStorage.setItem("leadCaptured", "true");
    localStorage.setItem("zipCode", values?.zipCode || "");
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setLoading(true);

    // Update the lead form and session data
    await handleLog();

    const newError = {}; // initialize newError object here
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email || "unknown",
        values.password || "unknown"
      );
      const user = userCredential.user;

      // Usage with values.firstName and values.lastName
      const formattedFirstName = capitalizeFirstLetter(values.firstName);
      const formattedLastName = capitalizeFirstLetter(values.lastName);

      // Run updateProfile and addMemberRole in parallel
      await Promise.all([
        updateProfile(user, {
          displayName: `${formattedFirstName} ${formattedLastName}`,
        }),
        addMemberRole(),
      ]);

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);

      console.log("3");

      const announcements = [
        "welcome_to_linked_lives",
        "scheduling_visits",
        "tools_and_supplies",
        "services_overview",
        "monthly_billing",
      ];

      const notificationSettings = {
        bookingConfirmation: { email: true, sms: true },
        visitCancellations: { email: true, sms: true },
        visitUpdates: { email: true, sms: true },
        visitReminders: { email: true, sms: true },
        visitExpirations: { email: true, sms: true },
        visitRatings: { email: true, sms: true },
        newsletters: { email: true, sms: true },
        promotions: { email: true, sms: true },
      };
      console.log("4");

      // Data for addAdminData function
      const adminData = {
        termsAccepted: values.termsAccepted,
        // referralParam,
      };

      console.log("5");

      // Create a document in Firestore for the member
      await Promise.all([
        // Setting the member's public data
        setDoc(doc(db, "members", user.uid), {
          avatarUrl: "",
          firstName: formattedFirstName,
          lastName: formattedLastName,
          billing: {
            nextMembershipFeeDate: null,
            nextInvoiceDate: null,
            lastInvoiceDate: Timestamp.fromDate(new Date()),
          },
          admin: {
            created: serverTimestamp(),
            lastDiscountDate: serverTimestamp(),
            lastVisitDate: false,
          },
        }),
        // Setting the member's private data
        setDoc(doc(db, "members", user.uid, "private", "data"), {
          email: values.email,
          phone: values.phone,

          announcements,

          autoPay: true,
          hasLoggedIn: true,
          notifications: notificationSettings,
        }),
        // Creating or updating the account document using the user's UID
        setDoc(
          doc(db, "accounts", user.uid),
          {
            roles: { [user.uid]: "owner" }, // Assigning the role of 'owner' to the user
          },
          { merge: true }
        ),
      ]);

      addAdminData(adminData)
        .then((result) => {
          console.log("Admin data function call result:", result);
        })
        .catch((error) => {
          console.error("Error calling addAdminData function:", error);
        });

      navigate(nextUrl);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        px: { xs: "0", sm: 3 },
        py: 3,
        maxWidth: "400px",
        border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
        overflow: "visible",
        animation: `${fadeInMoveDown} 1s forwards`,
      }}
    >
      {/* {referralParam && (
        <Alert
          severity="success"
          sx={{
            mx: { xs: -2, sm: -3 },
            mt: { xs: -3, sm: -3 },
            mb: 2,
            borderRadius: 0,
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",

          }}
        >
          You have been gifted a free 1-hour visit.
        </Alert>
      )} */}
      <Typography variant="h5" align="center" gutterBottom>
        Create Your Account
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Already have an account?{" "}
        <Link
          to="/member/login"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          Login
        </Link>
      </Typography>

      <form onSubmit={handleSignUp} noValidate>
        <Stack direction="column" gap={2}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <NameField
              name={"firstName"}
              value={values.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              label={"First Name"}
            />
            <NameField
              name={"lastName"}
              value={values.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              label={"Last Name"}
            />
          </Box>
          <PhoneField
            phone={values.phone}
            error={errors.phone}
            handleChange={handleChange}
          />

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.termsAccepted}
                  onChange={handleChange}
                  name="termsAccepted"
                />
              }
              label={
                <span>
                  I accept the{" "}
                  <a
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </span>
              }
            />
            {errors.termsAccepted && (
              <div>
                <Typography variant="caption" color="error">
                  {errors.termsAccepted}
                </Typography>
              </div>
            )}
          </Box>

          <Button
            variant={
              values.email &&
              values.password &&
              values.firstName &&
              values.lastName &&
              values.termsAccepted &&
              values.phone
                ? "contained"
                : "outlined"
            }
            type="submit"
            fullWidth
            disabled={loading}
            sx={{ height: { xs: "56px" } }}
          >
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </Button>
        </Stack>
      </form>
    </Card>
  );
};

export default MemberSignUp;
