import {
  ArrowForwardIosRounded,
  Block,
  CheckCircle,
  Done,
  NextPlan,
  NextPlanOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { db } from "../../firebase";
import { markComplete, planRoute } from "../../services/eventServices";
import ResponsiveDialog from "../ResponsiveDialog";
import EventInformation from "./EventInformation";
import MemberDetailsCard from "./MemberDetailsCard";
import RouteViewer from "./RouteViewer";

// Function to get member addresses
async function getMemberAddresses(eventId, memberIds) {
  try {
    // Reference to the signUpList sub-collection
    const signUpListRef = collection(db, "events", eventId, "signUpList");

    // Query for the specific member IDs
    const q = query(signUpListRef, where("userId", "in", memberIds));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map the query results to an array of objects with member ID and address
    const memberAddresses = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data && data.address) {
        memberAddresses.push({
          id: data.userId,
          address: `${data.address.street}, ${data.address.city}, ${data.address.state}`, // Adjust according to your address structure
        });
      }
    });

    return memberAddresses;
  } catch (error) {
    console.error("Error fetching member addresses:", error);
    throw error;
  }
}

const ServiceOrderView = ({ event, timeZoneId }) => {
  const [serviceOrder, setServiceOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [openMemberDetails, setOpenMemberDetails] = useState(false);
  const [openRouteView, setOpenRouteView] = useState(false);
  const [boxHeight, setBoxHeight] = useState("100px"); // New state for Favorite
  const eventInfoRef = useRef(null); // Ref for the EventInformation component

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!event || !event.serviceOrder || !Array.isArray(event.serviceOrder)) {
      return;
    }

    // Check if every order in serviceOrder has a valid memberId
    const allOrdersHaveMemberId = event.serviceOrder.every(
      (order) => order.memberId
    );
    if (!allOrdersHaveMemberId) {
      console.error("Some orders are missing memberId");
      return; // Exit if any memberId is missing
    }

    const fetchAddresses = async () => {
      const addresses = await getMemberAddresses(
        event.id,
        event.serviceOrder.map((order) => order.memberId)
      );
      const combinedServiceOrder = event.serviceOrder.map((order) => {
        const address = addresses.find((addr) => addr.id === order.memberId);
        return { ...order, address: address.address };
      });
      setServiceOrder(combinedServiceOrder);
    };

    fetchAddresses();
  }, [event]);

  useEffect(() => {
    if (eventInfoRef.current) {
      // setBoxHeight(`${eventInfoRef.current.scrollHeight}px`); // Set to the natural height of the component
    }
  }, [event]);

  const toggleBoxHeight = () => {
    if (boxHeight !== "100px") {
      setBoxHeight("100px"); // Collapse
    } else if (eventInfoRef.current) {
      setBoxHeight(`${eventInfoRef.current.scrollHeight}px`); // Expand to natural height
    }
  };

  // Check if event and signUpList are loaded
  if (!event || !event.signUpList) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleMarkComplete = async (member) => {
    console.log("Marking service for member ID", member.id, "as complete");
    setLoading(true);
    console.log("userId, eventId: ", [member.id, event.id]);
    await markComplete({ userId: member.id, eventId: event.id });
    setLoading(false);
  };

  console.log("userId, eventId: ", [selectedMember?.id, event?.id]);

  const handlePlanRoute = async () => {
    setLoading(true);
    await planRoute({ eventId: event.id });
    console.log("done!");
    setLoading(false);
  };

  // Function to handle member selection
  const handleMemberSelect = async (memberId) => {
    setLoading(true);
    try {
      // Check if eventId is defined
      if (!event.id) {
        console.log("event.id");
        throw new Error("Event ID is undefined");
      }

      // Directly reference the document by its ID
      const memberDocRef = doc(db, "events", event.id, "signUpList", memberId);
      const memberDocSnapshot = await getDoc(memberDocRef);

      if (!memberDocSnapshot.exists()) {
        throw new Error("Member document not found");
      }

      const memberData = memberDocSnapshot.data();
      setSelectedMember({ id: memberId, ...memberData });
      setOpenMemberDetails(true);
    } catch (error) {
      console.error("Error fetching member details:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("selectedMember: ", selectedMember);

  // Render service order or sign-up list
  return (
    <>
      <DialogContent>
        <Box
          sx={{
            height: boxHeight,
            transition: "height 0.3s ease-in-out",
            borderRadius: "15px",
            overflow: "hidden",
            bgcolor: "white",
            minWidth: { xs: "100%", sm: "400px" },
            position: "relative",
            cursor: "pointer",
            "&:hover .overlay": {
              opacity: 1,
            },
          }}
          onClick={toggleBoxHeight}
        >
          <div ref={eventInfoRef}>
            <EventInformation event={event} timeZoneId={timeZoneId} />
          </div>
          {boxHeight === "100px" && <div className="fadeOverlay" />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 2,
            cursor: "pointer",
          }}
          onClick={toggleBoxHeight}
        >
          <Chip label={boxHeight === "100px" ? "Show more" : "Show less"} />
        </Box>
        <Typography variant="h6">Sign Up List:</Typography>
        <Divider sx={{ mt: 2 }} />
        <List dense>
          {event.signUpList.map((member, index) => {
            // Function to get the appropriate chip properties based on status
            const getChipProps = () => {
              if (member.status === "complete") {
                return {
                  label: "Complete",
                  icon: <CheckCircle />,
                  color: "success",
                };
              }
              const isOnRoute = event.serviceOrder.some(
                (order) => order.userId === member.userId
              );
              if (!isOnRoute) {
                return {
                  label: "Not on Route",
                  icon: <Block />,
                  color: "error",
                };
              } else if (member.status === "awaiting") {
                return {
                  label: "On Route",
                  icon: <NextPlanOutlined />,
                  color: "info",
                };
              }
              // Default case for other statuses
              return { label: member.status, icon: null, color: "default" };
            };

            const chipProps = getChipProps();

            return (
              <ListItemButton
                disableGutters
                key={index}
                onClick={() => handleMemberSelect(member.userId)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "15px",
                }}
              >
                <ListItemAvatar>
                  <Avatar src={member.avatarUrl} alt={member.displayName} />
                </ListItemAvatar>
                <ListItemText primary={member.displayName} />
                <Chip {...chipProps} size="small" />
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <ArrowForwardIosRounded />
                </ListItemIcon>
              </ListItemButton>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={handlePlanRoute}
          sx={{
            width: { xs: "100%", sm: "auto" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : event.serviceOrder ? (
            "Recalculate"
          ) : (
            "Plan Route"
          )}
        </Button>
        {event.serviceOrder && (
          <Button
            variant="outline"
            disabled={loading}
            color="primary"
            onClick={() => setOpenRouteView(true)}
            sx={{
              width: { xs: "100%", sm: "auto" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            View Route
          </Button>
        )}
      </DialogActions>
      <ResponsiveDialog
        open={openMemberDetails}
        onClose={() => setOpenMemberDetails(false)}
        title={"Member details"}
        fullHeight={true}
        width="600px"
        anchor={"right"}
      >
        <DialogContent>
          <MemberDetailsCard
            member={selectedMember}
            eventId={event.id}
            open={openMemberDetails}
          />
        </DialogContent>
      </ResponsiveDialog>

      <ResponsiveDialog
        open={openRouteView}
        onClose={() => setOpenRouteView(false)}
        title={"Route"}
        fullHeight={true}
        width="600px"
        dialogActions={
          <Button
            onClick={() => handleMarkComplete(selectedMember)}
            color="primary"
            variant="contained"
            disabled={loading || selectedMember?.status === "complete"}
            fullWidth={isMobile}
            startIcon={
              loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <CheckCircle />
              )
            }
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "60px" },
            }}
          >
            {loading
              ? "Processing..."
              : selectedMember?.status === "complete"
              ? "Complete"
              : "Mark as Complete"}
          </Button>
        }
      >
        <RouteViewer
          event={event}
          setSelectedMember={setSelectedMember}
          open={openRouteView}
        />
      </ResponsiveDialog>
    </>
  );
};

export default ServiceOrderView;
