import {
  AccountBalance,
  AddIcCall,
  Autorenew,
  CalendarViewDayRounded,
  ContactPage,
  Event,
  ExitToApp,
  LocalOffer,
  PersonAdd,
} from "@mui/icons-material";
import { Box, Button, DialogContent, Grid } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  endAt,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { distanceBetween, geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useEmployee } from "../../providers/EmployeeProvider";
import VisitProvider from "../../providers/VisitProvider";
import {
  decodeGeoHash,
  isPointInPolygon,
} from "../../services/locationServices";
import ChangeMembership from "../ChangeMembership";
import DiscountsList from "../DiscountsList";
import ResponsiveDialog from "../ResponsiveDialog";
import ScheduleVisitAdmin from "../ScheduleVisitAdmin";
import SendLoginLink from "../SendLoginLink";
import VisitList from "../VisitList";
import SignupFeePayment from "../payment/SignupFeePayment";
import CreateMember from "./CreateMember";
import MemberCard from "./MemberCard";
import MemberInfo from "./MemberInfo";
import MemberSearchBar from "./MemberSearchBar";
import NewLead from "./NewLead";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const MemberSearch = ({ isAdmin = false }) => {
  const { data } = useEmployee();
  const currentEmployeeId = data?.auth?.uid;

  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  // for creating new members
  const [openCreateMember, setOpenCreateMember] = useState(false);
  const [openCreateLead, setOpenCreateLead] = useState(false);

  // for dialogs
  const [openMemberCard, setOpenMemberCard] = useState(false);
  const [openViewVisits, setOpenViewVisits] = useState(false);
  const [openScheduleVisit, setOpenScheduleVisit] = useState(false);
  const [openSendLoginLink, setOpenSendLoginLink] = useState(false);
  const [openChangeMembership, setOpenChangeMembership] = useState(false);
  const [openViewDiscounts, setOpenViewDiscounts] = useState(false);
  const [openViewInformation, setOpenViewInformation] = useState(false);
  const [openPaySignupFee, setOpenPaySignupFee] = useState(false);

  // for calendar view
  const [unsubVisits, setUnsubVisits] = useState(null); // State to hold the unsubscribe function

  ////////////////////////////////////////////////////////////////////////////////

  // This is to set the member Data

  const [memberData, setMemberData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [privateData, setPrivateData] = useState(null);
  const [visitsData, setVisitsData] = useState(null);
  const [employeesData, setEmployeesData] = useState(null);

  useEffect(() => {
    if (!selectedMember?.id) return;

    setLoading(true);

    // Member data listener
    const unsubMember = onSnapshot(
      doc(db, "members", selectedMember?.id),
      (docSnapshot) => {
        // Add the document ID under .id and spread the rest of the document data
        const dataWithId = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
        setMemberData(dataWithId);
      }
    );

    // Admin data listener
    const unsubAdmin = onSnapshot(
      doc(db, "members", selectedMember?.id, "admin", "data"),
      (doc) => {
        setAdminData(doc.data());
      }
    );

    // Private data listener
    const unsubPrivate = onSnapshot(
      doc(db, "members", selectedMember?.id, "private", "data"),
      (doc) => {
        setPrivateData(doc.data());
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubMember();
      unsubAdmin();
      unsubPrivate();
    };
  }, [selectedMember?.id]);

  useEffect(() => {
    if (!memberData?.location?.geohash) return;

    // employeesData listener
    const memberCoordinates = decodeGeoHash(memberData?.location?.geohash);
    console.log("memberCoordinates", memberCoordinates);

    // Define the radius within which you want to find employees, in meters.
    const radiusInM = 10 * 1609.34; // e.g., 10 miles to meters

    const bounds = geohashQueryBounds(memberCoordinates, radiusInM);

    // This Map helps avoid duplicate employees and easily manage the real-time data
    const allEmployees = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "employees"),
        where("status", "==", "active"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      console.log("Bounds: ", bounds);

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          console.log("inside change loop: ");

          if (change.type === "added" || change.type === "modified") {
            const employeeData = change.doc.data();

            const employeeMaxTravelMiles = employeeData.maxTravelMiles;
            let employeeCoordinates = decodeGeoHash(
              employeeData?.location?.geohash
            );

            const employeeDistance =
              distanceBetween(memberCoordinates, employeeCoordinates) *
              0.621371; // convert to miles

            // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
            // Assuming memberCoordinates are a { lat, lng } pair
            if (employeeData?.location?.bounds !== undefined) {
              if (
                isPointInPolygon(
                  memberCoordinates,
                  employeeData.location.bounds
                )
              ) {
                allEmployees.set(change.doc.id, employeeData);
                console.log("Employee inside: ", employeeData.firstName);
              }
            } else if (employeeDistance <= employeeMaxTravelMiles) {
              allEmployees.set(change.doc.id, employeeData);
              console.log("Employee inside: ", employeeData.firstName);
            }
          } else if (change.type === "removed") {
            // Employee has been removed from the query results, remove them from the Map
            allEmployees.delete(change.doc.id);
          }
        });

        // Extract a plain object from the Map
        const employeesData = Object.fromEntries(allEmployees);

        // Update state directly with employeesData
        setEmployeesData(employeesData);
      });
    });

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [memberData?.location?.geohash]);

  useEffect(() => {
    if (!adminData?.accountId) return;

    // Member data listener
    const unsubAccount = onSnapshot(
      doc(db, "accounts", adminData?.accountId),
      (doc) => {
        setAccountData(doc.data());
      }
    );

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubAccount();
    };
  }, [adminData?.accountId]);

  useEffect(() => {
    if (!openMemberCard) {
      // When the Member Card dialog closes, clean up the listener
      if (unsubVisits) {
        unsubVisits();
        setUnsubVisits(null);
        console.log("Unsubscribing from visits listener");
      }
      return;
    }

    if (unsubVisits) {
      // If there's already a listener, no need to set up another
      console.log("Listener already set up");
      return;
    }

    if (!selectedMember?.id) return;

    setLoading(true);

    // Set up the listener only when the dialog opens
    const visitsQuery = query(
      collection(db, "visits"),
      where("member", "==", selectedMember.id)
    );

    const unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
      const visitsData = snapshot.docs
        .filter((doc) => doc.data().status !== "cancelled") // Exclude cancelled visits
        .reduce((acc, doc) => {
          // Use the doc.id as the key and the doc data as the value
          acc[doc.id] = {
            ...doc.data(),
            id: doc.id, // Include the id inside the visit data as well
          };
          return acc;
        }, {});

      console.log("Subscribing to visit data");
      setVisitsData(visitsData); // Ensure setVisitsData can handle an object structure
      setLoading(false);
    });

    setUnsubVisits(() => unsubscribe); // Store the unsubscribe function
  }, [openMemberCard]);

  const handleListItemClick = (member) => {
    setSelectedMember(member);
    setOpenMemberCard(true);
  };

  const handleViewVisits = () => {
    setOpenViewVisits(true); // Assuming you have a state to control the modal/dialog visibility
    console.log("handle view visits");
  };

  const handleViewInformation = () => {
    setOpenViewInformation(true); // Assuming a state for viewing discounts
    console.log("handle view discounts");
  };

  const handleScheduleVisit = () => {
    setOpenScheduleVisit(true); // Assuming you have a state for this as well
    console.log("handle schedule visit");
  };

  const handleSendLoginLink = () => {
    setOpenSendLoginLink(true); // Assuming a state for sending login links
    console.log("handle send login link");
  };

  const handleChangeMembership = () => {
    setOpenChangeMembership(true); // Updated to match "Change Membership"
    console.log("handle change membership");
  };

  const handleViewDiscounts = () => {
    setOpenViewDiscounts(true); // Assuming a state for viewing discounts
    console.log("handle view discounts");
  };

  const handlePaySignupFee = () => {
    setOpenPaySignupFee(true); // Assuming a state for viewing discounts
    console.log("handle pay signup fee");
  };

  const actions = [
    {
      icon: <Event color="primary" />,
      primary: "Schedule",
      secondary: "Schedule a new visit.",
      action: handleScheduleVisit,
    },
    {
      icon: <CalendarViewDayRounded color="primary" />,
      primary: "Visits",
      secondary: "See all past and present visits.",
      action: handleViewVisits,
    },
    {
      icon: <ContactPage color="primary" />,
      primary: "Details",
      secondary: "See all member account and contact information.",
      action: handleViewInformation,
    },

    {
      icon: <ExitToApp color="primary" />,
      primary: "Send Login",
      secondary: "Send member a one-click login link via text and email.",
      action: handleSendLoginLink,
    },
  ];

  // Conditionally add actions for admin users
  if (isAdmin) {
    actions.push(
      {
        icon: <Autorenew color="primary" />,
        primary: "Membership",
        secondary: "View and change membership plan.",
        action: handleChangeMembership,
      },
      {
        icon: <LocalOffer color="primary" />,
        primary: "Discounts",
        secondary: "View and apply discounts and promotions.",
        action: handleViewDiscounts,
      },
      ...(privateData?.signupFeeStatus !== "paid"
        ? [
            {
              icon: <AccountBalance color="primary" />,
              primary: "Signup Fee",
              secondary: "Pay sign up fee.",
              action: handlePaySignupFee,
            },
          ]
        : [])
    );
  }

  const defaultAddressKey = privateData?.defaultAddress;

  const address = accountData?.addresses?.[defaultAddressKey];

  console.log("visitsData: ", visitsData);
  console.log("employeesData: ", employeesData);

  return (
    <div>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} gap={1}>
            <MemberSearchBar onMemberSelect={handleListItemClick} />
          </Grid>
          <Box
            sx={{
              position: {
                xs: "fixed",
                sm: "relative",
              },
              bottom: { xs: "72px", sm: "unset" },
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: { xs: "8px", sm: "16px" },
              width: "auto",
              left: { xs: 0, sm: "unset" },
              right: { xs: 0, sm: "unset" },
              backgroundColor: "white",
              boxShadow: {
                xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                sm: "none",
              },
            }}
          >
            <Button
              fullWidth
              onClick={() => setOpenCreateMember(true)}
              color="primary"
              variant="outlined"
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "56px", sm: "150px" },
                mb: 1,
              }}
              startIcon={<PersonAdd />}
            >
              New Member
            </Button>
            <Button
              fullWidth
              onClick={() => setOpenCreateLead(true)}
              color="primary"
              variant="outlined"
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "56px", sm: "150px" },
                mb: 1,
              }}
              startIcon={<AddIcCall />}
            >
              New Lead
            </Button>
          </Box>
        </Grid>
      </>
      {selectedMember && (
        <>
          <ResponsiveDialog
            open={openMemberCard}
            onClose={() => setOpenMemberCard(false)}
            title={`${memberData?.firstName} ${memberData?.lastName}`}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            fullHeight={true}
          >
            <MemberCard
              memberData={memberData}
              accountData={accountData}
              employeesData={employeesData}
              visitsData={visitsData}
              actions={actions}
              adminData={adminData}
              privateData={privateData}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openViewVisits}
            onClose={() => setOpenViewVisits(false)}
            title={"Visits"}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            showBackdrop={false}
            zIndex={1300}
            fullHeight={true}
          >
            {openViewVisits && (
              <DialogContent>
                <VisitList visits={visitsData} employees={employeesData} />
              </DialogContent>
            )}
          </ResponsiveDialog>
          <ResponsiveDialog
            title={"Schedule a visit"}
            open={openScheduleVisit}
            onClose={() => setOpenScheduleVisit(false)}
            width="800px"
            fullHeight={true}
            maxHeight="583px"
          >
            {openScheduleVisit && (
              <VisitProvider
                employees={employeesData}
                member={{
                  ...memberData,
                  admin: { data: adminData },
                  private: { data: privateData },
                }}
                memberId={memberData.id}
                address={address}
              >
                <ScheduleVisitAdmin
                  handleClose={() => setOpenScheduleVisit(false)}
                  admin={true}
                />
              </VisitProvider>
            )}
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openChangeMembership}
            onClose={() => setOpenChangeMembership(false)}
            title={"Change Plan"}
            width={"800px"}
          >
            <ChangeMembership
              memberId={memberData?.id}
              timeZoneId={memberData?.timeZoneId}
              zipCode={memberData?.location?.zipCode}
              membershipTier={adminData?.membershipTier}
              setOpenPricingCards={setOpenChangeMembership}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openViewDiscounts}
            onClose={() => setOpenViewDiscounts(false)}
            title={"Discounts"}
            width={"600px"}
            desktopAnchor={"right"}
            anchor={"right"}
            fullHeight={true}
          >
            <DiscountsList
              discounts={adminData?.discounts}
              memberId={memberData?.id}
              timeZoneId={memberData?.timeZoneId}
              isAdmin={true}
              memberPhone={privateData?.phone}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openViewInformation}
            onClose={() => setOpenViewInformation(false)}
            title={"Details"}
            width={"600px"}
            desktopAnchor={"right"}
            anchor={"right"}
            fullHeight={true}
          >
            <MemberInfo
              memberData={memberData}
              privateData={privateData}
              accountData={accountData}
              adminData={adminData}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openSendLoginLink}
            onClose={() => setOpenSendLoginLink(false)}
            title={"Send Login Link"}
            width={"600px"}
            desktopAnchor={"right"}
            anchor={"right"}
            fullHeight={true}
          >
            <SendLoginLink
              open={openSendLoginLink}
              onClose={() => setOpenSendLoginLink(false)}
              selectedMember={selectedMember}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openPaySignupFee}
            onClose={() => setOpenPaySignupFee(false)}
            title={"Signup Fee"}
            width={"600px"}
            desktopAnchor={"right"}
            anchor={"right"}
            fullHeight={true}
          >
            {openPaySignupFee && (
              <Elements stripe={stripePromise}>
                <SignupFeePayment
                  customerId={adminData?.stripeId}
                  memberId={memberData?.id}
                  handleClose={() => setOpenPaySignupFee(false)}
                  zipCode={memberData?.location?.zipCode}
                  signupFeeStatus={privateData?.signupFeeStatus}
                  locationStatus={memberData?.status}
                />
              </Elements>
            )}
          </ResponsiveDialog>
        </>
      )}
      <ResponsiveDialog
        open={openCreateMember}
        onClose={() => setOpenCreateMember(false)}
        title={"New Member"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        <CreateMember
          open={openCreateMember}
          handleClose={() => setOpenCreateMember(false)}
          setSelectedMember={setSelectedMember}
          handleOpenMember={() => setOpenMemberCard(true)}
          userUid={data?.auth?.uid}
          userName={`${data?.employee?.firstName} ${data?.employee?.lastName}`}
          userAvatar={data?.employee?.avatarUrl}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openCreateLead}
        onClose={() => setOpenCreateLead(false)}
        title={"New Lead"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"bottom"}
        fullHeight={true}
      >
        {openCreateLead && (
          <NewLead
            employeeId={currentEmployeeId}
            handleClose={() => setOpenCreateLead(false)}
          />
        )}
      </ResponsiveDialog>
    </div>
  );
};

export default MemberSearch;
