import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import React from "react";
import { useMember } from "../providers/MemberProvider";
import { useSnackbar } from "../providers/SnackbarProvider";

import { ContentCopy, Email, Share, Textsms } from "@mui/icons-material";

const WaitlistCard = () => {
  const { data, setData } = useMember();
  const { showSnackbar } = useSnackbar();

  const theme = useTheme();

  const defaultAddressKey = data?.member?.private?.data?.defaultAddress;
  const matchedAddress = data?.account?.addresses?.[defaultAddressKey];

  // Your referral message or link
  const referralLink = `http://www.ourLinkedLives.com/member/signup?referral=${data.auth.uid}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    showSnackbar("Referral link copied to clipboard", "success");
  };

  // Calculate the progress for the loading bar
  const progress = Math.min(
    (Object.keys(data?.waitlist || {}).length / 8) * 100,
    100
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack
        spacing={2}
        direction="column"
        maxWidth={"600px"}
        sx={{ py: { xs: 0, md: 4 } }}
      >
        <Typography variant="h6">
          We're working hard to reach {matchedAddress?.city}!
        </Typography>
        {/* <Typography>
          Our service is not in {matchedAddress?.city} quite yet, but we are
          working hard to get there.
        </Typography>
        <Typography variant="body1" fontWeight="600">
          We would love your help
        </Typography> */}
        <Typography>
          Our service grows through community involvment and your help. As soon
          as we reach 8 signups in the {matchedAddress?.city} area, we will
          launch our services. So the more friends, neighbors, and family that
          you tell at book club, church, or coffee, the faster we will reach
          you!
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 10,
            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor:
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor:
                theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
            },
          }}
        />

        {/* <Box
          display="flex"
          justifyContent="center" // center vertically
          alignItems="center" // center horizontally
          textAlign="center" // center the text inside the Typography
        >
          <Typography align="center">
            We only need {8 - Object.keys(data?.waitlist || {}).length} more
            signup
            {8 - Object.keys(data?.waitlist || {}).length == 1 ? "" : "s"} !
          </Typography>
        </Box> */}
        <Box sx={{ mt: 2 }}>
          <Typography>
            Share with your family, friends, or neighbors by clicking the
            options below:
          </Typography>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                sx={{ height: "56px" }}
                variant="outlined"
                startIcon={<Email />}
                component="a"
                href={`mailto:?subject=New service for seniors&body=${encodeURIComponent(
                  "I found this great service that helps seniors! If you're interested, you can sign up with my referral link: " +
                    referralLink
                )}`}
              >
                Email
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                sx={{ height: "56px" }}
                variant="outlined"
                startIcon={<Textsms />}
                component="a"
                href={`sms:?&body=${encodeURIComponent(
                  "I found this great service that helps seniors! If you're interested, you can sign up with my referral link: " +
                    referralLink
                )}`}
              >
                Text
              </Button>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                sx={{ height: "56px" }}
                variant="outlined"
                startIcon={<ContentCopy />}
                onClick={handleCopyLink}
              >
                Copy Link
              </Button>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                sx={{ height: "56px" }}
                variant="outlined"
                startIcon={<Share />}
                component="a"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  referralLink
                )}`} // example for Facebook
                target="_blank"
                rel="noopener noreferrer"
              >
                Share
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default WaitlistCard;
