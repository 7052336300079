import { CircularProgress, DialogActions, DialogContent } from "@mui/material";
import { doc, getFirestore, onSnapshot } from "firebase/firestore"; // Firebase Firestore imports
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import MemberDetailsCard from "./MemberDetailsCard";

const RouteViewer = ({ event, setSelectedMember, open }) => {
  const [memberDetails, setMemberDetails] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const db = getFirestore(); // Firestore instance

  useEffect(() => {
    if (!event || !event.serviceOrder) {
      return;
    }

    // Set up listeners for each member in the service order
    const unsubscribeListeners = event.serviceOrder.map((order) => {
      const userId = order.userId;
      const memberDocRef = doc(db, "events", event.id, "signUpList", userId);

      return onSnapshot(memberDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setMemberDetails((prevDetails) => ({
            ...prevDetails,
            [userId]: {
              ...docSnapshot.data(),
              travelTimeToNext: order.travelTimeToNext,
            },
          }));
        }
      });
    });

    // Cleanup function to unsubscribe from all listeners when the component unmounts
    return () => unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
  }, [event, db]);

  useEffect(() => {
    // New useEffect hook to update the selected member
    if (event && event.serviceOrder && event.serviceOrder[currentIndex]) {
      const selectedUserId = event.serviceOrder[currentIndex].userId;
      console.log("setting member: ", memberDetails[selectedUserId]?.firstName);
      setSelectedMember(
        memberDetails[selectedUserId]
          ? {
              id: selectedUserId,
              ...memberDetails[selectedUserId],
            }
          : null
      );
    } else {
      console.log("skipping");
    }
  }, [currentIndex, event, memberDetails, setSelectedMember, open]); // Depend on currentIndex, event, memberDetails, and setSelectedMember



  const handleSwipe = (index) => {
    setCurrentIndex(index);
  };

  if (loading || !event || !event.serviceOrder) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleComplete = () => {
    if (currentIndex < event.serviceOrder.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      // Handle the scenario when the end of the service order list is reached
      console.log("Reached the end of the service order list");
    }
  };

  return (
    <>
      <DialogContent>
        <SwipeableViews index={currentIndex} onChangeIndex={handleSwipe}>
          {event.serviceOrder.map((order, index) => (
            <div key={order.userId}>
              {memberDetails[order.userId] ? (
                <MemberDetailsCard
                  member={{ id: order.userId, ...memberDetails[order.userId] }}
                  eventId={event.id}
                  onComplete={handleComplete}
                  open={currentIndex === index}
                />
              ) : (
                <CircularProgress />
              )}
            </div>
          ))}
        </SwipeableViews>
      </DialogContent>
    </>
  );
};

export default RouteViewer;
