import { Bookmark, KeyboardArrowLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";

const DescriptionCard = ({
  handleNext = () => {},
  handleBack = () => {},
  canSkip = true,
  showBack = false,
  sessionId = "",
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ description: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only keep letters and spaces, and limit to the first 200 characters
    // let value = e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 200);
    // Update the event's value before passing it to handleChange
    // e.target.value = value;

    setValues((prevValues) => ({ ...prevValues, [name]: value }));

    // Clear error for the field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          description: values.description || "",
        },
        { merge: true }
      );

      handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleVisitHome = async () => {
    setLoading(true);

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          visitHome: true,
        },
        { merge: true }
      );

      navigate("/");
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const renderOwner = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        src={
          "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F9nRhRtIUV1fdKXANdzbI4wGli4E2?alt=media&token=d1e3dfd6-5fe0-4bca-b762-2eb77b5792a6"
        }
        alt="Alex Rodriguez"
        sx={{ width: 64, height: 64 }}
      />
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1">Alex Rodriguez</Typography>
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          Owner, Linked Lives
        </Typography>
      </Box>
    </Box>
  );

  const handleSmsClick = async () => {
    setLoading(true);

    // Create the SMS URL
    const smsUrl = `sms:3603366344?&body=${encodeURIComponent(
      "I'm interested in bookmarking senior helpers for later:\n\nLinked Lives\nwww.ourlinkedlives.com\n(360) 336-6344"
    )}`;

    // Open the SMS URL
    window.location.href = smsUrl;

    try {
      await setDoc(
        doc(db, "sessions", sessionId),
        {
          bookmarkDescription: true,
        },
        { merge: true }
      );

      // handleNext();
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <ButtonBase
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            <KeyboardArrowLeft /> Back
          </ButtonBase>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          How can we help?
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          {/* Your first free hour will be associated with this phone info.  */}
          Please describe the household tasks that you would like us to
          complete.
        </Typography>
        <Box sx={{ display: "flex", mb: 2, flexDirection: "column", gap: 2 }}>
          <TextField
            name="description"
            id="description"
            multiline
            minRows={5}
            label="Describe your tasks"
            fullWidth
            variant="outlined"
            value={values.description}
            onChange={handleChange}
            error={!!errors.description} // Show error state if there's an error for description
            helperText={errors.description} // Display the actual error message
          />
          {/* <Divider>or</Divider>
          <Button
            disableElevation
            color="primary"
            variant="outlined"
            onClick={handleSmsClick}
            disabled={loading}
            startIcon={<Bookmark />}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }}
          >
            <Typography fontWeight={"500"}>Bookmark app for later</Typography>
          </Button> */}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            mb: { xs: 0, sm: "50px" },
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || values.description.length === 0}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            {loading ? ( // Conditional rendering based on loading state
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Continue</Typography>
            )}
          </Button>
          <Button
            disableElevation
            variant="text"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }} // Take up half the available width
          >
            <Typography
              align="center"
              onClick={handleVisitHome}
              fontWeight={"500"}
              sx={{ my: 1, cursor: "pointer", color: "black" }}
            >
              Not ready?{" "}
              <span style={{ textDecoration: "underline" }}>
                Visit homepage
              </span>
            </Typography>
          </Button>
          {/* <Button
            fullWidth
            variant="text"
            size="large"
            component="a"
            href="tel:+13603366344"
            sx={{
              width: "100%",
              height: { xs: "60px" },
              textTransform: "none",
            }}
            startIcon={<Call />}
          >
            Call (360) 336-6344
          </Button> */}

          {canSkip && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "56px",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                onClick={handleNext}
                fontWeight={"500"}
                sx={{ textDecoration: "underline", my: 1, cursor: "pointer" }}
              >
                Skip for now
              </Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default DescriptionCard;
