import React from "react";

import {
  EmojiEvents,
  Event,
  ManageSearch,
  PhonelinkRing,
  PlaylistAddCheck,
  Savings,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import HelperStack from "./HelperStack";

const TaskMomHelpers = ({ handleScroll }) => {
  const bulletPoints = [
    {
      header: "Incredible Help",
      text: "Hire incredible helpers, personally vetted and hand picked. Hire the best in town.",
      Icon: EmojiEvents, // Replace with your chosen icon
    },
    {
      header: "Easy Booking",
      text: "Book with access to all helpers availability. Save hours texting back-and-forth.",
      Icon: Event, // Replace with your chosen icon
    },
    {
      header: "Browse Profiles",
      text: "Discover helper profiles, reviews, and skills. No more sifting through facebook.",
      Icon: ManageSearch, // Replace with your chosen icon
    },
    {
      header: "Save Money",
      text: "Hire your helper directly and save money. We are a platform, not the middle man.",
      Icon: Savings, // Replace with your chosen icon
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: { xs: "column", sm: "row-reverse" },
        alignItems: "center",
        maxWidth: "1100px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 0, sm: 6 },
        mt: { xs: 0, sm: 10 },
        alignItems: "flex-start",
        gap: 4,
      }}
    >
      {/* I want this Box below to scroll normally */}
      <Box
        sx={{
          display: "flex",
          position: { xs: "block", sm: "sticky" },
          top: 160,
          flexDirection: "column",
          p: { xs: 1, sm: 4 },
          pt: { xs: 4, sm: 0 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Amazing Helpers
        </Typography>
        <Typography align="center" variant="h6" fontWeight="400" sx={{ mb: 2 }}>
          TaskMom helps you find the right help for your needs.
        </Typography>
        <List>
          {bulletPoints.map(({ header, text, Icon }, index) => (
            <ListItem key={index} sx={{ alignItems: "center" }}>
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {header}
                  </Typography>
                }
                secondary={text}
              />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          disableElevation
          onClick={() => handleScroll("signup")}
          sx={{
            width: { xs: "100%", sm: "300px" },
            height: "56px",
            textTransform: "none",
            mx: "auto",
          }}
        >
          Book Your Helper!
        </Button>
      </Box>
      {/* I want this HelperStack to become sticky in view */}
      <HelperStack />
    </Box>
  );
};

export default TaskMomHelpers;
