import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

// Assuming these are your icons or images

function HowItWorksSection() {
  // Define steps in an array
  const steps = [
    {
      title: "Schedule a Visit",
      description:
        "Schedule a recurring or one-time visit with your favorite helper.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fcalendar-drawing.png?alt=media&token=5a30ea16-9140-4fc7-a564-21646299e1bf", // Using image for the first step
      alt: "Schedule a Visit",
    },
    {
      title: "Helper Arrives",
      description:
        "Your helper arrives on time, ready to assist with tasks and errands.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fhelper-drawing.png?alt=media&token=edd1eb5b-d700-4fbf-8484-999442f1cbd3", // Using image for the first step
      alt: "Helper Arrives",
    },
    {
      title: "Enjoy Your Time",
      description:
        "Free yourself from chores and enjoy what matters most to you.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fhands-drawing.png?alt=media&token=d55e722b-e80e-44d4-8f91-5e0873fa79b6", // Using image for the first step
      alt: "Enjoy Your Time",
    },
  ];

  return (
    <Box
      sx={{
        // backgroundColor: "hsla(100, 100%, 95%, 1)",
        borderRadius: "30px",
        mx: "auto",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: { xs: 0, sm: 2 },
        my: 4,
      }}
    >
      {/* How It Works Columns */}
      <Typography algin="center" variant="h4" sx={{ mx: "auto" }}>
        How TaskMom Works:
      </Typography>
      <Divider sx={{ width: "50%", mx: "auto", mb: 4 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {steps.map((step, index) => (
            <Grid key={index} item xs={12} sm={4} sx={{ textAlign: "center" }}>
              {step.image ? (
                <CardMedia
                  component="img"
                  sx={{ width: 100, height: 100, margin: "auto", mb: 2 }}
                  image={step.image}
                  alt={step.alt}
                />
              ) : (
                <img
                  src={step.icon} // For SVG icons as images
                  alt={step.title}
                  style={{ width: 60, height: 60, marginBottom: 16 }}
                />
              )}
              <Typography variant="h5" sx={{ mb: 2 }}>
                {step.title}
              </Typography>
              <Typography sx={{ maxWidth: "250px", mx: "auto" }}>
                {step.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default HowItWorksSection;
