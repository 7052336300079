import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  Box,
  Typography,
  MobileStepper,
  Button,
  useTheme,
  Stack,
  Paper,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AnnouncementCard = ({ announcements }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = announcements.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {announcements.map((announcement, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  objectFit: "cover",
                  height: 250,
                  display: "cover",
                  // maxWidth: 400,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={announcement.imgPath}
                alt={announcement.title}
              />
            ) : null}

            <Typography fontWeight={"600"} align="center" my={1}>
              {announcements[activeStep]?.title}
            </Typography>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* {announcements.length > 1 && (
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <ArrowBackIosNewOutlined />
              ) : (
                <ArrowForwardIosRounded />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <ArrowForwardIosRounded />
              ) : (
                <ArrowBackIosNewOutlined />
              )}
            </Button>
          }
        />
      )} */}
    </Box>
  );
};

export default AnnouncementCard;
