import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Image1 from "../../../assets/local-senior-help-linked-lives.jpeg";

function HiringLanding() {
  const theme = useTheme(); // hook to get current theme

  // Handle button click to scroll and update URL
  const handleScroll = (sectionId) => {
    // Prevent default action if this is triggered by an event
    // event.preventDefault(); // Uncomment if handleScroll is called with an event argument

    const element = document.getElementById(sectionId);

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }

    // Update the URL hash
    window.history.pushState({}, "", `#${sectionId}`);
  };

  return (
    <Grid container spacing={0} sx={{ display: { xs: "block", md: "flex" } }}>
      {/* Left Box with Image */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: { xs: "250px", md: "100%" }, // 150px tall on mobile and 50% viewport height on desktop and up
            backgroundImage: `url(${Image1})`,
            backgroundSize: "cover",
            backgroundPosition: "15% 50%",
          }}
        ></Box>
      </Grid>

      {/* Right Box with Centered Content */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: "white", //theme.palette.primary.lightest,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          // height={{ xs: "auto", md: "50vh" }} // auto height on mobile and 50% viewport height on desktop and up
          padding={{ xs: "32px 16px", md: "100px" }}
        >
          <Box>
            <Typography variant="h4" textAlign="left" gutterBottom>
              Feel valued and find purpose
            </Typography>
            <Typography variant="body1" paragraph>
              If you're looking for meaningful work within your community, enjoy
              setting your own schedule, and are handy with odd-jobs, you'll be
              a great fit at Linked Lives!
            </Typography>
            <Button
              variant="contained"
              fullWidth
              size="large"
              // component={Link}
              // href="/employee/signup"
              // underline="none"
              onClick={() => handleScroll("apply")}
              sx={{ height: "56px" }}
            >
              Apply now
            </Button>
            {/* <Typography variant="body1" paragraph>
              Linked Lives helps seniors with odd jobs and tasks around their
              homes as we prioritize exceptional service and meaningful
              relationships.
            </Typography> */}

            <Box align="center"></Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HiringLanding;
