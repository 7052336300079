import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";
import bbbBadge from "../assets/AB-seal-horz.svg";
import { MEMBER_COUNT } from "../services/variableServices";
import CredibilityGrid from "./CredibilityGrid";

const GettingStartedCard = () => {
  const theme = useTheme();
  const steps = [
    {
      primary: "Household Help on-demand",
      secondary:
        "Receive assistance whenever you need an extra hand. No more waiting or feeling stuck.",
    },
    {
      primary: "Easy to schedule",
      secondary:
        "Schedule visits online or over the phone with ease. Experience the convenience tailored for seniors.",
    },
    {
      primary: "Peace-of-mind",
      secondary:
        "Find peace as we tackle your stubborn chores and tasks. Experience the relief!",
    },
    {
      primary: `Trusted by hundreds`,
      secondary: `Join over ${
        Math.floor(MEMBER_COUNT / 10) * 10
      }+ seniors who count on us for their household help. Consistent, reliable, and here for you.`,
    },
  ];

  const renderBBB = () => {
    return (
      <Box
        component={"a"}
        href={
          "https://www.bbb.org/us/wa/anacortes/profile/home-services/linked-lives-1296-1000171080"
        }
        target={"_blank"}
        rel={"noopener noreferrer"}
        sx={{
          display: "block",
          width: "150px",
          height: "auto",
          mr: "auto",
          ml: { xs: 0, sm: "auto" },
        }}
      >
        <Box
          component="img"
          src={bbbBadge}
          sx={{ width: "100%", height: "auto", mx: "auto" }}
        />
      </Box>
    );
  };

  return (
    <Box variant="outlined" sx={{ m: { xs: 0, sm: 2 } }}>
      <List>
        {steps.map((step, index) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ minWidth: "44px" }}>
              <CheckCircle color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={step.primary}
              secondary={step.secondary}
              primaryTypographyProps={{
                color: "primary",
                fontWeight: "bold",
              }}
            />
          </ListItem>
        ))}
      </List>
      <CredibilityGrid noReviews={true} />
      {/* {renderBBB()} */}
    </Box>
  );
};

export default GettingStartedCard;
