import React from "react";

// Define SVG paths for each social media platform
const ICONS = {
  Facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 60 60"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#346DA6"
          d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Z"
        />
        <path
          fill="#FFF"
          d="M25.462 47.314V30h-3.519v-5.74h3.52v-3.47c0-4.682 1.399-8.058 6.525-8.058h6.098v5.727h-4.294c-2.15 0-2.64 1.43-2.64 2.926v2.875h6.617L36.866 30h-5.714v17.315h-5.69Z"
        />
      </g>
    </svg>
  ),
  Twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 60 60"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#4086CD"
          d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Z"
        />
        <path
          fill="#FFF"
          d="M41.052 18.437c-1.209-1.375-2.931-2.25-4.838-2.282-3.66-.061-6.628 3.032-6.628 6.908 0 .55.058 1.087.171 1.602-5.508-.358-10.393-3.226-13.662-7.55a7.332 7.332 0 0 0-.897 3.544c0 2.456 1.17 4.636 2.949 5.922a6.336 6.336 0 0 1-3.003-.916v.089c0 3.43 2.285 6.307 5.317 6.977a6.242 6.242 0 0 1-2.993.1c.843 2.813 3.29 4.866 6.191 4.934-2.268 1.883-5.126 3.004-8.232 2.998a12.627 12.627 0 0 1-1.58-.102c2.933 2.012 6.417 3.184 10.16 3.185 12.192.003 18.859-10.606 18.859-19.81 0-.301-.007-.601-.02-.9a13.634 13.634 0 0 0 3.308-3.557 12.65 12.65 0 0 1-3.807 1.041 6.797 6.797 0 0 0 2.914-3.806c-1.28.776-2.699 1.334-4.209 1.623Z"
        />
      </g>
    </svg>
  ),
  iMessage: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 60 60"
    >
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#57BB63"
          d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30Z"
        />
        <path
          fill="#FFF"
          d="M29.423 16.154c-8.603 0-15.577 5.569-15.577 12.438 0 3.49 1.803 6.643 4.703 8.902.825 4.513-3.159 5.347-3.159 5.347s-.497.636.739.871c4.974.907 8.997-3.084 8.997-3.084l.004-.08a19.27 19.27 0 0 0 4.293.482C38.026 41.03 45 35.461 45 28.592c0-6.87-6.974-12.438-15.577-12.438Z"
        />
      </g>
    </svg>
  ),
};

const SocialMediaIcon = ({ name }) => {
  const icon = ICONS[name] || <div>Icon not found</div>; // Default to a "not found" placeholder if name is not recognized
  return <div>{icon}</div>;
};

export default SocialMediaIcon;
