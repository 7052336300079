import {
  Close,
  HomeRepairServiceRounded,
  HomeRounded,
  Menu,
  PaidRounded,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../svgs/Logo";
import TaskMomLogo from "../../../svgs/TaskMomLogo";

function WaitlistHeader({ handleScroll }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const tabs = [
    {
      ref: "services",
      icon: <HomeRepairServiceRounded />,
      text: "How we help",
    },
    { ref: "howItWorks", icon: <PaidRounded />, text: "How it works" },
    { ref: "about", icon: <PaidRounded />, text: "About" },
    { ref: "pricing", icon: <PaidRounded />, text: "Pricing" },
    { ref: "referral", icon: <PaidRounded />, text: "Refer-a-friend" },
  ];

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleSignInClick = async () => {
    if (auth.currentUser) {
      try {
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to fetch Member data:", error);
      }
    } else {
      navigate("/member/login");
    }
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      style={{ width: isMobile ? "100%" : "250px" }}
    >
      <nav>
        {tabs.map((tab, index) => (
          <ListItemButton
            key={index}
            onClick={() => handleScroll(tab.ref)}
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              {/* <ListItemIcon>{tab.icon}</ListItemIcon>  */}
              <ListItemText primary={tab.text} />
            </Box>
          </ListItemButton>
        ))}
      </nav>
      <Box p={2} gap={2} display="flex" flexDirection="column">
        {/* {renderZipCode()} */}
        <Button
          disableElevation
          onClick={() => handleScroll("signup")}
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Sign up
        </Button>
        {/* <Button
          component={Link}
          disableElevation
          to="/member/login"
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          mb={2}
        >
          Login
        </Button> */}
      </Box>
    </div>
  );

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: "white", zIndex: 1301 }}
      elevation={0}
    >
      <Toolbar style={{ minHeight: isMobile ? "80px" : "100px" }}>
        {isMobile ? (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Box width={150}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <TaskMomLogo color="#000" />
              </Link>
            </Box>

            <IconButton aria-label="menu" onClick={toggleDrawer(!drawerOpen)}>
              {drawerOpen ? <Close /> : <Menu />}
            </IconButton>

            <Drawer
              anchor="top"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{
                style: {
                  top: "80px",
                  position: "absolute",
                  width: "100%",
                  zIndex: 1, // This makes sure the Drawer stays below the AppBar
                },
              }}
            >
              {list()}
            </Drawer>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            px={3}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <Link to="/taskmom" style={{ textDecoration: "none" }}>
                  <TaskMomLogo color="#000" />
                </Link>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              flexGrow={1}
            >
              {tabs.map((tab, index) => (
                <Button
                  key={index}
                  onClick={() => handleScroll(tab.ref)}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    fontWeight="400"
                    textTransform={"none"}
                    paddingX={"12px"}
                  >
                    {tab.text}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              {/* {renderZipCode()} */}
              {/* <Button
                onClick={handleSignInClick}
                variant="outlined"
                disableElevation
                color="primary"
                mr={2}
                sx={{ width: "125px", height: "40px" }}
              >
                Login
              </Button> */}
              <Button
                onClick={() => handleScroll("signup")}
                disableElevation
                variant="contained"
                color="primary"
                sx={{ width: "125px", height: "40px" }}
              >
                Sign up
              </Button>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default WaitlistHeader;
