import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { sendSignInLink } from "../services/memberServices";
import { useSnackbar } from "../providers/SnackbarProvider";
import { LockOpen } from "@mui/icons-material";

// If handleSendLoginLink logic is defined outside, pass it as a prop.
const SendLoginLink = ({ open, onClose, selectedMember }) => {
  const { showSnackbar, showLoading } = useSnackbar();
  // This handler will be triggered when the button is clicked
  const handleSendLoginLink = async () => {
    try {
      showLoading("Sending sign-in link");
      const uid = selectedMember?.id;
      const replyTo = "support@ourlinkedlives.com";
      // Here, we're calling the sendLoginLink function when the button is clicked
      const response = await sendSignInLink({ uid, replyTo });
      console.log(response); // handle your response accordingly
      showSnackbar("Sign-in link sent", "success");
    } catch (error) {
      console.error("Failed to send sign-in link: ", error);
      showSnackbar("Failed to send sign-in link", "error");

      // Handle your error accordingly
    }
  };

  return (
    <>
      <DialogContent>
        <Box textAlign="center" sx={{ my: 4 }}>
          <LockOpen color="primary" sx={{ fontSize: 40, mb: 1 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Password-less member login
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            Do you wish to send the member a one-click login link via text
            and email?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={onClose}
          variant="text"
          color="primary"
          sx={{
            minWidth: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSendLoginLink();
            onClose();
          }}
          color="primary"
          sx={{
            minWidth: { xs: "50%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          Send Link
        </Button>
      </DialogActions>
    </>
  );
};

export default SendLoginLink;
