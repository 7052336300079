import { Box, useMediaQuery, useTheme } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import StyledProgress from "../components/styled/StyledProgress";
import { db } from "../firebase";
import AboutCard from "./cards/AboutCard";
import BudgetCard from "./cards/BudgetCard";
import CompleteCard from "./cards/CompleteCard";
import ContactCard from "./cards/ContactCard";
import DateCard from "./cards/DateCard";
import DescriptionCard from "./cards/DescriptionCard";
import NameCard from "./cards/NameCard";
import ResultsCard from "./cards/ResultsCard";
import TasksCard from "./cards/TasksCard";
import TimelineCard from "./cards/TimelineCard";
import ZipcodeCard from "./cards/ZipcodeCard";
import ConfirmCard from "./cards/ConfirmCard";

const VisitFunnel = ({ handleClose = () => {}, canSkip = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [view, setView] = useState("ABOUT");

  // Define the order of views as an array of objects
  const [viewOrder, setViewOrder] = useState([
    { name: "ABOUT", title: "Finish signing up" },
    { name: "DESCRIPTION", title: "Finish signing up" },
    { name: "DATE", title: "Finish signing up" },
    { name: "CONFIRM", title: "Address Details" },
    // { name: "INTENT", title: "Finish signing up" },
    // { name: "GOALS", title: "Finish signing up" },
    // { name: "BUDGET", title: "Finish signing up" },
    // { name: "ZIPCODE", title: "Finish signing up" },
    // { name: "RESULTS", title: "Finish signing up" },
    // { name: "NAME", title: "Finish signing up" },
    { name: "COMPLETE", title: "Address Details" },
  ]);

  const [sessionId, setSessionId] = useState("");

  const currentIndex = viewOrder.findIndex((viewObj) => viewObj.name === view);
  const totalSteps = viewOrder.length;
  const formProgress = (currentIndex / (totalSteps - 1)) * 90 + 5;

  const getSystemData = () => {
    return {
      device: navigator.userAgent || "unknown device",
      screenSize: `${window.screen.width || "unknown"}x${
        window.screen.height || "unknown"
      }`,
      language: navigator.language || "unknown",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "unknown",
      effectiveType: navigator.connection
        ? navigator.connection.effectiveType || "unknown"
        : "unknown",
      type: navigator.connection
        ? navigator.connection.type || "unknown"
        : "unknown",
      windowSize: `${window.innerWidth || "unknown"}x${
        window.innerHeight || "unknown"
      }`,
    };
  };

  useEffect(() => {
    const initializeSession = async () => {
      const systemData = getSystemData();
      console.log("systemData: ", systemData);

      let storedSessionId = localStorage.getItem("sessionId");
      if (!storedSessionId) {
        storedSessionId = uuidv4();
        localStorage.setItem("sessionId", storedSessionId);

        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              created: new Date(),
              "~systemData": systemData, // Store system data
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
      setSessionId(storedSessionId);
    };

    initializeSession();
  }, []);

  const handleBack = () => {
    // Determine if there's a previous view in the sequence
    if (currentIndex > 0) {
      // Check there is a previous view
      const prevViewObj = viewOrder[currentIndex - 1];

      // Update the view to the previous one in the sequence
      setView(prevViewObj.name);
    } else {
      // Optionally, handle if this is the first step and user attempts to go back (e.g., close the modal or redirect)
    }
  };

  const handleNext = async () => {
    if (currentIndex >= 0 && currentIndex < viewOrder.length - 1) {
      const nextViewObj = viewOrder[currentIndex + 1];

      // If the current step is valid, update the view to the next one in the sequence
      setView(nextViewObj.name);
    } else {
      // FIX IN FUTURE - this is so that the userPrivateData state variable updates before we call. Would be fixed if values were stored in this top level.
      handleClose();
    }
  };

  const handleSpinEnd = (result) => {
    console.log("Spin ended on:", result);
  };

  const items = [
    { id: 1, bgColor: "#FF0000", text: "Prize 1" },
    { id: 2, bgColor: "#00FF00", text: "Prize 2" },
    { id: 3, bgColor: "#0000FF", text: "Prize 3" },
    { id: 4, bgColor: "#FFFF00", text: "Prize 4" },
    // { id: 5, bgColor: "#FF00FF", text: "Prize 5" },
    // { id: 6, bgColor: "#00FFFF", text: "Prize 6" },
    // { id: 7, bgColor: "#FF8000", text: "Prize 7" },
  ];

  const labels = ["0x", "1x", "2x", "3x", "4x", "5x"]; //, '6x', '7x'];
  const colors = [
    "#441ced",
    "#F9AA00",
    "#441ced",
    "#F9AA00",
    "#441ced",
    "#F9AA00",
  ];

  return (
    <>
      <Box
        sx={{
          position: "relative",
          left: { xs: 0 },
          right: { xs: 0 },
          width: "100%",
        }}
      >
        <StyledProgress
          variant={"determinate"}
          value={formProgress}
          borderRadius={isMobile ? 0 : "5px"}
        />
      </Box>
      {/* <WheelSpinner labels={labels} colors={colors} /> */}

      {view === "DESCRIPTION" && (
        <DescriptionCard
          handleNext={handleNext}
          canSkip={false}
          showBack={false}
          sessionId={sessionId}
        />
      )}
      {view === "DATE" && (
        <DateCard
          handleNext={handleNext}
          canSkip={true}
          showBack={false}
          sessionId={sessionId}
        />
      )}
      {view === "CONFIRM" && (
        <ConfirmCard
          handleNext={handleNext}
          canSkip={canSkip}
          showBack={false}
          sessionId={sessionId}
        />
      )}
      {view === "TASKS" && (
        <TasksCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={true}
          showBack={viewOrder.find((v) => v.name === "TASKS")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "TIMELINE" && (
        <TimelineCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={true}
          showBack={viewOrder.find((v) => v.name === "TASKS")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "ABOUT" && (
        <AboutCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "ABOUT")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "NAME" && (
        <NameCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={true}
          showBack={viewOrder.find((v) => v.name === "TASKS")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "ZIPCODE" && (
        <ZipcodeCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "ZIPCODE")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "RESULTS" && (
        <ResultsCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "RESULTS")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "CONTACT" && (
        <ContactCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "CONTACT")?.showBack}
          sessionId={sessionId}
        />
      )}
      {view === "COMPLETE" && (
        <CompleteCard
          handleNext={handleNext}
          handleBack={handleBack}
          canSkip={canSkip}
          showBack={viewOrder.find((v) => v.name === "COMPLETE")?.showBack}
          sessionId={sessionId}
        />
      )}
    </>
  );
};

export default VisitFunnel;
