import { getFunctions, httpsCallable } from "firebase/functions";

export const joinEvent = async (data) => {
  const functions = getFunctions();
  const joinEventFunction = httpsCallable(functions, "joinEvent");
  try {
    const result = await joinEventFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling joinEvent function:", error);
    throw error;
  }
};

export const planRoute = async (data) => {
  const functions = getFunctions();
  const planRouteFunction = httpsCallable(functions, "planRoute");
  try {
    const result = await planRouteFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling planRoute function:", error);
    throw error;
  }
};

export const markComplete = async (data) => {
  const functions = getFunctions();
  const markCompleteFunction = httpsCallable(functions, "markComplete");
  try {
    const result = await markCompleteFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling markComplete function:", error);
    throw error;
  }
};
