import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GuestHeader from "../../components/GuestHeader";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import { Helmet } from "react-helmet";
import InfiniteScrollAvatars from "../../components/popup/InfiniteScrollAvatars";
import FamilyPhoto from "./sections/FamilyPhoto";
import OurMissionSection from "./sections/OurMissionSection";
import ThreeBrothersSection from "./sections/ThreeBrothersSection";
import WebFooter from "./sections/WebFooter";
import {
  Timestamp,
  arrayUnion,
  doc,
  increment,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

function AboutPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    let scrollTimeout;
    let scrollDepth = 0;

    const logScrollDepth = async () => {
      const timestamp = Timestamp.now();

      const logEntry = {
        page: "about",
        timestamp: timestamp,
        scrollDepth: scrollDepth,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              "~logs": arrayUnion(logEntry),
            },
            { merge: true }
          );
        } catch (err) {
        }
      }
    };

    const handleScroll = () => {
      const currentScrollDepth = Math.round(window.scrollY); // Measure scroll depth from the top in pixels
      scrollDepth = currentScrollDepth;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        logScrollDepth();
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1), // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("About");
  }, []);

  return (
    <div>
      <Helmet>
        <title>Linked Lives Household Help for Older Adults</title>
        <meta
          name="description"
          content="At Linked Lives, we believe in the transformative power of community, family, and intergenerational relationships. We are here to provide excellent household help."
        />
      </Helmet>
      <GuestHeader />

      <InfiniteScrollAvatars />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            paddingY: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography
              variant="h3"
              component="h1" // This changes the underlying element to an h1
              fontWeight="700"
              gutterBottom
            >
              Why{" "}
              <span style={{ color: theme.palette.primary.vibrant }}>
                Linked Lives?
              </span>
            </Typography>
            <Typography variant="body1" mb={0}>
              At Linked Lives, we believe in the transformative power of
              community, family, and intergenerational relationships. In
              everything we do, we are guided by these three foundational
              principles. From your interest, we believe that you also share our
              values. Welcome to our community!
            </Typography>
          </Box>
        </Container>
      </Box>
      <FamilyPhoto />
      <ThreeBrothersSection />
      <OurMissionSection />
      {/* <AboutMission /> */}
      <Box
        sx={{
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            paddingY: theme.spacing(8),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography variant="h4" fontWeight="700" gutterBottom>
              Our Helpers Become{" "}
              <span style={{ color: theme.palette.primary.vibrant }}>
                Like Family{" "}
              </span>
            </Typography>

            <Typography variant="body1" mb={4}>
              In today's fast-paced world, families often find themselves
              dispersed across cities, states, even countries. It's increasingly
              difficult to provide physical support our loved ones, especially
              when distance becomes a barrier.{" "}
            </Typography>
            <Typography variant="body1" mb={4}>
              For adult children that do live near their senior parents, each
              visit can start to bring a growing to-do list. You love your
              family, but the constant tasks—moving boxes down from the attic,
              organizing a cluttered garage, changing hard-to-reach light
              bulbs—can overshadow the quality time you get to spend with them.
              Instead of enjoying family time, you find yourself busy with
              chores. This can lead to an unintended dynamic, one that can eat
              away at quality time spent.
            </Typography>
            <Typography variant="body1" mb={4}>
              At Linked Lives, we understand this challenge. That's why we've
              stepped in to bridge this gap, offering reliable and comprehensive
              assistance. Our exceptional “Links” can handle a variety of
              odd-jobs, freeing up your time while forming connections!
            </Typography>

            {/* <Typography variant="h4" fontWeight="700" gutterBottom>
              Helping{" "}
              <span style={{ color: theme.palette.primary.vibrant }}>
                Senior Parents{" "}
              </span>
            </Typography>

            <Typography variant="body1" mb={4}>
              That's where Linked Lives steps in. We're here to relieve this
              pressure by taking care of those tasks for your loved ones,
              enabling you to focus on quality time instead. Our trustworthy and
              reliable “Links” handle these odd-jobs, so your visits can return
              to what they should truly be about—enjoying each others company.
            </Typography> */}

            <Typography variant="h4" fontWeight="700" gutterBottom>
              Choose
              <span style={{ color: theme.palette.primary.vibrant }}>
                {" "}Your Helpers
              </span>
            </Typography>

            <Typography variant="body1" mb={4}>
              Inviting someone new into your home can feel like a big step,
              especially if you're used to relying on family for help. At Linked
              Lives, we prioritize your comfort and trust, allowing you to
              choose your helper. Our team members are thoroughly screened,
              trained, and committed to providing respectful, dependable help.
              We're here to support you while honoring the routines and
              preferences that make your house a home.
            </Typography>

            <Typography variant="body1" mb={4}>
              Once you find a Link that you connect well with, you can continue
              to book visits with the same person. This not only helps us tailor
              our services to your needs, but also builds meaningful
              intergenerational relationships that can last far beyond our
              service.
            </Typography>

            <Typography variant="h4" fontWeight="700" gutterBottom>
              Start Your Journey
            </Typography>

            <Typography variant="body1" mb={4}>
              See what you can accomplish with a Linked Lives Visit. Join us and
              experience the difference we can make, not only in your home but
              in your life.
            </Typography>

            <Button
              component={Link} // use the Link component as the root node of the Button
              to="/member/signup" // use 'to' instead of 'href' for react-router's Link
              variant="contained"
              size="large"
              color="primary"
              sx={{ width: 150, textTransform: "none" }}
            >
              Get Started
            </Button>
            {/* <UploadCSV /> */}
          </Box>
        </Container>
      </Box>
      <WebFooter />
    </div>
  );
}

export default AboutPage;

// import React from "react";
// import {
//   Typography,
//   Card,
//   CardContent,
//   Container,
//   Grid,
//   useMediaQuery,
//   GridItem,
//   Box,
//   Button,
// } from "@mui/material";
// import image from "../../../assets/odd-job-help-seniors.jpeg";
// import satisfactionBadge from "../../../assets/senior-help-satisfaction-guarantee.png";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// import { useTheme } from "@mui/material"; // Don't forget to import useTheme
