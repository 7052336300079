import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Circle,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { decodeGeoHash, getMembersInArea } from "../services/locationServices";
import MapDisplay from "./MapDisplay";
import ResponsiveDialog from "./ResponsiveDialog";
import { CircleRounded, Info } from "@mui/icons-material";

const LocationMapWithDrawing = ({
  geohash = "",
  onBoundsSave = () => {},
  onBoundsChange = () => {},
  initialBounds = [],
  initialCenter = {},

  loading = false,
  title = "Edit bounds",
}) => {
  const theme = useTheme();
  const [center, setCenter] = useState({ lat: 48.5126, lng: -122.6127 });
  const [shapeBounds, setShapeBounds] = useState(null);
  const [savedBounds, setSavedBounds] = useState(initialBounds); // State to store saved bounds
  const [open, setOpen] = useState(false);
  const [points, setPoints] = useState([]);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    // if (!open) return;
    if (!center) return;
    console.log("getting memberCoords");

    const fetchMemberCoords = async () => {
      try {
        const memberCoords = await getMembersInArea({ center }, 10); // Assuming getMembersInArea expects an object with center
        setPoints(memberCoords);
        console.log("memberCoords: ", memberCoords);
      } catch (error) {
        console.error("Error getting member coordinates: ", error);
        setPoints([]); // Set to empty array in case of error
      }
    };

    fetchMemberCoords();
  }, [center]);

  console.log("center: ", center);

  useEffect(() => {
    const defaultCoords = { lat: 48.5126, lng: -122.6127 }; // Default fallback coordinates

    const onSuccess = (position) => {
      console.log("position lat: ", position.coords.latitude);
      console.log("position lat: ", position.coords.longitude);

      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    const onError = () => {
      setCenter(defaultCoords);
    };

    console.log("in use effect");
    if (geohash) {
      console.log("geohash: ", geohash);
      const locationFromGeohash = decodeGeoHash(geohash);
      console.log("locationFromGeohash[0]: ", locationFromGeohash[0]);
      console.log("locationFromGeohash[1]: ", locationFromGeohash[1]);

      setCenter({
        lat: locationFromGeohash[0],
        lng: locationFromGeohash[1],
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      setCenter(defaultCoords); // Set to default if geolocation is not available
    }
  }, [geohash]);

  const mapRef = useRef(null); // Reference to the map instance

  const mapContainerStyle = {
    height: "65vh",
    width: "100%",
  };

  const mapOptions = {
    mapTypeControl: false, // Hide the map/satellite toggle
    fullscreenControl: false, // Hide the fullscreen button
    streetViewControl: false, // Hide the street view control
    // ... other options you might want to set ...
  };

  const clearShapeBounds = () => {
    if (shapeBounds) {
      shapeBounds.setMap(null);
      setShapeBounds(null);
      onBoundsChange(null); // Notify that the shape has been cleared
    }
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        clearShapeBounds();
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [clearShapeBounds]);

  useEffect(() => {
    if (mapRef.current && savedBounds) {
      const bounds = new window.google.maps.LatLngBounds();
      savedBounds.forEach((coord) => bounds.extend(coord));
      mapRef.current.fitBounds(bounds);
    }
  }, [savedBounds]);

  const onOverlayComplete = useCallback(
    (e) => {
      // Remove the previous shape if it exists
      if (shapeBounds) {
        shapeBounds.setMap(null);
      }

      const newShape = e.overlay;
      newShape.type = e.type;
      setShapeBounds(newShape);

      if (newShape.type === "polygon") {
        // Handle the polygon here
        // You can access the path of the polygon using newShape.getPath()
        // If needed, you can pass the path or other relevant data via onBoundsChange
        if (onBoundsChange) {
          const path = newShape
            .getPath()
            .getArray()
            .map((p) => ({ lat: p.lat(), lng: p.lng() }));
          onBoundsChange(path);
        }
      }

      setShapeBounds(newShape); // Store the shape
    },
    [onBoundsChange]
  );

  const handleSave = () => {
    if (shapeBounds && shapeBounds.getPath) {
      const path = shapeBounds
        .getPath()
        .getArray()
        .map((p) => ({ lat: p.lat(), lng: p.lng() }));
      setSavedBounds(path); // Save the bounds
      onBoundsSave(path);
      setOpen(false); // Close the dialog
    }
  };

  const handleMapClick = () => {
    if (loading) return;
    setOpen(true); // Open the dialog on map click
  };

  const drawingOptions = {
    drawingControl: true,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      strokeColor: theme.palette.primary.main,
      fillColor: theme.palette.primary.main,
      fillOpacity: 0.35,
      strokeWeight: 2,
      clickable: false,
      editable: true,
      zIndex: 1,
    },
    drawingMode: window.google.maps.drawing.OverlayType.POLYGON, // Set default mode to polygon
  };

  return (
    <>
      <Box
        onClick={handleMapClick}
        sx={{
          borderRadius: "15px",
          overflow: "hidden",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
        }}
      >
        <MapDisplay
          loading={loading}
          mapCenter={center}
          savedBounds={savedBounds}
          points={points}
        />
        {loading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent overlay
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <ResponsiveDialog
        title={title}
        open={open}
        onClose={() => setOpen(false)}
        width="800px"
      >
        <DialogContent sx={{ p: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <Info color="disabled" sx={{ mr: 1 }} />
            <Typography variant="caption">
              Tap the polygon icon next to the hand to enter edit mode
            </Typography>
          </Box>

          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={11}
            options={mapOptions}
          >
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "2px",
                fill: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                width: "auto",
                position: "absolute",
                top: 0,
                left: 0,
                m: "5px",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
                height: "30.5px",
                justifyContent: "center",
                px: "5px",
              }}
              onClick={() => setShowLegend(true)}
            >
              <Typography>Show legend</Typography>
            </Box>
            <Dialog open={showLegend} onClose={() => setShowLegend(false)}>
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "10px",
                  fill: "#f5f5f5",
                  display: "flex",
                  flexDirection: "column",
                  m: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <CircleRounded color="error" sx={{ mr: 1 }} />
                  <Typography variant="caption">
                    Waitlisted members that need service.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <CircleRounded color="success" sx={{ mr: 1 }} />
                  <Typography variant="caption">
                    Active members within service area.
                  </Typography>
                </Box>
              </Box>
            </Dialog>

            <DrawingManager
              onOverlayComplete={onOverlayComplete}
              options={drawingOptions}
            />
            {points &&
              points.map((point, index) => (
                <React.Fragment key={index}>
                  <Marker
                    position={{ lat: point.lat, lng: point.lng }}
                    icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      scale: 5,
                      fillColor: point.status === "active" ? "green" : "red",
                      fillOpacity: 1,
                      strokeWeight: 1,
                      strokeColor: "darkgreen",
                    }}
                  />
                  <Circle
                    center={{ lat: point.lat, lng: point.lng }}
                    radius={400} // Adjust radius for the uncertainty range
                    options={{
                      strokeWeight: 1,
                      strokeOpacity: 1,
                      strokeColor:
                        point.status === "active" ? "darkgreen" : "darkred",
                      fillColor: point.status === "active" ? "green" : "red",
                      fillOpacity: 0.3,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: true,
                      zIndex: 1,
                    }}
                  />
                </React.Fragment>
              ))}
          </GoogleMap>
        </DialogContent>
        <DialogActions
          sx={{
            zIndex: 1000,
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            align="right"
            disabled={loading}
            elevation={0}
            onClick={clearShapeBounds}
            color="primary"
            variant="outlined"
            sx={{
              height: { xs: "60px", sm: "auto" },
              width: { xs: "50%", sm: "150px" },
            }}
          >
            clear
          </Button>
          <Button
            onClick={handleSave}
            disabled={loading}
            color="primary"
            variant="contained"
            sx={{
              height: { xs: "60px", sm: "auto" },
              width: { xs: "50%", sm: "150px" },
            }}
          >
            {loading ? <CircularProgress color="inherit" size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default LocationMapWithDrawing;
