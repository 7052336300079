import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import React, { forwardRef } from "react";
import RatingChip from "./RatingChip";

const formatDate = (date) => {
  return moment(date).format("MMM Do");
};

const formatTimeRange = (startDate, endDate) => {
  const startTime = moment(startDate).format("h:mm A");
  const endTime = moment(endDate).format("h:mm A");
  return `${startTime} - ${endTime}`;
};

// Custom formatting function
const formatTime = (momentObj) => {
  return momentObj.minute() === 0
    ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
    : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
};

const VisitListItem = forwardRef(
  ({ visit, compact = false, showChips = true }, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const timeZoneId = visit?.timeZoneId;

    const formatWeekDay = (date) => {
      if (compact) {
        return moment(date).format("ddd"); // Short format for day of the week
      }
      return moment(date).format(isMobile ? "ddd" : "dddd");
    };

    // Helper function to get initials from displayName
    function getInitials(name) {
      return name
        .split(" ")
        .map((part) => part[0])
        .join("")
        .toUpperCase();
    }

    // Filter employees who meet the criteria
    const filteredEmployees = Object.values(visit.employees).filter(
      (employee) =>
        employee.responseStatus === "accepted" ||
        employee.responseStatus === "needsAction" ||
        employee.responseStatus === "refunded"
    );

    // Function to compare visit window with employee windows
    const isAnyWindowDifferent = (visit) => {
      if (!visit || !visit.employees || !visit.start || !visit.end) {
        return false; // Return false if visit or necessary properties are missing
      }

      const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
      const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

      // Check if any employee's window is different
      return Object.values(visit.employees).some((employee) =>
        Object.values(employee.windows || {}).some((window) => {
          const windowStart = moment(window.start.toDate()).tz(timeZoneId);
          const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

          return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
        })
      );
    };

    // Determining how to display time format
    const employeeCount =
      visit?.employees && Object.keys(visit.employees).length;
    const windowsAreDifferent = isAnyWindowDifferent(visit);
    const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

    const isPastEvent = moment().isAfter(moment(visit?.start?.toDate()));
    const isRefunded = visit.flags && visit.flags.includes("refundIssued");

    const needsReschedule = visit?.status === "reschedule";
    const isReviewed =
      typeof visit?.rating === "number" && !isNaN(visit?.rating);
    const canReview = isPastEvent && !isReviewed && !needsReschedule;

    // Prepare the AvatarGroup if there are filtered employees
    const avatarGroup = (
      <AvatarGroup max={4}>
        {/* Adjust 'max' value as needed */}
        {filteredEmployees.map((employee, index) => (
          <Avatar
            sx={{ width: 50, height: 50, mr: -2 }}
            key={index}
            alt={employee.displayName}
            src={employee.avatarUrl}
            {...(!employee.avatarUrl && {
              children: getInitials(employee.displayName),
            })}
          />
        ))}

        {/* Additional blank avatars if it's a future event */}
        {visit?.employeesNeeded &&
          !isPastEvent &&
          [...Array(visit?.employeesNeeded)].map((_, index) => (
            <Avatar
              sx={{ width: 50, height: 50, mr: -2 }}
              key={`blank-${index}`}
            />
          ))}
      </AvatarGroup>
    );

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            my: 2,
            mr: 2,
            ml: 1,
          }}
        >
          <ListItemIcon sx={{ mr: 3 }}>{avatarGroup}</ListItemIcon>
          <Stack direction="column" alignItems={"flex-start"}>
            <Typography variant="h6">
              {formatWeekDay(moment(visit?.start?.toDate()))}
              {", "}
              {formatDate(moment(visit?.start?.toDate()).toDate())}
            </Typography>

            {!shouldShowTimes && (
              <Typography variant="body1">
                {Object.entries(visit.employees)[0]?.[1]?.windows
                  ? Object.values(
                      Object.entries(visit.employees)[0][1].windows
                    ).map((window, index) => (
                      <React.Fragment key={index}>
                        {formatTime(
                          moment(window.start.toDate()).tz(timeZoneId)
                        )}{" "}
                        -{" "}
                        {formatTime(moment(window.end.toDate()).tz(timeZoneId))}
                        <br />
                      </React.Fragment>
                    ))
                  : formatTimeRange(
                      moment(visit?.start?.toDate()).toDate(),
                      moment(visit?.end?.toDate()).toDate()
                    )}
              </Typography>
            )}

            {visit?.recurrence?.frequency > 0 && (
              <Typography>
                {visit?.recurrence.frequency > 1
                  ? `Every ${visit?.recurrence.frequency}-weeks`
                  : `Every week`}
              </Typography>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            gap: 1,
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          {visit.status === "cancelled" && showChips && (
            <Chip
              label="Cancelled"
              color="error"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {isRefunded && showChips && (
            <Chip
              label="Refund issued"
              color="success"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {isReviewed && showChips &&(
            <Box
              sx={{
                zIndex: 1,
                display: "flex",
              }}
            >
              <RatingChip rating={visit.rating} />
            </Box>
          )}
          {canReview && showChips &&(
            <Chip
              label="Review"
              color="info"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
          {needsReschedule && showChips &&(
            <Chip
              label="Reschedule"
              color="success"
              size="small"
              sx={{
                zIndex: 1,
                borderRadius: "5px",
              }}
            />
          )}
        </Box>

        {/* {isPastEvent && (
        <>
          {visit?.rating ? (
            <Box
              sx={{
                zIndex: 1,
                display: "flex",
                ...(isMobile ? chipStyles : desktopChipStyles),
              }}
            >
              <RatingChip rating={visit.rating} />
            </Box>
          ) : (

          )}
        </>
      )} */}
      </Box>
    );
  }
);

export default VisitListItem;
