import {
  Call,
  FileCopyOutlined,
  LocationOnOutlined,
  Person,
  Recommend,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import MapDisplay from "../MapDisplay";

const MemberDetailsCard = ({
  eventId,
  member,
  onComplete = () => {},
  open = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  console.log("member: ", member);

  if (!member) return;

  const formatAddress = (address) => {
    const { line1, line2, city, state, zipCode } = address;
    return `${line1}${line2 ? `, ${line2}` : ""}, ${city}, ${state} ${zipCode}`;
  };

  const handlePhoneClick = (phoneNumber) => {
    const formattedNumber = phoneNumber?.replace(/[^+0-9]/g, "") || 5555555555;
    const telUrl = `tel:${formattedNumber}`;
    window.location.href = telUrl;
  };

  const handleAddressClick = (address) => {
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    if (isMobile) {
      if (/Android/i.test(navigator.userAgent)) {
        url = `geo:0,0?q=${encodeURIComponent(address)}`;
      } else if (/iPhone|iPad/i.test(navigator.userAgent)) {
        url = `maps://maps.google.com/maps?daddr=${encodeURIComponent(
          address
        )}&amp;ll=`;
      }
      window.location.href = url;
    } else {
      window.open(url, "_blank");
    }
  };

  const getStatusChipColor = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Pending":
        return "warning";
      case "Inactive":
        return "default";
      default:
        return "primary";
    }
  };

  console.log("member.address.geohash: ", member.address.geohash);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            src={member.avatarUrl}
            alt={`${member.firstName} ${member.lastName}`}
            sx={{ width: "100px", height: "100px" }}
          />
          <Box>
            <Typography variant="h6">{`${member.firstName} ${member.lastName}`}</Typography>
            {/* <Typography variant="subtitle2">{member.email}</Typography> */}
          </Box>
        </Box>
      </Box>
      <List>
        <ListItemButton onClick={() => handlePhoneClick(member.phone)}>
          <ListItemIcon>
            <Person color="primary" />
          </ListItemIcon>
          <ListItemText primary="Phone" secondary={member.phone} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => navigator.clipboard.writeText(member.phone)}
            >
              <Call />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleAddressClick(formatAddress(member.address))}
        >
          <ListItemIcon>
            <LocationOnOutlined color="primary" />
          </ListItemIcon>
          <ListItemText
            primary="Address"
            secondary={formatAddress(member.address)}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() =>
                navigator.clipboard.writeText(formatAddress(member.address))
              }
            >
              <FileCopyOutlined />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItem>
          <ListItemIcon>
            <Recommend color="primary" />
            {/* Icon for the status, can be changed */}
          </ListItemIcon>
          <Chip
            label={member.status}
            color={getStatusChipColor(member.status)}
            // size="small"
            variant="outlined"
          />
        </ListItem>
      </List>

      {open ? (
        <MapDisplay
          mapCenter={{ lat: member.address.lat, lng: member.address.lng }}
          points={[{ lat: member.address.lat, lng: member.address.lng }]}
          height={"300px"}
          width={"99%"}
          zoomLevel={14}
        />
      ) : (
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"300px"}
          animation="wave"
        />
      )}
    </>
  );
};

export default MemberDetailsCard;
