import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  CardHeader,
  Box,
  Chip,
  Alert,
} from "@mui/material";
import { Star } from "@mui/icons-material";

const StarRating = ({ value }) => {
  return (
    <Typography component="span">
      {Array.from({ length: 5 }, (_, index) => (
        <Star key={index} color={index < value ? "warning" : "disabled"} />
      ))}
    </Typography>
  );
};

const HelperCard = ({ helper }) => {
  return (
    <Box
      sx={{
        maxWidth: 400,
        m: 2,
        display: "flex",
        flexDirection: "column",
        border: "1px solid #f5f5f5",
        borderRadius: "30px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <Avatar
          src={helper.avatarUrl}
          aria-label="helper-avatar"
          sx={{ width: 72, height: 72, marginRight: 2 }}
        >
          {helper.firstName[0]}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{`${helper.firstName} ${helper.lastInitial}`}</Typography>
            {/* <Alert icon={false} sx={{ py: 0, borderRadius: "30px" }}>
              ${helper.hourlyRate}/hr
            </Alert> */}
          </Box>
          {/* <Typography variant="body2" color="text.secondary">
            {helper.location}
          </Typography> */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body2"
              color="textSecondary"
              // fontStyle={"italic"}
            >
              {helper.age}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="body1">{helper.bio}</Typography>
      </CardContent>
    </Box>
  );
};

export default HelperCard;
