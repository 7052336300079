import { CheckCircle } from "@mui/icons-material";
import { Chip } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const EventStatusChip = ({
  signUpList,
  maxSignUps,
  userId,
  eventDays,
  timeZoneId,
}) => {
  const calculateSpotsLeft = () => {
    const signUpListLength = signUpList ? signUpList.length : 0;
    const maxAllowedSignUps = maxSignUps || 0; // Assuming a default of 0 if not specified

    const spotsLeft = maxAllowedSignUps - signUpListLength;
    return Math.max(spotsLeft, 0); // Ensures that spots left is not negative
  };

  if (!eventDays || !timeZoneId) return;

  const spotsLeft = calculateSpotsLeft();

  const isJoined = signUpList?.some((member) => member.userId === userId);

  const getLatestDeadline = (eventDays) => {
    const deadlines = Object.values(eventDays)
      .filter((day) => day.signUpDeadline)
      .map((day) => moment(day.signUpDeadline.toDate()).tz(timeZoneId));

    if (deadlines.length === 0) return null;

    return deadlines.reduce(
      (latest, current) => (latest.isAfter(current) ? latest : current),
      moment(0)
    );
  };
  // Determine if the current time is past the deadline of the last day
  const latestDeadline = getLatestDeadline(eventDays);
  const isSignUpDisabled = moment().tz(timeZoneId).isAfter(latestDeadline);

  return (
    <Chip
      label={
        isSignUpDisabled
          ? "Past event"
          : isJoined
          ? "Signed up"
          : `${spotsLeft} spot${spotsLeft === 1 ? "" : "s"} left`
      }
      icon={isJoined ? <CheckCircle /> : null}
      color={isJoined ? "primary" : "default"}
      variant={isJoined ? "filled" : "outlined"}
      sx={{
        color: isJoined ? "white" : "inherit",
        fontWeight: "bold",
        my: 1,
      }}
    />
  );
};

export default EventStatusChip;
