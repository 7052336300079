import { useTheme } from "@emotion/react";
import {
  ArrowForwardIos,
  Brush,
  CleaningServices,
  Email,
  Extension,
  KeyboardDoubleArrowRight,
  KitchenRounded,
  LocalLaundryService,
  Park,
  WbTwilight,
} from "@mui/icons-material";
import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const tasks = [
  {
    id: 1,
    without: {
      intro: "Instead of...",

      text: "Tidy the play room",
      description:
        "Toys, books, and remnants of yesterday's snack litter the floor. Tidying up feels endless, as each cleared toy seems to be replaced by two more.",
      icon: <CleaningServices />,
      image: "path/to/living_room_cleaning_image.jpg",
    },
    with: {
      intro: "Go enjoy...",
      text: "Crafts with the kids",
      description:
        "Tidying done! You dive into the playroom action, folding origami pirate hats and paper airplanes with your kids. Giggles fill the air.",
      icon: <Brush />,
      image: "path/to/crafting_image.jpg",
    },
  },

  {
    id: 3,
    without: {
      intro: "Rather than...",
      text: "Prepping for breakfast",
      description:
        "The kitchen buzzes in the morning, as you juggle prepping a nutritious breakfast. You race against the clock to get everyone out the door on time.",
      icon: <KitchenRounded />,
      image: "path/to/meal_prep_image.jpg",
    },
    with: {
      intro: "Enjoy time to...",
      text: "Take a morning walk",
      description:
        "Breakfast is cooking, thanks to your helper. You seize the moment for a morning walk with the kids. The fresh air makes for an ideal start.",
      icon: <WbTwilight />,
      image: "path/to/morning_walk_image.jpg",
    },
  },
  {
    id: 4,
    without: {
      intro: "Instead of...",
      text: "Drop off a package",
      description:
        "You make a detour in your already packed day. Getting to the post office with kids, you navigate the line, all while keeping an eye on the clock.",
      icon: <Email />,
      image: "path/to/package_dropoff_image.jpg",
    },
    with: {
      intro: "Be free to go...",
      text: "Picnic in the park",
      description:
        "The package? Dropped off by your helper. Now, you're enjoying a sunny picnic at the park, catching up with a friend as your kids play nearby.",
      icon: <Park />,
      image: "path/to/picnic_image.jpg",
    },
  },
];

const TaskCard = ({ side, task }) => (
  <Box
    sx={{
      height: "100%",
      backgroundColor: side === "with" ? "hsla( 100, 100%, 95%, 1)" : "none",
      borderRadius: "15px",
      p: { xs: side === "with" ? 2 : "none", sm: 2 },
      mx: { xs: side === "with" ? -2 : "none", sm: 1 },
      mb: { xs: side === "with" ? 4 : "none", sm: 1 },
      mt: { xs: side === "with" ? 1 : "none", sm: 1 },
    }}
  >
    <Box>
      <Typography
        fontStyle={"italic"}
        // fontWeight={"600"}
        align="center"
        color="textSecondary"
      >
        {task[side].intro}
      </Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {task[side].icon}

        <Typography variant="h6" component="div">
          {task[side].text}
        </Typography>
      </Box>
      <Typography variant="body2">{task[side].description}</Typography>
    </Box>
  </Box>
);

const CompareSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ p: 4, maxWidth: "900px", mx: "auto" }}>
      <Typography variant="h3" sx={{ mb: 3 }} align="center">
        Free Your Time
      </Typography>
      <Typography
        align="center"
        variant="h6"
        fontWeight="400"
        sx={{ mb: 2, maxWidth: "450px", mx: "auto" }}
      >
        "Life as usual" just got better! TaskMom helps you flourish throughout
        the week.
      </Typography>
      {/* Title */}
      <Divider sx={{ mb: 4 }} /> {/* Divider for aesthetic separation */}
      <Grid container spacing={1} alignItems="center">
        {tasks.map((task) => (
          <React.Fragment key={task.id}>
            <Grid item xs={12} md={5}>
              <TaskCard side="without" task={task} />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <KeyboardDoubleArrowRight
                  sx={{
                    transform: isMobile ? "rotate(90deg) scale(2)" : "scale(2)",
                    mx: "auto",
                  }}
                />{" "}
                {/* Arrow icon pointing back */}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <TaskCard side="with" task={task} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default CompareSection;
