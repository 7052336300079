import React, { useState, useEffect } from "react";
import { GoogleMap, Polygon, Marker, Circle } from "@react-google-maps/api";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { decodeGeoHash } from "../services/locationServices";

const MapDisplay = ({
  zoomLevel = false,
  mapCenter = { lat: 48.5126, lng: -122.6127 },
  geohash = "",
  savedBounds = [],
  loading = false,
  width = "100%",
  height = "400px",
  points = [],
}) => {
  const theme = useTheme();
  const [initialCenter, setInitialCenter] = useState();
  const [center, setCenter] = useState(mapCenter);

  useEffect(() => {
    if (center) return;

    const defaultCoords = { lat: 48.5126, lng: -122.6127 }; // Default fallback coordinates

    const onSuccess = (position) => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    const onError = () => {
      setCenter(defaultCoords);
    };

    console.log("in use effect");
    if (geohash) {
      console.log("geohash: ", geohash);
      const locationFromGeohash = decodeGeoHash(geohash);
      console.log("locationFromGeohash[0]: ", locationFromGeohash[0]);
      console.log("locationFromGeohash[1]: ", locationFromGeohash[1]);

      setCenter({
        lat: locationFromGeohash[0],
        lng: locationFromGeohash[1],
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      setCenter(defaultCoords); // Set to default if geolocation is not available
    }
  }, [geohash]);

  // useEffect(() => {
  //   console.log("in use effect");
  //   console.log("geohash: ", geohash);
  //   if (geohash) {
  //     const locationFromGeohash = decodeGeoHash(geohash);
  //     console.log("locationFromGeohash: ", locationFromGeohash);
  //     setCenter({
  //       lat: locationFromGeohash[0],
  //       lng: locationFromGeohash[1],
  //     });
  //   } else if (
  //     navigator.geolocation &&
  //     center &&
  //     Object.keys(center).length === 0
  //   ) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         console.log("position.coords.latitude: ", [
  //           position.coords.latitude,
  //           position.coords.longitude,
  //         ]);

  //         setCenter({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting location: ", error);
  //         if (geohash) {
  //           const locationFromGeohash = decodeGeoHash(geohash);
  //           setCenter({
  //             lat: locationFromGeohash[0],
  //             lng: locationFromGeohash[1],
  //           });
  //         }
  //       }
  //     );
  //   }
  // }, [geohash]);

  const mapContainerStyle = {
    height,
    width,
  };

  const staticMapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false, // Disable clicks on POIs
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    draggable: false,
    gestureHandling: "none",
  };

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!savedBounds) {
      setInitialCenter(center);
    }
  }, []);

  useEffect(() => {
    if (!map) return;

    if (!zoomLevel && savedBounds.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      savedBounds.forEach((point) => {
        bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
      });

      map.fitBounds(bounds);
      const listener = map.addListener("bounds_changed", () => {
        const currentZoom = map.getZoom();
        map.setZoom(currentZoom); // Zoom in
        window.google.maps.event.removeListener(listener);
      });
    } else {
      console.log("zoomLevel: ", zoomLevel);
      if (zoomLevel) {
        map.setZoom(zoomLevel);
      }
    }
  }, [savedBounds, map]);

  const handleLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  return (
    <Box
      sx={{
        borderRadius: "15px",
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={11}
        options={staticMapOptions}
        onLoad={handleLoad}
      >
        {savedBounds && (
          <Polygon
            path={savedBounds}
            options={{
              strokeColor: theme.palette.primary.main,
              fillColor: theme.palette.primary.main,
              fillOpacity: 0.35,
              strokeWeight: 2,
              clickable: false,
              editable: false,
              zIndex: 1,
            }}
          />
        )}
        {points &&
          points.map((point, index) => (
            <React.Fragment key={index}>
              <Marker
                position={{ lat: point.lat, lng: point.lng }}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 5,
                  fillColor: point.status === "active" ? "green" : "red",
                  fillOpacity: 1,
                  strokeWeight: 1,
                  strokeColor: "darkgreen",
                }}
              />
              <Circle
                center={{ lat: point.lat, lng: point.lng }}
                radius={400} // Adjust radius for the uncertainty range
                options={{
                  strokeWeight: 1,
                  strokeOpacity: 1,
                  strokeColor:
                    point.status === "active" ? "darkgreen" : "darkred",
                  fillColor: point.status === "active" ? "green" : "red",
                  fillOpacity: 0.3,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  zIndex: 1,
                }}
              />
            </React.Fragment>
          ))}
      </GoogleMap>
    </Box>
  );
};

export default MapDisplay;
