import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { useEmployee } from "../providers/EmployeeProvider";
import { db } from "../firebase";
import CustomSwitch from "./CustomSwitch";
import HomeCard from "./HomeCard";

function AvailabilityToggle() {
  const { data } = useEmployee();
  const contentRef = useRef(null);

  let isAvailableToday = data?.employee?.isAvailableToday || false;
  let employeeId = data?.auth?.uid || "";

  const handleToggle = async () => {
    const newAvailability = !isAvailableToday;

    // Update in Firestore
    const employeeRef = doc(db, "employees", employeeId);
    try {
      await updateDoc(employeeRef, { isAvailableToday: newAvailability });
      console.log("Availability updated successfully");
    } catch (error) {
      console.error("Error updating availability: ", error);
    }
  };

  return (
    <HomeCard>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Day of booking</Typography>
        <CustomSwitch checked={isAvailableToday} onChange={handleToggle} />
      </Box>
      <Typography variant="subtitle1" sx={{ pr: 4 }}>
        {isAvailableToday &&
          "Members can book you for a visit today during your work hours."}
      </Typography>
    </HomeCard>
  );
}

export default AvailabilityToggle;
