import React, { useState, useEffect, useRef } from "react";
import moment, { isMoment } from "moment-timezone";
import {
  Box,
  Typography,
  Card,
  useTheme,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { formatTimeRangeCompact } from "../../services/dateServices";
import ResponsiveDialog from "../ResponsiveDialog";
import EmployeeVisitCard from "../EmployeeVisitCard";
import { db } from "../../firebase";
import { updateDoc, doc, deleteField } from "firebase/firestore";
import { addAndMergeWindows } from "../../services/employeeServices";

const VisitItem = ({
  visit,
  window,
  employeeId,
  timeZoneId,
  start,
  end,
  onSelect,
}) => {
  const theme = useTheme();

  const responseStatus = visit.employees[employeeId]?.responseStatus;
  const visitStart = window.start.clone();
  const visitEnd = window.end.clone();

  // Extract only the time portion of the visit start and end times
  const visitStartTime = visitStart.hour() * 60 + visitStart.minute();
  const visitEndTime = visitEnd.hour() * 60 + visitEnd.minute();

  // Similarly for the start and end of the timeline
  const timelineStart = start.hour() * 60 + start.minute();
  const timelineEnd = end.hour() * 60 + end.minute();

  const totalTimelineMinutes = timelineEnd - timelineStart;
  const startMinutes = visitStartTime - timelineStart;
  const durationMinutes = visitEndTime - visitStartTime;

  const top = (startMinutes / totalTimelineMinutes) * 100;
  const height = (durationMinutes / totalTimelineMinutes) * 100;

  let style;
  switch (responseStatus) {
    case "accepted":
      style = {
        border: `1px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      };
      break;
    case "needsAction":
      style = {
        border: `1px solid ${theme.palette.warning.main}`,
        backgroundColor: "hsla(36, 100%, 95%, 1)",
        color: theme.palette.warning.main,
      };
      break;
    default:
      style = { backgroundColor: "grey", color: "white" };
  }

  const handleClick = () => {
    onSelect(visit); // Pass the visit ID to the parent's handler
  };

  return (
    <Box
      sx={{
        ...style,
        position: "absolute",
        top: `${top}%`,
        height: `${height}%`,
        left: { xs: 0, sm: 5, md: 15 },
        right: { xs: 5, sm: 5, md: 15 },
        padding: "5px",
        overflow: "hidden",
        whiteSpace: "normal", // Allows text to wrap
        wordWrap: "break-word", // Breaks words to prevent overflow
        boxSizing: "border-box",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        zIndex: 1,
        cursor: "pointer", // Make it visually clear that it's clickable
        userSelect: "none",
      }}
      onClick={handleClick}
    >
      <Typography
        variant="subtitle2"
        fontWeight={"600"}
        sx={{
          fontSize: { xs: "0.75rem", sm: "0.875rem" },
          lineHeight: { xs: "0.8rem", sm: "0.9rem" },
          mb: 0.5,
        }}
      >
        {visit.displayName}
      </Typography>
      <Typography variant="caption" lineHeight={"0.8rem"}>
        {formatTimeRangeCompact({
          start: window.start,
          end: window.end,
          timeZoneId: visit.timeZoneId,
        })}
      </Typography>
    </Box>
  );
};

const AvailabilityWindowItem = ({
  window,
  dayStart,
  dayEnd,
  height,
  timeZoneId,
  employeeId,
}) => {
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    event.preventDefault();
    setMenuAnchorEl({
      left: event.clientX,
      top: event.clientY,
    });
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // Extract only the time portion of the window start and end times
  const windowStartTime = window.start.hour() * 60 + window.start.minute();
  const windowEndTime = window.end.hour() * 60 + window.end.minute();

  // Similarly for the start and end of the day
  const dayStartTime = dayStart.hour() * 60 + dayStart.minute();
  const dayEndTime = dayEnd.hour() * 60 + dayEnd.minute();

  const totalDayMinutes = dayEndTime - dayStartTime;
  const startMinutes = windowStartTime - dayStartTime;
  const durationMinutes = windowEndTime - windowStartTime;

  // Calculate the top position and height as percentages
  const top = (startMinutes / totalDayMinutes) * 100;
  const windowHeight = (durationMinutes / totalDayMinutes) * 100;

  const handleDeleteAvailability = async (id) => {
    try {
      console.log("Deleting availability with ID:", id);

      if (employeeId) {
        const employeeAvailabilityDataRef = doc(
          db,
          "employees",
          employeeId,
          "public",
          "availability"
        );

        // Update the document to delete that specific key
        await updateDoc(employeeAvailabilityDataRef, {
          [`windows.${id}`]: deleteField(),
        });

        console.log("Availability deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting availability:", error);
    }
  };

  const handleDelete = async () => {
    await handleDeleteAvailability(window.key); // Pass the window id for deletion
    handleCloseMenu();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: `${top}%`,
          height: `${windowHeight}%`,
          left: { xs: 0, sm: 2, md: 5 },
          right: { xs: 2, md: 5 },
          padding: { xs: "5px", md: "10px" },
          boxSizing: "border-box",
          backgroundColor: theme.palette.success.main,
          opacity: 0.8,
          borderRadius: "5px",
          border: "1px solid hsl(150,100%,20%)",
          zIndex: 0,
          textOverflow: "ellipsis", // Add this to truncate text
          display: "flex",
          flexDirection: "column",
          userSelect: "none",
          overflow: "hidden",
          whiteSpace: "normal", // Allows text to wrap
          wordWrap: "break-word", // Breaks words to prevent overflow
          cursor: "pointer", // Make it visually clear that it's clickable
        }}
        onClick={handleOpenMenu} // Attach the menu open handler
      >
        <Typography
          variant="subtitle2"
          fontWeight={"600"}
          color="white"
          sx={{
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            lineHeight: { xs: "0.8rem", sm: "0.9rem" },
            mb: 0.5,
          }}
        >
          Availability
        </Typography>
        <Typography variant="caption" lineHeight={"0.8rem"} color="white">
          {formatTimeRangeCompact({
            start: window.start,
            end: window.end,
            timeZoneId,
          })}
        </Typography>
      </Box>
      <Menu
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          menuAnchorEl
            ? { top: menuAnchorEl.top, left: menuAnchorEl.left }
            : undefined
        }
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const DayColumn = ({
  date,
  visits,
  employeeId,
  timeZoneId,
  start,
  end,
  index,
  onSelectVisit,
  height,
  availability,
  onCreateAvailability,
  visitsExist,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [touchStartTimeout, setTouchStartTimeout] = useState(null);

  // Convert height to a number for calculations
  const numericHeight = parseInt(height, 10);

  const intervalHeight = numericHeight / (end.diff(start, "minutes") / 15); // 600px height divided by number of intervals

  const delay = isMobile ? 100 : 0;

  // console.log("availability DayView: ", availability);

  // console.log("numericHeight: ", numericHeight);
  // console.log("dragging: ", dragging);
  // console.log("dragEnd: ", dragEnd);
  // console.log("dragStart: ", dragStart);

  // Current time marker
  const now = moment().tz(timeZoneId); // Current time in the specified timezone
  const isToday = now.isSame(date, "day"); // Check if the column represents today

  // Extract only the time portion of the current time
  const currentTimeInMinutes = now.hour() * 60 + now.minute();

  // Similarly for the start and end of the timeline
  const timelineStartInMinutes = start.hour() * 60 + start.minute();
  const timelineEndInMinutes = end.hour() * 60 + end.minute();

  // Calculate position
  const currentTimePosition = isToday
    ? ((currentTimeInMinutes - timelineStartInMinutes) /
        (timelineEndInMinutes - timelineStartInMinutes)) *
      numericHeight
    : null;

  // Define the start and end of the day
  let dayVisits = [];
  if (visitsExist) {
    dayVisits = Object.entries(visits) // Convert visits to an array of [id, visit] pairs
      .filter(([id, visit]) =>
        moment(visit.start.toDate()).tz(timeZoneId).isSame(date, "day")
      )
      .map(([id, visit]) => ({ ...visit, id })); // Add the id to each visit object
  }

  const snapToInterval = (y) => {
    const intervalIndex = Math.round(y / intervalHeight);
    return intervalIndex * intervalHeight;
  };

  const calculateTime = (y) => {
    const minutesFromStart = (y / intervalHeight) * 15;
    const timeOfDay = start.clone().add(minutesFromStart, "minutes");

    return moment(date)
      .hour(timeOfDay.hour())
      .minute(timeOfDay.minute())
      .second(0) // Reset seconds and milliseconds for consistency
      .millisecond(0);
  };

  const getTouchY = (event) => {
    const touch = event.touches[0] || event.changedTouches[0];
    const rect = event.currentTarget.getBoundingClientRect();
    return touch.clientY - rect.top;
  };

  const handleMouseDown = (event) => {
    // Clear any existing timeout
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
    }

    const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
    // Set a timeout to delay the start handling
    const timeoutId = setTimeout(() => {
      handleStart(y);
    }, delay); // 400ms delay, adjust as needed

    setTouchStartTimeout(timeoutId);
  };

  const handleMouseMove = (event) => {
    const y = event.clientY - event.currentTarget.getBoundingClientRect().top;
    handleMove(y);
  };

  const handleMouseUp = () => {
    // Clear the timeout if touch ends before the delay
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
      setTouchStartTimeout(null);
    }
    handleEnd();
  };

  const findTimeBounds = ({ visit, employeeId }) => {
    const employee = visit?.employees[employeeId];
    let timeWindows = [];

    if (employee && employee.windows) {
      Object.values(employee.windows).forEach((window) => {
        const windowStart = moment(window?.start.toDate()).tz(visit.timeZoneId);
        const windowEnd = moment(window?.end.toDate()).tz(visit.timeZoneId);
        timeWindows.push({ start: windowStart, end: windowEnd });
      });
    } else {
      // If no specific windows are set for the employee, use the visit's start/end times
      let start = moment(visit.start.toDate()).tz(visit.timeZoneId);
      let end = moment(visit.end.toDate()).tz(visit.timeZoneId);
      timeWindows.push({ start, end });
    }

    return timeWindows;
  };

  const handleStart = (y) => {
    setDragStart(snapToInterval(y));
    setDragEnd(snapToInterval(y + 10));
    setDragging(true);
  };

  const handleMove = (y) => {
    if (!dragging) {
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }
      return;
    }
    setDragEnd(snapToInterval(y));
  };

  const handleEnd = () => {
    setDragging(false);

    const startTime = calculateTime(Math.min(dragStart, dragEnd));
    const endTime = calculateTime(Math.max(dragStart, dragEnd));
    console.log("Drag Start Time:", startTime.format("MM-DD-YY HH:mm"));
    console.log("Drag End Time:", endTime.format("MM-DD-YY HH:mm"));
    onCreateAvailability({ start: startTime, end: endTime });
    setDragStart(null);
    setDragEnd(null);
    // Additional logic if needed when drag ends
  };

  const handleTouchStart = (event) => {
    // Clear any existing timeout
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
    }

    const touchY = getTouchY(event);
    // Set a timeout to delay the start handling
    const timeoutId = setTimeout(() => {
      handleStart(touchY);
    }, 200); // 400ms delay, adjust as needed

    setTouchStartTimeout(timeoutId);
  };

  const handleTouchMove = (event) => {
    if (!dragging) {
      if (touchStartTimeout) {
        clearTimeout(touchStartTimeout);
        setTouchStartTimeout(null);
      }
      return;
    }

    handleMove(getTouchY(event));

    // Reset the timer on movement
  };

  const handleTouchEnd = (event) => {
    // Clear the timeout if touch ends before the delay
    if (touchStartTimeout) {
      clearTimeout(touchStartTimeout);
      setTouchStartTimeout(null);
    }

    handleEnd();
  };

  return (
    <Box
      sx={{
        borderLeft: "1px solid hsl(0, 0%, 86%)", //index === 0 ? "1px solid hsl(0, 0%, 86%)" : "none",
        borderRight: "none", //"1px solid hsl(0, 0%, 86%)",
        borderTop: "1px solid hsl(0, 0%, 76%)",
        borderBottom: "none", //"1px solid hsl(0, 0%, 86%)",
        boxSizing: "border-box",
        position: "relative",
        height: height,
        flexGrow: 1, // Added for even splitting of width
        padding: 1,
        userSelect: "none", // Disable text selection across browsers
        WebkitTouchCallout: "none", // Disable touch callouts on iOS
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Render the temporary rectangle */}
      {dragging && (
        <Box
          sx={{
            position: "absolute",
            top: `${Math.min(dragStart, dragEnd)}px`,
            height: `${Math.abs(dragEnd - dragStart)}px`,
            left: 2,
            right: 2,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.4)",
            boxSizing: "border-box",
            zIndex: 10,
            overflow: "hidden",
            userSelect: "none",
            textOverflow: "ellipsis", // Add this to truncate text
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
        >
          <Typography variant="caption" lineHeight={"0.8rem"}>
            {formatTimeRangeCompact({
              start: calculateTime(Math.min(dragStart, dragEnd)),
              end: calculateTime(Math.max(dragStart, dragEnd)),
              timeZoneId,
            })}
          </Typography>
        </Box>
      )}
      {isToday && currentTimePosition !== null && (
        <Box
          sx={{
            position: "absolute",
            top: `${currentTimePosition}px`,
            left: 0,
            right: 0,
            height: "1px",
            zIndex: 20,
            backgroundColor: theme.palette.secondary.light,
            "&:before": {
              // Circle at the left edge
              content: '""',
              position: "absolute",
              left: "-5px",
              top: "-4px",
              width: "8px",
              height: "8px",
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "50%",
            },
          }}
        />
      )}
      {dayVisits.map((visit) => {
        const timeWindows = findTimeBounds({ visit, employeeId });
        return timeWindows.map((window, index) => (
          <VisitItem
            key={`${visit.id}-${index}`}
            visit={visit}
            employeeId={employeeId}
            timeZoneId={timeZoneId}
            window={window}
            start={start}
            end={end}
            onSelect={onSelectVisit}
          />
        ));
      })}
      {availability &&
        availability.map((window, index) => (
          <AvailabilityWindowItem
            key={index}
            window={window}
            dayStart={start}
            dayEnd={end}
            height={height}
            timeZoneId={timeZoneId}
            employeeId={employeeId}
          />
        ))}
    </Box>
  );
};

const TimeMarker = ({ start, end, height }) => {
  const totalDuration = end.diff(start, "minutes");
  const markerCount = totalDuration / 15; // 15-minute intervals

  // Determine the shift needed based on the start time
  const startMinute = start.minute();

  const isLessThanFourHours = end.diff(start, "hours") < 4;

  const shift =
    startMinute === 0
      ? 0
      : startMinute === 15
      ? 1
      : startMinute === 30
      ? 2
      : -1;

  console.log("shift: ", shift);

  // Convert height from "200px" to 200
  const numericHeight = parseInt(height, 10);

  // Generate markers
  const markers = Array.from({ length: markerCount + 1 }, (_, i) => {
    const time = start.clone().add(i * 15, "minutes");
    let label = "";
    if ((i + shift) % 4 === 0) {
      label =
        time.minutes() === 0
          ? time.format("ha").toLowerCase()
          : time.format("h:mma").toLowerCase();
    }

    const is45MinMarker = (i + shift) % 4 === 1 || (i + shift) % 4 === 3;
    const alpha =
      numericHeight < 300 && is45MinMarker && !isLessThanFourHours
        ? 0
        : (i + shift) % 4 === 0
        ? 0.14
        : 0.04;

    return {
      position: (i / markerCount) * 100,
      label: label,
      alpha: alpha,
    };
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
    >
      {markers.map((marker, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: `${marker.position}%`,
            height: "1px",
            left: 0, // Adjust top for vertical centering
            width: "100%",
            backgroundColor: `rgba(0, 0, 0, ${marker.alpha})`,
            zIndex: "-2",
          }}
        >
          {marker.label && (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "50%", // Changed from '100%' to '0'
                transform: "translate(-110%, -50%)", // Adjust to move labels above the line
                whiteSpace: "nowrap",
                color: `rgba(0, 0, 0, 0.6)`,
              }}
            >
              {marker.label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const DayHeader = ({ date }) => {
  const theme = useTheme(); // Access the theme
  const isToday = moment().isSame(date, "day"); // Check if the date is today

  return (
    <Box
      sx={{
        // border: "1px solid black",
        position: "relative",
        padding: "5px",
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        // boxSizing: "border-box",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{date.format("ddd")}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            // color: isToday ? "white" : "inherit", // Text color white if today, else default
            fontWeight: isToday ? "bold" : "inherit",
            backgroundColor: isToday
              ? theme.palette.primary.lighter
              : "transparent", // Circle color if today
            borderRadius: isToday ? "50%" : "none", // Circular if today
            width: theme.spacing(4), // Width of the circle if today
            height: theme.spacing(4), // Height of the circle if today
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {date.format("D")}
        </Typography>
      </Box>
    </Box>
  );
};

const ColumnView = ({
  numberOfDays,
  startDay,
  visits,
  employeeId,
  timeZoneId,
  truncateView = false,
  height = "400px",
  availability = {},
  hideAvailability = false,
}) => {
  const [days, setDays] = useState([]);
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [openVisitDialog, setOpenVisitDialog] = useState(false);
  const [visitsExist, setVisitsExist] = useState(false);

  let dayStart, dayEnd;

  // console.log("startDay: ", startDay);
  // console.log("availability: ", availability);

  useEffect(() => {
    const daysArray = [];
    for (let i = 0; i < numberOfDays; i++) {
      daysArray.push(moment(startDay).add(i, "days"));
    }
    setDays(daysArray);
  }, [numberOfDays, startDay]);

  useEffect(() => {
    if (visits && Object.keys(visits).length > 0) {
      setVisitsExist(true);
    }
  }, [visits]);

  if (!startDay) return;

  const filterAvailabilityForDay = (date) => {
    if (!availability || Object.keys(availability).length === 0) return [];

    // Convert the date to a moment object with the correct timezone
    const momentDate = moment(date).tz(timeZoneId);

    return Object.entries(availability).reduce(
      (filteredWindows, [key, window]) => {
        // Convert window start and end times to moment objects with the correct timezone
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        // Check if the window falls on the same day
        if (
          windowStart.isSame(momentDate, "day") ||
          windowEnd.isSame(momentDate, "day") ||
          (windowStart.isBefore(momentDate, "day") &&
            windowEnd.isAfter(momentDate, "day"))
        ) {
          // Push the converted start and end moments along with the original window data
          filteredWindows.push({
            key,
            start: windowStart,
            end: windowEnd,
          });
        }
        return filteredWindows;
      },
      []
    );
  };

  const handleSelectVisit = (visit) => {
    setSelectedVisitId(visit.id);
    setOpenVisitDialog(true);
  };

  let minTimeInMinutes = 24 * 60; // Latest possible time in minutes (23:59)
  let maxTimeInMinutes = 0; // Earliest possible time in minutes (00:00)

  if (truncateView && visitsExist) {
    days.forEach((day) => {
      Object.values(visits).forEach((visit) => {
        let employeeWindows = visit.employees[employeeId]?.windows;

        // Function to update min and max time based on a given time
        const updateTimeBounds = (time) => {
          const timeMinutes = time.hour() * 60 + time.minute();
          minTimeInMinutes = Math.min(minTimeInMinutes, timeMinutes);
          maxTimeInMinutes = Math.max(maxTimeInMinutes, timeMinutes);
        };

        // Check and update time bounds only if custom windows are not set
        if (!employeeWindows) {
          const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
          const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

          if (visitStart.isSame(day, "day") || visitEnd.isSame(day, "day")) {
            updateTimeBounds(visitStart);
            updateTimeBounds(visitEnd);
          }
        }

        // Iterate over each window in employee windows
        if (employeeWindows) {
          Object.values(employeeWindows).forEach((window) => {
            const windowStart = moment(window.start.toDate()).tz(timeZoneId);
            const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

            if (
              windowStart.isSame(day, "day") ||
              windowEnd.isSame(day, "day")
            ) {
              updateTimeBounds(windowStart);
              updateTimeBounds(windowEnd);
            }
          });
        }
      });
    });

    const dayStartHour = Math.floor(minTimeInMinutes / 60);
    const dayStartMinute = minTimeInMinutes % 60;
    const dayEndHour = Math.floor(maxTimeInMinutes / 60);
    const dayEndMinute = maxTimeInMinutes % 60;

    dayStart = moment()
      .hour(dayStartHour)
      .minute(dayStartMinute)
      .subtract(15, "minutes");
    dayEnd = moment().hour(dayEndHour).minute(dayEndMinute).add(15, "minutes");
  } else {
    // Standard time range if truncateView is false
    dayStart = moment(startDay).hour(8).minute(0);
    dayEnd = moment(startDay).hour(20).minute(0);
  }

  // funciton called when new availability slot is dragged and created
  const handleCreateAvailability = async ({ start, end }) => {
    // Return if start and end are on different days
    if (!start.isSame(end, "day")) return;

    if (hideAvailability) return;

    // Force end time to 8 PM if it's later than that
    if (end.hour() >= 20) {
      end = end.clone().hour(20).minute(0).second(0).millisecond(0);
    }

    // Force start time to 8 AM if it's earlier than that
    if (start.hour() < 8) {
      start = start.clone().hour(8).minute(0).second(0).millisecond(0);
    }

    // Assuming 'end' is a moment object
    const startOfTheWeek = moment().tz(timeZoneId).startOf("week"); // This will be Sunday of the current week

    if (start.isBefore(startOfTheWeek)) {
      return; // End is before the start of the week
    }

    if (end.diff(start, "minutes") < 30) return;

    try {
      const updatedAvailability = {
        start: start.toDate(),
        end: end.toDate(),
      };

      if (employeeId) {
        const employeeAvailabilityDataRef = doc(
          db,
          "employees",
          employeeId,
          "public",
          "availability"
        );

        const updatedWindows = addAndMergeWindows({
          availability,
          addWindow: updatedAvailability,
        });

        await updateDoc(employeeAvailabilityDataRef, {
          windows: updatedWindows, // This line now overwrites the entire 'windows' field
        });

        console.log("Availability created successfully:", updatedAvailability);
      }
    } catch (error) {
      console.error("Error creating availability:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          // borderRadius: "15px",
          overflow: "hidden",
          pl: { xs: "35px", sm: 6 },
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          zIndex: 15,
          userSelect: "none", // Disable text selection across browsers
          WebkitTouchCallout: "none", // Disable touch callouts on iOS
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            zIndex: "10",
            overflowX: "hidden",
            pt: { xs: 0, sm: 1 },
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          {days.map((date) => (
            <DayHeader key={date.toString()} date={date} />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            position: "relative",
            zIndex: "10",
            userSelect: "none", // Disable text selection across browsers
            WebkitTouchCallout: "none", // Disable touch callouts on iOS
          }}
        >
          <TimeMarker start={dayStart} end={dayEnd} height={height} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              position: "relative",
              width: "100%",
              overflow: "hidden",
              userSelect: "none", // Disable text selection across browsers
              WebkitTouchCallout: "none", // Disable touch callouts on iOS
            }}
          >
            {days.map((date, index) => (
              <DayColumn
                key={date.toString()}
                date={date}
                visits={visits}
                employeeId={employeeId}
                timeZoneId={timeZoneId}
                start={dayStart}
                end={dayEnd}
                index={index}
                onSelectVisit={handleSelectVisit}
                height={height}
                availability={
                  hideAvailability ? [] : filterAvailabilityForDay(date)
                } // Pass the availability data unless we hide it
                onCreateAvailability={handleCreateAvailability}
                visitsExist={visitsExist}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <ResponsiveDialog
        open={openVisitDialog}
        onClose={() => setOpenVisitDialog(false)}
        title={"Visit details"}
        width="600px"
        fullHeight={true}
        desktopAnchor={"right"}
      >
        {selectedVisitId && (
          <EmployeeVisitCard
            visit={visits[selectedVisitId]}
            visitId={selectedVisitId}
            type={"visit"}
            handleDecline={() => setOpenVisitDialog(false)}
            handleAccept={() => setOpenVisitDialog(false)}
            handlePickup={() => setOpenVisitDialog(false)}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

export default ColumnView;
