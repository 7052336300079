import { Box, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import IntakeForm from "./IntakeForm";
import PricingCards from "./PricingCards";
import CredibilityGrid from "../../../components/CredibilityGrid";

const PricingSectionV2 = () => {
  const [zipCode, setZipCode] = useState("98221");

  const handleButtonClick = (value) => {
    setLeadCaptured(true);
    setZipCode(value);
  };

  const [leadCaptured, setLeadCaptured] = useState(false); //change back to false

  useEffect(() => {
    const leadCaptured = localStorage.getItem("leadCaptured");
    const zipCode = localStorage.getItem("zipCode");

    // Assuming the intention is to check if leadCaptured is "true"
    if (leadCaptured === "true" && zipCode) {
      setLeadCaptured(true); // Assuming you have a state setter function named setLeadCaptured
      setZipCode(zipCode); // Correctly updating the zipCode within the values state
    }
  }, []);

  return (
    <Box sx={{ position: "relative", width: "auto" }}>
      {/* <CredibilityGrid /> */}

      {!leadCaptured ? (
        <Fade in={!leadCaptured} timeout={500}>
          <Box>
            <IntakeForm handleClick={handleButtonClick} setLeadCaptured={setLeadCaptured} />
          </Box>
        </Fade>
      ) : (
        <Fade in={leadCaptured} timeout={500}>
          <Box sx={{ py: {xs: 4, sm: 4}, px: 1 }}>
            <PricingCards dense={true} zipCode={zipCode} />
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default PricingSectionV2;
