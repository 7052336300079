import React, { useMemo } from "react";
import { Box, Card, useMediaQuery, useTheme } from "@mui/material";
import FooterSimple from "../components/FooterSimple";

const ContentView = ({ tabs, selectedTab }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let selectedTabData = useMemo(
    () => tabs.find((t) => t.label === selectedTab),
    [tabs, selectedTab]
  );
  let color = selectedTabData?.color || "white"; // Default to white if color is not specified

  const renderContent = () => {
    const tab = tabs.find((t) => t.label === selectedTab);
    if (!tab) return <div>Select a tab from the sidebar.</div>;

    const Component = tab.component;
    return <Component />;
  };

  return (
    <Box
      sx={{
        width: "100%",
        top: "56px",
        position: { xs: "relative", sm: "inherit" },
        overflowY: "scroll", // Enable vertical scrolling
        height: isMobile ? "calc(100vh - 56px - 72px)" : "auto", // Viewport height minus the height of the bottom nav
        // Custom styles to hide scrollbar

        ...(isMobile && {
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* Internet Explorer and Edge */,
          "&::-webkit-scrollbar": {
            display: "none" /* Chrome, Safari and Opera */,
          },
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%", // takes the full height of its container
          flex: 1, // this will make it grow and take up all available space
          // overflowY: isMobile ? "scroll" : "auto",
          minHeight: isMobile ? "calc(100vh - 56px - 72px)" : "auto", // Viewport height minus the height of the bottom nav
          // Custom styles to hide scrollbar
        }}
      >
        <Card
          // variant={isMobile ? undefined : "outlined"}
          elevation={0}
          sx={{
            flex: 1,
            minHeight: isMobile ? "calc(100vh - 56px - 72px)" : "100vh",
            borderRadius: 0,

            padding: { xs: 2, sm: 4 }, // Values are in the system's spacing unit
            boxSizing: "border-box",
            backgroundColor: { xs: color, sm: color }, // or replace 'smokegray' with the specific color code you have in mind
          }}
        >
          {renderContent()}
          {!isMobile && (
            <Box
              sx={{
                position: "relative", // Positions the box absolutely within its nearest positioned ancestor
                mt: "100px", // Adds margin-top of 100px
              }}
            >
              <FooterSimple />
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default ContentView;
