import React, { useRef, useState } from "react";
import { Box, Chip } from "@mui/material";

const CollapsibleBox = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const contentRef = useRef(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const collapsedHeight = "100px";
  const expandedHeight = contentRef.current
    ? `${contentRef.current.scrollHeight}px`
    : "auto";

  return (
    <>
      <Box
        sx={{
          height: isCollapsed ? collapsedHeight : expandedHeight,
          transition: "height 0.3s ease-in-out",
          borderRadius: "15px",
          overflow: "hidden",
          bgcolor: "white",
          minWidth: { xs: "100%", sm: "400px" },
          position: "relative",
          cursor: "pointer",
          "&:hover .overlay": {
            opacity: 1,
          },
        }}
        onClick={toggleCollapse}
      >
        <div ref={contentRef}>{children}</div>
        {isCollapsed && <div className="fadeOverlay" />}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mb: 2,
          cursor: "pointer",
        }}
        onClick={toggleCollapse}
      >
        <Chip label={isCollapsed ? "Show more" : "Show less"} />
      </Box>
    </>
  );
};

export default CollapsibleBox;
