import { Box, Container, Typography } from "@mui/material";
import React from "react";

const LandingVideoSection = () => {
  return (
    <Box sx={{ py: { xs: 4, sm: 4 }, backgroundColor: "white" }}>
      <Container maxWidth="md">
        <Typography
          variant="h3"
          align="center"
          sx={{ mb: { xs: 2, sm: 4 }, fontSize: { xs: "32px", sm: "48px" } }}
        >
          Your Household Helper
        </Typography>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
            borderRadius: "30px", // Add this line to round the borders of the container
            mt: 2,
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/5r1l1J61zAo?autoplay=1&mute=1&controls=1&cc_lang_pref=en&cc_load_policy=1&loop=1&playlist=5r1l1J61zAo"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingVideoSection;
