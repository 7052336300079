import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  SwipeableDrawer,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  Card,
  DialogActions,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
  MobileDatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const AdjustTime = ({
  timeZoneId,
  onSave,
  initialStartTime,
  initialEndTime,
}) => {
  const defaultStart = moment().tz(timeZoneId).set({ hour: 10, minute: 0 });
  const defaultEnd = moment().tz(timeZoneId).set({ hour: 16, minute: 0 });

  const [selectedDate, setSelectedDate] = useState(moment().tz(timeZoneId));
  const [startTime, setStartTime] = useState(initialStartTime || defaultStart);
  const [endTime, setEndTime] = useState(initialEndTime || defaultEnd);

  useEffect(() => {
    if (initialStartTime) {
      setStartTime(initialStartTime);
    }
    if (initialEndTime) {
      setEndTime(initialEndTime);
    }
  }, [initialStartTime, initialEndTime]);

  const modifyTime = (field, action) => {
    const time = field === "start" ? startTime : endTime;
    const adjustedTime =
      action === "add"
        ? time.clone().add(15, "minutes")
        : time.clone().subtract(15, "minutes");

    if (field === "start") {
      setStartTime(adjustedTime);
    } else if (field === "end") {
      setEndTime(adjustedTime);
    }
  };

  const handleSave = () => {
    const combinedStart = selectedDate.clone().set({
      hour: startTime.hour(),
      minute: startTime.minute(),
    });

    const combinedEnd = selectedDate.clone().set({
      hour: endTime.hour(),
      minute: endTime.minute(),
    });

    onSave(combinedStart, combinedEnd);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack gap={2} direction="column" alignItems="center">
          <Typography sx={{ mb: 1 }}>
            Add availability to work. You can also click or tap and drag
            directly on the calendar.
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              renderInput={(params) => <TextField fullWidth {...params} />}
              sx={{ width: "200px" }}
            />
          </LocalizationProvider>

          {/* Start time controls */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              disabled={startTime.isSameOrBefore(
                moment(startTime).set({ hour: 8, minute: 0 })
              )}
              onClick={() => modifyTime("start", "subtract")}
            >
              <Remove />
            </IconButton>

            <Card
              elevation={0}
              sx={{
                width: "150px",
                textAlign: "center",
                backgroundColor: "#e0e0e0", // Light gray background
                borderRadius: "10px", // Rounded edges
                padding: "4px", // Padding around the text
              }}
            >
              <Typography component="span" fontWeight="600">
                Start:{" "}
              </Typography>
              <Typography component="span">
                {startTime.format("h:mm A")}
              </Typography>
            </Card>
            <IconButton
              disabled={startTime
                .clone()
                .add(15, "minutes")
                .isSameOrAfter(endTime)}
              onClick={() => modifyTime("start", "add")}
            >
              <Add />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              disabled={endTime
                .clone()
                .subtract(15, "minutes")
                .isSameOrBefore(startTime)}
              onClick={() => modifyTime("end", "subtract")}
            >
              <Remove />
            </IconButton>
            <Card
              width="100%"
              elevation={0}
              sx={{
                width: "150px",
                textAlign: "center",
                backgroundColor: "#e0e0e0", // Light gray background
                borderRadius: "10px", // Rounded edges
                padding: "4px", // Padding around the text
              }}
            >
              <Typography component="span" fontWeight="600">
                End:{" "}
              </Typography>
              <Typography component="span">
                {endTime.format("h:mm A")}
              </Typography>
            </Card>
            <IconButton
              disabled={endTime.isSameOrAfter(
                moment(endTime).set({ hour: 20, minute: 0 })
              )}
              onClick={() => modifyTime("end", "add")}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            width: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AdjustTime;
