import React, { useState, useEffect } from "react";
import MemberDashboard from "../dashboard/member/MemberDashboard";
import EmployeeDashboard from "../dashboard/employee/EmployeeDashboard";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import EmployeeProvider from "../providers/EmployeeProvider";
import MemberProvider from "../providers/MemberProvider";
import LoadingUser from "../components/LoadingUser";
import { useNavigate } from "react-router-dom";
import { Timestamp, arrayUnion, doc, increment, setDoc } from "firebase/firestore";
import { db } from "../firebase";


const DashboardPage = () => {
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();


  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Get the ID token result and update userClaims state
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log("idTokenResult: ", idTokenResult);
            console.log("idTokenResult.claims: ", idTokenResult.claims);

            setUserClaims(idTokenResult.claims);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error retrieving token claims: ", error);
            setLoading(false);
          });
      } else {
        // User is not signed in
        setLoading(false);
      }
    });

    const logPage = async (pageTitle) => {
      // Get the current timestamp
      const timestamp = Timestamp.now();

      // Create a log entry
      const logEntry = {
        timestamp: timestamp,
        pageTitle: pageTitle,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              lastVisited: new Date(),
              "~logs": arrayUnion(logEntry),
              totalPageViews: increment(1) // Increment totalPageViews by 1
            },
            { merge: true }
          );
        } catch (err) {
          console.log("error setting initial document: ", err);
        }
      }
    };

    logPage("Dashboard");

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle sign out errors here, such as showing a notification to the user
    }
  };

  // Render components based on user claims and loading state
  if (loading) {
    return <LoadingUser />;
  } else if (userClaims) {
    if (userClaims.role === "employee") {
      return (
        <EmployeeProvider>
          <EmployeeDashboard />
        </EmployeeProvider>
      );
    } else if (userClaims.role === "member") {
      return (
        <MemberProvider>
          <MemberDashboard />
        </MemberProvider>
      );
    } else {
      handleSignOut();
    }
  } else {
    navigate("/login");
  }
};

export default DashboardPage;
