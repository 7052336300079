import { Check, Close, PlayArrowRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import "./WheelSpinner.css"; // Ensure to create and import the corresponding CSS file for styles
import CopyUrlButton from "./buttons/CopyUrlButton";
import PhoneField from "./fields/PhoneField";

const wheelSegments = [
  {
    label: "10% off",
    bgColor: "hsl(190,100%,30%)",
    fontColor: "white",
    phrase: "You've unlocked 10% off your visit!",
  },

  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "5% off",
    bgColor: "hsl(190,100%,20%)",
    fontColor: "white",
    phrase: "You've unlocked 5% off your visit!",
  },

  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "1-hour free",
    bgColor: "hsl(190,100%,50%)",
    fontColor: "black",
    phrase: "You've unlocked 1-hour free!",
  },
  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "$20 off",
    bgColor: "hsl(150,100%,45%)",
    fontColor: "black",
    phrase: "You've unlocked $20 off your visit!",
  },

  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "$10 off",
    bgColor: "hsl(150,100%,30%)",
    fontColor: "white",
    phrase: "You've unlocked $10 off your visit!",
  },

  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "5% off",
    bgColor: "hsl(190,100%,20%)",
    fontColor: "white",
    phrase: "You've unlocked 5% off your visit!",
  },

  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "$20 off",
    bgColor: "hsl(150,100%,45%)",
    fontColor: "black",
    phrase: "You've unlocked $20 off your visit!",
  },
  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
  {
    label: "$5 off",
    bgColor: "hsl(150,100%,20%)",
    fontColor: "white",
    phrase: "You've unlocked $5 off your visit!",
  },
  {
    label: "Try again",
    bgColor: "rgb(230, 251, 255)",
    fontColor: "black",
    phrase: "No deal this time, try later",
  },
];

const WheelSpinner = ({
  repeat = 1,
  SQUARE_HEIGHT = 500,
  SHOW_WIDTH = "100%",
  isDialog = true,
  handleSave = () => {},
  handleClose = () => {},
  fullScreen = false,
}) => {
  const wheelRef = useRef(null);
  const demoRef = useRef(true); // Use ref to store the interval
  const rotationRef = useRef(0);
  const velocityRef = useRef(1.2);
  const spinIntervalRef = useRef(null); // Use ref to store the interval
  const [open, setOpen] = useState(true);
  const [discount, setDiscount] = useState(null);
  const [win, setWin] = useState(null);
  const [arrowRotation, setArrowRotation] = useState(180);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const valuesRef = useRef({
    name: "",
    phone: "",
    zipCode: "",
    message: "",
    timeOfDay: "afternoon",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);

  const [leadCaptured, setLeadCaptured] = useState(
    localStorage.getItem("leadCaptured") === "true"
  );

  const validateStep = () => {
    let tempErrors = {};
    // if (!valuesRef.current.name) tempErrors.name = "Please enter your name";

    if (
      !valuesRef.current.phone ||
      valuesRef.current.phone.replace(/\D/g, "").length !== 10
    )
      tempErrors.phone = "Discount stored under your number, enter to spin.";

    // Add validation for zipCode not to exceed 5 digits
    // if (!valuesRef.current.zipCode || valuesRef.current.zipCode.length < 5)
    //   tempErrors.zipCode = "Please enter your zip code.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the ref
    valuesRef.current[name] = value;

    // If you need the component to re-render on change, force an update
    // forceUpdate((prev) => !prev);

    // Remove errors for the field
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  // Decompose the input map into individual arrays
  const repeatedMap = Array(repeat).fill(wheelSegments).flat();

  const labels = repeatedMap.map((item) => item.label);
  const bgColors = repeatedMap.map((item) => item.bgColor);
  const fontColors = repeatedMap.map((item) => item.fontColor);
  const phrases = repeatedMap.map((item) => item.phrase);

  useEffect(() => {
    const svg = d3.select(wheelRef.current);
    const width = isMobile ? 500 : SQUARE_HEIGHT;
    const height = isMobile ? 500 : SQUARE_HEIGHT;
    const radius = Math.min(width, height) / 2;

    // Configure pie layout
    const pie = d3
      .pie()
      .value(() => 1)
      .startAngle(Math.PI / 2) // 90 degrees in radians
      .endAngle((-3 * Math.PI) / 2); // 270 degrees in radians

    const arc = d3.arc().outerRadius(radius).innerRadius(0);

    // Clear previous content
    // svg.selectAll("*").remove();

    const arcs = svg
      .selectAll("g.slice")
      .data(pie(labels.map((label) => ({ label, value: 1 }))))
      .enter()
      .append("g")
      .attr("class", "slice")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    arcs
      .append("path")
      // .attr("fill", (d, i) => color(i))
      .attr("fill", (d, i) => bgColors[i]) // Use colors array to set the color
      .attr("d", arc);

    arcs
      .append("text")
      .attr("transform", (d) => {
        d.innerRadius = 0;
        d.outerRadius = radius;
        d.angle = (d.startAngle + d.endAngle) / 2;
        return `rotate(${(d.angle * 180) / Math.PI - 90})translate(${
          d.outerRadius - 20
        })`;
      })
      .attr("text-anchor", "end")
      .attr("font-size", "16px")
      .attr("font-weight", "600")
      .attr("fill", "#fff")
      .attr("fill", (d, i) => fontColors[i]) // Use colors array to set the color
      .text((d) => d.data.label);

    // Append small circles (tickers) at the intersection of segments and the rim of the circle
    arcs
      .append("circle")
      .attr("cx", (d) => arc.centroid(d)[0] * 1) // Scale the centroid slightly outward
      .attr("cy", (d) => arc.centroid(d)[1] * 1) // Scale the centroid slightly outward
      .attr("r", isMobile ? 5 : 12) // Radius of the small circle
      .attr("fill", (d, i) => bgColors[(i + 1) % bgColors.length]);
  }, [open, labels, bgColors, fontColors]);

  useEffect(() => {
    const wheelDiscount = localStorage.getItem("wheelDiscount");
    const lastWheelSpinTimestamp = localStorage.getItem(
      "lastWheelSpinTimestamp"
    );
    const gameWin = JSON.parse(localStorage.getItem("gameWin"));

    if (gameWin === true) {
      setWin(true);
      setDiscount(wheelDiscount);
    } else if (gameWin === false && lastWheelSpinTimestamp) {
      console.log("false and timestamp");
      const timeElapsed = new Date().getTime() - lastWheelSpinTimestamp;
      const hoursElapsed = timeElapsed / (1000 * 60 * 60); // Convert ms to hours

      if (hoursElapsed < 24) {
        console.log("false and timestamp and within 24 hours");

        startCountdown(24 * 60 * 60 * 1000 - timeElapsed); // Start countdown with the remaining time
        setWin(false);
        setDiscount(wheelDiscount);
      } else {
        console.log("timer expired");

        // Timer expired, allow them to play again
        localStorage.removeItem("lastWheelSpinTimestamp");
        localStorage.removeItem("gameWin");
        localStorage.removeItem("wheelDiscount");
        setWin(null);
        setDiscount(null);
      }
    }

    // Start the animation
    springSpin();

    // Start our auto stimulation
    startAutoSpin();

    // Cleanup function to stop automatic spinning when component unmounts
    return () => {
      stopAutoSpin();
    };
  }, []);

  useEffect(() => {
    const lastWheelSpinTimestamp = localStorage.getItem(
      "lastWheelSpinTimestamp"
    );

    if (win === false && lastWheelSpinTimestamp) {
      const timeElapsed = new Date().getTime() - lastWheelSpinTimestamp;
      const hoursElapsed = timeElapsed / (1000 * 60 * 60); // Convert ms to hours

      if (hoursElapsed < 24) {
        startCountdown(24 * 60 * 60 * 1000 - timeElapsed); // Start countdown with the remaining time
      } else {
        // Timer expired, allow them to play again
        localStorage.removeItem("loseTimestamp");
        localStorage.removeItem("gameWin");
        setWin(null);
        setDiscount(null);
      }
    }
  }, [win, discount]);

  // Stimulate the animation with a spike in velocity every 3.5 seconds
  const startAutoSpin = () => {
    // Define the interval for spinning
    spinIntervalRef.current = setInterval(() => {
      velocityRef.current = 1.5;
    }, 3500); // Spin every 4 seconds
  };

  // Clear the interval so that the velocity is no longer stimulated
  const stopAutoSpin = () => {
    if (spinIntervalRef.current) {
      clearInterval(spinIntervalRef.current);
      spinIntervalRef.current = null; // Clear the ref after stopping
    }
  };

  // Run the animation
  const springSpin = (
    drag = 0.99, // Drag to slow the spin gradually
    damping = 0.96, // Damping for the spring
    repulsorStrength = 0.01 // Strength of the repulsion near boundaries
  ) => {
    let rotation = rotationRef.current;
    let previousVelocity = velocityRef.current;
    let previousAcceleration = 0;

    const velocityThreshold = 0.018; // Velocity below which we consider the system to be slow
    const accelerationThreshold = 0.0093; // Acceleration below which we consider the system to be at rest

    // Animate is called recursively until there's a win. Then it will cease to call itself again, becoming unresponsive.
    const animate = () => {
      let velocity = velocityRef.current;

      // Normalize the rotation between 0 and 360 degrees
      const normalizedRotation = rotation % 360;
      const segmentSize = 360 / labels.length;
      const segmentIndex = Math.floor(normalizedRotation / segmentSize);
      const segmentCenter = (segmentIndex + 0.5) * segmentSize;

      // Calculate displacement from the nearest segment center
      let displacementToCenter = normalizedRotation - segmentCenter;

      const deadzoneThreshold = 3; // Define the deadzone threshold

      // Apply the deadzone logic
      let deadzoneDisplacement =
        Math.abs(displacementToCenter) < deadzoneThreshold
          ? 0
          : displacementToCenter -
            Math.sign(displacementToCenter) * deadzoneThreshold;

      if (displacementToCenter < 5 && Math.abs(velocity) < 0.5) {
        const repulsor = repulsorStrength * -Math.sign(displacementToCenter);
        velocity += repulsor; // * Math.max(Math.abs(velocity), 1);
        velocity *= damping; // Damping for smoother stop
      } else {
        velocity *= drag; // Slow down spin using drag
      }

      setArrowRotation(
        180 -
          Math.abs(deadzoneDisplacement) *
            (Math.abs(velocity) > 0.03
              ? 5
              : Math.sign(normalizedRotation - segmentCenter) * 5)
      );

      // Update rotation based on velocity
      rotation += velocity;

      // Calculate acceleration (change in velocity)
      let acceleration =
        (velocity - previousVelocity + previousAcceleration) / 2;
      previousVelocity = velocity;
      previousAcceleration = acceleration;

      // Apply the rotation to the wheel (using D3 or similar)
      // d3.select(wheelRef.current).attr("transform", `rotate(${rotation})`);

      d3.select(wheelRef.current).style("transform", `rotate(${rotation}deg)`);

      // Update rotation ref
      rotationRef.current = rotation;
      velocityRef.current = velocity;

      // Check if the system is at rest (low velocity and low acceleration)
      if (
        Math.abs(velocity) < velocityThreshold &&
        Math.abs(acceleration) < accelerationThreshold &&
        !demoRef.current &&
        valuesRef.current.phone !== "(555) 555-5555"
      ) {
        // Final alignment to the nearest segment center
        const finalRotation = rotation % 360;
        const pickedSegment = Math.floor(finalRotation / segmentSize);

        // Set the picked result
        setDiscount(phrases[pickedSegment]);
        localStorage.setItem("wheelDiscount", phrases[pickedSegment]);

        // Get the current timestamp and store locally
        const now = new Date().getTime();
        localStorage.setItem("lastWheelSpinTimestamp", now);

        console.log("valuesRef.current: ", valuesRef.current);

        // Update valuesRef.current.discount with the discount value
        const updatedValues = {
          ...valuesRef.current,
          discount: phrases[pickedSegment],
        };

        // Callback to log to firebase

        handleSave(updatedValues);

        // Determine if it was a winning segment
        if (labels[pickedSegment] === "Try again") {
          setWin(false);
          localStorage.setItem("gameWin", false);

          // requestAnimationFrame(animate); // comment out to allow for re-spin on fail
        } else {
          localStorage.setItem("gameWin", true);
          setWin(true);
        }

        setLoading(false);
      } else {
        // Continue animation if not at rest
        requestAnimationFrame(animate);
        if (valuesRef.current.phone === "(555) 555-5555") setLoading(false);
      }
    };

    // Start the animation loop
    requestAnimationFrame(animate);
  };

  const startCountdown = (duration = 24 * 60 * 60 * 1000) => {
    const endTime = new Date().getTime() + duration;
    const interval = setInterval(() => {
      const timeRemaining = endTime - new Date().getTime();
      if (timeRemaining <= 0) {
        clearInterval(interval);
        setTimeLeft(null);
        // localStorage.removeItem('loseTimestamp');
        // localStorage.removeItem('gameWin');
        // Optionally allow the user to try again
      } else {
        // Convert milliseconds to seconds
        setTimeLeft(Math.floor(timeRemaining / 1000));
      }
    }, 1000);
  };

  // Usage of the spinner function
  const handleSpin = (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setLoading(true);

    stopAutoSpin(); // Stop automatic spinning
    demoRef.current = false; // Now its the actual spin and will settle on an outcome
    velocityRef.current = Math.random() * 10 + 10;
  };

  const renderRules = () => {
    return (
      <List dense={true}>
        {[
          "Discount applied on your first visit",
          "Stored under your phone number",
          "Spin every day until you win!",
        ].map((benefit, idx) => (
          <ListItem key={idx} disableGutters>
            <Check color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1">{benefit}</Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const formatTime = (timeInSeconds) => {
    if (timeInSeconds <= 0) {
      return { hours: null, minutes: null, seconds: null };
    }

    const hours = Math.floor(timeInSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");

    return { hours, minutes, seconds };
  };

  const TimeDisplay = ({ timeLeft }) => {
    const {
      hours = null,
      minutes = null,
      seconds = null,
    } = formatTime(timeLeft);

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex">
          {renderTimeSegment(hours, "Hours")}
          <Typography
            variant="h4"
            color={"primary"}
            component="span"
            sx={{ mx: 1, mt: 0.5 }}
          >
            :
          </Typography>
          {renderTimeSegment(minutes, "Minutes")}
          <Typography
            variant="h4"
            color={"primary"}
            component="span"
            sx={{ mx: 1, mt: 0.5 }}
          >
            :
          </Typography>
          {renderTimeSegment(seconds, "Seconds")}
        </Box>
      </Box>
    );
  };

  const renderTimeSegment = (value, label) => (
    <Box
      sx={{
        width: "60px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" component="div" color={"primary"}>
        {value ? (
          value
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "2px",
              height: "100%",
            }}
          >
            <Skeleton width={"20px"} />
            <Skeleton width={"20px"} />
          </Box>
        )}
      </Typography>
      <Typography variant="caption" color={"primary"} component="div">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        height: fullScreen
          ? isMobile
            ? "100%"
            : "100vh"
          : "calc(100dvh - 48px)",
        overflow: "hidden",
        overflowY: { xs: "auto", sm: "hidden" },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: { xs: 1, sm: 2 },
        alignItems: "center",
      }}
    >
      {/* Close button */}
      <Button
        color="inherit"
        onClick={(event) => handleClose(event, "buttonClick")} // Pass a custom reason to handleClose
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          zIndex: 10, // or higher if needed
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
          borderRadius: "8px",
          textTransform: "none",
        }}
      >
        <Close />
        Close
      </Button>

      {/* Wheel left side */}
      <Box
        sx={{
          maxWidth: { xs: "200px", sm: fullScreen ? "25vw" : SHOW_WIDTH },
          maxHeight: { xs: "200px", sm: "unset" },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="container text-center mb-5 mt-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <Box
                sx={{
                  mt: isMobile ? "-150px" : 0,

                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  overflow: "visible",
                }}
              >
                <svg
                  ref={wheelRef}
                  width={isMobile ? 500 : SQUARE_HEIGHT}
                  height={isMobile ? 500 : SQUARE_HEIGHT}
                ></svg>
                <PlayArrowRounded
                  color={"primary"}
                  sx={{
                    transform: `rotate(${arrowRotation}deg)`,
                    fontSize: "96px",
                    my: "auto",
                    ml: "-20px",
                    boxSizing: "border-box",
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
      </Box>

      {/* Form right side */}
      <Box
        sx={{
          flex: 1,
          my: { xs: 2, sm: 10 },
          mr: { xs: 2, sm: 10 },
          pl: { xs: 2, sm: 0 },
          background: "white",
          height: { xs: "100%", sm: "unset" },
          maxWidth: "600px",
          zIndex: 1,
          boxShadow: "0 0 20px 25px rgb(255 255 255)",
        }}
      >
        {!discount ? (
          <>
            <Typography variant="h3" fontSize={isMobile ? "38px" : "48px"}>
              Ready?
            </Typography>
            <Typography variant="h5" fontSize={isMobile ? "20px" : "24px"}>
              Unlock your special bonus
            </Typography>
            <Typography variant="body1" sx={{ mb: { xs: 1, sm: 0 } }}>
              You have a chance to win an amazing discount for your first
              household help visit. Are you feeling lucky? Give it a spin!
            </Typography>
            {!isMobile && renderRules()}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column" },
                gap: 1,
              }}
            >
              <TextField
                name="name" // Add this line
                id="name"
                // label="Name (optional)"
                placeholder="Your name (optional)"
                type="text"
                fullWidth
                variant="outlined"
                disabled={loading}
                value={valuesRef.current.name}
                onChange={handleChange}
                error={!!errors.name} // Show error state if there's an error for zipCode
                helperText={errors.name} // Display the actual error message
              />
              <PhoneField
                phone={valuesRef.current.phone}
                error={errors.phone}
                label={null}
                disabled={loading}
                placeholder={"Your phone number"}
                handleChange={handleChange}
              />
              <Button
                variant="contained"
                sx={{
                  height: "60px",
                  textTransform: "none",
                  width: "100%",
                  overflow: "hidden", // Ensures the effect stays within the button boundaries
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: "-150%", // Start off the button
                    width: "100%", // Make it wide enough to cover the button
                    height: "100%",
                    background:
                      "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
                    transform: "skewX(-20deg)", // Slight skew to angle the wipe
                    animation: "wipe 3.5s infinite", // Smooth, infinite animation
                  },
                  "@keyframes wipe": {
                    "0%": { left: "-200%" },
                    "100%": { left: "200%" }, // Move it fully across
                  },
                }}
                onClick={handleSpin}
                disabled={loading}
              >
                Spin the Wheel
              </Button>
              {isMobile && renderRules()}
            </Box>
            {/* <Button variant="contained" onClick={spin}>
                Spin the Wheel
              </Button> */}
          </>
        ) : win ? (
          <>
            <Typography variant="h3" fontSize={isMobile ? "38px" : "48px"}>
              Hurray!
            </Typography>
            <Typography variant="h5" fontSize={isMobile ? "20px" : "24px"}>
              {discount}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Your discount is associated with your phone number. When you sign
              up, this discount will be applied to your account.
            </Typography>
            <CopyUrlButton
              url={"www.ourlinkedlives.com/spin-to-win"}
              extraText="and share with a friend"
            />
            {!isMobile && renderRules()}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mt: { xs: 2, sm: 2 },
              }}
            >
              {isDialog && (
                <Button
                  variant="contained"
                  onClick={(event) => handleClose(event, "buttonClick")}
                  sx={{ width: "100%", height: "60px", textTransform: "none" }}
                >
                  Close window
                </Button>
              )}
              <Button
                variant="text"
                sx={{
                  width: "100%",
                  height: "60px",
                  textTransform: "none",
                  textDecoration: "underline",
                }}
              >
                Continue to sign up
              </Button>
            </Box>
            {isMobile && renderRules()}
          </>
        ) : (
          <>
            <Typography variant="h3" fontSize={isMobile ? "38px" : "48px"}>
              So close!
            </Typography>
            <Typography variant="h5" fontSize={isMobile ? "20px" : "24px"}>
              {discount}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Use this link below to invite a friend to play. You'll be able to
              spin again once the timer ends. Check back soon!
            </Typography>
            <CopyUrlButton url={"www.ourlinkedlives.com/spin-to-win"} />
            {TimeDisplay({ timeLeft })}

            {renderRules()}
            {isDialog && (
              <Button
                variant="contained"
                onClick={(event) => handleClose(event, "buttonClick")}
                sx={{ width: "100%", height: "60px", textTransform: "none" }}
              >
                Close window
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default WheelSpinner;
