import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";
import {
  List,
  ListItem,
  ListItemText,
  InputBase,
  ListItemIcon,
  Paper,
  Chip,
  Card,
  CardContent,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";

function TaskList() {
  const [tasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("All");
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  console.log(isMobile);

  useEffect(() => {
    const fetchTasks = async () => {
      // Check if tasks are stored in localStorage and not stale
      const storedTasks = localStorage.getItem("tasks");
      const lastFetchTimestamp = localStorage.getItem("lastFetchTimestamp");
      const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000; // milliseconds in a week
      const now = new Date().getTime();

      if (
        storedTasks &&
        lastFetchTimestamp &&
        now - lastFetchTimestamp < oneWeekInMillis
      ) {
        setTasks(JSON.parse(storedTasks));
        console.log("don't fetch since the tasks are still fresh");
        return; // don't fetch if the tasks are still fresh
      }

      const tasksDocRef = doc(db, "public", "tasks");
      const tasksSnapshot = await getDoc(tasksDocRef);

      if (tasksSnapshot.exists()) {
        const fetchedTasks = tasksSnapshot.data().taskList;
        setTasks(fetchedTasks);
        localStorage.setItem("tasks", JSON.stringify(fetchedTasks));
        localStorage.setItem("lastFetchTimestamp", now.toString()); // store the timestamp of the fetch
        console.log("store the timestamp of the fetch");
      }
    };

    fetchTasks();
  }, []);

  // Getting unique categories from the tasks
  const uniqueCategories = Array.from(
    new Set(tasks.map((task) => task.category))
  ).sort();

  const filteredTasks = tasks
    .filter((task) => {
      if (filterCategory !== "All") {
        return task.category === filterCategory;
      }
      return true;
    })
    .filter((task) => {
      try {
        return task.task.toLowerCase().includes(searchTerm.toLowerCase());
      } catch (err) {
        return false;
      }
    });

  const trueTasks = filteredTasks.filter((task) => task.allowed === "TRUE");
  const falseTasks = filteredTasks.filter((task) => task.allowed !== "TRUE");

  console.log("-------");
  console.log(uniqueCategories);
  console.log("-------");

  function highlightText(text, searchTerm) {
    if (!searchTerm) return [text];

    const index = text.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (index === -1) return [text];

    const before = text.slice(0, index);
    const match = text.slice(index, index + searchTerm.length);
    const after = text.slice(index + searchTerm.length);

    return [before, match, after];
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        // height: "100vh",
        width: "100%",
        minWidth: { xs: "300px", sm: "500px" },
      }}
    >
      <Card
        style={{
          transition: "all 0.3s ease", // This will apply a transition to all property changes
          height: "450px",
          overflow: "hidden",
          backgroundColor: "white",
          maxWidth: "800px",
          margin: "16px",
          width: "100%",
        }}
        elevation={20}
      >
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr 1fr",
            gap: "8px",
            overflowX: "auto",
            width: "100%",
            whiteSpace: "nowrap",
            paddingLeft: "16px",
            paddingTop: "16px",
          }}
        >
          <Box display="flex" gap={1}>
            <Chip
              label="All"
              clickable
              color={filterCategory === "All" ? "primary" : "default"}
              onClick={() => setFilterCategory("All")}
            />
            {uniqueCategories
              .slice(0, uniqueCategories.length / 2)
              .map((category) => (
                <Chip
                  key={category}
                  label={category}
                  clickable
                  color={filterCategory === category ? "primary" : "default"}
                  onClick={() => {
                    setFilterCategory(category);
                    setSearchTerm(""); // Clear the search term
                  }}
                />
              ))}
          </Box>
          <Box display="flex" gap={1}>
            {uniqueCategories
              .slice(uniqueCategories.length / 2)
              .map((category) => (
                <Chip
                  key={category}
                  label={category}
                  clickable
                  color={filterCategory === category ? "primary" : "default"}
                  onClick={() => setFilterCategory(category)}
                />
              ))}
          </Box>
        </div>
        <CardContent
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            paddingBottom: "8px",
          }}
        >
          <Paper
            component="form"
            elevation={0}
            style={{
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "1px solid", // This creates the outline
              borderColor: theme.palette.divider, // Assuming you're using MUI's theme, this will use the default border color from the theme
            }}
          >
            <IconButton>
              <SearchIcon />
            </IconButton>
            <InputBase
              placeholder="Search tasks…"
              // fullWidth
              value={searchTerm} // Bind the value prop to the searchTerm state
              onChange={(event) => {
                setSearchTerm(event.target.value);
                setFilterCategory("All"); // reset the category filter to 'All' whenever something is typed
              }}
              style={{ marginLeft: "10px", flex: 1 }}
            />
            {searchTerm && (
              <IconButton
                onClick={() => setSearchTerm("")} // Clear the search field
              >
                <Close /> {/* Use Material-UI's Cancel icon */}
              </IconButton>
            )}
          </Paper>
        </CardContent>

        <CardContent
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            paddingTop: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap", // This allows the flex items to wrap to the next line on smaller screens
            }}
          >
            {isMobile ? (
              <div
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <List style={{ paddingBottom: "40px" }}>
                  {filteredTasks.map((task, index) => (
                    <ListItem
                      key={task.id} // Use the unique task id instead of index
                      style={{ paddingTop: "4px", paddingBottom: "4px" }}
                    >
                      <ListItemIcon>
                        {task.allowed === "TRUE" ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            {highlightText(task.task, searchTerm).map(
                              (segment, idx) => {
                                if (
                                  segment.toLowerCase() ===
                                  searchTerm.toLowerCase()
                                ) {
                                  return <strong key={idx}>{segment}</strong>;
                                }
                                return segment;
                              }
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    width: "calc(50% - 8px)", // minus 8px to account for the gap
                    marginRight: "8px", // half the gap
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  <List style={{ paddingBottom: "40px" }}>
                    {trueTasks.map((task, index) => (
                      <ListItem
                        key={index}
                        style={{ paddingTop: "4px", paddingBottom: "4px" }}
                      >
                        <ListItemIcon>
                          <CheckCircleIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              {highlightText(task.task, searchTerm).map(
                                (segment, idx) => {
                                  if (
                                    segment.toLowerCase() ===
                                    searchTerm.toLowerCase()
                                  ) {
                                    return <strong key={idx}>{segment}</strong>;
                                  }
                                  return segment;
                                }
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div
                  style={{
                    width: "calc(50% - 8px)", // minus 8px to account for the gap
                    marginLeft: "8px", // half the gap
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  <List style={{ paddingBottom: "40px" }}>
                    {falseTasks.map((task, index) => (
                      <ListItem
                        key={index}
                        style={{ paddingTop: "4px", paddingBottom: "4px" }}
                      >
                        <ListItemIcon>
                          <CancelIcon color="error" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              {highlightText(task.task, searchTerm).map(
                                (segment, idx) => {
                                  if (
                                    segment.toLowerCase() ===
                                    searchTerm.toLowerCase()
                                  ) {
                                    return <strong key={idx}>{segment}</strong>;
                                  }
                                  return segment;
                                }
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TaskList;
