import React, { useState } from "react";
import {
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import your Firebase configuration

const ChangeEmployeeStatus = ({
  currentStatus,
  employeeId,
  handleClose = () => {},
}) => {
  const [status, setStatus] = useState(currentStatus);
  const [loading, setLoading] = useState(false);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Reference to the employee document in Firestore
      const employeeRef = doc(db, "employees", employeeId);

      // Update the status in Firestore
      await updateDoc(employeeRef, { status: status });

      handleClose(status);
    } catch (error) {
      console.error("Error updating employee status: ", error);
      // Handle any errors here, such as updating component state or displaying a message
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="employee-status-label">Employee Status</InputLabel>
          <Select
            labelId="employee-status-label"
            value={status}
            label="Employee Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="applicant">Applicant</MenuItem>
            <MenuItem value="interviewing">Interviewing</MenuItem>
            <MenuItem value="paperwork">Paperwork</MenuItem>
            <MenuItem value="trainee">Trainee</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="fired">Fired</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ChangeEmployeeStatus;
