import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { CardMedia, IconButton, MobileStepper } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import SwipeableViews from "react-swipeable-views";

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

const PostMedia = ({ mediaUrls, roundCorners = false }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [mediaHeights, setMediaHeights] = React.useState([]);
  const mediaRefs = useRef([]); // Create a ref object for media elements
  const containerRef = useRef(); // Ref for the container

  const initialHeight = 200; // Example height in pixels

  useEffect(() => {
    // Initialize mediaHeights with initialHeight for each media item
    setMediaHeights(Array(mediaUrls.length).fill(initialHeight));
  }, [mediaUrls.length]);

  useEffect(() => {
    // Preload images when the component mounts
    mediaUrls.forEach(preloadImage);
  }, [mediaUrls]);

  useEffect(() => {
    // Pause all videos except the active one
    mediaRefs.current.forEach((video, index) => {
      if (index !== activeStep && video && video.pause) {
        video.pause();
      }
    });
  }, [activeStep]); // Depend on activeStep

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { top, bottom } = containerRef.current.getBoundingClientRect();
      const isOffScreen = bottom < 0 || top > window.innerHeight;

      // Pause all videos if container is off-screen
      if (isOffScreen) {
        mediaRefs.current.forEach((video) => {
          if (video && video.pause) {
            video.pause();
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const maxSteps = mediaUrls?.length;

  const handleMediaLoad = useCallback((index, height) => {
    setMediaHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[index] = height;
      return newHeights;
    });
  }, []);

  if (!mediaUrls || mediaUrls.length === 0) return null;

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, maxSteps - 1)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const renderMedia = (url, index) => {
    // Split the URL at the question mark
    const [path] = url.split("?");
    // Extract the file extension from the path
    const fileExtension = path.split(".").pop().toLowerCase();

    const isVideo = fileExtension === "mp4" || fileExtension === "webm";

    // console.log("fileExtension: ", fileExtension);

    // Use window.innerWidth for the viewport width and set a max width of 600px
    const containerWidth = Math.min(window.innerWidth, 600);
    const maxHeight = (containerWidth * 5) / 4;

    const mediaStyle = {
      width: "100%",
      maxHeight: maxHeight,
      objectFit: "cover",
      overflow: "hidden", // Prevent any kind of scrolling within the container
    };

    const containerStyle = {
      position: "relative",
      height: mediaHeights[activeStep] || "auto", // Set height based on the active media
      transition: "height 0.5s ease", // Smooth transition for height change
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      overflow: "hidden", // Prevent any kind of scrolling within the container
    };

    return (
      <div ref={containerRef} style={containerStyle} key={index}>
        {isVideo ? (
          <video
            ref={(el) => (mediaRefs.current[index] = el)} // Assign ref
            controls
            style={mediaStyle}
            onLoadedMetadata={(e) =>
              handleMediaLoad(index, e.target.clientHeight)
            }
            src={url}
            type={`video/${fileExtension}`}
          />
        ) : (
          <CardMedia
            component="img"
            image={url}
            alt={`Post media ${index}`}
            style={mediaStyle}
            onLoad={(e) => {
              handleMediaLoad(index, e.target.clientHeight);
            }}
          />
        )}
      </div>
    );
  };

  const containerStyle = {
    position: "relative",
    height: mediaHeights[activeStep] || "auto", // Default to auto if the height is not yet known
    borderRadius: roundCorners ? "15px" : "0px",
    overflow: "hidden",
  };

  return (
    <div ref={containerRef} style={containerStyle}>
      <SwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={(step) => setActiveStep(step)}
        height={"100%"}
      >
        {mediaUrls.map((url, index) => renderMedia(url, index))}
      </SwipeableViews>
      {maxSteps > 1 && (
        <>
          <IconButton
            sx={{
              position: "absolute",
              left: 0,
              m: "2px",
              top: "50%",
              transform: "translateY(-50%)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)", // 100% alpha on hover
              },
              backgroundColor:
                activeStep === 0
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(255, 255, 255, 0.7)", // Fainter background when disabled              borderRadius: "50%", // Circular shape
            }}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              m: "2px",

              top: "50%",
              transform: "translateY(-50%)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)", // 100% alpha on hover
              },
              backgroundColor:
                activeStep === maxSteps - 1
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(255, 255, 255, 0.7)", // Fainter background when disabled
              borderRadius: "50%", // Circular shape
            }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </IconButton>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<span />} // Empty spans for buttons as they are not needed
            backButton={<span />}
            style={{
              backgroundColor: "transparent", // Transparent background
              justifyContent: "center", // Center the dots
            }}
          />
        </>
      )}
    </div>
  );
};

export default PostMedia;
