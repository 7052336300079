import React, { useState } from 'react';
import { Dialog, MobileStepper, Slide, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
    Menu,
    Today,
    LiveHelp,
    AccountCircle,
    Inbox,
    Add,
  } from "@mui/icons-material";

const tutorialSteps = [
  { label: 'Welcome to the Dashboard', content: 'Introductory text goes here.' },
  { label: 'Understanding Visits', content: 'Description about visits.' },
  { label: 'Setting Up Your Profile', content: 'Profile-related information.' },
  // Add as many steps as needed
];

const NewEmployeeWelcome = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Slide direction="right" in={true}>
        <div>
          <div>{tutorialSteps[activeStep].content}</div>
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={
              <IconButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                <KeyboardArrowRight />
              </IconButton>
            }
            backButton={
              <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </IconButton>
            }
          />
        </div>
      </Slide>
    </Dialog>
  );
};

export default NewEmployeeWelcome;
