import React, { useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  useMediaQuery,
  GridItem,
  Box,
  Button,
} from "@mui/material";
import garagePhoto from "../../../assets/senior-help-organize-garage.jpeg";
import satisfactionBadge from "../../../assets/senior-help-satisfaction-guarantee.png";
import ImageTextSplit from "../../../components/ImageTextSplit";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme

function SatisfactionGuarantee() {
  const items = {
    imageName: garagePhoto,
    imageSide: "right",
    header: "Step 1: Reach Out to Us",
    subText:
      "We’d love to hear from you by phone, text, email, or website inquiry. We’ll schedule a meeting to make sure that our senior helpers will be a good fit.",
    buttonText: "Reach out",
    buttonDestination: "/member/signup",
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageComponent = (
    <Box
      component="img"
      src={items.imageName}
      alt={items.header}
      sx={{
        // width: "100%",
        maxWidth: "100%", // Ensure the image doesn't overflow

        // height: "100%",
        objectFit: "cover",
      }}
    />
  );

  const textComponent = (
    <Box
      m={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // height: "100%",
      }}
    >
      <Typography
        component="h2" // This changes the underlying element to an h1
        variant="h4"
        fontWeight="700"
        gutterBottom
      >
        Love Linked Lives{" "}
        <span
          style={{ color: theme.palette.primary.vibrant, fontStyle: "italic" }}
        >
          or your Money Back
        </span>
      </Typography>
      <Typography variant="body1" mb={4}>
        If you aren't satisfied with our senior help service after your first
        visit, you won't have to pay a cent
        <span
          style={{
            color: theme.palette.primary.vibrant,
            fontStyle: "italic",
            fontWeight: "800",
          }}
        >
          {" "}
          - it's totally free.
        </span>
        That is our satisfaction guarantee.
        {/* <Link
          to="/satisfaction-guarantee"
          style={{
            textDecoration: "underline",
            color: theme.palette.grayscale.dark,
          }}
        >
          satisfaction guarantee
        </Link> */}
      </Typography>
      <Link to={items.buttonDestination} style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: 150, textTransform: "none" }}
        >
          Get started
        </Button>
      </Link>
    </Box>
  );

  const orderBasedOnSide =
    items.imageSide === "left"
      ? ["row-reverse", "column"]
      : ["row-reverse", "column"];

  return (
    <Grid
      container
      direction={isMobile ? orderBasedOnSide[1] : orderBasedOnSide[0]}
      sx={{ display: "flex" }}
      bgcolor={"white"}
    >
      <Grid item xs={2} md={6} sx={{ display: "flex" }}>
        {imageComponent}
      </Grid>
      <Grid item xs={6} md={6} sx={{ display: "flex" }}>
        {textComponent}
      </Grid>
    </Grid>
  );
}

export default SatisfactionGuarantee;
