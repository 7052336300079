import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(2), // Adds 16px bottom padding

  "& .MuiBottomNavigationAction-root": {
    transition: "color 0.3s",
    "&, &.Mui-selected": {
      color: theme.palette.action.active,
      padding: theme.spacing(1),
      minWidth: 0, // Override min-width to maintain size consistency
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      position: "relative", // To position the pseudo-element
      "&::before": {
        // Pseudo-element for the highlight at the top
        content: '""',
        position: "absolute",
        top: 0, // Position at the top
        left: "50%",
        transform: "translateX(-50%)",
        width: "70%",
        height: "3px",
        backgroundColor: theme.palette.primary.main,
        // borderRadius: "2px",
      },
    },
  },
  "& .MuiBottomNavigationAction-label": {
    fontSize: "0.75rem", // Smaller label size for a more minimalist look
    transition: "font-size 0.2s",
    "&.Mui-selected": {
      fontSize: "0.75rem", // Maintain the same size even when selected
    },
  },
}));

const BottomNav = ({ tabs, selectedTab, setTab, mobileOnlyTabs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Filtering tabs based on mobile or desktop view
  const filteredTabs = tabs.filter((tab) => mobileOnlyTabs.includes(tab.label));

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: "10" }}
      elevation={3}
    >
      <StyledBottomNavigation
        value={selectedTab}
        onChange={(event, newValue) => setTab(newValue)}
        showLabels
      >
        {filteredTabs.map((tab) => (
          <BottomNavigationAction
            key={tab.label}
            label={tab.label}
            icon={selectedTab === tab.label ? tab.icon : tab.outlineIcon}
            value={tab.label}
            disabled={tab.disabled}
          />
        ))}
      </StyledBottomNavigation>
    </Paper>
  );
};

export default BottomNav;
