import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../providers/SnackbarProvider";
import { calculateVisits, createVisit } from "../services/memberServices";

import { Diversity1 } from "@mui/icons-material";
import moment from "moment-timezone";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import { useVisits } from "../providers/VisitProvider";
import { getRandomSalesMessage } from "../services/communicationServices";
import VisitSummary from "./VisitSummary";
import VisitPicker from "./scheduler/VisitPicker";

// Dummy data
const tasks = [
  "Outdoor",
  "Indoor",
  "Heavy Lifting",
  "Computer Help",
  "Errands",
];

const ScheduleVisitAdmin = ({ admin = false, handleClose = () => {} }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [taskInput, setTaskInput] = useState(""); // For storing user input
  const [selectedCategories, setSelectedCategories] = useState([]); // For storing selected categories
  const [notifyMember, setNotifyMember] = useState(true);
  const { showSnackbar } = useSnackbar();
  const { visitData, setVisitData } = useVisits();
  const [filters, setFilters] = useState({
    duration: 2,
    frequency: 0,
    employeeIds: [],
    numEmployees: "1",
  });

  const [randomSalesMessage, setRandomSalesMessage] = useState(
    getRandomSalesMessage()
  );

  // Additional state for selected employees
  const [loading, setLoading] = useState(false);

  const timeZoneId = visitData.timeZoneId;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const elevation = isMobile ? 4 : 0;

  // Additional states for selected date, filters, and available visits
  const [selectedDate, setSelectedDate] = useState();
  const [filteredVisits, setFilteredVisits] = useState([]); // filtered visits
  const [selectedVisit, setSelectedVisit] = useState({});

  // console.log("selectedVisit: ", selectedVisit);

  // Necessary to to keep a global concept of visits even when component is not mounted
  const [visits, setVisits] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // Corrected Object.keys usage
      if (
        visitData.employees &&
        Object.keys(visitData.employees).length > 0 &&
        visitData.timeZoneId
      ) {
        const newVisits = await calculateVisits(
          visitData.employees,
          visitData.timeZoneId
        );
        setVisits(newVisits);
      }
    };

    fetchData();
  }, [visitData.employees, visitData.timeZoneId]); // timeZone

  const [availableVisits, setAvailableVisits] = useState(visits);

  useEffect(() => {
    // Skip the logic if on the confirmation page
    if (activeStep === 3 || loading) return;

    if (
      selectedVisit.key &&
      !visits.some(
        (visit) =>
          generateVisitKey(visitData.memberId, visit, filters.frequency) ===
          selectedVisit.key
      )
    ) {
      if (activeStep > 1) setActiveStep(1); // return to the scheduler screen
      setSelectedVisit({}); // Reset selectedVisit
      // setSelectedDate(null);
      showSnackbar("The selected visit is no longer available.", "warning");
    }
  }, [visits]);

  const handleNext = async () => {
    if (activeStep === 2) {
      try {
        setLoading(true);
        // console.log("selectedVisit.visit: ", selectedVisit.visit);
        // console.log("selectedVisit.key: ", selectedVisit.key);

        const filteredEmployeeMap = Object.keys(visitData.employees)
          .filter((key) => selectedVisit.visit.employeeIds.includes(key))
          .reduce((acc, key) => {
            acc[key] = visitData.employees[key];
            return acc;
          }, {});

        // console.log("address:", address);
        // console.log("frequency: ", filters.frequency);

        let sendSMS = true;
        let sendEmail = true;
        const payload = {
          key: selectedVisit.key,
          start: selectedVisit.visit.start,
          end: selectedVisit.visit.end,
          frequency: filters.frequency,
          employees: filteredEmployeeMap,
          member: visitData.member,
          address: visitData.address,
          taskInput: taskInput,
          tags: selectedCategories,
          sendSMS: sendSMS,
          sendEmail: sendEmail,
        };

        // If admin is calling, we manually set the notification settings and memberId
        if (admin) {
          payload.memberId = visitData.memberId;
          if (!notifyMember) {
            payload.sendSMS = false;
            payload.sendEmail = false;
          }
        } else {
          const privateData = visitData?.member?.private?.data;
          payload.sendSMS =
            privateData?.notifications?.bookingConfirmation?.sms;
          payload.sendEmail =
            privateData?.notifications?.bookingConfirmation?.email;
        }

        console.log("payload: ", payload);

        const response = await createVisit(payload);

        setActiveStep(3);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setActiveStep(2);
        setLoading(false);
        showSnackbar("Booking visit failed. Please try again.", "error");
        return;
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    // Here, you would typically call some backend service to analyze the input and select the matching categories
    const matchedCategories = tasks.filter((category) =>
      taskInput.toLowerCase().includes(category.toLowerCase())
    );
    setSelectedCategories(matchedCategories);
  }, [taskInput]);

  const handleCategoryClick = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const findBestMatch = () => {
    if (!visitData.employees) return;
    // Get the keys of the employees object
    const employeeKeys = Object.keys(visitData.employees);

    // If no categories are selected, default to the first employee on the list
    if (selectedCategories.length === 0 && employeeKeys.length > 0) {
      return employeeKeys[0]; // Returns the key of the first employee
    }

    let bestMatchKey = null;
    let maxMatchCount = 0;

    for (const key of employeeKeys) {
      let matchCount = 0;
      const employee = visitData.employees[key];

      console.log("==>employee: ", employee);

      for (const category of selectedCategories) {
        if (employee.skills.includes(category)) {
          matchCount++;
        }
      }

      if (matchCount > maxMatchCount) {
        maxMatchCount = matchCount;
        bestMatchKey = key;
      }
    }

    return bestMatchKey; // Return the key (ID) of the best match employee
  };

  let bestMatchEmployee = findBestMatch();

  console.log("visitData: ", visitData);

  const generateVisitKey = (memberId, visit, frequency) => {
    return `${memberId}-${visit.employeeIds.join("-")}-${visit.start}-${
      visit.end
    }-${frequency}-${visit.duration}`;
  };

  const salesMessage = () => {
    return (
      !admin && (
        <Alert
          icon={<Diversity1 fontSize="inherit" />}
          severity="warning"
          sx={{ my: 1, alignItems: "center" }}
        >
          {randomSalesMessage}
        </Alert>
      )
    );
  };

  return (
    <>
      <div id="scrollToTop" />

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {/* <Paper
          elevation={0}
          sx={{
            borderRadius: "15px",
            mb: 1,
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
              <StepLabel>Tasks</StepLabel>
            </Step>
            <Step>
              <StepLabel>Visit</StepLabel>
            </Step>
            <Step>
              <StepLabel>Review</StepLabel>
            </Step>
          </Stepper>
        </Paper> */}
        <Box>
          {activeStep === 0 && (
            <Box sx={{ mx: { xs: 0, sm: 6 } }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                How can we help?
              </Typography>
              {/* <Typography>How can we help?</Typography> */}
              <TextField
                multiline
                rows={4}
                label="Enter the tasks you need help with:"
                variant="outlined"
                fullWidth
                value={taskInput}
                onChange={(e) => setTaskInput(e.target.value)}
              />
              {/* <Typography sx={{ marginY: "16px" }}>
                Special categories
              </Typography>

              <Box
                display="flex"
                flexWrap="wrap"
                gap={1}
                sx={{ margin: "16px" }}
              >
                {tasks.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    clickable
                    color={
                      selectedCategories.includes(category)
                        ? "primary"
                        : "default"
                    }
                    onClick={() => handleCategoryClick(category)}
                  />
                ))}
              </Box> */}
            </Box>
          )}

          {activeStep === 1 && (
            <VisitPicker
              visits={visits}
              setVisits={setVisits}
              selectedVisit={selectedVisit}
              setSelectedVisit={setSelectedVisit}
              filters={filters}
              setFilters={setFilters}
              employees={visitData.employees}
              timeZoneId={visitData.timeZoneId}
              memberId={visitData.memberId}
              bestMatchEmployee={bestMatchEmployee}
              parentSelectedDate={
                selectedVisit ? selectedVisit?.visit?.start : null
              }
            />
          )}

          {activeStep >= 2 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: { xs: "auto", sm: "50%" },
                  px: { xs: 0, sm: 2 },
                  pt: 1,
                  boxSizing: "border-box",
                }}
              >
                {activeStep === 2 && !loading && (
                  <Box>
                    <Typography variant="h5" align="center">
                      Review and Book
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ pt: 1 }}>
                      Double check the details before booking.
                    </Typography>
                    {salesMessage()}
                    {/* Inline toggle for Notify Member */}
                    {/* {admin && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={notifyMember}
                            onChange={(event) =>
                              setNotifyMember(event.target.checked)
                            }
                          />
                        }
                        label="Notify Member"
                      />
                    )} */}
                  </Box>
                )}
                {loading && (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <CircularProgress size={24} color="inherit" />
                      <Typography variant="h5" align="center">
                        Booking your visit
                      </Typography>
                    </Box>
                    <Typography variant="body1" align="center" sx={{ pt: 1 }}>
                      This may take several seconds.
                    </Typography>
                    {salesMessage()}
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <ConfirmationBurst />
                      <Typography variant="h5" align="center">
                        Visit Confirmed
                      </Typography>
                    </Box>
                    <Typography variant="body1" align="center" sx={{ pt: 1 }}>
                      You can close this page now.
                    </Typography>
                    {salesMessage()}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: "#F5F5F5", // Light gray background
                  borderRadius: "15px",
                  px: 2,
                  py: 1,
                  mt: { xs: 2, sm: 0 },
                  width: { xs: "100%", sm: "50%" },
                  boxSizing: "border-box",
                }}
              >
                <VisitSummary
                  tasks={taskInput}
                  address={visitData.address.line1}
                  frequency={filters.frequency}
                  timeZoneId={timeZoneId}
                  start={moment(selectedVisit.visit.start).tz(timeZoneId)}
                  end={moment(selectedVisit.visit.end).tz(timeZoneId)}
                  employees={
                    visitData?.employees &&
                    Object.keys(visitData.employees)
                      .filter((key) =>
                        selectedVisit.visit.employeeIds.includes(key)
                      )
                      .reduce((acc, key) => {
                        acc[key] = visitData.employees[key];
                        return acc;
                      }, {})
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <DialogActions
        sx={{
          zIndex: 100,
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        {activeStep < 3 && (
          <>
            <Button
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: "60px",
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: "60px",
              }}
              disabled={
                (activeStep === 1 && Object.keys(selectedVisit).length === 0) ||
                loading
              }
            >
              {activeStep === 2 ? "Book Visit" : "Next"}
            </Button>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Divider />
            <Button
              variant="contained"
              color="primary"
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: "60px",
              }}
              onClick={() => {
                handleClose();
                setActiveStep(0);
              }}
            >
              Back to Dashboard
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default ScheduleVisitAdmin;
