import { CheckCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import component_img from "../../../assets/booth-family-photo.jpg";
import { Link } from "react-router-dom";

const FamilyPhoto = ({ handleScroll }) => {
  const bulletPoints = [
    {
      text: "We cherish our family roots and are dedicated to building a service where everyone is cared for like family.",
      Icon: CheckCircleOutline, // Replace with your chosen icon
    },
    {
      text: "Our family values drive us to connect seniors with trustworthy, dependable, and excellent employees.",
      Icon: CheckCircleOutline, // Replace with your chosen icon
    },
    {
      text: "Through these connections, we believe our experiences are enriched, and our society strengthens",
      Icon: CheckCircleOutline, // Replace with your chosen icon
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row-reverse" },
        alignItems: "center",
        maxWidth: "1000px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 2, sm: 6 },
        mt: { xs: 0, sm: 10 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 4 },
          flex: 1, // This allows the box to grow and take up equal space
          maxWidth: "100%", // Adjusts maxWidth to allow the box to grow
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h4.fontSize", sm: "h3.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
          }}
        >
          Our Family Values
        </Typography>
        <List>
          {bulletPoints.map(({ text, Icon }, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          disableElevation
          component={Link} // use the Link component as the root node of the Button
          to="/member/signup" // use 'to' instead of 'href' for react-router's Link
          sx={{
            width: { xs: "100%", sm: "300px" },
            height: "56px",
            textTransform: "none",
            mx: "auto",
          }}
        >
          Sign up
        </Button>
      </Box>
      <CardMedia
        component="img"
        image={component_img}
        alt="Family at senior event"
        sx={{
          width: { xs: "100%", sm: "50%" }, // Ensures the image takes up half the width on sm screens and up
          height: { sm: "auto" }, // Adjusts height automatically to maintain aspect ratio
          aspectRatio: { xs: "1 / 1", sm: "1 / 1" }, // Maintains aspect ratio on xs, adjusts on sm
          borderRadius: "15px",
          flex: 1, // This will allow it to grow and take up equal space
        }}
      />
    </Box>
  );
};

export default FamilyPhoto;
