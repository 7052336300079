import { CheckCircle, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import PhoneField from "../components/fields/PhoneField";
import { functions } from "../firebase";

const JobApplicationForm = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    age: "",
    zipCode: "",
    pursuingEducation: "", // Add this line
    personalInterests: "",
    communityInvolvement: "",
    missionAlignment: "",
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required.";

    if (!formData.phone || formData.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";

    tempErrors.email = /.+@.+\.[A-Za-z]+$/.test(formData.email)
      ? ""
      : "Email is not valid.";
    tempErrors.age =
      formData.age && formData.age > 0 ? "" : "Valid age is required.";
    tempErrors.zipCode =
      formData.zipCode && formData.zipCode.length === 5
        ? ""
        : "Valid Zip Code is required.";
    tempErrors.personalInterests = formData.personalInterests
      ? ""
      : "Personal Interests are required.";
    tempErrors.communityInvolvement = formData.communityInvolvement
      ? ""
      : "Community Involvement is required.";
    tempErrors.missionAlignment = formData.missionAlignment
      ? ""
      : "Alignment with Our Mission is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const submitJobApplication = httpsCallable(
          functions,
          "submitJobApplication"
        );
        await submitJobApplication(formData);
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting application", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 15 }}>
      <Typography align="center" variant="h4" component="h1" gutterBottom>
        Application Form
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ mt: 3, gap: 2, display: "flex", flexDirection: "column" }}
      >
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Personal Information
          </Typography>
          <Typography>
            Please fill out your personal details so we can get in contact.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            fullWidth
            label="Age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            type="number"
            error={!!errors.age}
            helperText={errors.age}
          />
        </Box>

        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          type="email"
          error={!!errors.email}
          helperText={errors.email}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <PhoneField
            phone={formData.phone}
            required={true}
            handleChange={handleChange}
            error={errors.phone}
          />
          <TextField
            fullWidth
            label="Zip Code"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
            type="number"
            error={!!errors.zipCode}
            helperText={errors.zipCode}
          />
        </Box>
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Continued education *
          </Typography>
          <Typography>
            Are you currently pursuing any higher level of education or
            training?
          </Typography>
        </Box>
        <TextField
          fullWidth
          // label="Continued Education"
          name="pursuingEducation"
          value={formData.pursuingEducation}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          error={!!errors.pursuingEducation}
          helperText={errors.pursuingEducation}
          placeholder="I am currently enrolled at the community college, studying business management. I also take acting lessons on the side."
        />
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Personal interests *
          </Typography>
          <Typography>
            Please describe your personal interests and hobbies.
          </Typography>
        </Box>
        <TextField
          fullWidth
          // label="Personal Interests"
          name="personalInterests"
          value={formData.personalInterests}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          error={!!errors.personalInterests}
          helperText={errors.personalInterests}
          placeholder="I love working out at the gym, hiking and camping, and playing guitar with my friends."
        />
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Community involvement *
          </Typography>
          <Typography>How are you involved in your community?</Typography>
        </Box>
        <TextField
          fullWidth
          placeholder="I volunteer at my local food bank, tutor high school students, and help with habitat for humanity."
          // label="Involvement in the Community"
          name="communityInvolvement"
          value={formData.communityInvolvement}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          error={!!errors.communityInvolvement}
          helperText={errors.communityInvolvement}
        />
        <Box>
          <Typography variant="h6" fontSize={"1rem"}>
            Mission alignment *
          </Typography>
          <Typography>
            How do you align with our mission of helping seniors?
          </Typography>
        </Box>
        <TextField
          fullWidth
          placeholder="I am passionate about meaningful, intergenerational relationships and believe in providing excellent and dependable help to strengthen my community."
          // label="Alignment with Our Mission"
          name="missionAlignment"
          value={formData.missionAlignment}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          error={!!errors.missionAlignment}
          helperText={errors.missionAlignment}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, height: "56px", textTransform: "none" }}
          disabled={loading || submitted}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : submitted ? (
            <>
              <CheckCircle sx={{ mr: 1 }} /> Success
            </>
          ) : (
            <>
              <Send sx={{ mr: 1 }} /> Submit
            </>
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default JobApplicationForm;
