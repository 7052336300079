import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Checkbox,
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  useTheme,
  DialogContent,
} from "@mui/material";

const NotificationsTab = ({ employeeId, initialNotifications }) => {
  const [notifications, setNotifications] = useState(initialNotifications);
  const theme = useTheme();

  const handleToggle = async (event) => {
    const { name, checked } = event.target;
    const [medium, type] = name.split("_");
    const updatedNotifications = {
      ...notifications,
      [type]: { ...notifications?.[type], [medium]: checked },
    };

    setNotifications(updatedNotifications);

    // Update in Firebase
    const employeeRef = doc(db, "employees", employeeId);
    try {
      await updateDoc(employeeRef, {
        notifications: updatedNotifications,
      });
    } catch (error) {
      console.error("Error updating notifications", error);
    }
  };

  const notificationTypes = [
    {
      type: "bookingConfirmation",
      title: "Booking Confirmation",
      description: "Receive updates when visits are booked.",
    },
    {
      type: "visitCancellations",
      title: "Cancellations",
      description: "Receive updates for cancellations.",
    },
    {
      type: "visitUpdates",
      title: "Updates",
      description: "Receive updates for visit changes.",
    },
    {
      type: "visitReminders",
      title: "Reminders",
      description: "Get reminders for upcoming visits.",
    },
    {
      type: "visitRatings",
      title: "Ratings",
      description: "Receive messages to rate past visits.",
    },
    // {
    //   type: "newsletters",
    //   title: "Newsletters",
    //   description: "Receive our monthly newsletters.",
    // },
    // {
    //   type: "promotions",
    //   title: "Promotions",
    //   description: "Get notified about new promotions and special offers.",
    // },
    // ... add more notification types as needed
  ];

  return (
    <DialogContent>
      <Box
        sx={{
          position: "sticky",
          top: -20,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
      >
        {/* Sticky header box */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
          sx={{
            bgcolor: "background.paper",
            height: "60px",
            marginTop: "-20px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="subtitle2">Type</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" align="center">
              SMS
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" align="center"></Typography>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      {notificationTypes.map(({ type, title, description }) => (
        <Grid container spacing={2} key={type} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "center" }}>
            <Checkbox
              checked={notifications?.[type]?.sms ?? false}
              onChange={handleToggle}
              name={`sms_${type}`}
            />
          </Grid>
          {/* <Grid item xs={3} sx={{ textAlign: "center" }}>
            <Checkbox
              checked={notifications?.[type]?.email || false}
              onChange={handleToggle}
              name={`email_${type}`}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Divider sx={{ marginY: "10px" }} />
          </Grid>
        </Grid>
      ))}
    </DialogContent>
  );
};

export default NotificationsTab;
