import { Box, Button, Checkbox, useTheme } from "@mui/material";
import React from "react";

const StyledChecklist = ({ items, checkedItems, onChange, columns = 1 }) => {
  const theme = useTheme();

  // Calculate the fraction of the width each button should take depending on the number of columns
  const buttonWidth = `${100 / columns}%`;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`, // Use grid layout to support multiple columns
        gap: 1,
        alignItems: "center",
        width: "100%",
        mx: "auto",
      }}
    >
      {items.map(({ name, Icon }) => (
        <Button
          key={name}
          fullWidth
          variant="outlined"
          onClick={() => onChange(name)}
          // startIcon={Icon && <Icon />}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderRadius: "15px",
            height: "48px",
            textTransform: "none",
            borderColor: checkedItems.includes(name)
              ? theme.palette.primary.main
              : theme.palette.grey[200],
            backgroundColor: "#fff",
            color: checkedItems.includes(name)
              ? theme.palette.primary.main
              : "inherit",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: checkedItems.includes(name)
                ? theme.palette.primary.main
                : "#adadad",
            },
          }}
        >
          <Checkbox
            checked={checkedItems.includes(name)}
            sx={{ p: 0, mr: 1 }}
            inputProps={{ "aria-label": name }}
          />
          <Box sx={{display: "flex", flexDirection: "row", mx: "auto"}}>
            {Icon && <Icon sx={{ mr: 1 }} />}
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>{name}</Box>
          </Box>
        </Button>
      ))}
    </Box>
  );
};

export default StyledChecklist;
