import React, { useState, useEffect } from "react";
import {
  Dialog,
  Slide,
  Button,
  Box,
  Card,
  Stack,
  CardContent,
  CardMedia,
  CardHeader,
  MobileStepper,
  IconButton,
  Typography,
  useTheme,
  Skeleton,
  useMediaQuery,
  Fade,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import SwipeableViews from "react-swipeable-views";
import { useEmployee } from "../providers/EmployeeProvider";
import { useMember } from "../providers/MemberProvider";
import ActionFooter from "./ActionFooter";

const AnnouncementContent = ({ userCollection, userId, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [announcements, setAnnouncements] = useState([]); // Local state to hold announcements data
  const [seenAnnouncements, setSeenAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for loading indication

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxSteps = announcements.length;
  const step = announcements[activeStep];

  const employeeContext = useEmployee();
  const memberContext = useMember();

  const { data, setData } =
    userCollection === "employees" ? employeeContext : memberContext;

  useEffect(() => {
    if (data.announcements) {
      setAnnouncements(data.announcements);
      setIsLoading(false);
    }
  }, [data.announcements]);

  const handleNext = () => {
    const seenUpdateId = announcements[activeStep].id;
    setSeenAnnouncements((prevSeen) =>
      Array.from(new Set([...prevSeen, seenUpdateId]))
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        // If it's the right arrow, proceed to the next step (if available)
        if (activeStep !== maxSteps - 1) {
          handleNext();
        }
      } else if (event.key === "ArrowLeft") {
        // If it's the left arrow, go back to the previous step (if available)
        if (activeStep !== 0) {
          handleBack();
        }
      }
    };

    // Adding the event listener when the component is mounted
    document.addEventListener("keydown", handleKeyDown);

    // Cleaning up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeStep, maxSteps, handleNext, handleBack]); // Dependencies for the useEffect hook

  const handleStepChange = (step) => {
    // Mark the announcement as seen when changing steps
    const seenUpdateId = announcements[step]?.id; // Safely trying to access the id
    if (seenUpdateId) {
      setSeenAnnouncements(
        (prevSeen) => Array.from(new Set([...prevSeen, seenUpdateId])) // Prevent duplicates
      );
    }
    setActiveStep(step);
  };

  return (
    <>
      {announcements.length > 0 && !isLoading && step.imgPath && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1, // Allows the box to take all available space pushing the button block to the bottom
              overflow: "auto", // In case the content overflows the container
              marginX: isMobile ? "8px" : "32px",
            }}
          >
            <Card
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                borderRadius: isMobile ? "0px" : "auto",
              }}
            >
              <SwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className="hideScrollbar" // <-- add this class to the necessary element
              >
                {announcements.map((step, index) => (
                  <Box key={index} sx={{ overflow: "hidden" }}>
                    <Box
                      sx={{
                        display: "flex", // establishes a flex container
                        justifyContent: "center", // centers items horizontally in the container
                        alignItems: "center", // centers items vertically in the container
                        marginTop: isMobile ? "48px" : "16px",
                        marginX: "auto",
                        marginBottom: isMobile ? "16px" : "8px",
                        width: "30vh", // set the maximum width
                        height: "30vh", // set the maximum height
                        overflow: "hidden", // ensure the image doesn't spill out
                        borderRadius: "50%", // this shapes the container to be a circle
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative", // Set the parent box to relative
                          width: "100%", // Take the full width of the parent container
                          height: "100%", // Take the full height of the parent container
                          overflow: "hidden", // Ensure no overflow from this container
                        }}
                      >
                        {/* Image also positioned absolutely */}
                        <CardMedia
                          component="img"
                          image={step.imgPath}
                          alt={`Step ${index + 1} content`}
                          sx={{
                            position: "absolute", // Positioned absolutely within the parent Box
                            top: 0, // Align to the top of the parent Box
                            left: 0, // Align to the left of the parent Box
                            height: "100%", // Full height of the container
                            width: "100%", // Full width of the container
                            objectFit: "cover", // Ensure the aspect ratio is maintained
                          }}
                        />
                      </Box>
                    </Box>

                    <CardHeader
                      title={<Typography variant="h6">{step.title}</Typography>}
                      sx={{ textAlign: "left", marginBottom: -3 }}
                    />
                    <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
                      <Typography variant="body1">{step.content}</Typography>
                    </CardContent>
                  </Box>
                ))}
              </SwipeableViews>
            </Card>
            <ActionFooter>
              <Button
                fullWidth
                sx={{
                  width: { xs: "100%", sm: "150px" },
                  height: { xs: "60px", sm: "auto" },
                }}
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                Go Back
              </Button>
            </ActionFooter>
          </Box>

          {/* Button Stepper Block */}

          {announcements.length > 1 && (
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {theme.direction === "rtl" ? (
                    <ArrowBackIosNewOutlined size="large" />
                  ) : (
                    <ArrowForwardIosOutlined size="large" />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <ArrowForwardIosOutlined size="large" />
                  ) : (
                    <ArrowBackIosNewOutlined size="large" />
                  )}
                </Button>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default AnnouncementContent;
