import { useTheme } from "@emotion/react";
import {
  Create,
  DesktopMacOutlined,
  Inventory,
  KeyboardDoubleArrowRight,
  LocalFlorist,
  NaturePeople,
  NoteAdd,
  PhotoCamera,
  Search,
  Sort,
  Storage,
  WbSunny,
} from "@mui/icons-material";
import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const tasks = [
  {
    id: 5,
    without: {
      intro: "Instead of...",
      text: "Pulling weeds",
      description:
        "You bend and kneel, pulling stubborn weeds in the hot sun. Your back aches after each section, feeling like you'd rather be tending the garden.",
      icon: <WbSunny />,
    },
    with: {
      intro: "Now you can...",
      text: "Pick flowers",
      description:
        "Weeds? Taken care of! You're now picking a beautiful bouquet, enjoying the beauty of your garden without the strain.",
      icon: <LocalFlorist />,
    },
  },
  {
    id: 6,
    without: {
      intro: "Rather than...",
      text: "Lifting heavy boxes",
      description:
        "The garage is cluttered, and you attempt to lift a heavy boxes from the top shelf. It feels heavier than you remember.",
      icon: <Inventory />,
    },
    with: {
      intro: "Have the freedom to...",
      text: "Sort through items",
      description:
        "Heavy lifting? Done by us. We're now sorting through items with ease, deciding what to keep and what to part with.",
      icon: <Search />,
    },
  },
  {
    id: 7,
    without: {
      intro: "Instead of...",
      text: "Computer frustration",
      description:
        "You navigate through countless photos, trying to transfer them from your phone to your computer. You're not sure what to do next.",
      icon: <DesktopMacOutlined />,
    },
    with: {
      intro: "Be free to...",
      text: "Take detailed notes",
      description:
        "Photo transfer? Complete! You're now have a page of detailed notes from our demonstration, feeling confident for next time.",
      icon: <Create />,
    },
  },
];

const TaskCard = ({ side, task }) => (
  <Box
    sx={{
      height: "100%",
      backgroundColor: side === "with" ? "hsla( 100, 100%, 95%, 1)" : "none",
      borderRadius: "15px",
      p: { xs: side === "with" ? 2 : "none", sm: 2 },
      mx: { xs: side === "with" ? -2 : "none", sm: 1 },
      mb: { xs: side === "with" ? 4 : "none", sm: 1 },
      mt: { xs: side === "with" ? 1 : "none", sm: 1 },
    }}
  >
    <Box>
      <Typography
        fontStyle={"italic"}
        // fontWeight={"600"}
        align="center"
        color="textSecondary"
      >
        {task[side].intro}
      </Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {task[side].icon}

        <Typography variant="h6" component="div">
          {task[side].text}
        </Typography>
      </Box>
      <Typography variant="body2">{task[side].description}</Typography>
    </Box>
  </Box>
);

const CompareBeforeAfter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ p: 4, maxWidth: "900px", mx: "auto" }}>
      <Typography variant="h3" sx={{ mb: 3 }} align="center">
        Free Your Time
      </Typography>
      <Typography
        align="center"
        variant="h6"
        fontWeight="400"
        sx={{ mb: 2, maxWidth: "500px", mx: "auto" }}
      >
        "Life as usual" just got better! Linked Lives helps you enjoy the best
        aspects of your hobbies.
      </Typography>
      {/* Title */}
      <Divider sx={{ mb: 4 }} /> {/* Divider for aesthetic separation */}
      <Grid container spacing={1} alignItems="center">
        {tasks.map((task) => (
          <React.Fragment key={task.id}>
            <Grid item xs={12} md={5}>
              <TaskCard side="without" task={task} />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <KeyboardDoubleArrowRight
                  sx={{
                    transform: isMobile ? "rotate(90deg) scale(2)" : "scale(2)",
                    mx: "auto",
                  }}
                />{" "}
                {/* Arrow icon pointing back */}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <TaskCard side="with" task={task} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default CompareBeforeAfter;
