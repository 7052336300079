import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // Example for dollars
import LocalActivityIcon from "@mui/icons-material/LocalActivity"; // Example icon, replace with appropriate ones
import PercentIcon from "@mui/icons-material/Percent"; // Example for percentage
import StarIcon from "@mui/icons-material/Star"; // Example for hours
import Alert from "@mui/material/Alert";
import moment from "moment-timezone";
import React from "react";
import { useMember } from "../providers/MemberProvider";

const DiscountsBanner = () => {
  const { data } = useMember();
  const discounts = data?.member?.admin?.data?.discounts;
  const showDiscounts = discounts && Object.keys(discounts).length > 0;
  const timeZoneId = data?.member?.timeZoneId || "America/Los_Angeles";

  // Function to get the appropriate icon for each discount type
  const getIconForDiscount = (discount) => {
    switch (discount.type) {
      case "hours":
        return <StarIcon />;
      case "percent":
        return <PercentIcon />;
      case "dollars":
        return <AttachMoneyIcon />;
      default:
        return <LocalActivityIcon />; // Default icon
    }
  };

  return (
    <>
      {showDiscounts &&
        Object.entries(discounts)
          .filter(
            ([, discount]) =>
              !discount.used &&
              (!discount.expiration ||
                (discount?.expiration &&
                  moment(discount?.expiration?.toDate()).tz(timeZoneId) >
                    moment().tz(timeZoneId)))
          )
          .map(([key, discount]) => (
            <Alert
              key={key}
              severity="success"
              icon={getIconForDiscount(discount)}
              sx={{
                borderRadius: "0px",
                mb: "16px",
                mt: { xs: "-16px", sm: -3 },
                mx: { xs: "-16px", sm: -2 },
                cursor: "pointer",
              }}
            >
              {discount.description}
              {discount.expiration &&
                ` - Exp. ${moment(discount.expiration.toDate())
                  .tz(timeZoneId)
                  .format("MMM D")}`}
            </Alert>
          ))}
    </>
  );
};

export default DiscountsBanner;
