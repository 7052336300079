import React, { useEffect, useState } from "react";

import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";

const TaskMomGraduatedPricing = ({ handleScroll }) => {
  const [zipCode, setZipCode] = useState("");
  const [currentCount, setCurrentCount] = useState(0);
  const [monthlyFee, setMonthlyFee] = useState(0);

  // Function to calculate monthly fee based on current count
  const calculateMonthlyFee = (count) => {
    const tier =
      pricingTable.find((tier) => count <= tier.maxCount) ||
      pricingTable[pricingTable.length - 1];
    return tier.price;
  };

  useEffect(() => {
    // Fetch the zip code from localStorage when the component mounts
    const storedZipCode = localStorage.getItem("zipCode");
    if (storedZipCode) {
      setZipCode(storedZipCode);
    }

    // Assuming your counter is stored in a doc named '--counter--' with a field for each zip code
    const unsubscribe = onSnapshot(
      doc(db, "waitlist", "--counter--"),
      (doc) => {
        const data = doc.data();
        const zipCount = data.zipCodeCounts
          ? data.zipCodeCounts[zipCode] || 0
          : 0;
        setCurrentCount(zipCount);
        setMonthlyFee(calculateMonthlyFee(zipCount));
      }
    );

    return () => unsubscribe();
  }, [zipCode]);

  const handleZipCodeChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/[^\d]/g, "").substring(0, 5);
    setZipCode(formattedValue);
    // Store the zip code in localStorage whenever it changes
    localStorage.setItem("zipCode", formattedValue);
  };

  const deleteChip = () => {
    setZipCode(""); // Reset zip code
    localStorage.removeItem("zipCode"); // Also remove it from localStorage
  };

  const spotsRemainingInNextTier = () => {
    const nextTier = pricingTable.find((tier) => currentCount < tier.maxCount);
    return nextTier ? nextTier.maxCount - currentCount : 0;
  };

  // Define the perks array
  const plusPerks = [
    "Browse local helpers",
    "Unlimited visits per month",
    "Volunteer and premium helpers",
    "Same day and recurring visits",
    // "Send and receive messages",
    // "Google and iCalendar integration",
  ];

  const pricingTable = [
    { maxCount: 50, price: 0 },
    { maxCount: 100, price: 5 },
    { maxCount: 200, price: 6 },
    // Add more ranges as necessary
  ];

  const renderCardPricing = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            mb: 1,
          }}
        >
          {zipCode.length === 5 ? (
            <>
              <Typography
                align="center"
                variant="body1"
                fontWeight={"500"}
                sx={{ display: "flex", alignItems: "baseline" }}
              >
                Membership for{" "}
                <Chip
                  label={zipCode}
                  onDelete={deleteChip}
                  color="primary"
                  variant="outlined"
                  sx={{ ml: 1 }}
                />
              </Typography>

              <Typography align="center" variant="h4" fontSize={"1.75rem"}>
                ${monthlyFee} per month
              </Typography>
              <Typography
                align="center"
                variant="caption"
                fontWeight="400"
                sx={{ mx: "auto", maxWidth: "600px" }}
              >
                The next {spotsRemainingInNextTier()} signups grandfathered in
                at ${monthlyFee}/mo.
              </Typography>
            </>
          ) : (
            <>
              <Typography align="center" variant="body1" fontWeight={"600"}>
                Where are you located?
              </Typography>
              <TextField
                placeholder="Zip Code"
                variant="outlined"
                value={zipCode}
                onChange={handleZipCodeChange}
                inputProps={{
                  maxLength: 5,
                  style: { textAlign: "center" }, // Center text inside the input
                }}
                sx={{ mx: "auto", my: "auto" }}
              />
            </>
          )}
        </Box>
        <Box>
          <List sx={{ mb: 3 }}>
            {plusPerks.map((perk, index) => (
              <ListItem key={index} disableGutters disablePadding>
                <ListItemIcon sx={{ minWidth: "42px" }}>
                  <CheckCircle color="primary" />
                </ListItemIcon>
                <ListItemText primary={perk} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleScroll("signup")}
            sx={{
              width: { xs: "100%", sm: "300px" },
              height: "56px",
              textTransform: "none",
              mt: "auto",
              mx: "auto",
            }}
          >
            Sign up
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1100px",
        mx: { xs: 2, sm: "auto" },
        mb: { xs: 10, sm: 10 },
        mt: { xs: 0, sm: 6 },
        alignItems: "center",
      }}
    >
      {/* I want this Box below to scroll normally */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 0 },
          pt: { xs: 4, sm: 0 },
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
            maxWidth: { xs: "320px", sm: "650px" },
          }}
        >
          Discover Amazing Help
        </Typography>
        <Typography
          align="center"
          variant="h6"
          fontWeight="400"
          sx={{ mt: 1, mb: { xs: 0, sm: 2 }, mx: "auto" }}
        >
          Enter your zip code to view local membership prices.
        </Typography>
      </Box>
      {/* I want this HelperStack to become sticky in view */}
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Box
          sx={{
            borderTop: "2px solid hsl(190,100%,30%)",
            borderLeft: "2px solid hsl(190,100%,30%)",
            borderRight: "2px solid hsl(190,100%,30%)",
            borderBottom: "10px solid hsl(190,100%,30%)",
            borderRadius: "30px",
            margin: { xs: 1, sm: 2 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
            width: "auto",
            minWidth: { xs: "auto", sm: "350px" },
          }}
        >
          {renderCardPricing()}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskMomGraduatedPricing;
