import {
  Cake,
  Email,
  FileCopyOutlined,
  Home,
  Phone,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const MemberInfo = ({ memberData, privateData, accountData, adminData }) => {
  // Function to format the address
  const formatAddress = (address) => {
    const line1 = address?.line1 ? `${address?.line1}` : "";
    const line2 = address?.line2 ? `, ${address?.line2}` : "";
    const city = address?.city ? `, ${address?.city}` : "";
    const state = address?.state ? ` ${address?.state}` : "";
    const zipCode = address?.zipCode ? ` ${address?.zipCode}` : "";

    return `${line1}${line2}${city}${state}${zipCode}`;
  };

  const timeZoneId = memberData?.timeZoneId;
  const nextMembershipDate = memberData?.billing?.nextMembershipFeeDate;
  const membershipTier = adminData?.membershipTier;
  const defaultAddressKey = privateData?.defaultAddress;
  const address = accountData?.addresses?.[defaultAddressKey];
  const hourlyRate = adminData?.hourlyRate;
  const membershipFee = adminData?.membershipFee;

  const memberDetails = [
    {
      label: "Phone",
      data: privateData?.phone,
      icon: <Phone />,
    },
    {
      label: "Email",
      data: privateData?.email,
      icon: <Email />,
    },
    {
      label: "Address",
      data: formatAddress ? formatAddress(address) : address,
      icon: <Home />,
    },
  ];

  let planLabel, planTitle, chipColor;
  if (membershipTier === 0) {
    planTitle = "Basic";
    planLabel = `$${hourlyRate}/hr, no monthly fees`;
    chipColor = "success"; // Green for Basic
  } else if (membershipTier === 1) {
    planTitle = "Premium";
    planLabel = `$${hourlyRate}/hr + $${membershipFee}/mo`;
    chipColor = "primary"; // Blue for Premium
  }

  // Function to handle copying text to the clipboard
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Copied to clipboard");
        // Optionally, show a snackbar/notification here
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <DialogContent>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={4} textAlign={"center"}>
          <Avatar
            src={memberData?.avatarUrl}
            alt={`${memberData?.firstName} ${memberData?.lastName}`}
            sx={{ mx: "auto", width: 128, height: 128 }}
          />
          <ListItemText
            primary={
              memberData?.firstName ? (
                `${memberData.firstName} ${memberData.lastName}`
              ) : (
                <Skeleton width="150px" />
              )
            }
            sx={{ display: "inline-block" }}
          />
          {planTitle && (
            <Alert
              icon={false}
              color={chipColor}
              sx={{
                mb: 1,
                borderRadius: "10px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <AlertTitle sx={{ display: "flex", alignItems: "flex-start" }}>
                {planTitle} Member
              </AlertTitle>
              <Typography variant="body2" align="left">
                {planLabel}{" "}
                {nextMembershipDate &&
                  `| Next cycle: ${moment(nextMembershipDate.toDate())
                    .tz(timeZoneId)
                    .format("MMM Do")}`}
              </Typography>
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          <List>
            {memberDetails.map(({ label, data, icon }) => (
              <ListItem key={label} divider fullWidth>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={label}
                  secondary={data ? data : <Skeleton width="150px" />}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleCopy(data)}
                    disabled={!data}
                  >
                    <FileCopyOutlined />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem key={"member-bday"} divider fullWidth>
              <ListItemIcon>
                <Cake />
              </ListItemIcon>
              <ListItemText
                primary={"Joined"}
                secondary={moment(
                  memberData?.admin?.created
                    ? memberData?.admin?.created?.toDate()
                    : adminData?.created?.toDate()
                ).format("MMMM D, YYYY")}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default MemberInfo;
