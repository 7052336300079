import moment from "moment-timezone";

export const formatDate = (date) => {
  return moment(date).format("dddd, MMM Do");
};

export const formatTimeRange = (startDate, endDate) => {
  const startTime = moment(startDate).format("h:mm A");
  const endTime = moment(endDate).format("h:mm A");
  return `${startTime} - ${endTime}`;
};

export const formatAddress = (address) => {
  let formattedAddress = address.line1; // Start with line 1, which presumably always exists

  if (address.line2) {
    formattedAddress += `, ${address.line2}`; // Add line 2 if it exists
  }

  // Continue adding components with assured existence, separated by commas
  formattedAddress += `, ${address.city}`;
  formattedAddress += `, ${address.state}`;

  // Assuming zipCode always exists as well
  formattedAddress += ` ${address.zipCode}`;

  return formattedAddress;
};

// drop the :00 for a time string
export const formatTime = (time, timeZoneId) => {
  const formattedTime = moment(time.toDate()).tz(timeZoneId).format("h:mma");
  return formattedTime.replace(":00", "");
};

export const formatDateRange = (eventDays) => {
  const dates = Object.values(eventDays).map((day) => ({
    start: moment(day.startTime.toDate()),
    end: moment(day.endTime.toDate()),
  }));

  if (dates.length === 0) return "Dates not set";

  // Find the earliest start date and the latest end date
  const earliestStart = moment.min(dates.map((date) => date.start));
  const latestEnd = moment.max(dates.map((date) => date.end));

  if (earliestStart.isSame(latestEnd, "day")) {
    return earliestStart.format("MMMM Do"); // Single day event
  } else if (earliestStart.isSame(latestEnd, "month")) {
    // If start and end dates are in the same month
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format("Do")}`;
  } else {
    // If start and end dates are in different months
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format(
      "MMMM Do"
    )}`;
  }
};

export const formatTimeRangeCompact = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? " a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""} a`
  );

  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const formatTimeRangeCompactListItem = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? "a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""}a`
  );

  return `${startTimeFormatted} to ${endTimeFormatted}`;
};