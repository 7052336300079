import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Check, CheckCircle, ContentCopy, Support } from "@mui/icons-material"; // This is a gear icon in MUI
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { db, functions } from "../../../firebase";
import SocialMediaIcon from "../../../icons/SocialMediaIcon";

const SpinningIcon = styled(Support)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 5s linear infinite;
`;

function WaitlistDialog() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [waitlistNumber, setWaitlistNumber] = useState(null);
  const [referralCount, setReferralCount] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [referrerId, setReferrerId] = useState(null);

  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    zipCode: "",
  });
  const [errors, setErrors] = useState({});

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleZipChange = (e) => {
    // Only keep digits and limit to the first 5
    let value = e.target.value.replace(/\D/g, "").slice(0, 5);
    // Update the event's value before passing it to handleChange
    e.target.value = value;
    handleChange(e);
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateStep = () => {
    let tempErrors = {};
    if (!values.name) tempErrors.name = "Name is required";
    if (!values.email || !isValidEmail(values.email))
      tempErrors.email = "You must enter a valid email";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";

    // Add validation for zipCode not to equal to 5 digits
    if (!values.zipCode || values.zipCode.length !== 5)
      tempErrors.zipCode = "Zip code must be 5 digits.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const listItems = [
    { text: "Improve your family’s wellness" },
    { text: "Enjoy breathing room in your day" },
    { text: "Offload exhausting tasks" },
    // { text: "Declutter your living spaces", Icon: Storage }, // Icon representing organization or decluttering
    // { text: "Master your mealtime routines", Icon: Kitchen }, // Icon for cooking or meal prep
    // { text: "Streamline your local errands", Icon: ShoppingCart }, // Icon for errands or shopping assistance
  ];

  useEffect(() => {
    let unsubscribe = () => {};

    // Set initial state from localStorage
    const waitlistInfo = localStorage.getItem("waitlistInfo");
    if (waitlistInfo) {
      const { email, waitlistNumber, uniqueId } = JSON.parse(waitlistInfo);
      setValues((prev) => ({ ...prev, email: email }));
      setWaitlistNumber(waitlistNumber);
      setUniqueId(uniqueId);
    } else {
      // If not found in localStorage, check the URL for a 'id' parameter
      const queryParams = new URLSearchParams(window.location.search);
      const docId = queryParams.get("id");
      if (docId) {
        // Fetch the document by ID from Firestore and update the state
        const docRef = doc(db, "waitlist", docId);
        unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              // Assuming 'email', 'waitlistNumber', and 'uniqueId' are the fields you want
              setValues((prev) => ({ ...prev, email: data.email }));
              setWaitlistNumber(data?.localWaitlistNumber);
              setUniqueId(docSnap.id);
              // Optionally, you can also update localStorage here if needed
              localStorage.setItem(
                "waitlistInfo",
                JSON.stringify({
                  email: data.email,
                  waitlistNumber: data?.localWaitlistNumber,
                  uniqueId: docSnap.id,
                })
              );
            } else {
              console.log("Document not found with ID:", docId);
            }
          },
          (error) => {
            console.error("Error fetching document:", error);
          }
        );
      }
    }

    // Parse the URL for a referral ID
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get("ref");
    if (refId) {
      setReferrerId(refId);
    }
    // Subscribe to Firestore updates if uniqueId is available
    if (uniqueId) {
      const waitlistRef = doc(db, "waitlist", uniqueId);
      unsubscribe = onSnapshot(
        waitlistRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setWaitlistNumber(data?.localWaitlistNumber);
            setReferralCount(data.referralCount || null);
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error fetching waitlist data:", error);
        }
      );
    }

    setIsFetching(false);

    return () => unsubscribe(); // Cleanup on unmount or uniqueId change
  }, [uniqueId]);

  const handleSubmit = async () => {
    window.location.href = "https://task-mom.com";

    if (!validateStep()) return;
    setLoading(true);

    const addToWaitlist = httpsCallable(functions, "addToWaitlist");
    try {
      const result = await addToWaitlist({ values, referrerId }); // Pass referrerId
      setWaitlistNumber(result.data?.localWaitlistNumber);
      setUniqueId(result.data.uniqueId);
      setLoading(false);
      setDialogOpen(true);

      localStorage.setItem(
        "waitlistInfo",
        JSON.stringify({
          email: values.email,
          waitlistNumber: result.data.waitlistNumber,
          localWaitlistNumber: result.data?.localWaitlistNumber,
          uniqueId: result.data.uniqueId,
        })
      );
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setLoading(false);
    }
  };

  // New state variables for FAB text and icon
  const [fabText, setFabText] = useState("Share Link");
  const [fabIcon, setFabIcon] = useState(<ContentCopyIcon />);
  const [fabColor, setFabColor] = useState("primary"); // To manage FAB color dynamically

  const handleCopyLink = () => {
    const referralLink = `https://ourlinkedlives.com/taskmom?ref=${uniqueId}`;
    navigator.clipboard.writeText(referralLink);
    // Update FAB to show copied state
    setFabText("Link Copied!");
    setFabIcon(<Check />);
    setFabColor("success"); // Change color to indicate success

    // Reset FAB after a delay
    setTimeout(() => {
      setFabText("Share Link");
      setFabIcon(<ContentCopy />);
      setFabColor("primary");
    }, 3000); // Reset after 3 seconds
  };

  if (isFetching)
    return (
      <Box sx={{ gap: 1, my: 2 }}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 1,
          // border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "15px",
          alignItems: "center",
          p: { xs: 2, sm: 4 },
          mb: { xs: 10, sm: 10 },
          mt: { xs: 0, sm: 8 },
        }}
      >
        {waitlistNumber === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mx: "auto",
              gap: { xs: 2, sm: 8 },
              width: "100%",
              alignItems: "center",
              height: "augo",
              maxWidth: "800px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                align="center"
                variant={"h2"}
                gutterBottom
                sx={{
                  fontSize: { xs: "3rem", sm: "3.75rem" },
                  mt: 4,
                }}
              >
                {/* Take the first step */}
                Get Started
              </Typography>
              <Typography
                align="center"
                variant="body1"
                sx={{ mb: { xs: 2, sm: 4 } }}
              >
                Take a step towards amazing help and get your first month of
                TaskMom Plus for free!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxWidth: "500px",
                  mx: "auto",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 1,
                  }}
                >
                  <TextField
                    name="name" // Add this line
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <TextField
                    name="zipCode"
                    id="zipCode"
                    label="Zip Code"
                    type="tel" // Encourages a numeric keypad
                    inputMode="numeric" // Suggests a numeric keyboard on devices that support inputMode
                    fullWidth
                    variant="outlined"
                    value={values.zipCode}
                    onChange={handleZipChange}
                    error={!!errors.zipCode} // Show error state if there's an error for zipCode
                    helperText={errors.zipCode} // Display the actual error message
                  />
                </Box>
                <PhoneField
                  phone={values.phone}
                  error={errors.phone}
                  handleChange={handleChange}
                />
                <TextField
                  sx={{ width: "100%" }}
                  label="Email Address"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="name@email.com"
                  disabled={loading}
                  error={!!errors.email}
                  helperText={errors.email}
                /> */}
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    minWidth: "150px",
                    textTransform: "none",
                    height: "56px",
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Typography variant="body1">Sign Up</Typography>
                  )}
                </Button>
              </Box>
            </Box>
            {!isMobile && (
              <Box>
                <Box
                  sx={{
                    backgroundColor: "hsla( 100, 100%, 95%, 1)",
                    p: 4,
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    minWidth: "300px",
                    gap: 2,
                    borderTop: "2px solid hsla( 100, 50%, 80%, 1)", // Stronger top border
                    borderLeft: "2px solid hsla( 100, 50%, 80%, 1)",
                    borderRight: "2px solid hsla( 100, 50%, 80%, 1)",
                    borderBottom: "10px solid hsla( 100, 50%, 80%, 1)",
                  }}
                >
                  {listItems.map((item, index) => (
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ minWidth: "38px" }}>
                        <CheckCircle color="success" />
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <>
            <Box
              sx={{
                maxWidth: "1100px",
                mx: "auto",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: { xs: 2, sm: 4 },
              }}
            >
              <Box sx={{ width: { xs: "auto", sm: "50%" } }}>
                <Typography
                  align="center"
                  variant="h6"
                  sx={{ mt: 1 }}
                  gutterBottom
                  color="primary"
                  fontWeight={"400"}
                >
                  You are #{waitlistNumber} in your area!
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    color: "gray", // or a specific gray color code like '#888' or 'rgba(128, 128, 128, 0.8)'
                  }}
                >
                  Thank you so much for signing up. Help your community reach
                  the 20 signups needed to launch by sharing your link with a
                  friend!
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // mx: "auto",
                    }}
                  >
                    <Avatar
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Folivia-pic.jpg?alt=media&token=df724cf1-7464-485b-be74-5d387c10c242"
                      }
                      alt="Olivia Rodriguez"
                      sx={{ width: 64, height: 64 }}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="body1">Olivia Rodriguez</Typography>
                      <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                        Co-Founder
                      </Typography>
                      <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                        Anacortes, WA
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                <SpinningIcon />
              </Box> */}
              <Box
                sx={{
                  bgcolor: "#f5f5f5",
                  py: 2,
                  px: { xs: 2, sm: 4 },
                  width: { xs: "auto", sm: "50%" },
                  borderRadius: "30px",
                  mt: 2,
                  borderTop: "2px solid #e0e0e0", // Stronger top border
                  borderLeft: "2px solid #e0e0e0",
                  borderRight: "2px solid #e0e0e0",
                  borderBottom: "10px solid #e0e0e0",
                }}
              >
                <Typography
                  align="center"
                  variant="subtitle1"
                  fontSize={"2rem"}
                  fontWeight={"600"}
                  sx={{
                    mt: 1,
                    mx: "auto",
                    lineHeight: "1.15",
                  }}
                >
                  Share to Get Started
                </Typography>
                <Typography
                  align="center"
                  sx={{
                    color: "#5a5a5a", // or a specific gray color code like '#888' or 'rgba(128, 128, 128, 0.8)'
                    mt: 1,
                  }}
                >
                  Get TaskMom started in your community by sharing with friends
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    value={`https://ourlinkedlives.com/taskmom?ref=${uniqueId}`}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={handleCopyLink}>
                          <ContentCopyIcon />
                        </Button>
                      ),
                    }}
                    sx={{
                      backgroundColor: "white", // Light gray background
                    }}
                    variant="outlined"
                  />
                </Box>
                <Typography align="center" sx={{ my: 2 }}>
                  {referralCount != null ? (
                    <>
                      You invited{" "}
                      <strong>
                        {referralCount}{" "}
                        {referralCount === 1 ? "friend" : "friends"}
                      </strong>{" "}
                      to sign up!
                    </>
                  ) : (
                    <>
                      <strong>0 invites</strong> have joined with your link.{" "}
                      <br /> Check back soon!
                    </>
                  )}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                    mx: "auto",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SocialMediaIcon name={"iMessage"} />
                    <Typography sx={{ pb: "4px", ml: 1 }}>
                      Text your link in your friend chat
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <SocialMediaIcon name={"Facebook"} />
                    <Typography sx={{ pb: "4px", ml: 1 }}>
                      Share on a parenting Facebook page
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <SocialMediaIcon name={"Twitter"} />
                    <Typography sx={{ pb: "4px", ml: 1 }}>
                      Tweet your link to your followers
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Fab
              color={fabColor}
              variant="extended"
              onClick={handleCopyLink}
              sx={{
                position: "fixed",
                bottom: theme.spacing(2),
                left: theme.spacing(2),
                width: "fit-content",
                transition: "width 0.3s ease, background-color 0.3s ease",
              }}
            >
              {fabIcon}
              <Typography sx={{ ml: 1, textTransform: "none" }}>
                {fabText}
              </Typography>
            </Fab>
          </>
        )}
      </Box>
    </>
  );
}

export default WaitlistDialog;
