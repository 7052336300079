import { Typography, Box } from "@mui/material";
import React from "react";

const FooterSimple = () => {
  return (
    <Box display="flex" justifyContent="center" sx={{mt: 4, mb: 1}}>
      <Typography variant="body2" sx={{ opacity: 0.5 }}>
        © 2024 Linked Lives, LLC. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default FooterSimple;
